import { GET_CAPABILITIES, HIDE_WIDGET, OPEN_WIDGET, PAGE_NAVIGATION, PRELOAD, SET_CALLING_PROVIDER_SETTING, SET_CALL_METHOD, SET_CAPABILITIES, UPDATE_CALLEE_NUMBER, UPDATE_READY_STATE, UPDATE_THREAD_ID, UPDATE_IS_QUEUE_TASK, MINIMIZE_CALLING_WIDGET, ADD_EVENT_LISTENER, REMOVE_EVENT_LISTENER, TRIGGER_EVENT_LISTENER, ADD_CALLABLE_ASSOCIATION, REMOVE_CALLABLE_ASSOCIATION, SET_BROWSER_HISTORY, START_CALL, LEARN_MORE, REGISTER_FROM_NUMBER, ADD_PHONE_NUMBER, OPEN_THIRD_PARTY_WIDGET, HIDE_THIRD_PARTY_WIDGET, UPDATE_THIRD_PARTY_SUBJECT, ON_CALL_SAVED_THIRD_PARTY, CLOSE_THIRD_PARTY_DEMO_EXIT_CONFIRMATION, CHANGE_CALL_PROVIDER } from '../constants/MethodNames';
import * as getCallingCapabilities from './getCallingCapabilities';
import * as hideCallingWidget from './hideCallingWidget';
import * as preloadCallingWidget from './preloadCallingWidget';
import * as setCallingMethod from './setCallingMethod';
import * as setCallingCapabilities from './setCallingCapabilities';
import * as updateCallingReadyState from './updateCallingReadyState';
import * as updateCallingThreadId from './updateCallingThreadId';
import * as updateCalleeNumber from './updateCalleeNumber';
import * as callingHostPageNavigation from './callingHostPageNavigation';
import * as updateIsQueueTask from './updateIsQueueTask';
import * as openCallingWidget from './openCallingWidget';
import * as setCallingProviderSettings from './setCallingProviderSettings';
import * as minimizeCallingWidget from './minimizeCallingWidget';
import * as addCallingEventListener from './addCallingEventListener';
import * as removeCallingEventListener from './removeCallingEventListener';
import * as triggerCallingEventListener from './triggerCallingEventListener';
import * as addCallableAssociation from './addCallableAssociation';
import * as removeCallableAssociation from './removeCallableAssociation';
import * as setBrowserHistory from './setBrowserHistory';
import * as startCall from './startCall';
import * as learnMore from './learnMore';
import * as registerFromNumber from './registerFromNumber';
import * as addPhoneNumber from './addPhoneNumber';
import * as openThirdPartyWidget from './openThirdPartyWidget';
import * as hideThirdPartyWidget from './hideThirdPartyWidget';
import * as updateThirdPartySubject from './updateThirdPartySubject';
import * as onCallSavedThirdParty from './onCallSavedThirdParty';
import * as closeThirdPartyDemoExitConfirmation from './closeThirdPartyDemoExitConfirmation';
import * as changeCallProvider from './changeCallProvider';
let APIMethods = {};
const generateApiMethods = () => {
  APIMethods = {
    [OPEN_WIDGET]: openCallingWidget,
    [PRELOAD]: preloadCallingWidget,
    [SET_CAPABILITIES]: setCallingCapabilities,
    [GET_CAPABILITIES]: getCallingCapabilities,
    [SET_CALL_METHOD]: setCallingMethod,
    [UPDATE_READY_STATE]: updateCallingReadyState,
    [HIDE_WIDGET]: hideCallingWidget,
    [UPDATE_THREAD_ID]: updateCallingThreadId,
    [UPDATE_CALLEE_NUMBER]: updateCalleeNumber,
    [PAGE_NAVIGATION]: callingHostPageNavigation,
    [SET_CALLING_PROVIDER_SETTING]: setCallingProviderSettings,
    [UPDATE_IS_QUEUE_TASK]: updateIsQueueTask,
    [MINIMIZE_CALLING_WIDGET]: minimizeCallingWidget,
    [ADD_EVENT_LISTENER]: addCallingEventListener,
    [REMOVE_EVENT_LISTENER]: removeCallingEventListener,
    [TRIGGER_EVENT_LISTENER]: triggerCallingEventListener,
    [ADD_CALLABLE_ASSOCIATION]: addCallableAssociation,
    [REMOVE_CALLABLE_ASSOCIATION]: removeCallableAssociation,
    [SET_BROWSER_HISTORY]: setBrowserHistory,
    [START_CALL]: startCall,
    [LEARN_MORE]: learnMore,
    [REGISTER_FROM_NUMBER]: registerFromNumber,
    [ADD_PHONE_NUMBER]: addPhoneNumber,
    [OPEN_THIRD_PARTY_WIDGET]: openThirdPartyWidget,
    [HIDE_THIRD_PARTY_WIDGET]: hideThirdPartyWidget,
    [UPDATE_THIRD_PARTY_SUBJECT]: updateThirdPartySubject,
    [ON_CALL_SAVED_THIRD_PARTY]: onCallSavedThirdParty,
    [CLOSE_THIRD_PARTY_DEMO_EXIT_CONFIRMATION]: closeThirdPartyDemoExitConfirmation,
    [CHANGE_CALL_PROVIDER]: changeCallProvider
  };
};
generateApiMethods();
export default APIMethods;
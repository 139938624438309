export const getLocalStorageSupportsChannelConnection = () => {
  const defaultValue = false;
  if (!window.localStorage) {
    return defaultValue;
  }
  try {
    const supportsChannelConnection = window.localStorage.getItem('LocalSettings:Calling:supportsChannelConnection');
    if (!supportsChannelConnection || supportsChannelConnection === 'null' || supportsChannelConnection === 'undefined') {
      return defaultValue;
    }
    return supportsChannelConnection === 'true';
  } catch (error) {
    return defaultValue;
  }
};
import { useQuery } from 'data-fetching-client';
import { fetchObjectsQuery } from '../queries/framework-data';
import { useMemo } from 'react';
export const useObjects = () => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchObjectsQuery);
  const objectDefinitions = useMemo(() => {
    const definitions = new Map();
    if (data) {
      for (const objectDefinition of data.objects) {
        definitions.set(objectDefinition.fullyQualifiedName, objectDefinition);
        definitions.set(objectDefinition.objectTypeId, objectDefinition);
      }
    }
    return definitions;
  }, [data]);
  return {
    error,
    objectDefinitions,
    loading
  };
};
import http from 'hub-http/clients/apiClient';
const INVOICE_SETTINGS_ENDPOINT = 'invoices/v1/settings';
export const getInvoiceSettings = () => {
  return http.get(INVOICE_SETTINGS_ENDPOINT).then(response => ({
    initialPrefix: response.INVOICE_NUMBER_PREFIX.value,
    startingNumber: response.INVOICE_NUMBERING_START.value,
    generateSubscriptionInvoices: response.GENERATE_INVOICES_FOR_SUBSCRIPTIONS.value,
    canEditOpenInvoice: response.INVOICE_EDIT.value,
    canDeleteInvoice: response.ALLOW_INVOICE_DELETION.value,
    defaultEmail: response.DEFAULT_FROM_EMAIL_ADDRESS.value,
    allowOtherEmail: response.ALLOW_OTHER_EMAIL_ADDRESS.value,
    allowEmailReminders: response.ALLOW_EMAIL_REMINDERS.value,
    useCompanyBillingContactAssociationForAllInvoices: response.USE_COMPANY_BILLING_CONTACT_ASSOCIATION_FOR_ALL_INVOICES.value
  })).catch(error => {
    throw error;
  });
};
export const getInvoiceEmailSettings = () => {
  return getInvoiceSettings().then(settings => {
    return {
      defaultFromEmail: settings.defaultEmail,
      canSendFromOtherEmails: settings.allowOtherEmail
    };
  });
};
import { useCallback } from 'react';
const useInvoiceEditor = (onEditorClose, setInvoiceEditorOpen, setPreloadedInitialInvoiceData) => {
  const handleInvoiceEditorClose = useCallback(updatedInvoice => {
    setInvoiceEditorOpen(false);
    onEditorClose(updatedInvoice);
    setPreloadedInitialInvoiceData(undefined);
  }, [onEditorClose, setInvoiceEditorOpen, setPreloadedInitialInvoiceData]);
  return {
    handleInvoiceEditorClose
  };
};
export default useInvoiceEditor;
import unescapedText from 'I18n/utils/unescapedText';
export function getPricingModelLabel(pricingModel) {
  switch (pricingModel) {
    case 'volume':
      return {
        label: unescapedText('customerDataProperties.tieredPricing.modal.volumeBased.label'),
        help: unescapedText('customerDataProperties.tieredPricing.modal.volumeBased.help')
      };
    case 'graduated':
      return {
        label: unescapedText('customerDataProperties.tieredPricing.modal.graduated.label'),
        help: unescapedText('customerDataProperties.tieredPricing.modal.graduated.help')
      };
    case 'stairstep':
      return {
        label: unescapedText('customerDataProperties.tieredPricing.modal.stairStep.label'),
        help: unescapedText('customerDataProperties.tieredPricing.modal.stairStep.help')
      };
    default:
      return undefined;
  }
}
import PortalIdParser from 'PortalIdParser';
import { CALL_TYPE_ID, EMAIL_TYPE_ID, LEAD_TYPE_ID, MARKETING_EVENT_TYPE_ID, CAMPAIGN_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import enviro from 'enviro';
import { getFullUrl } from 'hubspot-url-utils';
const INDEX_PAGE_PATH = /\/contacts\/\d+\/objects\/\d+-\d+\/views\//;
export function getBaseUrl() {
  const subdomain = enviro.deployed() ? 'app' : 'local';
  return getFullUrl(subdomain); // app.hubspot.com/
}
export const getRecordPageHref = (objectTypeId, objectId, includeEschref) => {
  // If the current page is the index page, we don't want to include the eschref as it
  // is the fallback path with a dynamic return label
  // https://git.hubteam.com/HubSpot/card-preview-highlight-lib/blob/a1085771765ea71eb716f08b8e24c0f29cf9b77e/card-preview-highlight-lib/static/js/crm_ui/profile/common/components/IndexOrPageReturnLink.tsx#L21
  const isIndexPage = INDEX_PAGE_PATH.test(window.location.pathname);
  const eschref = includeEschref && !isIndexPage ? `?eschref=${encodeURIComponent(window.location.pathname)}` : '';
  return `${getBaseUrl()}/contacts/${PortalIdParser.get()}/record/${encodeURIComponent(objectTypeId)}/${encodeURIComponent(objectId)}${eschref}`;
};
const SUPPORTED_NON_RECORD_PAGE_OBJECT_TYPES = {
  [CALL_TYPE_ID]: (portalId, objectId) => `/calls/${portalId}/review/${encodeURIComponent(objectId)}`,
  [EMAIL_TYPE_ID]: (portalId, objectId) => `/go-to/${portalId}/${EMAIL_TYPE_ID}/${encodeURIComponent(objectId)}`,
  [LEAD_TYPE_ID]: (portalId, objectId) => `/prospecting/${portalId}/leads?leadId=${encodeURIComponent(objectId)}`,
  [MARKETING_EVENT_TYPE_ID]: (portalId, objectId) => `/marketing-events/${portalId}/details/${encodeURIComponent(objectId)}`,
  [CAMPAIGN_TYPE_ID]: (portalId, objectId) => `/go-to/${portalId}/${CAMPAIGN_TYPE_ID}/${encodeURIComponent(objectId)}`
};
export const isSupportedNonRecordPageObjectType = objectTypeId => {
  return Object.hasOwn(SUPPORTED_NON_RECORD_PAGE_OBJECT_TYPES, objectTypeId);
};
export const getNonRecordPageHref = (objectTypeId, objectId, includeEschref) => {
  const portalId = PortalIdParser.get();
  const getHref = SUPPORTED_NON_RECORD_PAGE_OBJECT_TYPES[objectTypeId];
  if (!getHref || !portalId) {
    return undefined;
  }
  const baseUrl = getBaseUrl();
  const href = getHref(portalId, objectId);
  const eschref = includeEschref ? `?eschref=${encodeURIComponent(window.location.pathname)}` : '';
  return `${baseUrl}${href}${eschref}`;
};
export const getRecordHref = (objectTypeId, objectId, includeEschref) => {
  var _getNonRecordPageHref;
  return (_getNonRecordPageHref = getNonRecordPageHref(objectTypeId, objectId, includeEschref)) !== null && _getNonRecordPageHref !== void 0 ? _getNonRecordPageHref : getRecordPageHref(objectTypeId, objectId, includeEschref);
};
import { HubUserAttributesClient } from 'frontend-preferences-client';

// Add new user settings as follows here:
// https://product.hubteam.com/docs/appsystems/frontend-preferences/frontend-preferences.html#i-want-to-store-data-in-a-new-key
export const HUB_USER_SETTINGS = {
  HAS_VIEWED_SPECIALISTS_CONTACT_INVOICES: 'CommerceGrowth:HasViewedSpecialistsContactModal:Invoices',
  HAS_VIEWED_SPECIALISTS_CONTACT_QUOTES: 'CommerceGrowth:HasViewedSpecialistsContactModal:Quotes',
  HAS_VIEWED_SPECIALISTS_CONTACT_SUBSCRIPTIONS: 'CommerceGrowth:HasViewedSpecialistsContactModal:Subscriptions',
  HAS_VIEWED_SPECIALISTS_CONTACT_PAYMENT_LINKS: 'CommerceGrowth:HasViewedSpecialistsContactModal:PaymentLinks'
};
export const hubUserAttributesClient = HubUserAttributesClient.forCaller('commerce-tools-ui-lib');
export const saveHubUserSetting = (key, value) => {
  return hubUserAttributesClient.write(key, value).catch(() => {});
};
export const fetchHubUserSetting = (key, defaultValue) => {
  return hubUserAttributesClient.fetch(key, defaultValue).catch(() => {});
};
import apiClient from 'hub-http/clients/apiClient';
// @ts-expect-error dependency missing types
import { catchAndRethrowNetworkError } from 'crm_data/bCommerce/monitoring/errorUtils';
import { registerQuery, useQuery } from 'data-fetching-client';
import { useEnrolledProcessor } from './usePaymentsAccountEligibility';
const fetchPaymentsAccountUnderwritingData = () => apiClient.get('payments/v1/payment-accounts/default/underwriting').catch(catchAndRethrowNetworkError);
export const GET_PAYMENTS_ACCOUNTS_UNDERWRITING_DATA = registerQuery({
  fieldName: 'paymentsAccountUnderwriting',
  fetcher: fetchPaymentsAccountUnderwritingData
});
const usePaymentsAccountUnderwritingData = options => {
  const {
    defaultProcessor,
    loading: defaultProcessorLoading
  } = useEnrolledProcessor();
  const {
    data,
    loading,
    error
  } = useQuery(GET_PAYMENTS_ACCOUNTS_UNDERWRITING_DATA, Object.assign({}, options, {
    skip: (options === null || options === void 0 ? void 0 : options.skip) || defaultProcessor !== 'HS_PAYMENTS'
  }));
  return {
    data,
    loading: defaultProcessorLoading || loading,
    error
  };
};
export default usePaymentsAccountUnderwritingData;
import { InvoiceOperation } from '../types/InvoiceOperation';
import { useInitialInvoice } from './useInitialInvoice';
import { usePropertyBlockList } from './usePropertyBlockList';
import { useEffect, useMemo } from 'react';
import { NO_DRAFT_ON_CREATE_GATE } from '../constants/gates';
import { useMutation as useDFCMutation } from 'data-fetching-client';
import { FETCH_INVOICE_WITH_AUTOSET_PROPERTIES } from '../graphQL/InvoicesEditorQueries';
export const usePreloadedInitialInvoiceData = ({
  objectId,
  hasAllGates,
  operation,
  skip,
  objectTypeId
}) => {
  const isUngatedToNoDraftOnCreate = hasAllGates(NO_DRAFT_ON_CREATE_GATE);
  const {
    propertyBlockList
  } = usePropertyBlockList();
  const initialInvoiceData = useInitialInvoice({
    objectId,
    objectTypeId,
    operation,
    propertyBlockList,
    hasAllGates
  }, {
    skip
  });
  const [getInvoiceWithAutosetProperties, {
    data: invoiceWithAutosetProperties,
    error: invoiceWithAutosetPropertiesError
  }] = useDFCMutation(FETCH_INVOICE_WITH_AUTOSET_PROPERTIES, {
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (skip || !isUngatedToNoDraftOnCreate || operation === InvoiceOperation.EDIT) {
      return;
    }
    if (initialInvoiceData.initialInvoice) {
      getInvoiceWithAutosetProperties({
        variables: {
          invoice: initialInvoiceData.initialInvoice
        }
      }).catch(() => {
        // handled in the useDFCMutation
      });
    }
  }, [getInvoiceWithAutosetProperties, isUngatedToNoDraftOnCreate, skip, initialInvoiceData.initialInvoice, operation]);
  const preloadedInitialInvoiceData = useMemo(() => {
    if (initialInvoiceData.error || !initialInvoiceData.initialInvoice || !initialInvoiceData.currencies) {
      return undefined;
    }
    if (!isUngatedToNoDraftOnCreate) {
      return {
        initialInvoice: initialInvoiceData.initialInvoice,
        numCompaniesRemoved: initialInvoiceData.numCompaniesRemoved,
        numContactsRemoved: initialInvoiceData.numContactsRemoved,
        currencies: initialInvoiceData.currencies
      };
    }
    if (operation === InvoiceOperation.EDIT) {
      return {
        initialInvoice: initialInvoiceData.initialInvoice,
        numCompaniesRemoved: initialInvoiceData.numCompaniesRemoved,
        numContactsRemoved: initialInvoiceData.numContactsRemoved,
        currencies: initialInvoiceData.currencies
      };
    } else {
      if (!invoiceWithAutosetProperties) {
        return undefined;
      }
      return {
        initialInvoice: initialInvoiceData.initialInvoice,
        numCompaniesRemoved: initialInvoiceData.numCompaniesRemoved,
        numContactsRemoved: initialInvoiceData.numContactsRemoved,
        currencies: initialInvoiceData.currencies,
        initialInvoiceWithAutosetProperties: invoiceWithAutosetProperties.invoiceWithAutosetProperties
      };
    }
  }, [initialInvoiceData, invoiceWithAutosetProperties, isUngatedToNoDraftOnCreate, operation]);
  const loading = useMemo(() => {
    if (skip) return false;
    if (isUngatedToNoDraftOnCreate) {
      return (!initialInvoiceData.initialInvoice || !initialInvoiceData.currencies || !invoiceWithAutosetProperties && operation !== InvoiceOperation.EDIT) && !initialInvoiceData.error;
    }
    return (!initialInvoiceData.initialInvoice || !initialInvoiceData.currencies) && !initialInvoiceData.error;
  }, [initialInvoiceData, skip, isUngatedToNoDraftOnCreate, invoiceWithAutosetProperties, operation]);
  return {
    preloadedInitialInvoiceData,
    loading,
    error: initialInvoiceData.error || !!invoiceWithAutosetPropertiesError
  };
};
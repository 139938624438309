/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { callingAPIMethodWithQueue } from 'calling-global-api/utils/hsCallingUtils';
export function addEventListener(event, callback) {
  callingAPIMethodWithQueue('addEventListener', {
    event,
    callback
  });
}
export function removeEventListener(event, callback) {
  callingAPIMethodWithQueue('removeEventListener', {
    event,
    callback
  });
}
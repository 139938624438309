export const ModalMessage = Object.freeze({
  OPEN_MODAL: 'INIT',
  CLOSE_MODAL: 'HIDE_MODAL',
  SUBMITTED_MODAL: 'MODAL_HIDDEN'
});

/** EmbeddedContext is passed to us from the iframe and acts as a messenger between this application and the iframe host.
 *
 * We don't manage the data in this object, it's passed to us and we only use it to send and receive messages.
 * Furthermore, this type is only capturing the parts we care about and is incomplete. */

/** HostMessage represents the data that comes out of IFrameContext.sendMessage.
 * We only really care about the payload type and values */
import { MAX_FRACTION_DIGITS } from '../../constants/FractionalCurrency';
import { getDecimalDigits } from '../currency';
import { roundDecimals } from './rounding';

/*
 * If we're converting from the same currency, we can support fractional currencies.
 * however, if we're converting different currency, we don't want to do math at extended
 * precision because it creates more potential for floating point bugs.
 */
function getCurrencyConversionPrecision(fromCurrencyCode, toCurrencyCode) {
  const toCurrencyDecimalsDigits = getDecimalDigits({
    currencyCode: toCurrencyCode
  });
  return fromCurrencyCode === toCurrencyCode ? MAX_FRACTION_DIGITS : toCurrencyDecimalsDigits;
}
export default function calculateExchangeRatePrice(multicurrencies, fromCurrencyCode, toCurrencyCode, initialPrice) {
  if (!multicurrencies) {
    return null;
  }
  const decimalDigits = getCurrencyConversionPrecision(fromCurrencyCode, toCurrencyCode);
  const fromInitialCurrency = multicurrencies.find(currency => currency.referencedObject.get('active') && currency.referencedObject.get('fromCurrencyCode') === fromCurrencyCode);
  const fromTargetCurrency = multicurrencies.find(currency => currency.referencedObject.get('active') && currency.referencedObject.get('fromCurrencyCode') === toCurrencyCode);
  if (!fromInitialCurrency || !fromTargetCurrency) {
    // The set of multicurrencies does not have the either the from OR target
    // currencies (eg. it may have been archived)
    return null;
  }
  const toHomeCurrencyMultiplier = fromInitialCurrency ? fromInitialCurrency.referencedObject.get('conversionMultiplier') : 1;
  const toTargetCurrencyMultiplier = 1 / fromTargetCurrency.referencedObject.get('conversionMultiplier');
  const untruncatedResult = initialPrice * toHomeCurrencyMultiplier * toTargetCurrencyMultiplier;
  return roundDecimals(untruncatedResult, decimalDigits);
}
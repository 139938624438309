import { Map as ImmutableMap } from 'immutable';
// @ts-expect-error module not typed
import { getInfoFromEmailAddressRecord } from 'conversations-thread-data/common-reply/public/operators/getInfoFromEmailAddressRecord';
import { buildEmailFromAddress } from './buildEmailFromAddress';
/**
 * @temporary this will be deleted in soon in favor of generic senders
 * @expiration 2025-04-15
 * @deprecated
 */
export const buildEmailMetadataWithFromAddress = (attachments, inReplyToMessage, additionalProps) => {
  const {
    channelInstances,
    connectedAccounts,
    defaultSendFrom
  } = additionalProps;

  // TODO - remove once we start using 'senders'
  const emailAddressRecord = buildEmailFromAddress({
    channelInstances,
    connectedAccounts,
    replyingToMessage: inReplyToMessage,
    defaultSendFrom
  });
  if (emailAddressRecord) {
    attachments['from'] = ImmutableMap(getInfoFromEmailAddressRecord(emailAddressRecord));
    attachments['connectedAccountAddress'] = emailAddressRecord.address;
  }
  return attachments;
};
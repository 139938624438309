import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["skip"];
import { registerQuery, useQuery } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
import { sortPaymentMethods, removePaymentMethodsWithAdditionalVerificationRequirements } from '../utils/ordering';
import { catchAndRethrowNetworkError
// @ts-expect-error dependency missing types
} from 'crm_data/bCommerce/monitoring/errorUtils';
import { useMemo } from 'react';
const fetchDefaultPaymentMethodsByCurrency = ({
  currencyCode
}) => apiClient.get(`commerce-payment-methods/v1/${currencyCode}`).then(paymentMethods => {
  return paymentMethods.sort(sortPaymentMethods).filter(removePaymentMethodsWithAdditionalVerificationRequirements).filter(({
    isAutomaticallySelected
  }) => isAutomaticallySelected).map(({
    paymentMethod
  }) => paymentMethod);
}).catch(catchAndRethrowNetworkError);
const GET_DEFAULT_PAYMENT_METHODS_BY_CURRENCY = registerQuery({
  fieldName: 'defaultPaymentMethods',
  args: ['currencyCode'],
  fetcher: fetchDefaultPaymentMethodsByCurrency
});
const useDefaultPaymentMethods = (currencyCode, _ref = {}) => {
  let {
      skip
    } = _ref,
    options = _objectWithoutPropertiesLoose(_ref, _excluded);
  const res = useQuery(GET_DEFAULT_PAYMENT_METHODS_BY_CURRENCY, Object.assign({
    skip: skip || !currencyCode,
    variables: {
      currencyCode
    }
  }, options));
  const defaultPaymentMethods = useMemo(() => {
    var _res$data$defaultPaym, _res$data;
    return (_res$data$defaultPaym = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.defaultPaymentMethods) !== null && _res$data$defaultPaym !== void 0 ? _res$data$defaultPaym : [];
  }, [res]);
  return Object.assign({}, res, {
    defaultPaymentMethods
  });
};
export default useDefaultPaymentMethods;
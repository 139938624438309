import { formatNumber } from 'I18n/utils/internal/light/numberFormatters';
function isScientificNotation(value) {
  return String(value).includes('e');
}

/**
 * Utilizes exponential notation to avoid toFixed rounding error where,
 * sometimes a decimal with a 5 is rounded down instead of up.
 * Cannot be used to increase precision
 *
 * @param {number} value
 * @param {number} decimalDigits
 *
 * @returns The rounded value factoring in the number of decimal digits
 */
export function roundDecimals(value, decimalDigits = 2) {
  if (isScientificNotation(value)) {
    return Number(formatNumber(value, {
      precision: decimalDigits
    }));
  }
  return Number(`${Math.round(Number(`${value}e${decimalDigits}`))}e-${decimalDigits}`);
}
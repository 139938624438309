import Raven from 'raven-js';
import { useMemo } from 'react';
import { fetchAssignees } from '../api/clients/fetchAssignees';
import { fetchAssignee } from '../api/fetchResponder';
import { DEFAULT_PAGINATION } from '../constants/reassignment-dropdown';
import { convertAssignableHumanToAssignee, convertRPCHumanToAssignee } from '../operators/assignees';
import { checkUserInAssignees } from '../operators/checkUserInAssignees';
import { buildAssigneeOptions, buildReassignmentOptions } from '../utils/build-options';
import { checkIsRPCAssigneeResponse, checkIsRPCAssigneesResponse } from '../api/clients/clientUtils';
import { useIsUngatedForGetAssigneeChirp } from '../../auth/hooks/useGates';
const getEmptyLoadOptions = (__filterValue, __callback, pagination = DEFAULT_PAGINATION) => Promise.resolve({
  options: [],
  pagination
});
const checkShouldFetchUser = (assignees, currentUserId, filterValue) => Boolean(!filterValue && currentUserId && assignees && !checkUserInAssignees(assignees, currentUserId));
const fetchCurrentUserAsAssignee = async (currentUserId, workspaceId, objectTypeId) => {
  const response = await fetchAssignee({
    agentId: currentUserId,
    userId: currentUserId,
    workspaceId,
    objectTypeId,
    inboxId: workspaceId
  });
  if (checkIsRPCAssigneeResponse(response)) {
    return convertRPCHumanToAssignee(response.assignee);
  }
  return convertAssignableHumanToAssignee(response);
};
const updateSections = ({
  currentUserId,
  filterValue,
  pagination,
  response,
  teams,
  routingSource
}) => {
  const {
    pagination: newPagination,
    assigneeOptions
  } = buildAssigneeOptions(response, pagination);
  const sections = buildReassignmentOptions({
    currentUserId,
    filterValue,
    hasMore: newPagination.hasMore,
    teams,
    users: assigneeOptions,
    routingSource
  });
  return {
    options: sections,
    pagination: newPagination
  };
};
const fetchAssigneeOptions = async ({
  currentUserId,
  filterValue,
  objectTypeId,
  offset,
  workspaceId,
  isUngatedForGetAssigneeChirp
}) => {
  const response = await fetchAssignees({
    objectTypeId,
    offset,
    searchQuery: filterValue,
    workspaceId
  });
  const assignees = checkIsRPCAssigneesResponse(response) ? response.results.map(convertRPCHumanToAssignee) : response.results.map(convertAssignableHumanToAssignee);
  const assigneesResponse = {
    assignees,
    hasMore: response.hasMore,
    offset: response.offset
  };
  if (checkShouldFetchUser(assignees, currentUserId, filterValue)) {
    try {
      const currentUserAsAssignee = await fetchCurrentUserAsAssignee(currentUserId, workspaceId, isUngatedForGetAssigneeChirp ? objectTypeId : undefined);
      assigneesResponse.assignees.push(currentUserAsAssignee);
    } catch (error) {
      Raven.captureException(error);
      return assigneesResponse;
    }
  }
  return assigneesResponse;
};
const getOptionsLoader = ({
  currentUserId,
  objectTypeId,
  teams,
  workspaceId,
  routingSource,
  isUngatedForGetAssigneeChirp
}) => (filterValue, __callback, pagination = DEFAULT_PAGINATION) => fetchAssigneeOptions({
  currentUserId,
  filterValue,
  objectTypeId,
  offset: pagination.offset,
  workspaceId,
  isUngatedForGetAssigneeChirp
}).then(response => updateSections({
  currentUserId,
  filterValue,
  pagination,
  response,
  teams,
  routingSource
})).catch(error => {
  Raven.captureException(error);
  return {
    options: [],
    pagination
  };
});
export const useLoadOptions = ({
  currentUserId,
  objectTypeId,
  teams,
  workspaceId,
  routingSource
}) => {
  const isUngatedForGetAssigneeChirp = useIsUngatedForGetAssigneeChirp();
  return useMemo(() => {
    if (!workspaceId) {
      return getEmptyLoadOptions;
    }
    return getOptionsLoader({
      currentUserId,
      objectTypeId,
      teams,
      workspaceId,
      routingSource,
      isUngatedForGetAssigneeChirp
    });
  }, [currentUserId, objectTypeId, teams, workspaceId, routingSource, isUngatedForGetAssigneeChirp]);
};
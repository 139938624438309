'use es6';

import { ApolloLink } from '@apollo/client';
import { createBatchedHubHttpLink } from 'apollo-link-hub-http';
import { createStack } from 'hub-http';
import { defaultTo } from 'hub-http/middlewares/core';
import hubapiStack from 'hub-http/stacks/hubapi';
import { earlyRequestLink } from './earlyRequestLink';
import { errorMonitoringLink } from './errorMonitoringLink';
export const apiLink = opts => {
  const apiStack = createStack(defaultTo('timeout', 60000), hubapiStack);
  return ApolloLink.from([earlyRequestLink, errorMonitoringLink, createBatchedHubHttpLink(apiStack)(opts)]);
};
import styled from 'foundations-theming/utils';
import SkeletonText from './SkeletonText';
export const ShinySkeletonText = styled(SkeletonText).withConfig({
  displayName: "ShinySkeletonText",
  componentId: "sc-1807h7k-0"
})(["background:", ";background:linear-gradient( to right,", ",", " 20%,", " 40% );background-size:1200px;"], ({
  theme
}) => theme.color['do-not-use-gypsum'], ({
  theme
}) => theme.color['do-not-use-gypsum'], ({
  theme
}) => theme.color['do-not-use-koala'], ({
  theme
}) => theme.color['do-not-use-gypsum']);
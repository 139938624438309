import { InMemoryCache } from '../../cache/InMemoryCache';
import identity from 'transmute/identity';

// This is a subset of the actual settings obj but its all that we care about within this usage.
// https://git.hubteam.com/HubSpot/lead-tracking/blob/4578f9c66d6a6e725881666eef118f530a2475f3/lead-tracking-lib/static/js/api/pipelineSettings.ts#L39
const fetchLeadSettings = (__unusedKey, httpClient) => {
  return httpClient.get('leads/v3/settings');
};
export const makeLeadSettingsCache = () => new InMemoryCache('LeadSettingsCache', fetchLeadSettings, identity);
export const cache = makeLeadSettingsCache();

// exported for unit test usage only
export const _resetCache = () => {
  cache.clear();
};
import unescapedText from 'I18n/utils/unescapedText';
const getTimeFrame = action => {
  if (action.activityFromDate && action.activityToDate) {
    return unescapedText('copilotToolkit.streamingMessage.summarizeActivityFeed.fromUntil', {
      activityFromDate: action.activityFromDate,
      activityToDate: action.activityToDate
    });
  }
  if (action.activityFromDate) {
    return unescapedText('copilotToolkit.streamingMessage.summarizeActivityFeed.fromDate', {
      activityFromDate: action.activityFromDate
    });
  }
  if (action.activityToDate) {
    return unescapedText('copilotToolkit.streamingMessage.summarizeActivityFeed.untilDate', {
      activityToDate: action.activityToDate
    });
  }
  return '';
};
export const summarizeActivityFeedActionTextParser = action => {
  const timeFrame = getTimeFrame(action);
  return unescapedText('copilotToolkit.streamingMessage.summarizeActivityFeed.baseText', {
    objectTypeLabel: action.objectTypeLabel.toLowerCase(),
    objectName: action.objectName,
    timeFrame
  });
};
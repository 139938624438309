import get from 'transmute/get';
import set from 'transmute/set';
const getProperty = property => view => get(property, view);

/**
 * @description Returns the userId of the creator of this custom view.
 */
export const getCreatedByUserId = getProperty('createdByUserId');

/**
 * @description Returns when the HydratedCustomView was createdAt. Note that the creation
 * time does not necessarily line up with when the HydratedCustomView completed processing.
 */
export const getCreatedAt = getProperty('createdAt');

/**
 * @description We generate some initial default views for user - this returns the
 * default view type for the given view. Null if it's not a default view, or
 * DefaultCustomViewType if it is a default view.
 */
export const getDefaultViewType = getProperty('defaultViewType');

/**
 * @description Returns if the custom view can be edited by a user
 */
export const getIsUserEditable = getProperty('isUserEditable');

/**
 * @description Returns the associated ListSeg list id
 */
export const getListId = getProperty('listId');

/**
 * @description Returns the associated ListSeg list processing state.
 */
export const getListProcessingState = getProperty('processingState');

/**
 * @description Returns the object property the custom view is sorting on
 */
export const getSortByProperty = getProperty('sortByProperty');

/**
 * @description Returns the customViewId for the custom view. Note this is not
 * the same as getId - customViewId is the number value id is derived from.
 */
export const getCustomViewId = getProperty('customViewId');

/**
 * @description Returns the timestamp for the last time the associated ListSeg list
 * had to refresh i.e. when the view filters were last changed and completed processing.
 * This value is null for newly created views until they complete processing.
 */
export const getLatestListRefreshEndedAt = getProperty('latestListRefreshEndedAt');

/**
 * @description Returns the FilterBranch for the given custom view. This is the
 * set of filters that define the associated ListSeg List.
 */
export const getFilterBranch = getProperty('filterBranch');

/**
 * @description A custom view can be user generated or a "default" view which we
 * provision as a starting point. This operator will return whether the given view
 * is a default view.
 */
export const isDefaultView = customView => {
  return !!getDefaultViewType(customView);
};

/**
 *
 * @description Determine whether a custom view is private to a user or not.
 */
export const isPrivateUserCustomView = customView => {
  return customView.viewShareInfo.viewShareType === 'USER';
};

/**
 *
 * @description Determine whether a custom view is private to a team or not.
 */
export const isPrivateTeamCustomView = customView => {
  return customView.viewShareInfo.viewShareType === 'TEAM';
};

/**
 *
 * @description Determine whether a custom view is shared or not.
 */
export const isSharedCustomView = customView => {
  return customView.viewShareInfo.viewShareType === 'ALL';
};
export const getObjectTypeId = getProperty('objectTypeId');
export const setCreatedByUserId = set('createdByUserId');
export const setDefaultViewType = set('defaultViewType');
export const setListProcessingState = set('processingState');
export const setSortByProperty = set('sortByProperty');
export const setIsUserEditable = set('isUserEditable');
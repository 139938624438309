import { useCallback, useMemo, useState } from 'react';
export const usePaginatedData = (items, initialItemsPerPage) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const offset = pageIndex * itemsPerPage;
  const numPages = Math.ceil(items.length / itemsPerPage);
  const page = useMemo(() => {
    const startIndex = (pageIndex - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items === null || items === void 0 ? void 0 : items.slice(startIndex, endIndex);
  }, [items, pageIndex, itemsPerPage]);
  const handleSetItemsPerPage = useCallback(updatedItemsPerPage => {
    setItemsPerPage(updatedItemsPerPage);

    // If the items per page results in the current page being out of bounds, set the current page to the expected last page
    const expectedNewTotalPageCount = Math.ceil(items.length / updatedItemsPerPage);
    if (pageIndex > expectedNewTotalPageCount) {
      setPageIndex(expectedNewTotalPageCount);
    }
  }, [items.length, pageIndex]);
  return [page, {
    itemsPerPage,
    numPages,
    offset,
    pageIndex,
    setPageIndex,
    setItemsPerPage: handleSetItemsPerPage
  }];
};
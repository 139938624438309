import { registerMutation, useMutation } from 'data-fetching-client';
import http from 'conversations-http/clients/http';
import { useCallback } from 'react';
const SET_AS_HOME_PAGE_VIEW = registerMutation({
  fetcher: ({
    workspaceId,
    viewId
  }) => http.post(`/conversations-custom-views/v1/settings/workspaces/${workspaceId}/defaultViews/${viewId}`),
  fieldName: 'setAsHomePageView'
});
export const useSetAsHomePageView = ({
  onSetHomePageView,
  workspaceId
}) => {
  const [_setHomePageView, {
    error,
    loading
  }] = useMutation(SET_AS_HOME_PAGE_VIEW);
  const setHomePageView = useCallback(({
    viewId,
    isDefaultCustomView
  }) => {
    if (!workspaceId) {
      return;
    }
    _setHomePageView({
      variables: {
        workspaceId,
        viewId
      }
    }).catch(() => {});
    onSetHomePageView === null || onSetHomePageView === void 0 || onSetHomePageView({
      isDefaultCustomView
    });
  }, [_setHomePageView, onSetHomePageView, workspaceId]);
  return {
    error,
    loading,
    setHomePageView
  };
};
import styled from 'foundations-theming/utils';
import { StyledButtonTextContainer, UnstyledButton } from '../Button/PrivateButton';
import { StyledTag } from '../Tag/Tag';
import { CheckboxWrapper } from '../Checkbox/Checkbox';
import { SpinnerOuter } from '../LoadingSpinner/LoadingSpinner';
import { StyledContentLabel } from '../Typeahead/TypeaheadResultsItem';
const commonSelectControlStyles = theme => ({
  backgroundColor: theme.color['fill-surface-raised'],
  color: theme.color['text-primary-default'],
  display: 'flex',
  lineHeight: '22px',
  width: '100%',
  alignItems: 'center',
  userSelect: 'none',
  cursor: 'inherit',
  height: 'auto',
  minHeight: '40px',
  verticalAlign: 'middle',
  textAlign: 'left',
  fontSize: theme.typography['body-200'].fontSize
});
const commonOptionStyles = theme => ({
  padding: theme.space['0'],
  [`& ${UnstyledButton}`]: {
    border: 0,
    borderRadius: theme.borderRadius['0'],
    color: theme.color['text-primary-default'],
    display: 'block',
    minHeight: '40px',
    paddingInline: theme.space['500'],
    paddingBlock: theme.space['200'],
    textAlign: 'left',
    width: '100%',
    fontSize: theme.typography['body-125'].fontSize,
    fontWeight: theme.typography['body-300'].fontWeight,
    '&:hover': {
      background: theme.color['fill-secondary-subtle'],
      color: theme.color['specialty-scrim']
    },
    '&:focus': {
      background: theme.color['fill-secondary-subtle'],
      color: theme.color['specialty-scrim']
    }
  },
  [`& ${StyledButtonTextContainer}:not(:has([data-loading="true"]))`]: {
    justifyContent: 'flex-start'
  },
  [`& ${CheckboxWrapper}`]: {
    minHeight: '40px'
  },
  '&:last-child': {
    borderRadius: theme.borderRadius['0']
  },
  '&[class*="is-selected"]': {
    backgroundColor: 'inherit',
    color: 'inherit'
  },
  '&[class*="is-focused"]': {
    background: theme.color['fill-secondary-subtle'],
    color: theme.color['specialty-scrim']
  },
  '&[class*="is-disabled"]': {
    [`& ${UnstyledButton}`]: {
      backgroundColor: `${theme.color['fill-surface-default']} !important`,
      borderColor: `${theme.color['border-secondary-disabled']} !important`,
      color: `${theme.color['text-secondary-disabled']} !important`,
      cursor: 'not-allowed',
      opacity: 0.5
    }
  }
});

/**
 * Used by the following components:
 * - Select
 * - SelectInput
 */
export const commonFocusStyles = {
  borderColor: 'rgba(0,208,228,0.5) !important',
  boxShadow: '0 0 4px 1px rgba(0,208,228,0.3), 0 0 0 1px #00d0e4 !important'
};
export const StyledTypeaheadResultsSpan = styled.span.withConfig({
  displayName: "Selectstyles__StyledTypeaheadResultsSpan",
  componentId: "sc-167nrg7-0"
})(({
  theme
}) => {
  return {
    WebkitTapHighlightColor: theme.color['specialty-scrim'],
    borderRadius: 'inherit',
    listStyle: 'none',
    margin: theme.space['0'],
    maxHeight: '220px',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.space['0'],
    position: 'relative'
  };
});
export const StyledMultiValueSpan = styled.span.withConfig({
  displayName: "Selectstyles__StyledMultiValueSpan",
  componentId: "sc-167nrg7-1"
})(({
  theme,
  $hasValueTags
}) => {
  return Object.assign({
    display: 'flex',
    flexWrap: 'wrap'
  }, $hasValueTags && {
    marginBlockStart: '-9px'
  }, {
    [`& ${StyledTag}`]: {
      marginBlockStart: '7px',
      '&:not(:last-child)': {
        marginInlineEnd: '5px'
      },
      [`& + ${StyledTag}`]: {
        marginInlineStart: theme.space['0']
      }
    }
  });
});
export const HiddenDiv = styled.div.withConfig({
  displayName: "Selectstyles__HiddenDiv",
  componentId: "sc-167nrg7-2"
})(({
  $open
}) => {
  return {
    '&&': {
      position: 'absolute',
      visibility: $open ? 'visible' : 'hidden',
      height: 0,
      width: 0,
      overflow: 'hidden'
    }
  };
});
export const StyledDropdownFooterContainer = styled.div.attrs({
  'data-dropdown-footer': true
})(({
  theme
}) => {
  return {
    paddingBlock: theme.space['400'],
    paddingInline: theme.space['500'],
    borderBlockStart: `${theme.borderWidth['100']} solid ${theme.color['border-primary-default']}`
  };
});
export const StyledSelectArrow = styled.div.withConfig({
  displayName: "Selectstyles__StyledSelectArrow",
  componentId: "sc-167nrg7-3"
})(({
  theme
}) => {
  return {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '6px',
    borderColor: theme.color['fill-transparent-default'],
    borderTopColor: theme.color['fill-secondary-default'] || '#00a4bd',
    marginBlockStart: '6px'
  };
});
export const StyledMultiValueWrapper = styled.div.withConfig({
  displayName: "Selectstyles__StyledMultiValueWrapper",
  componentId: "sc-167nrg7-4"
})(({
  theme
}) => {
  return {
    marginBlockEnd: `-${theme.space['25']}`,
    marginBlockStart: `-${theme.space['25']}`,
    minHeight: '40px',
    width: 'calc(100% - 33px)'
  };
});
export const StyledArrowZone = styled.div.withConfig({
  displayName: "Selectstyles__StyledArrowZone",
  componentId: "sc-167nrg7-5"
})(() => ({
  display: 'table-cell',
  marginInlineStart: '5px',
  // no token for 5px
  width: '28px'
}));
export const StyledSelectControl = styled.div.withConfig({
  displayName: "Selectstyles__StyledSelectControl",
  componentId: "sc-167nrg7-6"
})(({
  theme,
  $multi
}) => {
  return {
    '&&': Object.assign({}, commonSelectControlStyles(theme), $multi && {
      flexWrap: 'wrap',
      '& [class*="has-value-tags"]': {
        marginBlockStart: '-9px'
      },
      [`& ${StyledTag}`]: {
        display: 'inline-flex',
        flexShrink: '0',
        marginInlineStart: theme.space['200'],
        marginBlockStart: theme.space['200'],
        maxWidth: 'calc(100% - 16px)',
        verticalAlign: 'top',
        '&:last-of-type': {
          marginBlockEnd: theme.space['200']
        },
        '& [class*="Select-arrow-zone"], & [class*="Select-clear-zone"]': {
          paddingInlineEnd: $multi ? `${theme.space['400']} !important` : '',
          width: $multi ? '28px' : 'auto',
          display: $multi ? 'table-cell' : '',
          marginInlineStart: $multi ? '5px' : ''
        }
      }
    }, {
      '& [class*="Select-arrow-zone"] > [class*="Select-arrow"]': {
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: '6px',
        display: 'inline-block',
        verticalAlign: 'middle',
        borderTopColor: 'currentColor',
        marginBlockStart: '6px',
        borderBlockStartColor: theme.color['fill-secondary-default'],
        top: '0'
      }
    })
  };
});
export const StyledSelectPlusWrapper = styled.div.withConfig({
  displayName: "Selectstyles__StyledSelectPlusWrapper",
  componentId: "sc-167nrg7-7"
})(({
  theme,
  $multi,
  $cssStyles
}) => {
  return {
    '&&': Object.assign({
      borderRadius: '3px',
      cursor: 'pointer',
      width: '100%',
      [`& ${StyledContentLabel}`]: {
        lineHeight: '22px'
      },
      '& [class*="Select-control"]': commonSelectControlStyles(theme),
      //TODOVAR: add styles for maxHeight

      '& [class*="is-open"]': {
        cursor: 'text'
      },
      '& .Select-placeholder, &:not(.Select--multi) .Select-control .Select-value': {
        color: 'inherit',
        lineHeight: 'inherit',
        right: '32px',
        paddingBlock: '9px',
        paddingInline: theme.space['250']
      },
      '& [class*="Select--single"]': {
        '& [class*="Select-value"]': {
          position: 'relative'
        },
        [`&${StyledTag}`]: {
          cursor: 'inherit',
          marginBlockEnd: `-${theme.space['25']}`,
          marginBlockStart: `-${theme.space['25']}`
        },
        '& [class*="Select-value"] + [class*="Select-input"]': {
          left: '0',
          opacity: '0',
          position: 'absolute',
          top: '0'
        },
        [`& ${UnstyledButton}`]: {
          cursor: 'pointer'
        }
      },
      '& [class*="Select-option"]': commonOptionStyles(theme),
      '& [class*="Select-multi-value-wrapper"]': {
        marginBlockEnd: `-${theme.space['25']}`,
        marginBlockStart: `-${theme.space['25']}`,
        minHeight: '40px',
        width: 'calc(100% - 32px)'
      },
      '& [class*="Select-input"]': {
        flex: '1 1 auto',
        height: 'auto',
        '& > input': {
          paddingBlock: '9px',
          paddingInline: theme.space['250'],
          cursor: 'text',
          height: 'auto',
          lineHeight: '22px',
          paddingLeft: '0',
          '&:-moz-focusring': {
            outline: '0'
          }
        }
      },
      '& [class*="Select-clear-zone"]': {
        '&:hover': {
          color: 'inherit'
        }
      },
      '& [class*="Select-clear"]': {
        lineHeight: 'inherit'
      },
      '& [class*="Select-multi"]': {
        [`& ${StyledTag}`]: {
          display: 'inline-flex',
          flexShrink: '0',
          marginLeft: theme.space['200'],
          marginTop: theme.space['200'],
          maxWidth: 'calc(100% - 16px)',
          verticalAlign: 'top',
          '&:last-of-type': {
            marginBlockEnd: theme.space['200']
          }
        }
      },
      '& [class*="Select-arrow-zone"], & [class*="Select-clear-zone"]': {
        paddingInlineEnd: $multi ? `${theme.space['400']} !important` : '',
        width: $multi ? '28px' : 'auto',
        display: $multi ? 'table-cell' : '',
        marginInlineStart: $multi ? '5px' : ''
      },
      '& [class*="Select-arrow-zone"] > [class*="Select-arrow"]': {
        borderColor: theme.color['fill-transparent-default'],
        borderStyle: 'solid',
        borderWidth: '6px',
        display: 'inline-block',
        verticalAlign: 'middle',
        borderTopColor: 'currentColor',
        marginTop: '6px',
        borderBlockStartColor: theme.color['fill-secondary-default'],
        top: '0'
      },
      '& [class*="Select"]:not([class*="is-open"])': {
        '& [class*="Select-input"]': {
          cursor: 'pointer'
        }
      },
      '& [class*="is-focused"]:not([class*="is-open"]) > [class*="Select-control"]': commonFocusStyles,
      '&[class*="has-value"]:not([class*="Select--multi"]) > [class*="Select-control"] [class*="Select-value"] [class*="Select-value-label"]': {
        color: theme.color['fill-accent-neutral-pressed']
      },
      '&[class*="is-disabled"]': {
        '&:not([class*="is-readonly"]) > [class*="Select-control"]': {
          [`& ${UnstyledButton}`]: {
            backgroundColor: `${theme.color['fill-surface-default']} !important`,
            borderColor: `${theme.color['border-secondary-disabled']} !important`,
            color: `${theme.color['text-secondary-disabled']} !important`,
            cursor: 'not-allowed'
          },
          '& [class*="Select-arrow-zone"] > [class*="Select-arrow"]': {
            borderTopColor: theme.color['fill-tertiary-alt']
          },
          '& [class*="Select-value-label"]': {
            color: '#b0c1d4 !important'
          }
        }
      },
      '&[class*="is-readonly"] > [class*="Select-control"]': {
        [`& ${UnstyledButton}`]: {
          backgroundColor: `${theme.color['fill-surface-default']} !important`,
          borderColor: `${theme.color['border-secondary-disabled']} !important`,
          color: `${theme.color['text-secondary-disabled']} !important`,
          cursor: 'not-allowed'
        },
        '& [class*="Select-arrow-zone"] > [class*="Select-arrow"]': {
          display: 'none'
        }
      }
    }, $cssStyles)
  };
});
export const StyledDropdownResults = styled.div.withConfig({
  displayName: "Selectstyles__StyledDropdownResults",
  componentId: "sc-167nrg7-8"
})(({
  theme,
  $isVirtualized,
  $searchable,
  $anchorType,
  $menuHeight,
  $cssStyles
}) => {
  return {
    '&&': Object.assign({
      borderRadius: 'inherit'
    }, $menuHeight && {
      height: `${$menuHeight}px`
    }, {
      // Select container
      '& > div': {
        borderRadius: 'inherit'
      },
      // Menu outer wrapper
      '& [class*="Select-menu-outer"]': {
        border: 0,
        borderRadius: 'inherit',
        boxShadow: 'none',
        marginTop: theme.space['0'],
        maxHeight: 'none',
        position: 'relative',
        padding: `${theme.space['0']} !important`
      },
      // Menu
      '& [class*="Select-menu"]': {
        borderBottomLeftRadius: 'inherit',
        borderBottomRightRadius: 'inherit',
        maxHeight: $menuHeight ? `${$menuHeight}px` : $isVirtualized || $anchorType !== 'input' && $anchorType !== 'combined' && $searchable ? '220px' : '228px',
        paddingBottom: $isVirtualized || $anchorType !== 'input' && $anchorType !== 'combined' && $searchable ? theme.space['0'] : theme.space['200'],
        paddingTop: $isVirtualized || $anchorType !== 'input' && $anchorType !== 'combined' && $searchable ? theme.space['0'] : theme.space['200'],
        overflowY: 'auto'
      },
      [`& ${SpinnerOuter}`]: {
        marginInline: 'auto !important',
        transform: 'scale(1) !important' // Override default 0.75 scale to show spinner at normal size
      },
      // Options
      '& [class*="Select-option"]': commonOptionStyles(theme),
      '& [class*="is-focused"]:not([class*="is-open"]) > [class*="Select-control"]': commonFocusStyles,
      // Option group label
      '& [class*="Select-option-group-label"]': {
        margin: 0,
        minHeight: '40px',
        color: 'inherit',
        padding: '4px 0 0 !important',
        [`& ${UnstyledButton}`]: {
          fontWeight: theme.typography['heading-50'].fontWeight,
          fontSize: theme.typography['heading-50'].fontSize,
          display: 'block',
          lineHeight: 'normal',
          textTransform: 'none',
          cursor: 'default',
          '&:hover': {
            background: theme.color['fill-transparent-default']
          }
        }
      },
      // No results
      '& [class*="Select-noresults"]': {
        opacity: 0.5,
        backgroundColor: `${theme.color['fill-surface-default']} !important`,
        borderColor: `${theme.color['border-secondary-disabled']} !important`,
        color: `${theme.color['text-secondary-disabled']} !important`,
        cursor: 'not-allowed'
      },
      // Tags
      [`& ${StyledTag}`]: {
        cursor: 'inherit'
      },
      '& [class*="Select-option-group-label"] ~ [class*="Select-option-group"]': {
        padding: theme.space['0'],
        '& [class*="Select-option-group"]': {
          padding: theme.space['0']
        }
      },
      // TODOVAR: Look into padding for nested groups

      /* Base padding for options and group labels */
      '& [class*="Select-option-group"]': {
        '& > [class*="Select-option"], & > [class*="Select-option-group-label"]': {
          paddingInlineStart: theme.space['500']
        },
        /* First level nesting */
        '& [class*="Select-option-group"]': {
          '& > [class*="Select-option"], & > [class*="Select-option-group-label"]': {
            paddingInlineStart: theme.space['1000']
          },
          /* Second level nesting */
          '& [class*="Select-option-group"]': {
            '& > [class*="Select-option"], & > [class*="Select-option-group-label"]': {
              paddingInlineStart: theme.space['1300']
            },
            /* Third level nesting */
            '& [class*="Select-option-group"]': {
              '& > [class*="Select-option"], & > [class*="Select-option-group-label"]': {
                paddingInlineStart: '80px'
              },
              /* Fourth level nesting */
              '& [class*="Select-option-group"]': {
                '& > [class*="Select-option"], & > [class*="Select-option-group-label"]': {
                  paddingInlineStart: '100px'
                }
              }
            }
          }
        }
      }
    }, $cssStyles)
  };
});
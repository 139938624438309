import { HubProducts, ProductLevel } from '../types/products';
export const AllHubProducts = Object.values(HubProducts);
const productLevelOrder = [ProductLevel.NONE, ProductLevel.STARTER, ProductLevel.PRO, ProductLevel.ENTERPRISE];
export function calculateHighestLevelFromProducts(levels) {
  if (!levels) {
    return undefined;
  }
  const highestIndex = Object.values(levels).reduce((highest, current) => {
    const index = productLevelOrder.indexOf(current);
    return Math.max(index, highest);
  }, -1);
  return highestIndex === -1 ? undefined : productLevelOrder[highestIndex];
}
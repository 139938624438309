import http from 'conversations-http/clients/http';
import { registerQuery, useQuery } from 'data-fetching-client';
import { gate } from 'hub-http/gates';
import { useGate } from 'conversations-thread-data/auth/public/hooks';
const fetchDefaultSendersForUser = ({
  inboxId
}) => {
  return http.get(`conversations-channels/v1/channel-instances/inboxId/${inboxId}/default-senders-for-user`);
};
export const FETCH_DEFAULT_SENDERS_FOR_USER_QUERY = registerQuery({
  fieldName: 'defaultSendersForUser',
  fetcher: fetchDefaultSendersForUser,
  args: ['inboxId']
});
export const useDefaultSendFrom = ({
  inboxId,
  channelId,
  deferred
}) => {
  var _data$defaultSendersF;
  const isUngatedForDefaultSendFrom = useGate(gate('Communication:Composer:DefaultSendFromEmail'));
  const {
    loading,
    error,
    data
  } = useQuery(FETCH_DEFAULT_SENDERS_FOR_USER_QUERY, {
    skip: deferred || !inboxId || !channelId || !isUngatedForDefaultSendFrom,
    variables: {
      inboxId: inboxId
    }
  });
  const defaultSendFrom = channelId && inboxId ? data === null || data === void 0 || (_data$defaultSendersF = data.defaultSendersForUser) === null || _data$defaultSendersF === void 0 ? void 0 : _data$defaultSendersF[channelId] : undefined;
  return {
    loading,
    error,
    defaultSendFrom: defaultSendFrom === null || defaultSendFrom === void 0 ? void 0 : defaultSendFrom.instanceDeliveryIdentifier
  };
};
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { useCallback, useContext, useEffect, useRef } from 'react';
import uniqueId from 'transmute/uniqueId';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { useObjectId } from '../../v2/hooks/useObjectId';
import { useObjectTypeId } from '../../v2/hooks/useObjectTypeId';
import { useProperty } from '../../v2/hooks/useProperty';
import { EDIT_INPUT_MODE } from '../../v2/types/PropertyInputV2Component';
import { buildRefreshAlert, buildRetryAlert } from '../alerts';
import { AutosaveContext } from '../context/AutosaveContext';
import { getPropertyAutosaveEvent } from '../getPropertyAutosaveEvent';
import { useHasAutosave } from './useHasAutosave';
import { usePropertyEdits } from './usePropertyEdits';
import { useIdentifierContext } from '../../v2/context/IdentifierContext';
import { useGetIsFocused } from '../../v2/hooks/useGetIsFocused';
import { canValidateImmediately } from '../../validation/utils/canValidateImmediately';
import { Metrics } from '../../metrics/Metrics';
import { HighlySensitivePropertyContext } from '../../dataSensitivity/HighlySensitivePropertyContext';
const getShouldAutosaveOnValidationChange = ({
  property,
  isOpen,
  isFocused
}) => {
  const propertyAutosaveEvent = getPropertyAutosaveEvent(property);
  if (propertyAutosaveEvent === 'close') {
    return !isOpen;
  }
  if (propertyAutosaveEvent === 'blur') {
    return !isFocused || canValidateImmediately(property);
  }
  const __never = propertyAutosaveEvent;
  return false;
};
const buildAutosaveRequest = ({
  sourceId,
  objectTypeId,
  objectId,
  edits,
  formId,
  formNodeId,
  previousProperties
}) => {
  return {
    sourceId,
    objectTypeId,
    objectId: Number(objectId),
    properties: Object.entries(edits).map(([name, value]) => ({
      name,
      value
    })),
    previousProperties: Object.entries(previousProperties).map(([name, value]) => ({
      name,
      value
    })),
    formMetadata: formId && formNodeId ? {
      formId,
      formNodeId
    } : undefined
  };
};
export const useInputAutosave = ({
  onBlur,
  onFocus,
  inputMode,
  onChange,
  onOpenChange,
  onSave,
  onValidationChange,
  enableAutosave,
  use
}) => {
  const autosaveContext = useContext(AutosaveContext);
  const highlySensitivePropertyContext = useContext(HighlySensitivePropertyContext);
  const objectId = useObjectId();
  const objectTypeId = useObjectTypeId();
  const property = useProperty();
  const hasAutosave = useHasAutosave();
  const sendUpdateObjectsMessage = useSendCrmMessageTopic(TOPIC_NAMES.UPDATE_OBJECTS);
  const propertyAutosaveEvent = getPropertyAutosaveEvent(property);
  const getIsFocused = useGetIsFocused();
  const {
    inputId,
    formId,
    formNodeId
  } = useIdentifierContext();

  // this defaults to true if use === 'table-cell' because in a table cell, the first
  // `onOpenChange` function  does not fire when clicking into a table cell formatter.
  const isOpenRef = useRef(use === 'table-cell');
  const validationRef = useRef(undefined);
  const {
    clearEdits,
    getHasPendingEdits,
    getPendingEdits,
    getOriginalValues,
    onChange: wrappedOnChange
  } = usePropertyEdits({
    onChange
  });

  // Use non-wrapped change handler for undo because we do not want to track undo changes as edits
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const registerHandler = autosaveContext === null || autosaveContext === void 0 ? void 0 : autosaveContext.registerHandler;
  useEffect(() => {
    return registerHandler === null || registerHandler === void 0 ? void 0 : registerHandler({
      id: inputId,
      getIsHandlerForRequest: request => request.sourceId === inputId,
      getIsValidRequest: () => {
        var _validationRef$curren;
        return !!((_validationRef$curren = validationRef.current) !== null && _validationRef$curren !== void 0 && _validationRef$curren.saveable);
      }
    });
  }, [inputId, registerHandler]);

  // AutosaveContext tracks global property edit state to prevent some behavior in synchronized UIs,
  // where validation may trigger autosave events while a user is still typing. This can happen
  // if we have two properties in a form, for example.
  const getIsPropertyBeingEdited = useCallback(() => autosaveContext === null || autosaveContext === void 0 ? void 0 : autosaveContext.getIsEditing({
    propertyName: property.name,
    objectTypeId,
    objectId: objectId !== null && objectId !== void 0 ? objectId : ''
  }), [autosaveContext, objectId, objectTypeId, property.name]);
  const setIsPropertyBeingEdited = useCallback(isEditing => {
    // Table cells are a particularly annoying case because they support ENTER keypresses
    // which do not play well with focus-based editing state. We special case this for now,
    // but ideally the Trellis migration will improve our options here.
    if (use !== 'table-cell') {
      autosaveContext === null || autosaveContext === void 0 || autosaveContext.setIsEditing({
        propertyName: property.name,
        objectTypeId,
        objectId: objectId !== null && objectId !== void 0 ? objectId : '',
        isEditing
      });
    }
  }, [autosaveContext, objectId, objectTypeId, property.name, use]);
  const attemptSave = useCallback(() => {
    var _validationRef$curren2;
    // Table cell does not play well with our autosave request handlers because it unmounts the input,
    // so we need to block enqueuing requests when the input is not saveable in a table cell.
    if (use === 'table-cell' && !((_validationRef$curren2 = validationRef.current) !== null && _validationRef$curren2 !== void 0 && _validationRef$curren2.saveable)) {
      return;
    }
    if (!hasAutosave || !enableAutosave || !autosaveContext || inputMode !== EDIT_INPUT_MODE || !objectId || !getHasPendingEdits()) {
      return;
    }
    const edits = Object.assign({}, getPendingEdits());
    const originalValues = Object.assign({}, getOriginalValues());
    const request = buildAutosaveRequest({
      sourceId: inputId,
      objectTypeId,
      objectId,
      edits,
      previousProperties: originalValues,
      formId,
      formNodeId
    });
    const handleSave = onFailure => {
      autosaveContext.enqueueRequest({
        request,
        onUndo: updates => {
          const messageBusPayload = Object.keys(updates).map(id => ({
            objectId: id,
            objectTypeId,
            propertyNames: Object.keys(updates[id])
          }));
          sendUpdateObjectsMessage(messageBusPayload, {
            sourceId: 'customer-data-properties'
          });
          clearEdits();
          onChangeRef.current(originalValues);
        },
        onSuccess: updates => {
          const messageBusPayload = Object.keys(updates).map(id => ({
            objectId: id,
            objectTypeId,
            propertyNames: Object.keys(updates[id])
          }));
          clearEdits();
          sendUpdateObjectsMessage(messageBusPayload, {
            sourceId: 'customer-data-properties'
          });
          highlySensitivePropertyContext === null || highlySensitivePropertyContext === void 0 || highlySensitivePropertyContext.updateHighlySensitivePropertyValues({
            objectTypeId,
            objectId,
            values: updates[objectId]
          });
          onSave(updates);
        },
        onFailure
      });
      autosaveContext.saveValidRequests();
    };
    handleSave(error => {
      const retryAlertId = uniqueId('autosave');
      FloatingAlertStore.addAlert(buildRetryAlert({
        id: retryAlertId,
        __error: error,
        onRetry: () => {
          FloatingAlertStore.removeAlert(retryAlertId);
          handleSave(retryError => {
            FloatingAlertStore.addAlert(buildRefreshAlert({
              __error: retryError
            }));
          });
        }
      }));
    });
  }, [use, hasAutosave, enableAutosave, autosaveContext, inputMode, objectId, getHasPendingEdits, inputId, objectTypeId, getPendingEdits, formId, formNodeId, getOriginalValues, clearEdits, sendUpdateObjectsMessage, onSave, highlySensitivePropertyContext]);
  const wrappedOnBlur = useCallback(evt => {
    onBlur(evt);
    if (propertyAutosaveEvent === 'blur') {
      var _validationRef$curren3;
      setIsPropertyBeingEdited(false);
      attemptSave();
      if ((_validationRef$curren3 = validationRef.current) !== null && _validationRef$curren3 !== void 0 && _validationRef$curren3.saveable) {
        autosaveContext === null || autosaveContext === void 0 || autosaveContext.saveValidRequests();
      }
    }
  }, [attemptSave, autosaveContext, onBlur, propertyAutosaveEvent, setIsPropertyBeingEdited]);
  const wrappedOnFocus = useCallback(evt => {
    onFocus(evt);
    if (propertyAutosaveEvent === 'blur') {
      setIsPropertyBeingEdited(true);
    }
  }, [onFocus, propertyAutosaveEvent, setIsPropertyBeingEdited]);
  const wrappedOnOpenChange = useCallback(evt => {
    onOpenChange(evt);
    isOpenRef.current = evt.target.value;
    if (propertyAutosaveEvent === 'close' && !isOpenRef.current) {
      var _validationRef$curren4;
      attemptSave();
      if ((_validationRef$curren4 = validationRef.current) !== null && _validationRef$curren4 !== void 0 && _validationRef$curren4.saveable) {
        autosaveContext === null || autosaveContext === void 0 || autosaveContext.saveValidRequests();
      }
    }
  }, [onOpenChange, propertyAutosaveEvent, attemptSave, autosaveContext]);
  const wrappedOnValidationChange = useCallback(validation => {
    validationRef.current = validation;
    onValidationChange(validation);
    const isPropertyBeingEdited = getIsPropertyBeingEdited();
    const shouldAutosaveOnValidationChange = getShouldAutosaveOnValidationChange({
      property,
      isOpen: isOpenRef.current,
      isFocused: getIsFocused()
    });
    if (shouldAutosaveOnValidationChange && !isPropertyBeingEdited) {
      attemptSave();
      autosaveContext === null || autosaveContext === void 0 || autosaveContext.saveValidRequests();
    }
  }, [onValidationChange, getIsPropertyBeingEdited, property, getIsFocused, attemptSave, autosaveContext]);

  // Bundle these into refs to avoid stale closures, while limiting the number of times
  // the cleanup effect is triggered. Some fields are unlikely to change so we do not mind
  // dependeing on them (e.g., formId, use)
  const attemptSaveRef = useRef(attemptSave);
  const autosaveContextRef = useRef(autosaveContext);
  const getOriginalValuesRef = useRef(getOriginalValues);
  getOriginalValuesRef.current = getOriginalValues;
  attemptSaveRef.current = attemptSave;
  autosaveContextRef.current = autosaveContext;
  useEffect(() => () => {
    // To ensure table cells save when they unmount our input and replace it with a FormattedPropertyV2,
    // make a last attempt to save
    if (enableAutosave && use === 'table-cell') {
      attemptSaveRef.current();
    } else {
      var _autosaveContextRef$c;
      // Clear any requests and revert to original value to ensure that host app is notified
      // and can update any other inputs on the page. This can notably occur if a user changes a
      // controlling property and an invalid dependent field change gets removed from the form.
      if ((_autosaveContextRef$c = autosaveContextRef.current) !== null && _autosaveContextRef$c !== void 0 && _autosaveContextRef$c.hasRequestForSource(inputId)) {
        var _autosaveContextRef$c2;
        onChangeRef.current(getOriginalValuesRef.current());
        (_autosaveContextRef$c2 = autosaveContextRef.current) === null || _autosaveContextRef$c2 === void 0 || _autosaveContextRef$c2.cancelRequestsForSource(inputId);
      }
    }
  }, [use, enableAutosave, inputId, property.name, objectId, objectTypeId]);
  const handleBeforeUnload = useCallback(e => {
    if (enableAutosave && getHasPendingEdits() && objectId) {
      Metrics.counter('autosave-pending-edits-and-beforeunload').increment();
      e.preventDefault();
      e.returnValue = true;
    }
  }, [enableAutosave, getHasPendingEdits, objectId]);
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
  return {
    onBlur: wrappedOnBlur,
    onFocus: wrappedOnFocus,
    onChange: wrappedOnChange,
    onOpenChange: wrappedOnOpenChange,
    onValidationChange: wrappedOnValidationChange,
    attemptAutosave: attemptSave
  };
};
import { CANDY_APPLE, GYPSUM, KOALA } from 'HubStyleTokens/colors';
import { BUTTON_RADIUS } from 'HubStyleTokens/sizes';
import UIFlex from 'UIComponents/layout/UIFlex';
import styled from 'styled-components';
const StyledFileValueContainer = styled(UIFlex).attrs({
  align: 'center',
  justify: 'between'
}).withConfig({
  displayName: "StyledFileValueContainer",
  componentId: "sc-22lpo2-0"
})(["height:34px;box-sizing:border-box;margin-top:4px;background-color:", ";border:1px solid ", ";border-radius:", ";"], GYPSUM, ({
  withError
}) => withError ? CANDY_APPLE : KOALA, BUTTON_RADIUS);
export default StyledFileValueContainer;
import { createStack } from 'hub-http';
import * as core from 'hub-http/middlewares/core';
import { formatResponse } from 'chatspot-core/utils/PromptTemplateUtils';
import HubapiStack from 'hub-http/stacks/hubapi';

/**
 * TODO - @mlegocki: consider deprecating these stacks from being exported to other modules
 * Consider doing the following:
 * 1) recreate these stacks within existing modules to decentralize logic
 * 2) migrate these to chatspot-core and centralize the logic (though this limits flexibility in case there are multiple use-cases)
 */

/** @deprecated Prefer chatSpotV1Stack */
export const chatSpotV1ApiStack = createStack(core.defaultTo('timeout', 5000), core.base('chatspot/v1/api/'), HubapiStack, formatResponse);
export const chatSpotV1StreamingStack = createStack( /** A long timeout because streaming can take a long time */
core.defaultTo('timeout', 25000), core.base('chatspot-streaming/v1/'), HubapiStack);
export const chatSpotV1Stack = createStack(core.defaultTo('timeout', 5000), core.base('chatspot/v1/'), HubapiStack, formatResponse);
export const chatSpotV1HighVolumeStack = createStack(core.defaultTo('timeout', 5000), core.base('chatspot-high-volume/v1/'), HubapiStack);
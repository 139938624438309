import { AsyncStatus } from 'find-and-filter-data/common/public';
import isEqual from 'hs-lodash/isEqual';
import { createSelector } from 'reselect';
const getViewIdFromProps = (_state, props) => props.viewId;
export const selectHelpDeskViewMembersPage = state => state.findAndFilterData.helpDeskViewMembers;
export const selectHelpDeskViewMembersPageById = createSelector([selectHelpDeskViewMembersPage, getViewIdFromProps], (viewMembersPage, viewId) => {
  if (viewId) return viewMembersPage.data.get(viewId);else return undefined;
});
export const selectHelpDeskViewMembersPageStatus = createSelector([selectHelpDeskViewMembersPage], page => page.status);
export const selectPendingSearchParams = state => state.findAndFilterData.helpDeskViewMembers.pendingSearchParams;
export const selectLastResolvedSearchParams = state => state.findAndFilterData.helpDeskViewMembers.lastResolvedSearchParams || null;
function paramsAreEqual(paramsA, paramsB) {
  return isEqual(paramsA === null || paramsA === void 0 ? void 0 : paramsA.filterGroups, paramsB.filterGroups) && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.inboxId) === paramsB.inboxId && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.searchQuery) === paramsB.searchQuery && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.sortDirection) === paramsB.sortDirection && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.sortProperty) === paramsB.sortProperty && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.viewId) === paramsB.viewId && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.limit) === paramsB.limit && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.offsetId) === paramsB.offsetId && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.offsetTimestamp) === paramsB.offsetTimestamp && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.primaryOffsetValue) === paramsB.primaryOffsetValue && (paramsA === null || paramsA === void 0 ? void 0 : paramsA.secondaryOffsetValue) === paramsB.secondaryOffsetValue;
}
export const selectIsDuplicateRequestPending = (state, params) => {
  const status = selectHelpDeskViewMembersPageStatus(state);
  if (status !== AsyncStatus.INITIAL_FETCH_LOADING && status !== AsyncStatus.PAGINATED_FETCH_LOADING) {
    return false;
  }
  const pendingSearchParams = selectPendingSearchParams(state);
  return paramsAreEqual(pendingSearchParams, params);
};
export const selectAreSearchParamsEqual = (state, params) => {
  const lastResolvedSearchParams = selectLastResolvedSearchParams(state);
  return paramsAreEqual(lastResolvedSearchParams, params);
};
export const selectLastViewMemberId = createSelector([selectHelpDeskViewMembersPageById], page => {
  var _page$indexedMembers$;
  return page === null || page === void 0 || (_page$indexedMembers$ = page.indexedMembers.last()) === null || _page$indexedMembers$ === void 0 ? void 0 : _page$indexedMembers$.objectKey.objectId;
});
import I18n from 'I18n';
// Cleared property's value is set to empty string - https://product.hubteam.com/docs/crm-properties/values/empty.html
export function fromEmptyStringToNull(value) {
  return value === '' ? null : value;
}
export function toNumberOrNull(value) {
  // prevents null conversion to 0 for inital price calculations
  if (value === null) return null;
  const number = Number(value);
  return isNaN(number) ? null : number;
}
export function toNumberOrUndefined(value) {
  // prevents null conversion to 0 for inital price calculations
  if (value == null) return undefined;
  const number = Number(value);
  return isNaN(number) ? undefined : number;
}

/** Exists to facilitate TS conversion when null or undefined are not acceptable */
export function toNumber(value) {
  const number = Number(value);
  return isNaN(number) ? 0 : number;
}

// ported from https://git.hubteam.com/HubSpot/CRM/blob/master/crm_ui/static/js/utils/FormatNumber.js
export function parseNumber(number) {
  if (typeof number === 'number') {
    return number;
  }
  const parsed = Number(number);
  if (!isNaN(parsed)) {
    return parsed;
  }
  // @ts-expect-error type dependency error
  return I18n.parseNumber(number);
}
export default toNumberOrNull;
import I18n from 'I18n';
import { getDynamicNamespace } from 'conversations-infrastructure/i18n/getDynamicNamespace';
import { getFileMetadata, getFileUsageType
// @ts-expect-error module not typed
} from 'conversations-internal-schema/resolved-attachment/operators/resolvedAttachmentGetters';
import { AUDIO, DOCUMENT, IMG, MOVIE, OTHER } from 'conversations-message-history/common-message-format/constants/fileAttachmentTypes';
import { STICKER } from 'conversations-message-history/common-message-format/constants/fileUsageTypes';
//foundations icons
import { VolumeUpIcon, FileIcon, InsertImageIcon, InsertVideoIcon } from 'foundations-assets';
const FALLBACK_ICON = FileIcon;
const fileAttachmentTypeIcons = {
  [AUDIO]: VolumeUpIcon,
  [DOCUMENT]: FileIcon,
  [IMG]: InsertImageIcon,
  [MOVIE]: InsertVideoIcon,
  [OTHER]: FALLBACK_ICON,
  [STICKER]: FileIcon
};
export const formatResolvedAttachmentsData = attachments => {
  const knownTypes = Object.keys(fileAttachmentTypeIcons);
  const firstAttachment = attachments && attachments.first();
  const fileUsageType = getFileUsageType(firstAttachment);
  const fileMetadata = getFileMetadata(firstAttachment);
  const fileType = fileMetadata && fileMetadata.type;
  const isSticker = fileUsageType === STICKER;
  const isDocument = fileType === DOCUMENT;
  const type = isSticker ? STICKER : fileType;
  const supportedType = type && knownTypes.includes(type) ? type : OTHER;
  const hasThumbnail = isSticker || supportedType === IMG;
  const Icon = fileAttachmentTypeIcons[supportedType];
  const name = isDocument && fileMetadata ? fileMetadata.name : null;
  const url = hasThumbnail ? fileMetadata && fileMetadata.url : null;
  const friendlyType = I18n.text(getDynamicNamespace({
    prefix: 'conversations-inbox-message-history.attachments.type',
    translationOptions: knownTypes,
    defaultOption: OTHER
  }, supportedType));
  return {
    Icon,
    name,
    type: friendlyType,
    url
  };
};
import { UNFORMATTED } from 'customer-data-objects/property/NumberDisplayHint';
import { isCurrency, isNumber, isPropertySourceUserId } from 'customer-data-objects/property/PropertyIdentifier';
import { parsePropertySourceUserId } from 'customer-data-objects/property/PropertySourceIdentifier';
import { useMemo } from 'react';
import { usePropertyContext } from '../context/PropertyContext/usePropertyContext';
import I18n from 'I18n';
export const usePropertyValue = () => {
  const {
    property,
    value
  } = usePropertyContext();
  return useMemo(() => {
    // If a value can be read in standard format, do so;
    // otherwise, try local format
    if (isNumber(property) && !isCurrency(property) && value && property.numberDisplayHint !== UNFORMATTED) {
      // we assume that the number is a valid number (in english)
      const parsedNumber = Number(value);
      if (isNaN(parsedNumber)) {
        // if the number is not valid, try to parse it in the current locale
        const localeParsedNumber = I18n.parseNumber(value, {
          precision: 5
        });
        // if the locale-parsed number is NaN, return the raw value
        return isNaN(localeParsedNumber) ? String(value) : String(localeParsedNumber);
      } else {
        return String(parsedNumber);
      }
    }
    if (value && isPropertySourceUserId(property, value)) {
      return String(parsePropertySourceUserId(value));
    }
    return value;
  }, [value, property]);
};
import { getSenders, getEmailMetadataAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { getMetadataConnectedAccountAddress } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
import { getDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/senderGetters';
export const getCommonMessageDeliveryIdentifierValue = commonMessage => {
  const emailMetadataAttachment = getEmailMetadataAttachment(commonMessage);
  if (emailMetadataAttachment) {
    // In case of email, the options are key'd off the connectedAccountAddresses
    const address = getMetadataConnectedAccountAddress(emailMetadataAttachment);
    return address && address.toLowerCase();
  }
  const senders = getSenders(commonMessage);
  const sender = senders && senders.first();
  const deliveryIdentifier = sender && getDeliveryIdentifier(sender);
  return deliveryIdentifier && getDeliveryIdentifierValue(deliveryIdentifier);
};
import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useDataFetchingClient } from 'data-fetching-client';
import { bulkFetchValidatedViewMembers } from 'find-and-filter-data/view-members-data/public';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line conversations/no-private-internals-import
import { fetchAndCacheCustomView } from '../../../spaces/_internal/utils/fetchAndCacheCustomView';
// eslint-disable-next-line conversations/no-private-internals-import
import { fetchUpdatedViewCounts } from '../../../spaces/_internal/utils/fetchUpdatedViewCounts';
// eslint-disable-next-line conversations/no-private-internals-import
import { updateViewTableCount } from '../../../view-members-data/_internal/help-desk-view-member/helpDeskViewMemberActions';
export const REALTIME_BUFFER_INTERVAL = 5000;
export const LOW_PRIORITY_REALTIME_BUFFER_INTERVAL = 30000;
const REALTIME_UPDATE_LIMIT = 100;

/**
 * Provide a backdoor for unit tests to flush the realtime buffers while
 * avoiding jasmine.clock(), which causes subtle, difficult to debug problems
 * with promises, MSW, and library internals.
 */
/* eslint-disable hs-react-native/no-web-globals */
function setRealtimeBufferInterval(callback, timeout) {
  if (window.jasmine) {
    const currentFlushRealtimeBuffers = window.flushRealtimeBuffers;
    window.flushRealtimeBuffers = () => {
      currentFlushRealtimeBuffers === null || currentFlushRealtimeBuffers === void 0 || currentFlushRealtimeBuffers();
      callback();
    };
  }
  return setInterval(callback, timeout);
}
function clearRealtimeBufferInterval(intervalId) {
  if (window.jasmine) {
    window.flushRealtimeBuffers = undefined;
  }
  clearInterval(intervalId);
}
/* eslint-enable hs-react-native/no-web-globals */

const defaultMessageBuffer = {
  added: new Set(),
  removed: new Set(),
  updated: new Set(),
  viewCountUpdated: new Set(),
  viewStatusChanged: new Set()
};

/**
 * Buffers incoming realtime messages to be flushed at a regular interval
 */
export function useRealtimeBuffer({
  handleRealtimeUpdate,
  isBoardView,
  priorityViewIds,
  quickFilters,
  refetchMembers,
  viewId
}) {
  const dispatch = useDispatch();
  const dataFetchingClient = useDataFetchingClient();
  const highPriorityIntervalId = useRef(0);
  const lowPriorityIntervalId = useRef(0);
  const messageBuffer = useRef(defaultMessageBuffer);

  // Reset view member buffers when changing views, as we only store one view's members at a time.
  useEffect(() => {
    messageBuffer.current.added = new Set();
    messageBuffer.current.removed = new Set();
    messageBuffer.current.updated = new Set();
  }, [viewId]);
  const flushHighPriorityMessages = useCallback(async () => {
    const viewCountUpdatedHighPriority = new Set();
    const {
      added,
      removed,
      updated,
      viewCountUpdated,
      viewStatusChanged
    } = messageBuffer.current;
    for (const id of viewCountUpdated) {
      if (priorityViewIds !== null && priorityViewIds !== void 0 && priorityViewIds.includes(`${id}`)) {
        viewCountUpdatedHighPriority.add(id);
        viewCountUpdated.delete(id);
      }
    }
    const objectIds = new Set([...added, ...updated]);
    const realtimeUpdateCount = objectIds.size;

    // Realtime updates do not respect CRM filters. If a view count update
    // is not received for current view, we still need to fetch the table count if a member update
    // message is received while filters are applied
    if (quickFilters.length && updated.size) {
      viewCountUpdatedHighPriority.add(+viewId);
    }

    // If the number of realtime updates to validate exceeds the limit,
    // refetch all members and clear buffer
    if (realtimeUpdateCount >= REALTIME_UPDATE_LIMIT) {
      refetchMembers();
      messageBuffer.current.added = new Set();
      messageBuffer.current.updated = new Set();
      messageBuffer.current.removed = new Set();
    }
    // Otherwise, validate the updated members and dispatch update action
    else if (realtimeUpdateCount && realtimeUpdateCount < REALTIME_UPDATE_LIMIT) {
      // If there are duplicate ids across `added`/`updated` and `removed`,
      // delete them from `removed` and rely on the bulk fetch api to tell us
      // whether to remove them from the view. This is necessary because we
      // don't know the order in which these messages arrived (they may have
      // even come out of order) and we must use backend as the source of truth.
      for (const id of objectIds) {
        removed.delete(id);
      }

      // Use new bulk fetch endpoint to validate view members
      const results = await bulkFetchValidatedViewMembers({
        isBoardView,
        objectIds: [...objectIds.values()],
        objectTypeId: TICKET_TYPE_ID,
        viewId: +viewId,
        quickFilters
      });
      const addedTickets = [];
      const updatedTickets = [];
      for (const ticket of results.viewMembers) {
        if (added.has(ticket.objectKey.objectId)) {
          addedTickets.push(ticket);
          objectIds.delete(ticket.objectKey.objectId);
        } else {
          updatedTickets.push(ticket);
          objectIds.delete(ticket.objectKey.objectId);
        }
      }

      // Any added/updated objectIds that were missing in the bulk fetch
      // response presumably failed the view membership check, and should be
      // removed from state.
      for (const id of objectIds) {
        removed.add(id);
      }
      handleRealtimeUpdate({
        added: addedTickets,
        updated: updatedTickets,
        removed: [...removed.values()],
        viewId
      });
      messageBuffer.current.added = new Set();
      messageBuffer.current.removed = new Set();
      messageBuffer.current.updated = new Set();
    }
    // If no updates, just remove the removed tickets
    else if (removed.size) {
      handleRealtimeUpdate({
        removed: [...removed.values()],
        viewId
      });
      messageBuffer.current.removed = new Set();
    }

    // Fetch updated view counts for all views that have been updated
    if (viewCountUpdatedHighPriority.size) {
      const currentViewIdIsIncluded = viewCountUpdatedHighPriority.has(+viewId);
      const result = await fetchUpdatedViewCounts(Object.assign({
        client: dataFetchingClient,
        viewIds: [...viewCountUpdatedHighPriority, ...viewCountUpdated]
      }, currentViewIdIsIncluded ? {
        currentCustomViewId: +viewId,
        quickFilters
      } : {})).catch(() => ({
        currentViewTableCount: undefined
      }));
      if (currentViewIdIsIncluded && result.currentViewTableCount !== undefined) {
        dispatch(updateViewTableCount({
          viewId: viewId,
          currentViewTableCount: result.currentViewTableCount
        }));
      }
      messageBuffer.current.viewCountUpdated = new Set();
    }
    if (viewStatusChanged.size) {
      const viewIds = Array.from(viewStatusChanged);
      for (const changedViewId of viewIds) {
        fetchAndCacheCustomView({
          client: dataFetchingClient,
          viewIdToFetch: changedViewId
        }).catch(() => {
          //ignore errors
        });
      }
      messageBuffer.current.viewStatusChanged = new Set();
    }
  }, [dataFetchingClient, dispatch, isBoardView, quickFilters, viewId, handleRealtimeUpdate, refetchMembers, priorityViewIds]);
  const flushLowPriorityMessages = useCallback(() => {
    const {
      viewCountUpdated
    } = messageBuffer.current;
    if (viewCountUpdated.size) {
      // Fetch updated view counts for all views that have been updated
      fetchUpdatedViewCounts({
        viewIds: Array.from(viewCountUpdated),
        client: dataFetchingClient
      }).catch(() => {
        //ignore errors
      });
      messageBuffer.current.viewCountUpdated = new Set();
    }
  }, [dataFetchingClient]);

  //Handle high priority updates
  useEffect(() => {
    highPriorityIntervalId.current = setRealtimeBufferInterval(flushHighPriorityMessages, REALTIME_BUFFER_INTERVAL);
    return () => clearRealtimeBufferInterval(highPriorityIntervalId.current);
  }, [flushHighPriorityMessages]);

  //Handle low priority updates
  useEffect(() => {
    lowPriorityIntervalId.current = setRealtimeBufferInterval(flushLowPriorityMessages, LOW_PRIORITY_REALTIME_BUFFER_INTERVAL);
    return () => clearRealtimeBufferInterval(lowPriorityIntervalId.current);
  }, [flushLowPriorityMessages]);
  return useMemo(() => ({
    add(...added) {
      for (const id of added) {
        messageBuffer.current.added.add(id);
      }
    },
    remove(...removed) {
      for (const id of removed) {
        messageBuffer.current.removed.add(id);
      }
    },
    update(...updated) {
      for (const id of updated) {
        messageBuffer.current.updated.add(id);
      }
    },
    viewCountUpdate(...viewCountsUpdated) {
      for (const id of viewCountsUpdated) {
        messageBuffer.current.viewCountUpdated.add(id);
      }
    },
    viewStatusChanged(...viewIds) {
      for (const id of viewIds) {
        messageBuffer.current.viewStatusChanged.add(id);
      }
    }
  }), []);
}
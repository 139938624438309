import { getQuoteProperty } from 'quotes-ui-lib/utils/quote';
import { PROPOSAL, QUOTE, CUSTOMIZABLE_QUOTE_TEMPLATE, CPQ_QUOTE } from '../constants/templates';
export function hasDeprecatedTemplateType(quote) {
  return [QUOTE, PROPOSAL].includes(getQuoteProperty(quote, 'hs_template_type'));
}
export function isProposalOrHasCustomTemplate(quote) {
  const templateType = getQuoteProperty(quote, 'hs_template_type');
  return templateType === PROPOSAL || templateType === CUSTOMIZABLE_QUOTE_TEMPLATE;
}
export function isCPQQuote(quote) {
  return getQuoteProperty(quote, 'hs_template_type') === CPQ_QUOTE;
}
export function hasCustomTemplate(quote) {
  return getQuoteProperty(quote, 'hs_template_type') === CUSTOMIZABLE_QUOTE_TEMPLATE;
}
export function getIsLegacyQuote(quote) {
  return !hasCustomTemplate(quote) && !isCPQQuote(quote);
}
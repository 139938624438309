import { CALLING_EXTENSIONS_INBOUND_CALLING, CALLING_THIRD_PARTY_CHANNELS, CALLING_EXTENSIONS_INBOUND_CALLING_ROLL_OUT } from 'calling-internal-common/constants/CallingGates';
import { getSettingString } from './localSettingsOperators';
import { appIds } from '../constants/appIds';
export const SUPPORTS_INBOUND_CALLING_KEY = 'supportsInboundCalling';
const SUPPORTS_INBOUND_CALLING_VALUE = 'true';
export const isExtensionsInboundCallingEnabledInLocalStorage = () => {
  return getSettingString(SUPPORTS_INBOUND_CALLING_KEY) === SUPPORTS_INBOUND_CALLING_VALUE;
};

/**
 * Toggles the calling extensions inbound calling experience.
 * @param gates
 * @returns boolean
 */
export const isExtensionsInboundCallingEnabled = (gates, callingProvider) => {
  var _callingProvider$appI;
  if (isExtensionsInboundCallingEnabledInLocalStorage()) {
    return true;
  }
  if (!gates || gates.length === 0) {
    return false;
  }
  if (gates.includes(CALLING_THIRD_PARTY_CHANNELS)) {
    return true;
  }
  const isAircall = (callingProvider === null || callingProvider === void 0 || (_callingProvider$appI = callingProvider.appId) === null || _callingProvider$appI === void 0 ? void 0 : _callingProvider$appI.toString()) === appIds.AIRCALL.toString();
  return isAircall ? gates.includes(CALLING_EXTENSIONS_INBOUND_CALLING) : gates.includes(CALLING_EXTENSIONS_INBOUND_CALLING) || gates.includes(CALLING_EXTENSIONS_INBOUND_CALLING_ROLL_OUT);
};
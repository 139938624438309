import { fetchDocuments } from './fetchDocuments';
import { renderDocument } from './renderDocuments';
import { fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
export const documentsApiWrapper = (httpClient, contactEmail) => ({
  fetchRenderedContent({
    id
  }) {
    return renderDocument({
      httpClient,
      contentId: id,
      contactEmail
    }).then(linkInfo => {
      const {
        url,
        text
      } = linkInfo;
      return {
        htmlBody: `<a href="${url}">${text}</a>`
      };
    });
  },
  searchArticles({
    query,
    count
  }) {
    return fetchDocuments({
      httpClient,
      queryParams: {
        query
      }
    }).then(results => {
      const finalResults = count ? results.slice(0, count) : results;
      return finalResults.map(item => {
        return Object.assign({}, item, {
          value: item.id
        });
      });
    });
  }
});
export function fetchDeck(id) {
  return http.get(`presentations/v1/deck/${id}`).then(fromJS);
}
export function createDeckLink(deckId, skipForm, sharedWith) {
  return http.post(`presentations/v1/deck/${deckId}/share`, {
    query: {
      isLive: true,
      skipForm,
      sharedWith
    }
  }).then(fromJS);
}
export function searchDecks(searchInput) {
  return http.post('/salescontentsearch/v2/search', {
    data: {
      filters: [{
        field: 'content_type',
        values: ['DOCUMENT']
      }],
      limit: 100,
      offset: 0,
      query: searchInput
    }
  });
}
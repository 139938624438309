import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["__typename"],
  _excluded2 = ["__typename"];
import memoize from 'transmute/memoize';
import { getDefaultPropertiesFromQuote, getDefaultInitialInvoiceProperties, getDefaultPropertiesForBlankInvoice, getCompanyAddressProperties, makeNewProperty, getDifferenceBetweenDates, getCurrentDateInPortalTz, getDefaultDueDate, filterOutInvoicePropertiesByKeys, COMPANY_OVERRIDE_ADDRESS_PROPERTIES, resetShippingAddressProperties } from '../utils/properties';
import PortalIdParser from 'PortalIdParser';
import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, LINE_ITEM_TYPE_ID, QUOTE_TYPE_ID, DISCOUNT_TYPE_ID, FEE_TYPE_ID, TAX_TYPE_ID, INVOICE_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, PRODUCT_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { makeInvoice } from 'invoices-ui-lib/utils/invoice';
import { getInvoiceProperty } from 'invoices-ui-lib/utils/property';
import { FIXED, PERCENT } from 'customer-data-objects/adjustment/AdjustmentProperties';
import { DISCOUNT, FEE, TAX } from 'customer-data-objects/constants/ObjectTypes';
import I18n from 'I18n';
import { HS_INVOICE_PAYMENT_COLLECTION_METHOD, INVOICE_PROPERTY_VALUES } from 'invoices-ui-lib/constants/properties';
import { isAccountApprovedForPayments } from 'invoices-ui-lib/utils/paymentsEligibility';
import { InvoiceOperation } from 'invoices-ui-lib/types/InvoiceOperation';
import { getInitialInvoiceCurrency } from '../utils/validCurrencies';
import { convertProductToLineItem } from 'commerce-products-lib';
import { convertLineItemToAssociatedObject } from 'invoices-ui-lib/utils/lineItems';
export const getDefaultCurrencyCode = (objectTypeId, initialDataFetchData) => {
  if (objectTypeId === QUOTE_TYPE_ID) {
    return initialDataFetchData.crmObject.defaultProperties['hs_currency'].value;
  } else if (objectTypeId === INVOICE_TYPE_ID) {
    return initialDataFetchData.crmObject.defaultProperties['hs_currency'].value;
  } else if (objectTypeId === DEAL_TYPE_ID) {
    return initialDataFetchData.crmObject.defaultProperties['deal_currency_code'].value;
  }
};
export const parsePropertiesNode = gqlNode => {
  const _ref = gqlNode.defaultProperties || {},
    defaultProperties = _objectWithoutPropertiesLoose(_ref, _excluded);
  const propertiesList = gqlNode.properties || [];
  const allPropertiesList = gqlNode.allProperties || [];
  const favoritePropertiesList = gqlNode.favoriteProperties || [];

  // map all properties arrays to maps of InvoicePropertyValues keyed by name
  return [...propertiesList, ...allPropertiesList, ...favoritePropertiesList, ...Object.values(defaultProperties)].reduce((acc, {
    name,
    value
  }) => {
    acc[name] = {
      name,
      value
    };
    return acc;
  }, {});
};
export const parsePropertiesCrmObject = gqlCrmObject => {
  const _ref2 = gqlCrmObject.defaultProperties || {},
    defaultProperties = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  const allProperties = gqlCrmObject.allProperties || [];
  const parsedAllProperties = allProperties.reduce((acc, property) => {
    acc[property.name] = {
      name: property.name,
      value: property.value
    };
    return acc;
  }, {});
  return Object.values(defaultProperties).reduce((acc, {
    name,
    value
  }) => {
    acc[name] = {
      name,
      value
    };
    return acc;
  }, parsedAllProperties);
};
export const getAssociatedDataFirst = (gqlCrmObject, key) => gqlCrmObject.defaultAssociations[key] && gqlCrmObject.defaultAssociations[key].edges[0] && gqlCrmObject.defaultAssociations[key].edges[0].node || null;
export const getAssociatedData = (gqlCrmObject, key) => gqlCrmObject.defaultAssociations[key] && gqlCrmObject.defaultAssociations[key].edges.map(edge => edge.node) || [];
const isUserPermissionsValid = node => {
  return !node.userPermissions || node.userPermissions.currentUserCanEdit;
};
export const parseNode = (node, objectTypeId) => node && isUserPermissionsValid(node) ? [{
  objectId: node.id,
  objectTypeId,
  portalId: PortalIdParser.get(),
  properties: parsePropertiesNode(node)
}] : [];
export const parseNodes = (nodes, objectTypeId) => nodes ? nodes.filter(isUserPermissionsValid).map(node => ({
  objectId: node.id,
  objectTypeId,
  portalId: PortalIdParser.get(),
  properties: parsePropertiesNode(node)
})) : [];
export const parseCompaniesNode = crmObject => parseNode(getAssociatedDataFirst(crmObject, 'toCompanies'), COMPANY_TYPE_ID);
export const parseContactsNodes = crmObject => parseNodes(getAssociatedData(crmObject, 'toContacts'), CONTACT_TYPE_ID);
const parseDealsNode = crmObject => parseNode(getAssociatedDataFirst(crmObject, 'toDeals'), DEAL_TYPE_ID);
const parseTicketsNode = crmObject => parseNode(getAssociatedDataFirst(crmObject, 'toTickets'), TICKET_TYPE_ID);
const parseLineItemsNodes = crmObject => parseNodes(getAssociatedData(crmObject, 'toLineItems'), LINE_ITEM_TYPE_ID);
const parseQuotesNode = crmObject => parseNode(getAssociatedDataFirst(crmObject, 'toQuotes'), QUOTE_TYPE_ID);
const parseFeeNodes = crmObject => parseNodes(getAssociatedData(crmObject, 'toFees'), FEE_TYPE_ID);
const parseTaxNodes = crmObject => parseNodes(getAssociatedData(crmObject, 'toTaxes'), TAX_TYPE_ID);
const parseDiscountNodes = crmObject => parseNodes(getAssociatedData(crmObject, 'toDiscounts'), DISCOUNT_TYPE_ID);
const parseCommercePaymentNode = crmObject => parseNode(getAssociatedDataFirst(crmObject, 'toCommercePayments'), COMMERCE_PAYMENT_TYPE_ID);
const feeCategoryToObjectTypeId = {
  [TAX]: TAX_TYPE_ID,
  [DISCOUNT]: DISCOUNT_TYPE_ID,
  [FEE]: FEE_TYPE_ID
};
export const TOTAL_ADJUSTMENT_DURATION = {
  ONCE: 'ONCE'
};
export const parseAdditionalFees = crmObject => {
  const additionalFees = crmObject.associatedObjects && crmObject.associatedObjects.additionalFees || [];
  return additionalFees.reduce((acc, fee, index) => {
    let adjustmentObject = {
      portalId: PortalIdParser.get(),
      objectId: null,
      objectTypeId: feeCategoryToObjectTypeId[fee.category],
      properties: {
        hs_value: {
          name: 'hs_value',
          value: fee.amount
        },
        hs_label: {
          name: 'hs_label',
          value: fee.name
        },
        hs_type: {
          name: 'hs_type',
          value: fee.isPercentage ? PERCENT : FIXED
        },
        hs_sort_order: {
          name: 'hs_sort_order',
          value: index
        }
      }
    };
    if (fee.category === DISCOUNT) {
      adjustmentObject = Object.assign({}, adjustmentObject, {
        properties: Object.assign({}, adjustmentObject.properties, {
          hs_duration: {
            name: 'hs_duration',
            value: TOTAL_ADJUSTMENT_DURATION.ONCE
          }
        })
      });
    }
    acc[fee.category].push(adjustmentObject);
    return acc;
  }, {
    [FEE]: [],
    [TAX]: [],
    [DISCOUNT]: []
  });
};
export const parseBillingContact = memoize(companyCrmObject => {
  var _companyCrmObject$ass;
  const billingContactCrmObject = companyCrmObject.associationsBySpec && ((_companyCrmObject$ass = companyCrmObject.associationsBySpec[0].associatedObjects) === null || _companyCrmObject$ass === void 0 ? void 0 : _companyCrmObject$ass.edges[0].node);
  if (billingContactCrmObject) {
    const billingContactProperties = parsePropertiesCrmObject(billingContactCrmObject);
    return billingContactCrmObject && {
      objectId: billingContactCrmObject.id,
      objectTypeId: CONTACT_TYPE_ID,
      portalId: PortalIdParser.get(),
      properties: billingContactProperties
    };
  }
  return null;
});
export const parseBillingContactFromCrmObjectAssociation = memoize(companyCrmObjectAssociation => {
  if (companyCrmObjectAssociation) {
    var _companyCrmObjectAsso, _companyCrmObjectAsso2;
    const billingContactCrmObject = ((_companyCrmObjectAsso = companyCrmObjectAssociation.edges[0]) === null || _companyCrmObjectAsso === void 0 || (_companyCrmObjectAsso = _companyCrmObjectAsso.node) === null || _companyCrmObjectAsso === void 0 ? void 0 : _companyCrmObjectAsso.associationsBySpec) && ((_companyCrmObjectAsso2 = companyCrmObjectAssociation.edges[0].node.associationsBySpec[0]) === null || _companyCrmObjectAsso2 === void 0 || (_companyCrmObjectAsso2 = _companyCrmObjectAsso2.associatedObjects) === null || _companyCrmObjectAsso2 === void 0 || (_companyCrmObjectAsso2 = _companyCrmObjectAsso2.edges[0]) === null || _companyCrmObjectAsso2 === void 0 ? void 0 : _companyCrmObjectAsso2.node);
    if (billingContactCrmObject) {
      const billingContactProperties = parsePropertiesCrmObject(billingContactCrmObject);
      return billingContactCrmObject && {
        objectId: billingContactCrmObject.id,
        objectTypeId: CONTACT_TYPE_ID,
        portalId: PortalIdParser.get(),
        properties: billingContactProperties
      };
    }
  }
  return null;
});
export const parseQuoteWithAssociatedObjects = memoize(({
  userAttributeValues,
  crmObject,
  commerceCurrencyLimits
}, onRemoveCompany, onRemoveContact, ungatedForAccountingCompanyBillingContactAssociation, useBillingContactOnQuotesAndDealsInvoices) => {
  const associatedQuote = {
    objectId: crmObject.id,
    objectTypeId: QUOTE_TYPE_ID,
    portalId: PortalIdParser.get(),
    properties: parsePropertiesCrmObject(crmObject)
  };
  const associatedCompany = parseCompaniesNode(crmObject);
  const associatedBillingContact = ungatedForAccountingCompanyBillingContactAssociation && useBillingContactOnQuotesAndDealsInvoices && parseBillingContactFromCrmObjectAssociation(crmObject.defaultAssociations.toCompanies);
  const associatedContacts = associatedBillingContact ? [associatedBillingContact] : parseContactsNodes(crmObject);
  const gqlCompanyEdges = crmObject.defaultAssociations.toCompanies.edges;
  const gqlContactEdges = crmObject.defaultAssociations.toContacts.edges;
  const defaultCurrencyCode = associatedQuote.properties['hs_currency'].value;
  const initialInvoiceCurrency = getInitialInvoiceCurrency(commerceCurrencyLimits, defaultCurrencyCode);
  if (associatedCompany.length !== gqlCompanyEdges.length) {
    onRemoveCompany(gqlCompanyEdges.length - associatedCompany.length);
  }
  if (associatedContacts.length !== gqlContactEdges.length) {
    onRemoveContact(gqlContactEdges.length - associatedContacts.length);
  }
  return makeInvoice({
    associatedObjects: {
      INVOICE_TO_COMPANY: associatedCompany,
      INVOICE_TO_CONTACT: associatedContacts,
      INVOICE_TO_DEAL: parseDealsNode(crmObject),
      INVOICE_TO_LINE_ITEM: parseLineItemsNodes(crmObject),
      INVOICE_TO_QUOTE: [associatedQuote],
      INVOICE_TO_FEE: parseAdditionalFees(crmObject)[FEE],
      INVOICE_TO_TAX: parseAdditionalFees(crmObject)[TAX],
      INVOICE_TO_DISCOUNT: parseAdditionalFees(crmObject)[DISCOUNT]
    },
    properties: getDefaultPropertiesFromQuote(associatedQuote, initialInvoiceCurrency, userAttributeValues[0].value, associatedCompany[0] || associatedContacts[0])
  });
});
export const getBlankInvoice = memoize((data, associatedObjects = {}, objectTypeId) => {
  const {
    userAttributeValues,
    commerceCurrencyLimits
  } = data;
  const defaultCurrencyCode = objectTypeId === DEAL_TYPE_ID ? getDefaultCurrencyCode(objectTypeId, data) : undefined;
  const initialInvoiceCurrency = getInitialInvoiceCurrency(commerceCurrencyLimits, defaultCurrencyCode);
  return makeInvoice({
    associatedObjects,
    portalId: PortalIdParser.get(),
    properties: getDefaultPropertiesForBlankInvoice(initialInvoiceCurrency, userAttributeValues[0].value)
  });
});
export const parseCompanyWithAssociation = memoize(({
  crmObject,
  userAttributeValues,
  commerceCurrencyLimits
}, ungatedForAccountingCompanyBillingContactAssociation) => {
  const properties = parsePropertiesCrmObject(crmObject);
  const initialInvoiceCurrency = getInitialInvoiceCurrency(commerceCurrencyLimits, undefined);
  const associatedCompany = {
    objectId: crmObject.id,
    objectTypeId: COMPANY_TYPE_ID,
    portalId: PortalIdParser.get(),
    properties
  };
  const associatedBillingContact = ungatedForAccountingCompanyBillingContactAssociation ? parseBillingContact(crmObject) : null;
  return makeInvoice({
    associatedObjects: {
      INVOICE_TO_COMPANY: [associatedCompany],
      INVOICE_TO_CONTACT: associatedBillingContact ? [associatedBillingContact] : []
    },
    properties: Object.assign({}, getDefaultPropertiesForBlankInvoice(initialInvoiceCurrency, userAttributeValues[0].value), getCompanyAddressProperties(associatedCompany))
  });
});
export const parseContactWithAssociation = memoize(({
  crmObject,
  userAttributeValues,
  commerceCurrencyLimits
}) => {
  const associatedCompanies = parseCompaniesNode(crmObject);
  const initialInvoiceCurrency = getInitialInvoiceCurrency(commerceCurrencyLimits, undefined);
  const associatedContact = {
    objectId: crmObject.id,
    objectTypeId: CONTACT_TYPE_ID,
    portalId: PortalIdParser.get(),
    properties: parsePropertiesCrmObject(crmObject)
  };
  return makeInvoice({
    associatedObjects: {
      INVOICE_TO_CONTACT: [associatedContact],
      INVOICE_TO_COMPANY: associatedCompanies
    },
    properties: Object.assign({}, getDefaultPropertiesForBlankInvoice(initialInvoiceCurrency, userAttributeValues[0].value), getCompanyAddressProperties(associatedCompanies[0] || associatedContact))
  });
});
export const parseProduct = ({
  crmObject,
  userAttributeValues,
  commerceCurrencyLimits,
  currency
}, propertyBlockList) => {
  const initialInvoiceCurrency = getInitialInvoiceCurrency(commerceCurrencyLimits, undefined);
  const product = {
    objectId: crmObject.id,
    properties: parsePropertiesCrmObject(crmObject)
  };
  const multicurrencyItems = currency.exchangeRates.map(exchangeRate => ({
    referencedObject: new Map(Object.entries(exchangeRate))
  }));
  const lineItem = convertProductToLineItem(product, currency.isMulticurrencyActive ? initialInvoiceCurrency : null, multicurrencyItems, currency.homeCurrency.currencyCode);
  // default hs_position_on_quote to 0
  lineItem.properties.hs_position_on_quote = {
    name: 'hs_position_on_quote',
    value: '0'
  };
  return makeInvoice({
    associatedObjects: {
      INVOICE_TO_LINE_ITEM: [convertLineItemToAssociatedObject(lineItem, propertyBlockList)]
    },
    portalId: PortalIdParser.get(),
    properties: Object.assign({}, getDefaultPropertiesForBlankInvoice(initialInvoiceCurrency, userAttributeValues[0].value))
  });
};
export const parseWithAssociatedObjects = ({
  crmObject,
  userAttributeValues,
  commerceCurrencyLimits,
  onRemoveCompany,
  onRemoveContact,
  ungatedForAccountingCompanyBillingContactAssociation,
  useBillingContactOnQuotesAndDealsInvoices,
  objectTypeId,
  associationsKey
}) => {
  var _associatedObject$pro;
  const associatedObject = {
    objectId: crmObject.id,
    objectTypeId,
    portalId: PortalIdParser.get(),
    properties: parsePropertiesCrmObject(crmObject)
  };
  const associatedCompany = parseCompaniesNode(crmObject);
  const associatedBillingContact = ungatedForAccountingCompanyBillingContactAssociation && useBillingContactOnQuotesAndDealsInvoices && parseBillingContactFromCrmObjectAssociation(crmObject.defaultAssociations.toCompanies);
  const associatedContacts = associatedBillingContact ? [associatedBillingContact] : parseContactsNodes(crmObject);
  const initialInvoiceCurrency = getInitialInvoiceCurrency(commerceCurrencyLimits, (_associatedObject$pro = associatedObject.properties['deal_currency_code']) === null || _associatedObject$pro === void 0 ? void 0 : _associatedObject$pro.value);
  const gqlCompanyEdges = crmObject.defaultAssociations.toCompanies.edges;
  const gqlContactEdges = crmObject.defaultAssociations.toContacts.edges;
  if (associatedCompany.length !== gqlCompanyEdges.length) {
    onRemoveCompany(gqlCompanyEdges.length - associatedCompany.length);
  }
  if (associatedContacts.length !== gqlContactEdges.length) {
    onRemoveContact(gqlContactEdges.length - associatedContacts.length);
  }
  const associatedObjects = {
    INVOICE_TO_COMPANY: associatedCompany,
    INVOICE_TO_CONTACT: associatedContacts,
    INVOICE_TO_LINE_ITEM: parseLineItemsNodes(crmObject),
    [associationsKey]: [associatedObject]
  };
  return makeInvoice({
    associatedObjects,
    portalId: PortalIdParser.get(),
    properties: getDefaultInitialInvoiceProperties(initialInvoiceCurrency, userAttributeValues[0].value, associatedCompany[0] || associatedContacts[0])
  });
};
export const parseDealWithAssociatedObjects = memoize(({
  userAttributeValues,
  crmObject,
  commerceCurrencyLimits
}, onRemoveCompany, onRemoveContact, ungatedForAccountingCompanyBillingContactAssociation, useBillingContactOnQuotesAndDealsInvoices) => {
  return parseWithAssociatedObjects({
    crmObject,
    userAttributeValues,
    commerceCurrencyLimits,
    onRemoveCompany,
    onRemoveContact,
    ungatedForAccountingCompanyBillingContactAssociation,
    useBillingContactOnQuotesAndDealsInvoices,
    objectTypeId: DEAL_TYPE_ID,
    associationsKey: 'INVOICE_TO_DEAL'
  });
});
export const parseTicketWithAssociatedObjects = memoize(({
  userAttributeValues,
  crmObject,
  commerceCurrencyLimits
}, onRemoveCompany, onRemoveContact, ungatedForAccountingCompanyBillingContactAssociation, useBillingContactOnQuotesAndDealsInvoices) => {
  return parseWithAssociatedObjects({
    crmObject,
    userAttributeValues,
    commerceCurrencyLimits,
    onRemoveCompany,
    onRemoveContact,
    ungatedForAccountingCompanyBillingContactAssociation,
    useBillingContactOnQuotesAndDealsInvoices,
    objectTypeId: TICKET_TYPE_ID,
    associationsKey: 'INVOICE_TO_TICKET'
  });
});
export const parseInvoiceWithAssociatedObjects = memoize(({
  crmObject
}) => crmObject ? makeInvoice({
  objectId: crmObject.id,
  properties: parsePropertiesCrmObject(crmObject),
  associatedObjects: {
    INVOICE_TO_COMPANY: parseCompaniesNode(crmObject),
    INVOICE_TO_CONTACT: parseContactsNodes(crmObject),
    INVOICE_TO_DEAL: parseDealsNode(crmObject),
    INVOICE_TO_LINE_ITEM: parseLineItemsNodes(crmObject),
    INVOICE_TO_QUOTE: parseQuotesNode(crmObject),
    INVOICE_TO_FEE: parseFeeNodes(crmObject),
    INVOICE_TO_TAX: parseTaxNodes(crmObject),
    INVOICE_TO_TICKET: parseTicketsNode(crmObject),
    INVOICE_TO_DISCOUNT: parseDiscountNodes(crmObject),
    INVOICE_TO_COMMERCE_PAYMENT: parseCommercePaymentNode(crmObject)
  }
}) : makeInvoice());
export const getEditInvoice = memoize(data => {
  const invoice = parseInvoiceWithAssociatedObjects(data);
  const defaultCurrencyCode = invoice.properties['hs_currency'].value;
  const initialInvoiceCurrency = getInitialInvoiceCurrency(data.commerceCurrencyLimits, defaultCurrencyCode);
  invoice.properties['hs_currency'].value = initialInvoiceCurrency;

  // This is to update old invoices that used start of day for invoice date and due date
  invoice.properties['hs_due_date'].value = I18n.moment.portalTz(Number(invoice.properties['hs_due_date'].value)).endOf('day').valueOf();
  invoice.properties['hs_invoice_date'].value = I18n.moment.portalTz(Number(invoice.properties['hs_invoice_date'].value)).endOf('day').valueOf();
  return invoice;
});
export const getClonedInvoice = memoize((data, paymentsEligibility) => {
  const invoice = parseInvoiceWithAssociatedObjects(data);
  const defaultCurrencyCode = invoice.properties['hs_currency'].value;
  const initialInvoiceCurrency = getInitialInvoiceCurrency(data.commerceCurrencyLimits, defaultCurrencyCode);
  const hasBillingAddress = COMPANY_OVERRIDE_ADDRESS_PROPERTIES.some(addressKey => invoice.properties[addressKey] && !!invoice.properties[addressKey].value);
  const oldInvoiceDateDiff = getDifferenceBetweenDates(getInvoiceProperty(invoice, 'hs_invoice_date'), getInvoiceProperty(invoice, 'hs_due_date'));
  const existingInvoicePaymentCollectionMethod = getInvoiceProperty(invoice, HS_INVOICE_PAYMENT_COLLECTION_METHOD);
  const newInvoicePaymentCollectionMethod = existingInvoicePaymentCollectionMethod === INVOICE_PROPERTY_VALUES[HS_INVOICE_PAYMENT_COLLECTION_METHOD].AUTOMATIC ? INVOICE_PROPERTY_VALUES[HS_INVOICE_PAYMENT_COLLECTION_METHOD].MANUAL : existingInvoicePaymentCollectionMethod;
  const filteredProperties = filterOutInvoicePropertiesByKeys(invoice.properties, ['hs_tax_id']);
  const shouldResetShippingAddressProperties = !!paymentsEligibility && !isAccountApprovedForPayments(paymentsEligibility);
  return Object.assign({}, invoice, {
    associatedObjects: Object.assign({}, invoice.associatedObjects, {
      INVOICE_TO_COMMERCE_PAYMENT: []
    }),
    objectId: null,
    properties: Object.assign({}, filteredProperties, {
      hs_currency: makeNewProperty('hs_currency', initialInvoiceCurrency),
      hs_invoice_date: makeNewProperty('hs_invoice_date', getCurrentDateInPortalTz()),
      hs_due_date: makeNewProperty('hs_due_date', getDefaultDueDate(oldInvoiceDateDiff))
    }, !hasBillingAddress && Object.assign({}, getCompanyAddressProperties(invoice.associatedObjects.INVOICE_TO_COMPANY[0] || invoice.associatedObjects.INVOICE_TO_CONTACT[0])), shouldResetShippingAddressProperties && resetShippingAddressProperties, {
      hs_invoice_status: makeNewProperty('hs_invoice_status', 'draft'),
      [HS_INVOICE_PAYMENT_COLLECTION_METHOD]: makeNewProperty(HS_INVOICE_PAYMENT_COLLECTION_METHOD, newInvoicePaymentCollectionMethod)
    })
  });
});
export const parsePropertyDefinitions = memoize(propertyGroups => {
  if (!propertyGroups) return {};
  const propertiesArray = propertyGroups.allPropertyGroups.reduce((acc, propertyGroup) => {
    acc.push(...propertyGroup.allPropertyDefinitions);
    return acc;
  }, []);
  const properties = {};
  propertiesArray.forEach(property => {
    properties[property.name] = property;
  });
  return properties;
});
export const parseInitialInvoice = (data, objectTypeId, operation, onRemoveCompany, onRemoveContact, ungatedForAccountingCompanyBillingContactAssociation, useBillingContactOnQuotesAndDealsInvoices, propertyBlockList, paymentsEligibility) => {
  switch (objectTypeId) {
    case INVOICE_TYPE_ID:
      {
        if (operation === InvoiceOperation.CLONE) {
          return getClonedInvoice(data, paymentsEligibility);
        }
        return getEditInvoice(data);
      }
    case DEAL_TYPE_ID:
      {
        return parseDealWithAssociatedObjects(data, onRemoveCompany, onRemoveContact, ungatedForAccountingCompanyBillingContactAssociation, useBillingContactOnQuotesAndDealsInvoices);
      }
    case QUOTE_TYPE_ID:
      {
        return parseQuoteWithAssociatedObjects(data, onRemoveCompany, onRemoveContact, ungatedForAccountingCompanyBillingContactAssociation, useBillingContactOnQuotesAndDealsInvoices);
      }
    case COMPANY_TYPE_ID:
      {
        return parseCompanyWithAssociation(data, ungatedForAccountingCompanyBillingContactAssociation);
      }
    case CONTACT_TYPE_ID:
      {
        return parseContactWithAssociation(data);
      }
    case PRODUCT_TYPE_ID:
      {
        return parseProduct(data, propertyBlockList);
      }
    case TICKET_TYPE_ID:
      {
        return parseTicketWithAssociatedObjects(data, onRemoveCompany, onRemoveContact, ungatedForAccountingCompanyBillingContactAssociation, useBillingContactOnQuotesAndDealsInvoices);
      }
    case null:
      {
        return getBlankInvoice(data, {}, objectTypeId);
      }
    default:
      throw new Error('Unexpected objectTypeId in deriveInitialInvoice');
  }
};
import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { AllHubProducts, calculateHighestLevelFromProducts } from '../utils/productLevels';
export const fetchProductLevels = products => {
  const queryParams = new URLSearchParams(products.map(product => ['hubField', `${product}ProductLevel`]));
  return http.get(`iris-read/v1/app?${queryParams.toString()}&portalId=${PortalIdParser.get()}`).then(response => response.hub);
};
export function fetchHighestProductLevel(products = AllHubProducts) {
  return fetchProductLevels(products).then(productLevels => {
    return calculateHighestLevelFromProducts(productLevels);
  });
}
import { useMutation } from '@apollo/client';
import { PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useCallback } from 'react';
import { getProperty } from 'customer-data-objects/objects/ObjectAccessors';
import { UPDATE_PRODUCT_MUTATION } from '../graphQL/queries';
export function useUpdateProduct({
  onUpdate,
  onError
}) {
  const [updateProductMutation] = useMutation(UPDATE_PRODUCT_MUTATION, {});
  const handleConfirmUpdate = useCallback(({
    subject: newSubject,
    modifiedProperties,
    addAnother
  }) => {
    if (modifiedProperties.size > 0) {
      const modifiedPropertyValues = [...modifiedProperties].map(modifiedPropertyKey => ({
        name: modifiedPropertyKey || '',
        value: getProperty(newSubject, modifiedPropertyKey)
      }));
      updateProductMutation({
        variables: {
          propertyUpdatesInput: {
            // created products should have an ID
            objectId: newSubject.objectId,
            objectType: PRODUCT_TYPE_ID,
            properties: modifiedPropertyValues
          }
        }
      }).then(() => {
        if (onUpdate) {
          Promise.resolve(onUpdate({
            subject: newSubject,
            modifiedProperties,
            addAnother
          })).catch(onError);
        }
      }).catch(onError);
    }
  }, [updateProductMutation, onError, onUpdate]);
  return handleConfirmUpdate;
}
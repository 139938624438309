import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { makeProperty } from '../utils/invoice';
import { makeContact } from '../utils/invoice';
const CRM_SEARCH_URL = 'crm-search/search';
export const CONTACT_TYPE_ID = '0-1';
export const mapCrmSearchContactToEmailContact = crmSearchContact => {
  var _crmSearchContact$pro, _crmSearchContact$pro2, _crmSearchContact$pro3, _crmSearchContact$pro4, _crmSearchContact$pro5, _crmSearchContact$pro6;
  return {
    objectId: crmSearchContact.objectId,
    portalId: crmSearchContact.portalId,
    properties: {
      email: {
        name: 'email',
        value: (_crmSearchContact$pro = (_crmSearchContact$pro2 = crmSearchContact.properties.email) === null || _crmSearchContact$pro2 === void 0 ? void 0 : _crmSearchContact$pro2.value) !== null && _crmSearchContact$pro !== void 0 ? _crmSearchContact$pro : ''
      },
      firstname: {
        name: 'firstname',
        value: (_crmSearchContact$pro3 = (_crmSearchContact$pro4 = crmSearchContact.properties.firstname) === null || _crmSearchContact$pro4 === void 0 ? void 0 : _crmSearchContact$pro4.value) !== null && _crmSearchContact$pro3 !== void 0 ? _crmSearchContact$pro3 : ''
      },
      lastname: {
        name: 'lastname',
        value: (_crmSearchContact$pro5 = (_crmSearchContact$pro6 = crmSearchContact.properties.lastname) === null || _crmSearchContact$pro6 === void 0 ? void 0 : _crmSearchContact$pro6.value) !== null && _crmSearchContact$pro5 !== void 0 ? _crmSearchContact$pro5 : ''
      }
    },
    objectTypeId: crmSearchContact.objectTypeId
  };
};
export const fetchEmailContacts = async input => {
  const {
    results
  } = await http.post(CRM_SEARCH_URL, {
    data: {
      count: 20,
      objectTypeId: CONTACT_TYPE_ID,
      offset: 0,
      portalId: PortalIdParser.get(),
      query: input,
      requestOptions: {
        properties: ['email', 'firstname', 'lastname']
      }
    }
  });
  return results.map(mapCrmSearchContactToEmailContact);
};
export const fetchContactByEmail = async email => {
  const {
    results
  } = await http.post(CRM_SEARCH_URL, {
    data: {
      count: 1,
      objectTypeId: CONTACT_TYPE_ID,
      offset: 0,
      portalId: PortalIdParser.get(),
      filterGroups: [{
        filters: [{
          operator: 'EQ',
          property: 'email',
          value: email
        }]
      }],
      requestOptions: {
        properties: ['email', 'firstname', 'lastname']
      }
    }
  });
  if (results && results.length > 0) {
    var _contact$properties$e, _contact$properties$f, _contact$properties$l;
    const contact = results[0];
    return makeContact({
      objectId: contact.objectId,
      portalId: contact.portalId,
      objectTypeId: contact.objectTypeId,
      properties: {
        email: makeProperty('email', ((_contact$properties$e = contact.properties.email) === null || _contact$properties$e === void 0 ? void 0 : _contact$properties$e.value) || ''),
        firstname: makeProperty('firstname', ((_contact$properties$f = contact.properties.firstname) === null || _contact$properties$f === void 0 ? void 0 : _contact$properties$f.value) || ''),
        lastname: makeProperty('lastname', ((_contact$properties$l = contact.properties.lastname) === null || _contact$properties$l === void 0 ? void 0 : _contact$properties$l.value) || '')
      }
    });
  }
  return null;
};
export const fetchCrmSearch = async query => {
  return await http.post(CRM_SEARCH_URL, {
    data: query
  });
};
import { ONBOARDING_SCOPES } from '../scopeConstants';
export const TRIAL_MARKETING_HUB_PROFESSIONAL = 'trial-marketing-hub-professional';
export const TRIAL_SALES_HUB_PROFESSIONAL = 'trial-sales-hub-professional';
export const TRIAL_SERVICE_HUB_PROFESSIONAL = 'trial-service-hub-professional';
export const TRIAL_CMS_HUB_PROFESSIONAL = 'trial-cms-hub-professional';
export const TRIAL_OPERATIONS_HUB_PROFESSIONAL = 'trial-operations-hub-professional';
export const TRIAL_MARKETING_HUB_ENTERPRISE = 'trial-marketing-hub-enterprise';
export const TRIAL_SALES_HUB_ENTERPRISE = 'trial-sales-hub-enterprise';
export const TRIAL_SERVICE_HUB_ENTERPRISE = 'trial-service-hub-enterprise';
export const TRIAL_CMS_HUB_ENTERPRISE = 'trial-cms-hub-enterprise';
export const TRIAL_OPERATIONS_HUB_ENTERPRISE = 'trial-operations-hub-enterprise';
export const TRIAL_SCOPES = {
  TRIAL_MARKETING_HUB_PROFESSIONAL,
  TRIAL_SALES_HUB_PROFESSIONAL,
  TRIAL_SERVICE_HUB_PROFESSIONAL,
  TRIAL_CMS_HUB_PROFESSIONAL,
  TRIAL_OPERATIONS_HUB_PROFESSIONAL,
  TRIAL_MARKETING_HUB_ENTERPRISE,
  TRIAL_SALES_HUB_ENTERPRISE,
  TRIAL_SERVICE_HUB_ENTERPRISE,
  TRIAL_CMS_HUB_ENTERPRISE,
  TRIAL_OPERATIONS_HUB_ENTERPRISE
};
export const PRO_TRIAL_SCOPES = [TRIAL_MARKETING_HUB_PROFESSIONAL, TRIAL_SALES_HUB_PROFESSIONAL, TRIAL_SERVICE_HUB_PROFESSIONAL, TRIAL_CMS_HUB_PROFESSIONAL, TRIAL_OPERATIONS_HUB_PROFESSIONAL];
export const ENTERPRISE_TRIAL_SCOPES = [TRIAL_MARKETING_HUB_ENTERPRISE, TRIAL_SALES_HUB_ENTERPRISE, TRIAL_SERVICE_HUB_ENTERPRISE, TRIAL_CMS_HUB_ENTERPRISE, TRIAL_OPERATIONS_HUB_ENTERPRISE];
export const ALL_TRIAL_SCOPES = [...PRO_TRIAL_SCOPES, ...ENTERPRISE_TRIAL_SCOPES];
export const ONBOARDING_SCOPES_TO_TRIAL_SCOPES = {
  [ONBOARDING_SCOPES.MARKETING_PRO]: TRIAL_MARKETING_HUB_PROFESSIONAL,
  [ONBOARDING_SCOPES.SALES_PRO]: TRIAL_SALES_HUB_PROFESSIONAL,
  [ONBOARDING_SCOPES.SERVICE_PRO]: TRIAL_SERVICE_HUB_PROFESSIONAL,
  [ONBOARDING_SCOPES.CMS_PRO]: TRIAL_CMS_HUB_PROFESSIONAL,
  [ONBOARDING_SCOPES.OPS_PRO]: TRIAL_OPERATIONS_HUB_PROFESSIONAL,
  [ONBOARDING_SCOPES.MARKETING_ENTERPRISE]: TRIAL_MARKETING_HUB_ENTERPRISE,
  [ONBOARDING_SCOPES.SALES_ENTERPRISE]: TRIAL_SALES_HUB_ENTERPRISE,
  [ONBOARDING_SCOPES.SERVICE_ENTERPRISE]: TRIAL_SERVICE_HUB_ENTERPRISE,
  [ONBOARDING_SCOPES.CMS_ENTERPRISE]: TRIAL_CMS_HUB_ENTERPRISE,
  [ONBOARDING_SCOPES.OPS_ENTERPRISE]: TRIAL_OPERATIONS_HUB_ENTERPRISE
};
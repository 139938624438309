// Originally found at: https://git.hubteam.com/HubSpot/ui-addon-conversations-settings/blob/89335dcab597f7b135a36693b1755b57fb76e997/static/js/schema/events/events.ts
export const trackClickManagePaidUsers = type => ({
  key: 'clicked-manage-paid-users',
  properties: {
    action: 'clicked to manage paid users',
    type
  }
});
export const trackClickManageSeats = () => ({
  key: 'clicked-manage-seats',
  properties: {
    action: 'clicked to manage seats'
  }
});
import baseStyled, { css, useTheme } from 'styled-components';

// borrowed from https://hubspot.slack.com/archives/C0857GUDC7P/p1735928925392919
// Original Author Shea Hunter Belsky

// Re-exporting styled allows consumers to gain full TypeScript typing
// when working with Trellis tokens. Otherwise it has to be manually coerced
// in every single implementation, and that's a lot of work.
export const styledWithTrellis = baseStyled;
export const cssWithTrellis = css;
export function useTrellisTheme() {
  return useTheme();
}
import { buildCommonMessage } from 'conversations-message-history/common-message-format/operators/buildCommonMessage';
import { setGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import Sender from 'conversations-message-history/common-message-format/records/Sender';
import CommonReply from 'conversations-thread-data/common-reply/public/records/CommonReply';
import { EMAIL_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { getSenders } from 'conversations-thread-data/channel-configuration/public/operators';
import { isGenericChannelId } from 'conversations-thread-data/channel-configuration/public/types';
// @ts-expect-error module not typed
import { setCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplySetters';
import { getChannelId, getId, getInstanceDeliveryIdentifier, getIsActive, getIsAuthorized } from 'conversations-thread-data/channel-instances/public/operators';
import { COMPOSITION_THREAD_ID } from 'conversations-thread-data/communicator/constants/compositionThreadId';
import { COMPOSITION } from 'conversations-thread-data/common-reply/public/constants/commonReplyTypes';
import { buildEmptyEmailComposition } from './buildEmptyEmailComposition';
import { buildReplyId } from './buildReplyId';
import { buildEmptyMessageDefaults } from './buildEmptyMessageDefaults';
function buildBaseCommonMessage({
  capabilitiesBlob,
  channelInstances,
  channelId,
  userId
}) {
  const channelInstancesForChannel = channelInstances && channelInstances.filter(instance => getChannelId(instance) === channelId && getIsActive(instance) && getIsAuthorized(instance));
  const firstValidSendingChannelInstance = channelInstancesForChannel && channelInstancesForChannel.first();
  const instanceId = firstValidSendingChannelInstance && getId(firstValidSendingChannelInstance);
  const senderConfig = getSenders(capabilitiesBlob);
  const channelInstanceSender = Sender({
    actorId: `A-${userId}`,
    deliveryIdentifier: firstValidSendingChannelInstance && getInstanceDeliveryIdentifier(firstValidSendingChannelInstance),
    senderField: senderConfig && senderConfig.fieldName
  });
  const genericChannelId = isGenericChannelId(channelId) ? channelId : null;
  return buildCommonMessage({
    genericChannelId,
    channelInstanceId: instanceId,
    senders: [channelInstanceSender]
  });
}
function buildBaseReply({
  capabilitiesBlob,
  channelId,
  channelInstances,
  connectedAccounts,
  inboxId,
  userId,
  replyKey,
  defaultTeamSendFrom
}) {
  const replyId = buildReplyId(`${inboxId}`, `${COMPOSITION}`, replyKey || COMPOSITION_THREAD_ID);
  switch (channelId) {
    case EMAIL_GENERIC_CHANNEL_ID:
      return buildEmptyEmailComposition({
        connectedAccounts,
        channelInstances,
        inboxId,
        replyId,
        userId,
        defaultTeamSendFrom
      });
    // TODO: build WhatsApp empty reply with default channel instance id
    case WHATSAPP_GENERIC_CHANNEL_ID:
    default:
      return CommonReply({
        replyId,
        commonMessage: buildBaseCommonMessage({
          capabilitiesBlob,
          channelId,
          channelInstances,
          userId
        })
      });
  }
}
export function buildCompositionEmptyReply({
  capabilitiesBlob,
  channelId,
  channelInstances,
  connectedAccounts,
  inboxId,
  userId,
  replyKey,
  defaultMessageValue,
  defaultTeamSendFrom
}) {
  const commonReply = buildBaseReply({
    capabilitiesBlob,
    channelId,
    channelInstances,
    connectedAccounts,
    inboxId,
    userId,
    replyKey,
    defaultMessageValue,
    defaultTeamSendFrom
  });
  const commonMessageFromReply = getCommonMessage(commonReply);
  const canSetChannelIdOnReply = commonMessageFromReply && isGenericChannelId(channelId);
  if (canSetChannelIdOnReply) {
    const commonMessageWithGenericChannelId = setGenericChannelId(channelId, commonMessageFromReply);
    const commonMessageWithDefaults = buildEmptyMessageDefaults({
      commonMessage: commonMessageWithGenericChannelId,
      defaultMessageValue
    });
    return setCommonMessage(commonMessageWithDefaults, commonReply);
  }
  return commonReply;
}
import Raven from 'raven-js';
import { Metrics } from '../../utils/Metrics';
export function errorHasCustomState(error) {
  return error && error.errorState;
}

/**
 * @description
 * A utility function to report errors to Sentry.
 * Note: network errors will be ignored if passed in raw as error
 *
 * @param {Error} error
 * @param {Raven.RavenOptions} options
 * @returns
 */
export const logError = (error, options) => {
  if ('xhr' in error) {
    // eslint-disable-next-line no-console
    console.warn('Network errors should not be directly logged to Sentry. The logging has been ignored.');
    return;
  }
  Metrics.counter('handled-js-errors');
  Raven.captureException(error, options);

  // eslint-disable-next-line no-console
  console.debug(`Error reported with '${error.message}'`, Object.assign({}, options && options.extra));
};
import { assertIsTierArray, assertIsTierPriceArray, assertIsTierRangeArray } from 'customer-data-objects/product/Pricing';
import { rethrowError } from './promiseHandler';
export function parseTiers(value, throwOnError = true) {
  if (value) {
    try {
      const tiers = JSON.parse(value);
      assertIsTierArray(tiers);
      return tiers;
    } catch (error) {
      if (throwOnError) {
        rethrowError(error);
      }
    }
  }
  return undefined;
}
export const stringifyTiers = tiers => {
  return JSON.stringify(tiers);
};
export function parseTierRanges(value, throwOnError = true) {
  if (value) {
    try {
      const tierRanges = JSON.parse(value);
      assertIsTierRangeArray(tierRanges);
      return tierRanges;
    } catch (error) {
      if (throwOnError) {
        rethrowError(error);
      }
    }
  }
  return undefined;
}
export const stringifyTierRanges = ranges => {
  return JSON.stringify(ranges);
};
export function parseTierPrices(value, throwOnError = true) {
  if (value) {
    try {
      const tierPrices = JSON.parse(value);
      assertIsTierPriceArray(tierPrices);
      return tierPrices.sort((a, b) => a.index - b.index);
    } catch (error) {
      if (throwOnError) {
        rethrowError(error);
      }
    }
  }
  return undefined;
}
export const stringifyTierPrices = prices => {
  return JSON.stringify(prices);
};

/**
 * Represents a tier range with an optional price
 */

/**
 * Creates a Map of currency codes to arrays of tier ranges with their prices
 *
 * @example
 * // Example 1: Prices with currency codes
 * const tierRanges = [
 *   { index: 0, start: 0, end: 9 },
 *   { index: 1, start: 10, end: 19 },
 *   { index: 2, start: 20 }
 * ];
 * const tierPrices = [
 *   { index: 0, price: 3.5, currencyCode: 'USD' },
 *   { index: 1, price: 2.94, currencyCode: 'USD' }
 * ];
 * const result1 = combineTierRangesAndPrices(tierRanges, tierPrices);
 * // Result: Map(1) {
 * //   'USD' => [
 * //     { start: 0, end: 9, price: 3.5, index: 0 },
 * //     { start: 10, end: 19, price: 2.94, index: 1 },
 * //     { start: 20, price: undefined, index: 2 }
 * //   ]
 * // }
 *
 * // Example 2: Prices without currency codes will have an undefined key
 * const tierPricesNoCurrency = [
 *   { index: 0, price: 3.5 },
 *   { index: 1, price: 2.94 },
 *   { index: 2, price: 1 }
 * ];
 * const result2 = combineTierRangesAndPrices(tierRanges, tierPricesNoCurrency);
 * // Result: Map(1) {
 * //   undefined => [
 * //     { start: 0, end: 9, price: 3.5, index: 0 },
 * //     { start: 10, end: 19, price: 2.94, index: 1 },
 * //     { start: 20, price: 1, index: 2 }
 * //   ]
 * // }
 */
export const combineTierRangesAndPrices = (tierRanges, tierPrices) => {
  return tierPrices.reduce((acc, tierPrice) => {
    const currencyCode = tierPrice.currency;

    // Get existing ranges for this currency or create new ones
    const currencyRanges = acc.get(currencyCode) || tierRanges.map((tierRange, index) => {
      const range = {
        start: tierRange.start,
        price: undefined,
        index
      };
      if (tierRange.end !== undefined) {
        range.end = tierRange.end;
      }
      return range;
    });

    // Update the price for the matching range and add an index
    const updatedRanges = currencyRanges.map(tierRange => tierRange.index === tierPrice.index ? Object.assign({}, tierRange, {
      price: tierPrice.price
    }) : tierRange);
    acc.set(currencyCode, updatedRanges);
    return acc;
  }, new Map());
};

/**
 * Gets the tier ranges and prices for a specific currency
 *
 * @param combinedTierPricesAndRanges Map of currency codes to tier ranges with prices
 * @param currencyCode The currency code to get prices for
 * @returns Array of tier ranges with prices for the specified currency or undefined if there is none for that currency
 */
export const getTierRangesAndPricesForCurrency = (combinedTierRangesAndPrices, currencyCode) => {
  return combinedTierRangesAndPrices.get(currencyCode);
};
import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
import { EMAIL_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID, SMS_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { isConfigKeyGenericChannelId } from 'conversations-thread-data/channel-configuration/public/operators';
/**
 * Maps GenericChannelIds to their canonical human-friendly URL fragment.
 */
const HUBSPOT_CHANNEL_TO_FRAGMENT_MAP = {
  UNSET: 'new',
  [THREAD_COMMENT]: 'comment',
  [LIVE_CHAT_GENERIC_CHANNEL_ID]: 'live-chat',
  [EMAIL_GENERIC_CHANNEL_ID]: 'email',
  [WHATSAPP_GENERIC_CHANNEL_ID]: 'whatsapp',
  [SMS_GENERIC_CHANNEL_ID]: 'sms',
  [FB_MESSENGER_GENERIC_CHANNEL_ID]: 'fb-messenger'
};

/**
 * Maps some non-canonical URL fragments to valid GenericChannelIds.
 * Only includes entries that are not already inside of HUBSPOT_CHANNEL_TO_FRAGMENT_MAP.
 *
 * For extra context, see this conversation in Slack:
 * https://hubspot.slack.com/archives/C04MD1BAD2L/p1709763026419739?thread_ts=1709747899.878659&cid=C04MD1BAD2L
 *
 * There are codepaths elsewhere in the product that point to these fragments, so we
 * need to support them.
 */
const FRAGMENT_ALIAS_MAP = {
  'email-reply-editor': EMAIL_GENERIC_CHANNEL_ID,
  'comment-editor': THREAD_COMMENT,
  comments: THREAD_COMMENT,
  'live-chat-reply-editor': LIVE_CHAT_GENERIC_CHANNEL_ID,
  chat: LIVE_CHAT_GENERIC_CHANNEL_ID,
  'fb-messenger-reply-editor': FB_MESSENGER_GENERIC_CHANNEL_ID
};
function isFragmentMapKey(key) {
  return Boolean(HUBSPOT_CHANNEL_TO_FRAGMENT_MAP[key]);
}
export function getUrlFragmentFromConfigKey({
  editorConfigKey,
  customViewFragmentMap = {}
}) {
  if (isFragmentMapKey(editorConfigKey)) {
    return HUBSPOT_CHANNEL_TO_FRAGMENT_MAP[editorConfigKey];
  }
  if (typeof editorConfigKey === 'string' && customViewFragmentMap[editorConfigKey]) {
    return customViewFragmentMap[editorConfigKey];
  }
  return `${editorConfigKey}`;
}
export function geConfigKeyFromUrlFragment({
  fragment,
  customViewFragmentMap = {}
}) {
  const mappedEditorConfigKey = Object.keys(HUBSPOT_CHANNEL_TO_FRAGMENT_MAP).find(key => HUBSPOT_CHANNEL_TO_FRAGMENT_MAP[key] === fragment) || FRAGMENT_ALIAS_MAP[fragment] || customViewFragmentMap[fragment];
  if (!mappedEditorConfigKey) {
    // #reply-editor is a very common redirect target, but we handle it by
    // using the default channel logic
    const channelId = Number(fragment);
    return channelId;
  }
  const editorConfigKey = isConfigKeyGenericChannelId(mappedEditorConfigKey) ? Number(mappedEditorConfigKey) : mappedEditorConfigKey;
  return editorConfigKey;
}
import { useFetchAiSettingsAccess } from 'ai-settings-ui-library/hooks/useFetchAiSettingsAccess';
export const useIsDisabledByAccessControls = () => {
  const {
    conversationDataEnabled,
    generativeAiEnabled,
    crmPropertiesEnabled,
    isAccessRequestLoading
  } = useFetchAiSettingsAccess();
  return {
    isDisabledByAccessControls: !isAccessRequestLoading && (!conversationDataEnabled || !generativeAiEnabled || !crmPropertiesEnabled),
    isAccessRequestLoading
  };
};
import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const sortByPriority = (vm1PropertyValue, vm2PropertyValue) => {
  const priorities = {
    LOW: 0,
    MEDIUM: 1,
    HIGH: 2
  };
  const priority1 = priorities[vm1PropertyValue];
  const priority2 = priorities[vm2PropertyValue];
  if (priority1 < priority2) {
    return -1;
  } else if (priority2 < priority1) {
    return 1;
  } else return 0;
};
const sortByObjectId = (vm1ObjectId, vm2ObjectId) => vm1ObjectId < vm2ObjectId ? -1 : 1;
function getSortablePropertyValues(property, vm1, vm2) {
  // Parse out the value of the sort property
  if (property) {
    return [vm1.objectPropertyMap[TICKET_TYPE_ID][property], vm2.objectPropertyMap[TICKET_TYPE_ID][property]];
  }

  // If there's no sort property defined, sort by sort timestamp
  if (vm1.sortTimestamp && vm2.sortTimestamp) {
    return [`${vm1.sortTimestamp}`, `${vm2.sortTimestamp}`];
  }

  // Otherwise, sort by hs_last_message_sent_at
  return [vm1.objectPropertyMap[TICKET_TYPE_ID]['hs_last_message_sent_at'], vm2.objectPropertyMap[TICKET_TYPE_ID]['hs_last_message_sent_at']];
}
export const sortHelpDeskViewMembers = ({
  sortDirection,
  sortOverride,
  property
}) => (vm1, vm2) => {
  if (vm1 === vm2) {
    return 0;
  }

  // When sortProperty exists, sortDirection should always be 'ascending' or
  // 'descending'. The default sortDirection of 'none' should return -1 here
  // because by default we sort by sortTimestamp DESCENDING.
  const SORT_DIRECTION_ADJUSTED_VALUE = sortDirection === 'ascending' ? 1 : -1;
  const [vm1Prop, vm2Prop] = getSortablePropertyValues(property, vm1, vm2);

  //If the values of the sort properties are equal,
  //and no custom sort exists, just sort by objectID.
  if (vm1Prop === vm2Prop && !sortOverride || !vm1Prop && !vm2Prop) {
    return sortByObjectId(vm1.objectKey.objectId, vm2.objectKey.objectId) * SORT_DIRECTION_ADJUSTED_VALUE;
  }

  //If either HDVM is missing the sort property, put that HDVM last.
  if (!vm1Prop) {
    return 1;
  }
  if (!vm2Prop) {
    return -1;
  }

  //Handle specific properties that aren't simple string or number comparisons.
  if (property && sortOverride) {
    return SORT_DIRECTION_ADJUSTED_VALUE * sortOverride(vm1Prop, vm2Prop, vm1.objectPropertyMap[TICKET_TYPE_ID], vm2.objectPropertyMap[TICKET_TYPE_ID], property);
  }

  //If the value of sort property is a string representing a number, sort numerically.
  //Comparing +variable === +variable is a very reliable and fast way to check if a variable
  //is either a number or a string representation of a number.
  // eslint-disable-next-line no-self-compare
  if (+vm1Prop === +vm1Prop && +vm2Prop === +vm2Prop) {
    return +vm1Prop < +vm2Prop ? -SORT_DIRECTION_ADJUSTED_VALUE : SORT_DIRECTION_ADJUSTED_VALUE;
  }

  //Otherwise, use string comparisons.
  return vm1Prop.toLocaleLowerCase() < vm2Prop.toLocaleLowerCase() ? -SORT_DIRECTION_ADJUSTED_VALUE : SORT_DIRECTION_ADJUSTED_VALUE;
};
export const Scopes = Object.freeze({
  user: {
    jitaUser: 'jita-user' // https://tools.hubteamqa.com/launch/scopes/1399/parcels
  },
  subscription: {
    access: 'subscriptions-access',
    // https://tools.hubteamqa.com/launch/scopes/2201/parcels
    write: 'subscriptions-write',
    // https://tools.hubteamqa.com/launch/scopes/2200/parcels
    read: 'subscriptions-read',
    // https://tools.hubteamqa.com/launch/scopes/2199/parcels
    delete: 'subscriptions-delete' // https://tools.hubteamqa.com/launch/scopes/5694/parcels
  },
  invoices: {
    read: 'invoices-read' // https://tools.hubteamqa.com/launch/scopes/2196/parcels
  }
});

/**
 * @deprecated Use Scopes instead */
export const SCOPES = Object.freeze({
  /**
   * @deprecated Use Scopes.user.jitaUser instead
   */
  JITA_USER: Scopes.user.jitaUser,
  /**
   * @deprecated Use Scopes.subscription.access instead
   */
  SUBSCRIPTIONS_ACCESS: Scopes.subscription.access,
  /**
   * @deprecated Use Scopes.subscription.write instead
   */
  SUBSCRIPTIONS_WRITE: Scopes.subscription.write,
  /**
   * @deprecated Use Scopes.invoices.read instead
   */
  INVOICES_READ: Scopes.invoices.read
});
export const URLS = Object.freeze({
  KBCancelSubscription: 'https://knowledge.hubspot.com/payments/manage-subscriptions-for-recurring-payments#cancel-subscriptions',
  KBUpdatePaymentMethod: 'https://knowledge.hubspot.com/payments/update-payment-method-for-failed-subscription-payments',
  KBUpdateBouncedEmail: 'https://knowledge.hubspot.com/email/how-can-i-send-an-email-to-a-contact-that-has-previously-bounced#how-hubspot-handles-bounced-contacts',
  KBEmailConfirmation: 'https://knowledge.hubspot.com/payments/edit-subscriptions#email-confirmation',
  KBPause: 'https://knowledge.hubspot.com/payments/edit-subscriptions#pause-and-resume-a-subscription',
  KBPauseTooltip: 'https://knowledge.hubspot.com/payments/manage-subscriptions-for-recurring-payments',
  KBDeleteSubscription: 'https://knowledge.hubspot.com/subscriptions/manage-subscriptions-for-recurring-payments#delete-a-subscription'
});
export const TEST_IDS = Object.freeze({
  Cancel: 'subscription-cancellation-modal',
  EditNextBillingDate: 'edit-next-payment-date-modal',
  UpdatePaymentMethod: 'update-payment-method-modal',
  Pause: 'pause-modal',
  Resume: 'resume-modal',
  Delete: 'delete-modal'
});
export const IS_KITCHEN_SINK = window.location.pathname.includes('kitchen-sink');
export const EMPTY_VALUE = '--';
export const SUBSCRIPTION_SETTINGS_TIP_ID = 'commerce-subscription-lib:subscription-settings-tip';
import Raven from 'raven-js';
import { createAsyncLocalSuperstore } from 'superstore';
const getStore = () => {
  let storeInstance;
  try {
    storeInstance = createAsyncLocalSuperstore({
      namespace: 'ai-addon-copilot-preview'
    });
  } catch (error) {
    if (error instanceof Error) {
      Raven.captureException(error, {
        level: 'warning'
      });
    }
  }
  return storeInstance;
};
export const localSuperstore = getStore();
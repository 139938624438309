/**
 * Converts a value to a string if it is not null or undefined.
 * @param value - The value to convert
 * @returns The converted string
 */
export const convertToValue = value => value != null ? String(value) : value;

/**
 * Converts empty string to null, otherwise returns the original value.
 *
 * Cleared property's values are set to empty string - https://product.hubteam.com/docs/crm-properties/values/empty.html
 * @param value - The value to convert
 * @returns null if the value is an empty string, otherwise the original value
 */
export function fromEmptyStringToNull(value) {
  return value === '' ? null : value;
}
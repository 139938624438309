import { getIsDefault, getDisplayGlobally, getSendFromEmail
// @ts-expect-error module not typed
} from 'conversations-internal-schema/connected-email-account/operators/connectedEmailAccountGetters';
// @ts-expect-error module not typed

import { getChannelId, getId, getIsActive } from '../../../channel-instances/public/operators';
import { EMAIL_GENERIC_CHANNEL_ID } from '../../../generic-channels/public/constants';
import { findAccountByChannelInstanceId } from './findAccountByChannelInstanceId';
export const getFallbackAccount = ({
  connectedAccounts,
  channelInstances,
  defaultTeamSendFrom
}) => {
  // CASE 1: If defaultTeamSendFrom is provided, return the account that matches the defaultTeamSendFrom value
  const connectedAccount = connectedAccounts === null || connectedAccounts === void 0 ? void 0 : connectedAccounts.find(account => !!(defaultTeamSendFrom && getSendFromEmail(account) === defaultTeamSendFrom.value));
  if (connectedAccount) return connectedAccount;

  // CASE 2: If defaultSendFrom is not provided,
  // return the default account (the HS fallback email account)
  const defaultAccount = connectedAccounts && connectedAccounts.find(getIsDefault);
  if (getDisplayGlobally(defaultAccount)) return defaultAccount;

  // CASE 3: If there is no default account enabled,
  // return the first active email account
  let fallbackAccount;
  const fallbackInstance = channelInstances && channelInstances.find(instance => {
    const isActiveEmailInstance = getChannelId(instance) === EMAIL_GENERIC_CHANNEL_ID && getIsActive(instance);
    if (!isActiveEmailInstance) return false;

    // ensure there is a matching connected account
    fallbackAccount = findAccountByChannelInstanceId({
      connectedAccounts,
      channelInstanceId: getId(instance)
    });

    // we want to ensure the fallback is not the default account,
    // since the default is always `isActive: true`
    return fallbackAccount && !getIsDefault(fallbackAccount);
  });
  if (fallbackInstance && fallbackAccount) return fallbackAccount;

  // CASE 4: If there is no fallback account, return the first active email account
  return connectedAccounts && connectedAccounts.first();
};
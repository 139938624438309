import { useState, useCallback } from 'react';
import { updateEmailReminders } from '../api/InvoicesAPI';
const useEmailReminders = (refetchInvoice, setIsModalOpen, onSuccess, onFailure, onFailureGeneric) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const onConfirmEmailReminders = useCallback((objectId, emailReminders) => {
    if (!objectId) {
      onFailureGeneric();
      setIsModalOpen(false);
      return;
    }
    const upcomingReminderDatesToCancel = emailReminders.filter(reminder => !reminder.checked).map(reminder => reminder.date);
    if (upcomingReminderDatesToCancel.length > 0) {
      setIsLoading(true);
      updateEmailReminders(objectId, upcomingReminderDatesToCancel).then(() => {
        refetchInvoice(objectId).then(() => {
          onSuccess();
        }).catch(() => {
          onFailureGeneric();
        }).finally(() => {
          setIsModalOpen(false);
          setIsLoading(false);
        });
      }).catch(() => {
        onFailure();
        setIsFailed(true);
        setIsLoading(false);
        setIsModalOpen(false);
      });
    } else {
      onSuccess();
      setIsModalOpen(false);
    }
  }, [refetchInvoice, onFailure, onSuccess, onFailureGeneric, setIsModalOpen]);
  return {
    emailRemindersLoading: isLoading,
    emailRemindersFailed: isFailed,
    onConfirmEmailReminders
  };
};
export default useEmailReminders;
export const isProcessorSupportedCurrency = currencyOption => currencyOption.isProcessorSupportedCurrency;
export const isHomeCurrency = currencyOption => currencyOption.isHomeCurrency;
export const getInitialInvoiceCurrency = (commerceCurrencyLimits, defaultCurrencyCode) => {
  const homeCurrency = commerceCurrencyLimits.filter(isHomeCurrency)[0];
  const defaultCurrencyCodeExists = commerceCurrencyLimits.some(({
    currencyCode
  }) => currencyCode === defaultCurrencyCode);
  if (defaultCurrencyCodeExists) {
    return defaultCurrencyCode;
  }
  return homeCurrency.currencyCode;
};
export const getIsCurrencyCheckoutSupported = (currency, commerceCurrencyLimits) => {
  const currencyLimit = commerceCurrencyLimits.find(({
    currencyCode
  }) => currencyCode === currency);
  return !!currencyLimit && isProcessorSupportedCurrency(currencyLimit);
};
import { useCallback, useMemo, useRef } from 'react';
import { is } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { indexedDataInvariant } from '../invariants/indexedDataInvariant';
import { idsSetInvariant } from '../invariants/idsSetInvariant';
import { useIndexedAsyncFetcher } from './useIndexedAsyncFetcher';
const defaultIdsTransform = ids => ({
  ids
});

/**
 * @deprecated Use Data Fetching Client instead (and Immer if you need immutability)
 * @description a redux hook built around `useIndexedAsyncFetcher` that
 * is passed a selector, fetching action, and a set of ids to manage.
 * It will handle connecting to the store to get data, as well as
 *  wiring up the action to a dispatcher.
 *
 * It delegates data fetching logic to `useIndexedAsyncFetcher` by
 * passing in a pre-bound fetcher along with the selected IndexedAsyncData
 * record.
 * @param options
 * @param options.action an action (thunk) creator that
 * will trigger the async data to be fetched when called.
 * @param options.deferred if true, the fetcher will not fire regardless of
 * status. Defaults to false.
 * @param options.ids a required set of ids to keep track of.
 * @param [options.idsTransform] a transformer
 * function to mutate how the ids are passed to the action.
 * @param options.selector a selector that returns
 * a valid IndexedAsyncData record. It is an error for this selector
 * to return a non-IndexedAsyncData record.
 */
export const useReduxIndexedAsyncFetcher = ({
  action,
  deferred = false,
  ids,
  idsTransform = defaultIdsTransform,
  selector
}) => {
  const idsRef = useRef(ids);
  if (!is(ids, idsRef.current)) {
    idsRef.current = ids;
  }
  const internalIds = idsRef.current;
  const transformedIds = useMemo(() => {
    return idsTransform(internalIds);
  }, [internalIds, idsTransform]);
  const dispatch = useDispatch();
  const indexedAsyncData = useSelector(state => selector(state, transformedIds));
  idsSetInvariant(ids);
  indexedDataInvariant(indexedAsyncData);
  const fetcher = useCallback(({
    ids: idsToFetch
  }) => dispatch(action(idsTransform(idsToFetch))), [action, dispatch, idsTransform]);
  const {
    entries,
    retryFailed
  } = useIndexedAsyncFetcher({
    deferred,
    ids: internalIds,
    indexedAsyncData,
    fetcher
  });
  return {
    entries,
    retryFailed
  };
};
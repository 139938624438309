import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'conversations-http/clients/http';
const CONNECTED_ACCOUNTS_PREFIX = 'conversations-email/v1/connected-accounts';
const getConnectedEmailAccount = ({
  id
}) => http.get(`${CONNECTED_ACCOUNTS_PREFIX}/${id}`);
const getConnectedEmailAccounts = () => http.get(`${CONNECTED_ACCOUNTS_PREFIX}/all`);
const getConnectedEmailAccountByChannelInstanceId = ({
  channelInstanceId
}) => http.get(`${CONNECTED_ACCOUNTS_PREFIX}/channel-instance/${channelInstanceId}/non-deleted`);
const fetchConnectedEmailAccount = registerQuery({
  args: ['id'],
  fetcher: getConnectedEmailAccount,
  fieldName: 'connectedEmailAccount'
});
export const fetchConnectedEmailAccounts = registerQuery({
  fetcher: getConnectedEmailAccounts,
  fieldName: 'connectedEmailAccounts'
});
export const fetchConnectedEmailByChannelInstanceId = registerQuery({
  args: ['channelInstanceId'],
  fetcher: getConnectedEmailAccountByChannelInstanceId,
  fieldName: 'connectedEmailAccount'
});
export const useConnectedEmailAccount = id => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchConnectedEmailAccount, {
    variables: {
      id
    }
  });
  return {
    connectedEmailAccount: data && data.connectedEmailAccount,
    error,
    loading
  };
};
export const useConnectedEmailAccountByChannelInstanceId = channelInstanceId => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchConnectedEmailByChannelInstanceId, {
    variables: {
      channelInstanceId
    }
  });
  return {
    connectedEmailAccount: data && data.connectedEmailAccount,
    error,
    loading
  };
};
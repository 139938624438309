import { registerQuery } from 'data-fetching-client';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
const fetchCrmPageEditorAppSettings = () => {
  return getFrameworkDataSchemasClient().then(client => client.typeMetadata.get({
    appSettingNames: ['crmPageEditor']
  }));
};
export const CRM_PAGE_EDITOR_APP_SETTINGS = registerQuery({
  fieldName: 'crmPageEditorAppSettings',
  fetcher: fetchCrmPageEditorAppSettings
});
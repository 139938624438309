import formatPhoneNumber from 'I18n/utils/formatPhoneNumber';
// @ts-expect-error Untyped dependency
import formatPhoneNumberWithExtension from 'I18n/utils/formatPhoneNumberWithExtension';
// @ts-expect-error Untyped dependency
import * as TelephoneData from 'I18n/constants/TelephoneData';
const UTF_16_A = 65;
const UTF_16_REGION_A = 127462;
const REGION_START = UTF_16_REGION_A - UTF_16_A;
const allCountries = TelephoneData.allCountries;

/**
 * Returns the corresponding country dial code.
 *
 * @example
 * getCountryDialCode('us'); // => '1'
 * getCountryDialCode('gb'); // => '44'
 * getCountryDialCode('unknown'); // => undefined
 */
export function getCountryDialCode(country) {
  if (!country) {
    return undefined;
  }
  const iso2 = TelephoneData.iso2Lookup[country];
  const dialCode = allCountries[iso2].dialCode;
  return dialCode;
}
export function parseRawPhoneNumber(rawPhoneNumber) {
  const regex = new RegExp(/^(.*) ext\D*(\d+)$/);
  const phoneNumberParts = regex.exec(rawPhoneNumber);
  const phoneNumber = phoneNumberParts ? phoneNumberParts[1] : rawPhoneNumber;
  const extension = phoneNumberParts ? phoneNumberParts[2] : '';
  return {
    phoneNumber,
    extension
  };
}
export function isValidRawPhoneNumber(rawPhoneNumber) {
  const {
    phoneNumber,
    extension
  } = parseRawPhoneNumber(rawPhoneNumber);
  const regex = /(?=[^+])\D/; // match non-digits excluding '+' character

  return !regex.test(phoneNumber) && !regex.test(extension);
}
export function formatRawPhoneNumber(rawPhoneNumber) {
  if (isValidRawPhoneNumber(rawPhoneNumber)) {
    const {
      phoneNumber,
      extension
    } = parseRawPhoneNumber(rawPhoneNumber);
    return extension ? formatPhoneNumberWithExtension(phoneNumber, extension) : formatPhoneNumber(phoneNumber, '');
  }
  return rawPhoneNumber;
}
export function removeNonNumberCharacters(value) {
  return value.replace(/[^\d]/g, '');
}
export function removeInvalidPhoneNumberCharacters(value) {
  if (value.trim().startsWith('+')) {
    return `+${removeNonNumberCharacters(value.slice(1))}`;
  }
  return removeNonNumberCharacters(value);
}

// This is a direct copy of https://git.hubteam.com/HubSpot/ui-addon-i18n/blob/master/static/js/components/CountryFlag.tsx#L12
export function getCountryFlagEmoji(country) {
  const unavailableCountryCodes = ['uk', 'an', 'ap'];
  if (unavailableCountryCodes.includes(country) || country === '') {
    return '';
  }
  const codePoints = country.toUpperCase().split('').map(char => REGION_START + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
export function addPlusIfNeeded(value) {
  return value.startsWith('+') ? value : `+${value}`;
}
export const applyFormatToPhoneNumber = (value, format) => {
  if (!value || value === '+') {
    return value;
  }
  const numberText = (value || '').replace(/\D/g, '');
  if (numberText && numberText.length < 2 || !format) {
    return `+${numberText}`;
  }
  const formattedObject = {
    remainingText: numberText,
    formattedText: ''
  };
  for (const character of (format || '').split('')) {
    if (formattedObject.remainingText.length === 0) {
      break;
    }
    if (character !== '.') {
      formattedObject.formattedText = formattedObject.formattedText + character;
    } else {
      formattedObject.formattedText = formattedObject.formattedText + formattedObject.remainingText[0];
      formattedObject.remainingText = formattedObject.remainingText.slice(1);
    }
  }
  return formattedObject.formattedText + formattedObject.remainingText;
};
export const getPhoneNumberString = (phoneNumber, extension) => phoneNumber !== '' && extension !== null && extension !== '' && extension !== undefined ? `${phoneNumber} ext ${extension}` : phoneNumber;
export const COUNTRIES_BY_ISO2 = allCountries.reduce((acc, country) => {
  acc[country.iso2] = country;
  return acc;
}, {});
export const getNewCursorPosition = (newValue, countryFormat, newInput, cursorPosition) => {
  const cleanNewInput = newValue === '+' ? newValue : removeNonNumberCharacters(newInput);
  const formattedNumber = applyFormatToPhoneNumber(newValue, countryFormat);
  let formattedNumberPointer = cursorPosition - newInput.length;
  let newInputPointer = 0;
  while (formattedNumberPointer < formattedNumber.length) {
    if (newInputPointer >= cleanNewInput.length) {
      return formattedNumberPointer;
    }
    const formattedNumberChar = formattedNumber[formattedNumberPointer];
    const newInputChar = cleanNewInput[newInputPointer];
    if (formattedNumberChar === newInputChar) {
      newInputPointer++;
    }
    formattedNumberPointer++;
  }
  return formattedNumberPointer;
};
export const getIsPhoneNumberOnlyDialCode = (phoneNumber, countryCode) => {
  const dialCode = getCountryDialCode(countryCode);
  if (!dialCode) {
    return false;
  }
  return phoneNumber === addPlusIfNeeded(dialCode);
};
import { CHANNEL_MIGRATION_STATES } from '../_internal/constants';
import { fetchInboxActiveMigrationStatusQuery, fetchMigrationStatusQuery } from '../_internal/queries/help-desk-migration.queries';
import { useQuery } from 'data-fetching-client';
export const useMigrationState = ({
  channelInstanceId,
  notifyOnNetworkStatusChange,
  onCompleted,
  onError,
  skip
}) => {
  const {
    data,
    error,
    loading,
    refetch,
    startPolling,
    stopPolling
  } = useQuery(fetchMigrationStatusQuery, {
    notifyOnNetworkStatusChange,
    onCompleted: response => {
      if (onCompleted) {
        onCompleted(response.helpdeskMigrationStatus);
      }
    },
    onError,
    skip,
    variables: {
      channelInstanceId
    }
  });
  let isChannelBeingMigrated = false;
  let migrationStatus = CHANNEL_MIGRATION_STATES.FAILED;
  let moveConversations = false;
  if (data) {
    ({
      migrationStatus,
      moveConversations
    } = data.helpdeskMigrationStatus);
    isChannelBeingMigrated = migrationStatus === CHANNEL_MIGRATION_STATES.SCHEDULED || migrationStatus === CHANNEL_MIGRATION_STATES.STARTED;
  }
  return {
    error,
    isChannelBeingMigrated,
    loading,
    migrationStatus,
    moveConversations,
    refetch,
    startPolling,
    stopPolling
  };
};
export const useMigrationStateForInbox = (inboxId, skip) => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchInboxActiveMigrationStatusQuery, {
    variables: {
      inboxId
    },
    skip
  });
  return {
    error,
    loading,
    migrationStates: data ? data.helpdeskInboxActiveMigrationStatus : []
  };
};

// migrationStates is an array of channels that are being migrated
// if the array is empty, it means there is no active migration for the inbox
export const useHasActiveMigrationForInbox = (inboxId, skip) => {
  const {
    migrationStates
  } = useMigrationStateForInbox(inboxId, skip);
  // when there is no active migration, migrationStates is an empty array
  // when there is an active migration, migrationStates is the object of the channel being migrated
  return migrationStates.length !== 0;
};
import { INVOICE_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, COMPANY_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import PortalIdParser from 'PortalIdParser';
import { getInvoiceProperty } from './property';
import { HS_INVOICE_PAYMENT_COLLECTION_METHOD, INVOICE_PROPERTY_VALUES } from '../constants/properties';
import { isAccountApprovedForPayments } from './paymentsEligibility';
export const makeInvoice = invoice => ({
  associatedObjects: Object.assign({
    INVOICE_TO_DEAL: [],
    INVOICE_TO_LINE_ITEM: [],
    INVOICE_TO_CONTACT: [],
    INVOICE_TO_COMPANY: [],
    INVOICE_TO_QUOTE: [],
    INVOICE_TO_FEE: [],
    INVOICE_TO_DISCOUNT: [],
    INVOICE_TO_TAX: [],
    INVOICE_TO_TICKET: []
  }, invoice && invoice.associatedObjects),
  objectId: invoice && invoice.objectId || null,
  objectTypeId: INVOICE_TYPE_ID,
  portalId: invoice && invoice.portalId ? invoice.portalId : PortalIdParser.get(),
  properties: invoice && invoice.properties || {}
});
const BASE_PATH = `/contacts/${PortalIdParser.get()}/`;
export function getFinalizedInvoiceUrl(invoice) {
  const domain = getInvoiceProperty(invoice, 'hs_domain');
  const slug = getInvoiceProperty(invoice, 'hs_slug');
  return `https://${domain}/${slug}`;
}
export function getInvoicePropertyHistoryUrl(invoice) {
  return `${BASE_PATH}record/${encodeURIComponent(invoice.objectTypeId)}/${invoice.objectId}/history`;
}
export function getFirstInvoiceAssociationWithRecordPage(invoice) {
  return invoice.associatedObjects.INVOICE_TO_DEAL[0] || invoice.associatedObjects.INVOICE_TO_COMPANY[0] || invoice.associatedObjects.INVOICE_TO_CONTACT[0];
}
export function getInvoiceEmailCommunicatorUrl(invoice, associatedObjectToLink) {
  let associatedPath;
  switch (associatedObjectToLink.objectTypeId) {
    case CONTACT_TYPE_ID:
      associatedPath = `${BASE_PATH}contact/`;
      break;
    case COMPANY_TYPE_ID:
      associatedPath = `${BASE_PATH}company/`;
      break;
    case DEAL_TYPE_ID:
      associatedPath = `${BASE_PATH}deal/`;
      break;
    default:
      throw new Error('Unexpected objectTypeId in getInvoiceEmailCommunicatorUrl');
  }
  const invoiceUrl = getFinalizedInvoiceUrl(invoice);
  const invoiceNumber = getInvoiceProperty(invoice, 'hs_number');
  const encodedSubject = encodeURIComponent(invoiceNumber);
  const encodedBody = encodeURIComponent(`<a href="${invoiceUrl}">${invoiceNumber}</a>`);
  return `${associatedPath}${associatedObjectToLink.objectId}?interaction=email&initialEmailSubject=${encodedSubject}&initialEmailBody=${encodedBody}`;
}
export function getIsNativeInvoice(invoice) {
  return getInvoiceProperty(invoice, 'hs_invoice_source') === 'native_invoice';
}
export function isCheckoutEnabled(invoice) {
  return getInvoiceProperty(invoice, HS_INVOICE_PAYMENT_COLLECTION_METHOD) === INVOICE_PROPERTY_VALUES[HS_INVOICE_PAYMENT_COLLECTION_METHOD].MANUAL;
}
export function hasPayments(invoice) {
  return invoice.associatedObjects.INVOICE_TO_COMMERCE_PAYMENT && invoice.associatedObjects.INVOICE_TO_COMMERCE_PAYMENT.length > 0;
}
export function isAssociatedPaymentsFailed(invoice) {
  return invoice.associatedObjects.INVOICE_TO_COMMERCE_PAYMENT && invoice.associatedObjects.INVOICE_TO_COMMERCE_PAYMENT.every(payment => payment.properties.hs_latest_status.value === 'failed');
}
export function isAnyAssociatedPaymentFailed(invoice) {
  return !!invoice.associatedObjects.INVOICE_TO_COMMERCE_PAYMENT && invoice.associatedObjects.INVOICE_TO_COMMERCE_PAYMENT.some(payment => payment.properties.hs_latest_status.value === 'failed');
}
export function getHasMultipleDiscounts(invoice) {
  return invoice.associatedObjects.INVOICE_TO_DISCOUNT && invoice.associatedObjects.INVOICE_TO_DISCOUNT.length > 1;
}
export const getPaymentsEligibilityCategory = paymentsEligibility => {
  if (paymentsEligibility) {
    return [isAccountApprovedForPayments(paymentsEligibility) ? 'payments-approved' : 'not-payments-approved'];
  }
  return [];
};
export function getFirstAssociatedSubscription(invoice) {
  var _invoice$associatedOb;
  return (_invoice$associatedOb = invoice.associatedObjects.INVOICE_TO_SUBSCRIPTION) === null || _invoice$associatedOb === void 0 ? void 0 : _invoice$associatedOb[0];
}
export const getInvoiceCategories = (invoice, paymentsEligibility) => {
  return [getInvoiceProperty(invoice, 'hs_allow_partial_payments') === 'true' ? 'partial-payment-enabled' : 'partial-payment-disabled', getInvoiceProperty(invoice, 'hs_custom_fields') ? 'has-custom-fields' : 'no-custom-fields', getInvoiceProperty(invoice, 'hs_invoice_payment_collection_method') === 'automatic' ? 'automatic-collection' : getInvoiceProperty(invoice, 'hs_invoice_payment_collection_method') === 'manual' ? 'manual-collection' : 'no-collection', getFirstAssociatedSubscription(invoice) ? 'has-subscription' : 'no-subscription', ...getPaymentsEligibilityCategory(paymentsEligibility)];
};
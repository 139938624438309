import unescapedText from 'I18n/utils/unescapedText';
export const objectUpdateActionTextParser = action => {
  if (action.label) {
    return action.label;
  }
  const propertiesMessages = Object.keys(action.properties).map(property => {
    return unescapedText('copilotToolkit.streamingMessage.objectUpdate', {
      property,
      objectType: action.objectTypeLabel && action.objectTypeLabel.toLowerCase(),
      value: action.properties[property]
    });
  });
  return propertiesMessages.join('\n');
};
import { BLANK_BACKGROUND, COLOUR_MAPPINGS, getTokenizedColors, makeBlankBackground, makeGradientBackgroundCss } from '../constants/background';
import { BLANK } from '../constants/useOptions';
const getColourPattern = useOption => COLOUR_MAPPINGS[useOption];
export const getBackground = ({
  use
}) => use === BLANK ? BLANK_BACKGROUND : makeGradientBackgroundCss(getColourPattern(use));
export const getTokenizedBackground = ({
  use,
  theme
}) => use === BLANK ? makeBlankBackground({
  theme
}) : makeGradientBackgroundCss(getTokenizedColors({
  use,
  theme
}));
import { registerQuery, useQuery } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
// @ts-expect-error dependency missing types
import { catchAndRethrowNetworkError } from 'crm_data/bCommerce/monitoring/errorUtils';
import { GET_DEFAULT_ACTION_ELIGIBILITY_QUERY, GET_DEFAULT_PUBLISHABLE_KEY_QUERY, GET_PAYMENTS_ACCOUNT_ELIGIBILITY_QUERY } from '../../../__generated__/chirp/com/hubspot/payments/accounts/rpc/PaymentsAccountEligibilityService.dfc';
import { createRpcClientV2 } from 'rpc-client-utils';
import { getDefaultPaymentsAccountDetailsRpc } from '../../../__generated__/chirp/com/hubspot/payments/accounts/rpc/PaymentsAccountEligibilityService';
import { ApolloError } from '@apollo/client';
const chirpClient = createRpcClientV2({
  hubHttpClient: apiClient
});
export let EligibleActionType;
(function (EligibleActionType) {
  EligibleActionType["EDIT_ACCOUNT_INFO"] = "EDIT_ACCOUNT_INFO";
  EligibleActionType["ADD_BANK_INFO"] = "ADD_BANK_INFO";
  EligibleActionType["EDIT_BANK_INFO"] = "EDIT_BANK_INFO";
  EligibleActionType["EDIT_INNOCENT_PUBLIC_INFO"] = "EDIT_INNOCENT_PUBLIC_INFO";
  EligibleActionType["EDIT_NON_INNOCENT_PUBLIC_INFO"] = "EDIT_NON_INNOCENT_PUBLIC_INFO";
  EligibleActionType["EDIT_PERSON_INFO"] = "EDIT_PERSON_INFO";
  EligibleActionType["DELETE_PERSON_INFO"] = "DELETE_PERSON_INFO";
})(EligibleActionType || (EligibleActionType = {}));
const fetchPaymentsAccountEligibility = () => apiClient.get(`payments/v1/payment-accounts/eligibility`).catch(catchAndRethrowNetworkError);
const GET_PAYMENTS_ACCOUNT_ELIGIBILITY = registerQuery({
  fieldName: 'commercePaymentsAccountEligibility',
  fetcher: fetchPaymentsAccountEligibility
});
const usePaymentsAccountEligibility = options => useQuery(GET_PAYMENTS_ACCOUNT_ELIGIBILITY, options);

/**
 * @deprecated - This rest call has been deprecated in favor of CHIRP hooks.
 */
export default usePaymentsAccountEligibility;

/**
 * @deprecated - This rest call has been deprecated in favor of CHIRP hooks. Use useEnrolledProcessor() instead.
 */
export const useDefaultProcessor = () => {
  const {
    data,
    loading
  } = usePaymentsAccountEligibility();
  return {
    defaultProcessor: data === null || data === void 0 ? void 0 : data.commercePaymentsAccountEligibility.processorType,
    loading
  };
};
export const usePaymentsEligibility = options => useQuery(GET_PAYMENTS_ACCOUNT_ELIGIBILITY_QUERY, options);
export const GET_DEFAULT_PAYMENTS_ACCOUNT_DETAILS_QUERY_WITH_ERROR = registerQuery({
  fieldName: 'getDefaultPaymentsAccountDetails',
  args: [],
  fetcher() {
    return chirpClient.callWithResultUnion(getDefaultPaymentsAccountDetailsRpc, {}).then(response => {
      if (response.type === 'data') {
        return response.data;
      } else if (response.type === 'rpcError') {
        throw new ApolloError({
          networkError: {
            responseJSON: {
              message: response.rpcError.chirpErrorMessage,
              category: response.rpcError.errorType
            }
          }
        });
      }
    }).catch(e => catchAndRethrowNetworkError(e));
  }
});
export const useDefaultPaymentsAccountDetails = options => useQuery(GET_DEFAULT_PAYMENTS_ACCOUNT_DETAILS_QUERY_WITH_ERROR, options);
export const useDefaultPublishableKey = options => useQuery(GET_DEFAULT_PUBLISHABLE_KEY_QUERY, options);

/**
 * Check if useHasActionEligibility() hook suites your needs.
 */
export const useDefaultActionEligibility = options => useQuery(GET_DEFAULT_ACTION_ELIGIBILITY_QUERY, options);
export const useEnrolledProcessor = () => {
  const {
    data,
    loading
  } = usePaymentsEligibility();
  return {
    defaultProcessor: data === null || data === void 0 ? void 0 : data.getPaymentsAccountEligibility.enrolledProcessorTypes[0],
    loading
  };
};
export const useHasActionEligibility = action => {
  const {
    loading,
    data
  } = useDefaultActionEligibility();
  return {
    loading,
    isActionEligible: !!data && data.getDefaultActionEligibility.actionEligibility[action]
  };
};

/**
 * Checks if a payments account is approved. Receiving payments or checkout can be disabled.
 * If you need to check if checkout is enabled use useCanTransactUsingPayments() instead.
 */
export const useIsApprovedForPayments = () => {
  const {
    data,
    loading
  } = useDefaultPaymentsAccountDetails();
  return {
    loading,
    isApprovedForPayments: Boolean((data === null || data === void 0 ? void 0 : data.getDefaultPaymentsAccountDetails.underwritingStatus) === 'APPROVED')
  };
};

/**
 * Checks if a payments account is approved and if checkout is enabled.
 */
export const useCanTransactUsingPayments = () => {
  const {
    data,
    loading
  } = useDefaultPaymentsAccountDetails();
  return {
    loading,
    canTransact: Boolean(data === null || data === void 0 ? void 0 : data.getDefaultPaymentsAccountDetails.capabilities.canTransact)
  };
};
import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { GET_VALID_CURRENCIES } from '../rest/queries';
import { useHasAllSupportedCurrenciesGate, useHasExpandedMultiCurrencyGate } from './useAuthGate';
export function usePortalCurrencies(options = {}) {
  const {
    data,
    loading
  } = useQuery(GET_VALID_CURRENCIES, options);
  const hasAllSupportedCurrenciesGate = useHasAllSupportedCurrenciesGate();
  const hasExpandedMultiCurrencyGate = useHasExpandedMultiCurrencyGate();
  return useMemo(() => {
    var _supportedCurrencies$;
    const portalCurrencies = data !== null && data !== void 0 && data.currencyData && Array.isArray(data.currencyData) ? data.currencyData : [];
    const supportedCurrencies = portalCurrencies.filter(currency => currency.isProcessorSupportedCurrency);
    const currencyList = hasAllSupportedCurrenciesGate || hasExpandedMultiCurrencyGate ? portalCurrencies : supportedCurrencies;
    const portalDefaultCurrency = currencyList.find(currency => currency.isHomeCurrency && currency.isActivePortalCurrency);
    const defaultCurrency = portalDefaultCurrency !== null && portalDefaultCurrency !== void 0 && portalDefaultCurrency.isProcessorSupportedCurrency ? portalDefaultCurrency === null || portalDefaultCurrency === void 0 ? void 0 : portalDefaultCurrency.currencyCode : (_supportedCurrencies$ = supportedCurrencies[0]) === null || _supportedCurrencies$ === void 0 ? void 0 : _supportedCurrencies$.currencyCode;
    return {
      loading,
      supportedCurrencies,
      defaultCurrency,
      allCurrencies: portalCurrencies
    };
  }, [data === null || data === void 0 ? void 0 : data.currencyData, hasAllSupportedCurrenciesGate, hasExpandedMultiCurrencyGate, loading]);
}
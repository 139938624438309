export const normalizeObjectType = objectTypeId => {
  switch (objectTypeId) {
    case '0-1':
      return 'CONTACT';
    case '0-2':
      return 'COMPANY';
    case '0-3':
      return 'DEAL';
    case '0-5':
      return 'TICKET';
    case '0-18':
      return 'COMMUNICATION';
    case '0-19':
      return 'FEEDBACK_SUBMISSION';
    case '0-35':
      return 'CAMPAIGN';
    default:
      return objectTypeId;
  }
};
export const parseObjectId = objectId => {
  try {
    return parseInt(objectId, 10);
  } catch (e) {
    return objectId;
  }
};
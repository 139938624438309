import { useLocalSuperstore } from './useLocalSuperstore';
import { useIsDisabledByAccessControls } from '../components/CopilotPreview/internal/hooks/useIsDisabledByAccessControls';
export const COPILOT_PREVIEW_DONT_SHOW_AGAIN_WHEN_DISABLED_STORAGE_KEY = 'COPILOT_PREVIEW_DONT_SHOW_AGAIN_WHEN_DISABLED';
export const useShouldShowCopilotPreviews = ({
  overrideDisabled = false
} = {}) => {
  const {
    isDisabledByAccessControls,
    isAccessRequestLoading
  } = useIsDisabledByAccessControls();
  const {
    localSuperstoreValue,
    handleSetLocalSuperstoreValue
  } = useLocalSuperstore({
    key: COPILOT_PREVIEW_DONT_SHOW_AGAIN_WHEN_DISABLED_STORAGE_KEY
  });
  const isDisabled = isDisabledByAccessControls || overrideDisabled;
  const shouldShowCopilotPreviews = !isAccessRequestLoading && (!isDisabled || localSuperstoreValue !== 'true');
  const setDontShowAgain = () => {
    handleSetLocalSuperstoreValue('true');
  };
  return {
    shouldShowCopilotPreviews,
    setDontShowAgain
  };
};
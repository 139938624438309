import { HS_AUTO_TAX_AMOUNT, HS_POST_TAX_AMOUNT, HS_TAX_AMOUNT, HS_TAX_LABEL, HS_TAX_RATE } from '../constants/properties';
import { makeNewProperty } from './property';
export const removeAutomatedSalesTaxLineItemProperties = lineItems => lineItems.map(lineItem => Object.assign({}, lineItem, {
  properties: Object.assign({}, lineItem.properties, {
    [HS_AUTO_TAX_AMOUNT]: makeNewProperty(HS_AUTO_TAX_AMOUNT, undefined),
    [HS_TAX_AMOUNT]: makeNewProperty(HS_TAX_AMOUNT, undefined),
    [HS_TAX_RATE]: makeNewProperty(HS_TAX_RATE, undefined),
    [HS_POST_TAX_AMOUNT]: makeNewProperty(HS_POST_TAX_AMOUNT, undefined),
    [HS_TAX_LABEL]: makeNewProperty(HS_TAX_LABEL, undefined)
  })
}));
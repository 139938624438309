import apiClient from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import Raven from 'raven-js';

// todo: remove this once the client-types are generated
// PaymentsSubscriptionsService can't have duplicate entries in the client-types config :/
export const fetchSubscriptionSettings = () => apiClient.get('payments-subscriptions/v1/settings').catch(e => {
  Raven.captureException(e);
  throw e;
});
export const GET_SUBSCRIPTION_SETTINGS = registerQuery({
  fieldName: 'subscriptionSettings',
  fetcher: fetchSubscriptionSettings,
  args: []
});
export function useSubscriptionSettings(options) {
  const {
    loading,
    data
  } = useQuery(GET_SUBSCRIPTION_SETTINGS, Object.assign({}, options));
  return useMemo(() => {
    return {
      loading,
      data: data === null || data === void 0 ? void 0 : data.subscriptionSettings
    };
  }, [data, loading]);
}
import apiClient from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
export const COMMERCE_ANALYTICS_QUERY = registerQuery({
  fieldName: 'commerceAnalytics',
  fetcher: () => apiClient.get('commerce/analytics/v1/home')
});
export const usePaymentTrackingProperties = () => {
  const {
    data: commerceAnalyticsData
  } = useQuery(COMMERCE_ANALYTICS_QUERY);
  return useMemo(() => {
    if (!commerceAnalyticsData) {
      return null;
    }
    const {
      isEnrolled,
      isActivated,
      processorType
    } = commerceAnalyticsData.commerceAnalytics;
    return {
      isEnrolled: String(isEnrolled),
      isActive: String(isActivated),
      processorType
    };
  }, [commerceAnalyticsData]);
};
import { NOT_INITIALIZED } from 'ui-addon-scriptloader/installStatus/InstallStatusConstants';
// @ts-expect-error dependency missing types
import { atom, deref, swap, watch, unwatch } from 'atom';
const InstallStatusStoreAtom = atom(NOT_INITIALIZED);

// this proxy makes InstallStatusStore easier to test.
const InstallStatusStore = {
  get() {
    return deref(InstallStatusStoreAtom);
  },
  set(value) {
    return swap(InstallStatusStoreAtom, () => value);
  },
  subscribe(callback) {
    return watch(InstallStatusStoreAtom, callback);
  },
  unsubscribe(callback) {
    return unwatch(InstallStatusStoreAtom, callback);
  }
};
export default InstallStatusStore;
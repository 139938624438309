export const ASSIGNEE = ['assignee'];
export const ASSIGNEE_ID = ['assignee', 'agentId'];
export const ASSOCIATED_TICKET_IDS = ['associatedTicketIds'];
export const CHANNELS = ['channels'];
export const CONVERSATION_ID = ['cvCrmObjectId'];
export const EMAIL_METADATA = ['latestMessagePreview', 'emailMetadata'];
export const FILTERED_SOURCE = ['filteredSource'];
export const HS_TIME_TO_CLOSE_SLA_AT = 'hs_time_to_close_sla_at';
export const HS_TIME_TO_CLOSE_SLA_STATUS = 'hs_time_to_close_sla_status';
export const HS_TIME_TO_FIRST_RESPONSE_SLA_AT = 'hs_time_to_first_response_sla_at';
export const HS_TIME_TO_FIRST_RESPONSE_SLA_STATUS = 'hs_time_to_first_response_sla_status';
export const HAS_FILE_ATTACHMENT = ['latestMessagePreview', 'hasFileAttachment'];
export const IS_COMMENT = ['latestMessagePreview', 'isThreadComment'];
export const IS_LATEST_MESSAGE_A_DRAFT = ['latestMessagePreview', 'isLatestMessageADraft'];
export const LATEST_MESSAGE_TIMESTAMP = ['latestMessageTimestamp'];
export const LATEST_RECEIVED_TIMESTAMP = ['latestReceivedTimestamp'];
export const LATEST_SENT_TIMESTAMP = ['latestSentTimestamp'];
export const LATEST_MESSAGE_FAILED = ['latestMessagePreview', 'failed'];
export const LATEST_MESSAGE_PREVIEW = ['latestMessagePreview'];
export const ORIGINAL_GENERIC_CHANNEL_ID = 'originalGenericChannelId';
export const PREVIEW = ['latestMessagePreview', 'text'];
export const PREVIEW_MESSAGE_ID = ['latestMessagePreview', 'previewMessageId'];
export const PREVIOUS = ['previous'];
export const REMOVAL = ['removal'];
export const SEEN = ['seen'];
export const SEEN_BY_AGENT_IDS = ['seenByAgentIds'];
export const SLA_DETAILS = 'goalDetails';
export const SLA_DETAILS_GOALS = ['goalDetails', 'goals'];
export const SLA_DETAILS_ACTIVE_SLA = ['goalDetails', 'activeGoalType'];
export const SORT_TIMESTAMP = ['sortTimestamp'];
export const STATUS = ['status'];
export const THREAD_ID = ['threadId'];
export const UPDATE = ['update'];
export const VID = ['vid'];
export const VISITOR = ['visitor'];
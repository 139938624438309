import { useMemo } from 'react';
import I18n from 'I18n';
import { CommerceTool } from '../types/CommerceTool';
const useLocalizedCommerceToolName = toolName => {
  const localizedToolName = useMemo(() => {
    switch (toolName) {
      case CommerceTool.QUOTES:
        return I18n.text('CommerceToolsUiLib.commerceTools.quotes');
      case CommerceTool.INVOICES:
        return I18n.text('CommerceToolsUiLib.commerceTools.invoices');
      case CommerceTool.SUBSCRIPTIONS:
        return I18n.text('CommerceToolsUiLib.commerceTools.subscriptions');
      case CommerceTool.PAYMENT_LINKS:
        return I18n.text('CommerceToolsUiLib.commerceTools.paymentLinks');
      default:
        return I18n.text('CommerceToolsUiLib.commerceTools.default');
    }
  }, [toolName]);
  return localizedToolName;
};
export default useLocalizedCommerceToolName;
import enviro from 'enviro';
import http from 'hub-http/clients/apiClient';
import { HubsSettingClient } from 'frontend-preferences-client';
import { INSTALLED, UNINSTALLED, ERROR, WORDPRESS_PLUGIN_ID_PROD, WORDPRESS_PLUGIN_ID_QA, INSTALL_TYPE } from 'ui-addon-scriptloader/installStatus/InstallStatusConstants';
export const INSTALL_STATUS_SETTING_NAME = 'scriptLoaderInstallStatus';
export const HUBSETTINGS_URL_WP = '/integrators/v1/portal-installs/with-success';
export function fetchWordpressPluginInstallStatus() {
  const wordpressPluginId = enviro.isProd() ? WORDPRESS_PLUGIN_ID_PROD : WORDPRESS_PLUGIN_ID_QA;
  return http.get(HUBSETTINGS_URL_WP).then(response => {
    var _response$listings;
    const scriptLoaderInstallStatus = response === null || response === void 0 || (_response$listings = response.listings) === null || _response$listings === void 0 ? void 0 : _response$listings.find(responseItem => responseItem.id === wordpressPluginId);
    return scriptLoaderInstallStatus ? INSTALLED : UNINSTALLED;
  }).catch(() => {
    return ERROR;
  });
}
export const hubsSettingClient = HubsSettingClient.forCaller('ui-addon-scriptloader');
export function fetchTrackingCodeInstallStatus() {
  return hubsSettingClient.fetch(INSTALL_STATUS_SETTING_NAME, UNINSTALLED).then(status => status).catch(() => {
    return ERROR;
  });
}
export function fetchBothAndCombine() {
  return Promise.all([fetchWordpressPluginInstallStatus(), fetchTrackingCodeInstallStatus()]).then(results => {
    switch (true) {
      case results.includes(INSTALLED):
        return INSTALLED;
      case results.includes(UNINSTALLED):
        return UNINSTALLED;
      case results.includes(ERROR):
        return ERROR;
      default:
        return UNINSTALLED;
    }
  }).catch(() => {
    return ERROR;
  });
}
export function fetchInstallStatus(installType) {
  switch (installType) {
    case INSTALL_TYPE.WORDPRESS:
      return fetchWordpressPluginInstallStatus();
    case INSTALL_TYPE.TRACKINGCODE:
      return fetchTrackingCodeInstallStatus();
    default:
      return fetchBothAndCombine();
  }
}
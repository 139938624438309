export const EMBED_SOURCE = {
  DEAL_RECORD: 'dealRecord',
  DEAL_LINE_ITEM_EDITOR: 'dealLineItemEditor',
  QUOTE_TEMPLATE_SETTINGS: 'quoteTemplateSettings',
  QUOTE_PREVIEW_PANEL: 'quotePreviewPanel',
  SHARE_PAYMENTS_APP: 'sharePaymentsApp',
  KITCHEN_SINK: 'kitchenSink',
  CONTACT_RECORD: 'contactRecord',
  COMPANY_RECORD: 'companyRecord',
  CONTRACT_RECORD: 'contractRecord'
};
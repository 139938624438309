import { EditorState, Plugin } from 'prosemirror-state';
import parseHTML from 'rich-text-lib/utils/parseHTML';
import { convertToPlaintextState, removeNonPlaintextNodes } from '../utils/plaintextUtils';

/**
 * TODO: move this into state for the PasteOverridePlugin
 */
// eslint-disable-next-line no-restricted-syntax
let pasteOverrideConfig;
/**
 * Create a transformer which removes a given CSS rule from any children
 * that have a style attribute.
 *
 * @param styleProperty CSS property to remove from all DOM nodes
 * @returns {PasteTransformer}
 */
const styleTransformer = styleProperty => {
  return htmlEl => {
    if (htmlEl.querySelectorAll('[data-pm-slice]').length === 0) {
      htmlEl.querySelectorAll('[style]').forEach(el => el.style.removeProperty(styleProperty));
    }
    return htmlEl;
  };
};

/**
 * Create a transformer which removes all instances of an HTML element from
 * the children of a DOM node.
 *
 * @param tag Name of an HTML tag to remove
 * @returns {PasteTransformer}
 */
const removeTagTransformer = tag => {
  return htmlEl => {
    if (htmlEl.querySelectorAll('[data-pm-slice]').length === 0) {
      const collection = htmlEl.getElementsByTagName(tag);
      while (collection.length > 0) {
        collection[0].remove();
      }
    }
    return htmlEl;
  };
};

/**
 * Create a transformer which replaces all instances of an HTML element with
 * their text content as a text node.
 *
 * @param tag Name of an HTML tag to replace with text nodes
 * @returns {PasteTransformer}
 */
const tagTextTransformer = tag => {
  return htmlEl => {
    if (htmlEl.querySelectorAll('[data-pm-slice]').length === 0) {
      const collection = htmlEl.getElementsByTagName(tag);
      while (collection.length > 0) {
        collection[0].replaceWith(document.createTextNode(collection[0].innerText));
      }
    }
    return htmlEl;
  };
};
const pasteTransformers = {
  ['stripTextHighlights']: styleTransformer('background-color'),
  ['stripTextColor']: styleTransformer('color'),
  ['stripTextStyle']: styleTransformer('font-family'),
  ['stripTextSize']: styleTransformer('font-size'),
  ['stripImages']: removeTagTransformer('img'),
  ['stripLinks']: tagTextTransformer('a')
};
export const PasteOverridePlugin = new Plugin({
  state: {
    init() {
      return {};
    },
    apply(tr, currentPluginState) {
      const meta = tr.getMeta(PasteOverridePlugin);
      if (meta) {
        pasteOverrideConfig = meta.pasteOverrideConfig || pasteOverrideConfig;
        return meta;
      }
      return currentPluginState;
    }
  },
  props: {
    transformPastedHTML: html => {
      if (!pasteOverrideConfig) return html;
      let htmlEl = parseHTML(html);
      for (const prop in pasteOverrideConfig) {
        if (pasteOverrideConfig[prop] && pasteTransformers[prop]) {
          htmlEl = pasteTransformers[prop](htmlEl);
        }
      }
      return htmlEl.outerHTML;
    },
    handlePaste: (view, _event, slice) => {
      var _pasteOverrideConfig;
      if ((_pasteOverrideConfig = pasteOverrideConfig) !== null && _pasteOverrideConfig !== void 0 && _pasteOverrideConfig.usePlaintextMode) {
        let sliceState = EditorState.create({
          schema: view.state.schema
        });
        sliceState = sliceState.apply(sliceState.tr.replace(1, undefined, slice));
        const doc = removeNonPlaintextNodes(convertToPlaintextState(sliceState, pasteOverrideConfig.plaintextOptions)).doc;
        view.dispatch(view.state.tr.replaceSelection(doc.slice(1, doc.content.size - 1)));
        return true;
      }
      return false;
    }
  }
});
import http from 'hub-http/clients/apiClient';
const getFilterGroups = quoteId => [{
  filters: [{
    property: 'associations.quote',
    operator: 'EQ',
    value: String(quoteId)
  }]
}];
export const getAssociatedPaymentSchedules = (quoteId, portalId) => http.post('crm-search/search', {
  data: {
    count: 1,
    filterGroups: getFilterGroups(quoteId),
    objectTypeId: '0-106',
    offset: 0,
    portalId,
    query: '',
    requestOptions: {},
    sorts: []
  }
}).then(response => response.results);
export const getAssociatedInvoices = (quoteId, portalId) => http.post('crm-search/search', {
  data: {
    count: 1,
    filterGroups: getFilterGroups(quoteId),
    objectTypeId: '0-53',
    offset: 0,
    portalId,
    query: '',
    requestOptions: {},
    sorts: []
  }
}).then(response => response.results);
export const getAssociatedLineItems = (quoteId, portalId) => http.post('crm-search/search', {
  data: {
    count: 100,
    filterGroups: getFilterGroups(quoteId),
    objectTypeId: '0-8',
    offset: 0,
    portalId,
    query: '',
    requestOptions: {
      allPropertiesFetchMode: 'latest_version',
      includeAllProperties: true
    },
    sorts: []
  }
}).then(response => response.results);
export const getAssociatedDiscounts = (quoteId, portalId) => http.post('crm-search/search', {
  data: {
    count: 2,
    filterGroups: getFilterGroups(quoteId),
    objectTypeId: '0-84',
    offset: 0,
    portalId,
    query: '',
    requestOptions: {},
    sorts: []
  }
}).then(response => response.results);
export const getQuoteIdsNeedingApproval = userId => {
  const quoteIdsQuery = {
    data: {
      objectTypeId: '0-80',
      properties: ['hs_request_note', 'hs_approval_for_object_type', 'hs_approval_concluded'],
      filterGroups: [{
        filters: [{
          operator: 'EQ',
          property: 'hs_approval_for_object_type',
          value: '0-14'
        }, {
          operator: 'IN',
          property: 'hs_user_ids_of_all_owners',
          values: [userId.toString()]
        }, {
          operator: 'EQ',
          property: 'hs_approval_concluded',
          value: 'false'
        }]
      }],
      offset: 0,
      count: 25,
      sorts: [{
        property: 'hs_createdate',
        order: 'DESC'
      }]
    }
  };
  return http.post('crm-search/search', quoteIdsQuery).then(response => response.results.flatMap(result => result.allAssociations).flatMap(association => {
    var _association$associat;
    return (_association$associat = association === null || association === void 0 ? void 0 : association.associatedObjects.edges) !== null && _association$associat !== void 0 ? _association$associat : [];
  }).map(edge => edge === null || edge === void 0 ? void 0 : edge.node.id).filter(id => id !== undefined));
};
export function crmSearch(data) {
  return http.post('crm-search/search', {
    data
  });
}
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { PRODUCT_BY_ID_ALL_PROPERTIES_QUERY, PRODUCT_DEPENDENCIES_QUERY } from '../graphql/queries';
import { parseProductDetails } from '../graphql/queryParser';
export function useProductDependencies(objectId) {
  const {
    error: productDependenciesError,
    loading: productDependenciesLoading,
    data: productDependenciesData
  } = useQuery(PRODUCT_DEPENDENCIES_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const {
    error: productCRMDataError,
    loading: productCRMDataLoading,
    data: productCRMData
  } = useQuery(PRODUCT_BY_ID_ALL_PROPERTIES_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: objectId
    },
    skip: objectId === undefined
  });
  const productData = useMemo(() => parseProductDetails(productCRMData), [productCRMData]);
  const {
    defaultCurrencyCode,
    isMulticurrencyActive
  } = useMemo(() => {
    var _productDependenciesD, _productDependenciesD2;
    return {
      defaultCurrencyCode: productDependenciesData === null || productDependenciesData === void 0 || (_productDependenciesD = productDependenciesData.currency) === null || _productDependenciesD === void 0 || (_productDependenciesD = _productDependenciesD.homeCurrency) === null || _productDependenciesD === void 0 ? void 0 : _productDependenciesD.currencyCode,
      isMulticurrencyActive: productDependenciesData === null || productDependenciesData === void 0 || (_productDependenciesD2 = productDependenciesData.currency) === null || _productDependenciesD2 === void 0 ? void 0 : _productDependenciesD2.isMulticurrencyActive
    };
  }, [productDependenciesData]);
  const errors = useMemo(() => [productDependenciesError, productCRMDataError].filter(error => error !== undefined), [productDependenciesError, productCRMDataError]);
  const isLoading = productDependenciesLoading || productCRMDataLoading;
  return {
    loading: isLoading,
    errors,
    productData,
    defaultCurrencyCode,
    isMulticurrencyActive
  };
}
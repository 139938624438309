import { useAsyncGenericChannelsWithCapabilities } from 'composer-data/channel-capabilities/public/hooks';
// @ts-expect-error module not typed
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import { isThreadPausedOnGenericChannelMessage } from 'conversations-message-history/thread-paused-on-generic-channel-message/operators/isThreadPausedOnGenericChannelMessage';
import { getIsPausedOn } from 'conversations-message-history/thread-paused-on-generic-channel-message/operators/threadPausedOnGenericChannelMessageGetters';
import { getGates } from 'conversations-thread-data/auth/public/selectors';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
// @ts-expect-error module not typed
import { getCurrentThreadHistory } from 'conversations-thread-data/thread-histories/public/selectors/getCurrentThreadHistory';
import { gate } from 'hub-http/gates';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
const FRONTEND_SUPPORTS_CHANNEL_SWITCHING = [EMAIL_GENERIC_CHANNEL_ID];
function useIsOriginalChannelPaused({
  originalGenericChannelId
}) {
  const gates = useSelector(getGates);
  const isUngatedForCloseLiveChat = gates.includes(gate('Communication:CloseLiveChat'));
  const threadHistory = useSelector(getCurrentThreadHistory);
  if (!isUngatedForCloseLiveChat) {
    return {
      isOriginalChannelPaused: false
    };
  }
  const messages = getMessages(threadHistory);
  const isOriginalChannelPaused = messages === null || messages === void 0 ? void 0 : messages.some(message => {
    return isThreadPausedOnGenericChannelMessage(message) && getIsPausedOn(message) === originalGenericChannelId;
  });
  return {
    isOriginalChannelPaused
  };
}
export function useAllowedMessageChannelIds({
  originalGenericChannelId
}) {
  const {
    data,
    loading
  } = useAsyncGenericChannelsWithCapabilities();
  const {
    isOriginalChannelPaused
  } = useIsOriginalChannelPaused({
    originalGenericChannelId
  });
  return useMemo(() => {
    const allowedChannelSwitchingChannels = data ? Object.keys(data).map(Number).filter(channelId => {
      const {
        capabilities
      } = data[channelId];
      const hasConversationStartCapability = Boolean(capabilities && capabilities.allowConversationStart);
      const isFEPreparedToChannelSwitch = FRONTEND_SUPPORTS_CHANNEL_SWITCHING.includes(channelId);
      const isOriginalChannel = channelId === originalGenericChannelId;
      return hasConversationStartCapability && isFEPreparedToChannelSwitch && !isOriginalChannel;
    }) : [];
    const originalChannelCapabilites = data && data[originalGenericChannelId] && data[originalGenericChannelId].capabilities;
    const originalChannelAllowsOutgoingMessages = Boolean(originalChannelCapabilites && originalChannelCapabilites.allowOutgoingMessages && !isOriginalChannelPaused);
    const allowedMessageChannelIds = originalChannelAllowsOutgoingMessages ? [originalGenericChannelId, ...allowedChannelSwitchingChannels] : allowedChannelSwitchingChannels;
    return {
      allowedMessageChannelIds,
      loading
    };
  }, [loading, data, originalGenericChannelId, isOriginalChannelPaused]);
}
import eventsConfig from 'commerce-tools-ui-lib/events.yaml';
import memoize from 'react-utils/memoize';
import Raven from 'raven-js';
import { events } from './events';
import isEmpty from 'transmute/isEmpty';
import { createTracker } from 'usage-tracker';
let localTracker = null;
let baseProperties = {};
export const resetLocalTracker = () => {
  localTracker = null;
  baseProperties = {};
};
export const getAppName = memoize(() => {
  var _window$hubspot;
  if ( // @ts-expect-error No hubspot type in global window yet
  (_window$hubspot = window.hubspot) !== null && _window$hubspot !== void 0 && (_window$hubspot = _window$hubspot.bender) !== null && _window$hubspot !== void 0 && _window$hubspot.currentProject) {
    var _window$hubspot2;
    // @ts-expect-error No hubspot type in global window yet
    return (_window$hubspot2 = window.hubspot) === null || _window$hubspot2 === void 0 || (_window$hubspot2 = _window$hubspot2.bender) === null || _window$hubspot2 === void 0 ? void 0 : _window$hubspot2.currentProject;
  }
  return 'unknown app';
});
export function getTracker() {
  if (!localTracker) {
    localTracker = createTracker({
      events: eventsConfig,
      trackerName: 'commerce-tools-ui-lib'
    });
  }
  return localTracker;
}
export const trackInteraction = properties => {
  track(events.interaction, properties);
};
export const trackPageView = properties => {
  track(events.view, properties);
};
export const trackUsage = properties => {
  track(events.usage, properties);
};
export function track(event, properties) {
  const tracker = getTracker();
  if (!tracker) {
    const err = new Error(`[${getAppName()}] MISSING USAGE TRACKER (commerce-tools-ui-lib)`);
    Raven.captureException(err);
    console.error(err);
    return;
  }
  tracker.track(event, Object.assign({
    sourceApp: getAppName()
  }, baseProperties, properties));
}
export function setTracker({
  trackerInstance,
  trackerProperties
}) {
  if (!localTracker) {
    localTracker = trackerInstance.clone({
      events: eventsConfig,
      preserveTrackerEvents: false,
      preserveTrackerProperties: false,
      trackerName: 'commerce-tools-ui-lib'
    });
  }
  if (isEmpty(baseProperties)) {
    baseProperties = trackerProperties;
  }
}
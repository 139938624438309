import I18n from 'I18n';
export function parseValue(format, rawValue) {
  if (rawValue == null || rawValue === '') {
    return undefined;
  }
  const moment = I18n.moment.utc(rawValue, format);
  return moment.hours() * 60 + moment.minutes();
}
export function formatValue(format, value) {
  if (value == null) {
    return '';
  }
  return I18n.moment.utc(0).minutes(value).format(format);
}
export const getOptionForValue = (value, valueFormatter) => ({
  text: value != null && valueFormatter ? valueFormatter(value) : value,
  value
});
import http from 'conversations-http/clients/http';
import { parseViewId } from 'find-and-filter-data/views-schema/public';
const DEFAULT_LIMIT = 100;

/**
 * @description Client to handle fetching thread list members
 * for a ThreadList or a HydratedCustomView based on the type of viewId passed in.
 */
export const fetchThreadListMembersService = ({
  inboxId = null,
  limit = DEFAULT_LIMIT,
  offsetId,
  offsetTimestamp,
  sortDirection = 'DESC',
  threadStatus = null,
  viewId
}) => {
  const {
    customViewId,
    threadListId,
    threadListType
  } = parseViewId(viewId);
  if (customViewId) {
    return http.get(`conversations-custom-views/v1/members/custom-view/${customViewId}`, {
      query: {
        includeHiddenFromVisitor: true,
        limit,
        maxPreviewLength: 200,
        offsetId,
        offsetTimestamp,
        sortDirection
      }
    });
  }
  const searchOffset = offsetId ? {
    offsetId,
    offsetTimestamp
  } : undefined;
  return http.put('threadlists/v1/members', {
    data: {
      inboxId,
      limit,
      maxPreviewLength: 200,
      searchOffset,
      sortDirection,
      threadListId,
      threadListType,
      threadStatus
    }
  });
};
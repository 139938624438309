import { CREATE_DATE, getCurrencyPriceProperty, isCurrencyPriceProperty } from 'customer-data-objects/lineItem/PropertyNames';
import LineItemRecord from 'customer-data-objects/lineItem/LineItemRecord';
import uniqueId from 'transmute/uniqueId';
import isNumber from 'transmute/isNumber';
import { setProperty } from 'customer-data-objects/objects/ObjectAccessors';
import { getId, getLineItem } from './subject';
import { convertToValue } from './propertyValue';
import calculateExchangeRatePrice from './calculations/calculateExchangeRatePrice';
import { getProperty as getObjectProperty } from 'customer-data-objects/objects/ObjectAccessors';
// TODO: Remove this implementation after getObjectProperty supports null
export const getProperty = (...args) => getObjectProperty(...args);
export function getTemporaryLineItemId() {
  return Number(uniqueId('-'));
}
export function convertProductToLineItem(product, currency, multiCurrencies, defaultCurrencyCode, globalTerms = {}) {
  const productId = getId(product);
  const productWithoutCurrencyPrices = Object.assign({}, product, {
    properties: Object.entries(product.properties).filter(([name]) => !isCurrencyPriceProperty(name) && name !== CREATE_DATE).reduce((acc, [name, property]) => {
      acc[name] = property;
      return acc;
    }, {})
  });
  let lineItem = getLineItem(LineItemRecord.fromJS(productWithoutCurrencyPrices).merge({
    objectId: getTemporaryLineItemId(),
    isNew: true
  }));
  lineItem = Object.entries(globalTerms).reduce((acc, [key, globalTerm]) => setProperty(acc, key, globalTerm), lineItem);
  lineItem = setProperty(lineItem, 'hs_product_id', String(productId));
  lineItem = setProperty(lineItem, 'quantity', '1');
  if (currency) {
    lineItem = setProperty(lineItem, 'price', getProperty(product, getCurrencyPriceProperty(currency)));

    // if the product has a unit cost, convert it to deal currency
    const unitCost = Number(getProperty(lineItem, 'hs_cost_of_goods_sold'));
    if (isNumber(unitCost) && multiCurrencies.length > 0) {
      const unitCostInDealCurrency = calculateExchangeRatePrice(multiCurrencies, defaultCurrencyCode, currency, unitCost);
      lineItem = setProperty(lineItem, 'hs_cost_of_goods_sold', convertToValue(unitCostInDealCurrency));
    }
  }
  return setProperty(lineItem, 'hs_line_item_currency_code', currency);
}
import { getIsCurrencyCheckoutSupported } from './validCurrencies';
export const isAccountApprovedForPayments = paymentsEligibility => {
  return paymentsEligibility.status === 'ACCOUNT_EXISTS' && paymentsEligibility.underwritingStatus === 'APPROVED';
};
export const getCanAcceptDigitalPayments = paymentsEligibility => {
  const accountHasBeenApproved = isAccountApprovedForPayments(paymentsEligibility);
  const chargesEnabled = paymentsEligibility.chargesEnabled;
  return accountHasBeenApproved && chargesEnabled;
};
export const getShouldDisableCheckout = (paymentsEligibility, commerceCurrencyLimits, invoiceCurrency) => {
  const canAcceptDigitalPayments = getCanAcceptDigitalPayments(paymentsEligibility);
  const isCurrencySupported = getIsCurrencyCheckoutSupported(invoiceCurrency, commerceCurrencyLimits);
  return !isCurrencySupported || !canAcceptDigitalPayments;
};
import { getChannelInstanceId as getMessageChannelInstanceId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getEmailAddressRecordFromConnectedAccount } from 'conversations-thread-data/common-reply-metadata/public/operators/getEmailAddressRecordFromConnectedAccount';
import { getChannelInstanceById, getIsActive } from 'conversations-thread-data/channel-instances/public/operators';
import { getFallbackAccount, findAccountByChannelInstanceId } from 'conversations-thread-data/connected-accounts/public';
import { getIsDefault, getDisplayGlobally, getChannelInstanceId
// @ts-expect-error module not typed
} from 'conversations-internal-schema/connected-email-account/operators/connectedEmailAccountGetters';
export const buildEmailFromAddress = ({
  replyingToMessage,
  channelInstances,
  connectedAccounts,
  defaultSendFrom
}) => {
  const replyChannelInstanceId = replyingToMessage && getMessageChannelInstanceId(replyingToMessage);
  const replyChannelInstance = getChannelInstanceById({
    channelInstances,
    id: replyChannelInstanceId
  });
  const replyConnectedAccount = findAccountByChannelInstanceId({
    connectedAccounts,
    channelInstanceId: replyChannelInstanceId
  });
  const fallbackAccount = getFallbackAccount({
    channelInstances,
    connectedAccounts,
    defaultTeamSendFrom: defaultSendFrom
  });
  const fallbackAccountChannelInstanceId = getChannelInstanceId(fallbackAccount);
  const fallbackChannelInstance = getChannelInstanceById({
    channelInstances,
    id: fallbackAccountChannelInstanceId
  });
  let sendFromAccount;
  const replyConnectedAccountIsEnabled = replyConnectedAccount && getIsDefault(replyConnectedAccount) ? getDisplayGlobally(replyConnectedAccount) : replyConnectedAccount && replyChannelInstance && getIsActive(replyChannelInstance);
  if (replyConnectedAccountIsEnabled) {
    sendFromAccount = replyConnectedAccount;
  } else if (fallbackChannelInstance && getIsActive(fallbackChannelInstance)) {
    sendFromAccount = fallbackAccount;
  } else {
    sendFromAccount = replyConnectedAccount || fallbackAccount;
  }
  return getEmailAddressRecordFromConnectedAccount(sendFromAccount);
};
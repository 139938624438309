import { useQuery } from 'data-fetching-client';
import { GET_DRAFTS_QUERY } from '../../../../__generated__/chirp/com/hubspot/cv/threads/drafts/rpc/DraftsFrontendService.dfc';
export function usePersistentDraftsQuery({
  idType,
  idNumber,
  isUngatedForPersistedDrafts,
  fetchPolicy = 'network-only'
}) {
  const {
    loading: loadingCurrentDrafts,
    data: currentDrafts,
    refetch
  } = useQuery(GET_DRAFTS_QUERY, {
    variables: {
      idType,
      id: idNumber
    },
    skip: !isUngatedForPersistedDrafts,
    fetchPolicy,
    onError: error => {
      reportError({
        error: new Error('Error fetching drafts'),
        extra: {
          error
        }
      });
    }
  });
  return {
    loadingCurrentDrafts,
    currentDrafts,
    refetch
  };
}
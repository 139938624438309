import { useCallback, useEffect, useState } from 'react';
import invariant from 'react-utils/invariant';
const getInitialState = (key, initialValue) => {
  try {
    const value = window.localStorage.getItem(key) || initialValue;
    window.localStorage.setItem(key, value);
    return value;
  } catch (error) {
    return initialValue;
  }
};
export const useLocalStorage = ({
  key,
  initialValue
}) => {
  invariant(!!key, 'A key must be set when using local storage');
  const [item, setValue] = useState(() => getInitialState(key, initialValue));
  const setItem = useCallback(value => {
    setValue(value);
    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      // do nothing
    }
  }, [key]);
  const removeItem = useCallback(() => {
    setValue(null);
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      //do nothing
    }
  }, [key]);
  return [item, setItem, removeItem];
};
export function useLocalStorageWithSubscribe({
  key,
  initialValue
}) {
  const [item, setValue] = useState(() => getInitialState(key, initialValue));
  useEffect(() => {
    const callback = () => {
      try {
        const newValue = window.localStorage.getItem(key);
        setValue(newValue);
      } catch (error) {
        // do nothing
      }
    };
    window.addEventListener('storage', callback);
    return () => window.removeEventListener('storage', callback);
  }, [key]);
  const setItem = useCallback(value => {
    try {
      window.localStorage.setItem(key, value);
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      // do nothing
    }
  }, [key]);
  const removeItem = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      //do nothing
    }
  }, [key]);
  return [item, setItem, removeItem];
}
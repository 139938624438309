import memoize from 'transmute/memoize';
export const parseProductDetails = memoize(productDetails => {
  var _productDetails$crmOb;
  if (productDetails !== null && productDetails !== void 0 && (_productDetails$crmOb = productDetails.crmObject) !== null && _productDetails$crmOb !== void 0 && (_productDetails$crmOb = _productDetails$crmOb.allProperties) !== null && _productDetails$crmOb !== void 0 && _productDetails$crmOb.length) {
    return {
      objectId: productDetails.crmObject.id,
      properties: productDetails.crmObject.allProperties.reduce((acc, {
        name,
        value
      }) => {
        acc[name] = {
          name,
          value: value !== null && value !== void 0 ? value : undefined
        };
        return acc;
      }, {})
    };
  }
  return undefined;
});
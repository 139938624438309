import { registerQuery, useDataFetchingClient, useQuery } from 'data-fetching-client';
import { useCallback, useMemo } from 'react';
import { getPrefetchState } from '../../../prefetch/prefetchState';
// eslint-disable-next-line conversations/no-private-internals-import
import { useSpamThreadList } from '../../../views-data/_internal/views/useSpamThreadList';
import { generateViewId, isHydratedCustomView } from '../../../views-schema/public';
import { fetchSpacesAndViewsClient } from '../clients/fetchSpacesAndViewsClient';
import { fetchAndCacheCustomView } from '../utils/fetchAndCacheCustomView';
const EMPTY_ARRAY = [];
let quickFetchReferenceId = -1;
const fetchSpacesAndViewsWithPrefetch = async ({
  spaceId,
  viewId,
  workspaceId
}) => {
  var _prefetchState$prefet;
  const prefetchState = getPrefetchState();
  const prefetchId = (_prefetchState$prefet = prefetchState === null || prefetchState === void 0 ? void 0 : prefetchState.prefetchId) !== null && _prefetchState$prefet !== void 0 ? _prefetchState$prefet : -1;
  const prefetchParams = prefetchState === null || prefetchState === void 0 ? void 0 : prefetchState.views.params;
  const spacesAndViewsPromise = prefetchState === null || prefetchState === void 0 ? void 0 : prefetchState.views.spacesAndViewsPromise;
  const stringViewId = typeof viewId === 'number' ? `${viewId}` : undefined;
  if (
  // If spaceId is passed, we want to always call the API to update the selectedSpaceId
  !spaceId &&
  // If there is an existing prefetch with matching params, use that instead
  (prefetchParams === null || prefetchParams === void 0 ? void 0 : prefetchParams.inboxId) === workspaceId && ((prefetchParams === null || prefetchParams === void 0 ? void 0 : prefetchParams.viewId) === stringViewId || !stringViewId) && spacesAndViewsPromise && prefetchId > quickFetchReferenceId) {
    try {
      const {
        spacesAndViewsResponse
      } = await spacesAndViewsPromise;
      quickFetchReferenceId = prefetchId;
      if (spacesAndViewsResponse) {
        return spacesAndViewsResponse;
      }
    } catch (_) {
      void _;
    }
  }
  return fetchSpacesAndViewsClient({
    spaceId,
    viewId,
    workspaceId
  });
};
const FETCH_SPACES_AND_VIEWS_KEY = 'fetchSpacesAndViews';
export const fetchSpacesAndViews = registerQuery({
  fetcher: fetchSpacesAndViewsWithPrefetch,
  fieldName: FETCH_SPACES_AND_VIEWS_KEY
});
const convertViewIdToNumber = viewId => viewId && !isNaN(+viewId) ? +viewId : undefined;
export const useSpacesAndViews = ({
  viewId,
  workspaceId
}) => {
  var _data$fetchSpacesAndV, _data$fetchSpacesAndV2, _data$fetchSpacesAndV3, _data$fetchSpacesAndV4, _data$fetchSpacesAndV5, _data$fetchSpacesAndV6, _data$fetchSpacesAndV7, _data$fetchSpacesAndV8, _data$fetchSpacesAndV9, _data$fetchSpacesAndV10, _data$fetchSpacesAndV11;
  const {
    data,
    error,
    networkStatus,
    refetch
  } = useQuery(fetchSpacesAndViews, {
    nextFetchPolicy: 'cache-only',
    skip: !workspaceId,
    variables: {
      viewId: convertViewIdToNumber(viewId),
      workspaceId: workspaceId !== null && workspaceId !== void 0 ? workspaceId : 0
    }
  });

  //Parse out the view arrays
  const globalViews = (_data$fetchSpacesAndV = data === null || data === void 0 || (_data$fetchSpacesAndV2 = data.fetchSpacesAndViews) === null || _data$fetchSpacesAndV2 === void 0 ? void 0 : _data$fetchSpacesAndV2.globalViews) !== null && _data$fetchSpacesAndV !== void 0 ? _data$fetchSpacesAndV : EMPTY_ARRAY;
  const preSpaceViews = (_data$fetchSpacesAndV3 = data === null || data === void 0 || (_data$fetchSpacesAndV4 = data.fetchSpacesAndViews) === null || _data$fetchSpacesAndV4 === void 0 ? void 0 : _data$fetchSpacesAndV4.preSpaceViews) !== null && _data$fetchSpacesAndV3 !== void 0 ? _data$fetchSpacesAndV3 : EMPTY_ARRAY;
  const spaceViews = (_data$fetchSpacesAndV5 = data === null || data === void 0 || (_data$fetchSpacesAndV6 = data.fetchSpacesAndViews) === null || _data$fetchSpacesAndV6 === void 0 ? void 0 : _data$fetchSpacesAndV6.spaceViews) !== null && _data$fetchSpacesAndV5 !== void 0 ? _data$fetchSpacesAndV5 : EMPTY_ARRAY;

  //Fetch the spam thread list
  //and append it to the global views array
  const {
    threadList
  } = useSpamThreadList();
  const globalViewsWithSpamThreadList = useMemo(() => threadList ? [...globalViews, threadList] : globalViews, [globalViews, threadList]);

  //Construct the allViews map
  const allViews = useMemo(() => {
    return [...globalViewsWithSpamThreadList, ...preSpaceViews, ...spaceViews].reduce((acc, view) => {
      acc[generateViewId(view)] = view;
      return acc;
    }, {});
  }, [globalViewsWithSpamThreadList, preSpaceViews, spaceViews]);

  //Define a function to update the cached selected space ID
  const client = useDataFetchingClient();
  const updateCachedSelectedSpaceId = useCallback(spaceId => {
    if (!(data !== null && data !== void 0 && data.fetchSpacesAndViews)) return;
    client.cache.writeQuery({
      query: fetchSpacesAndViews,
      data: {
        fetchSpacesAndViews: Object.assign({}, data.fetchSpacesAndViews, {
          selectedSpaceId: spaceId
        })
      }
    });
  }, [client.cache, data === null || data === void 0 ? void 0 : data.fetchSpacesAndViews]);

  //Define a function to update the selected space ID
  const updateSelectedSpace = useCallback(spaceId => {
    updateCachedSelectedSpaceId(spaceId);
    refetch({
      spaceId,
      viewId: convertViewIdToNumber(viewId),
      workspaceId
    }).catch(() => {});
  }, [refetch, updateCachedSelectedSpaceId, viewId, workspaceId]);
  const refetchView = useCallback(async viewIdToFetch => {
    await fetchAndCacheCustomView({
      viewIdToFetch,
      client
    });
  }, [client]);
  const getDefaultView = useCallback(({
    internalViewName
  }) => {
    return [...globalViewsWithSpamThreadList, ...preSpaceViews, ...spaceViews].find(view => {
      return isHydratedCustomView(view) && view.defaultViewType === internalViewName;
    });
  }, [globalViewsWithSpamThreadList, preSpaceViews, spaceViews]);
  return {
    allViews,
    error,
    getDefaultView,
    globalViews: globalViewsWithSpamThreadList,
    homePageViewId: data === null || data === void 0 || (_data$fetchSpacesAndV7 = data.fetchSpacesAndViews) === null || _data$fetchSpacesAndV7 === void 0 ? void 0 : _data$fetchSpacesAndV7.homePageDefaultCustomViewId,
    networkStatus,
    preSpaceViews,
    refetch,
    selectedSpaceId: (_data$fetchSpacesAndV8 = data === null || data === void 0 || (_data$fetchSpacesAndV9 = data.fetchSpacesAndViews) === null || _data$fetchSpacesAndV9 === void 0 ? void 0 : _data$fetchSpacesAndV9.selectedSpaceId) !== null && _data$fetchSpacesAndV8 !== void 0 ? _data$fetchSpacesAndV8 : undefined,
    spaces: (_data$fetchSpacesAndV10 = data === null || data === void 0 || (_data$fetchSpacesAndV11 = data.fetchSpacesAndViews) === null || _data$fetchSpacesAndV11 === void 0 ? void 0 : _data$fetchSpacesAndV11.spaces) !== null && _data$fetchSpacesAndV10 !== void 0 ? _data$fetchSpacesAndV10 : [],
    spaceViews,
    updateSelectedSpace,
    refetchView
  };
};
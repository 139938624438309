import { gql } from '@apollo/client';
export const FETCH_PAYMENTS_ELIGIBILITY = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"fetchEligibilityForPayments\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"paymentsEligibility\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"chargesEnabled\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"processorType\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"status\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"underwritingStatus\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "fetchEligibilityForPayments"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "paymentsEligibility"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chargesEnabled"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "processorType"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "status"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "underwritingStatus"
            }
          }]
        }
      }]
    }
  }]
});
import I18n from 'I18n';
import { safeParse } from 'chatspot-core/_internal/utils/JsonUtils';
import { PROMPT_TEMPLATE_CATEGORY, PROMPT_TEMPLATE_CATEGORY_LABEL, PROMPT_TEMPLATE_CATEGORY_UPPERCASE_TEMPORARY, PROMPT_TEMPLATE_MAPPING_TO_APP_NAME_PAGE_SLUG } from 'chatspot-core/constants/PromptTemplate';
import { onResponse } from 'hub-http/middlewares/core';

/**
 * TODO - @mlegocki:
 * Investigate if we can refactor this file or break it up to be more readible
 * In its current state, this is an overwhelming amount of information to sift through
 */

// TODO: Look into if group prompts by slug and get featured templates can be combined into one function
/**
 * We want to group the prompts based on the above template mappings
 * i.e. /home: [{ name: 'Add company', id: 'add_company' }]
 */
export const groupPromptsBySlug = prompts => {
  return Object.entries(PROMPT_TEMPLATE_MAPPING_TO_APP_NAME_PAGE_SLUG).reduce((acc, [pageSlug, promptIds]) => {
    const featuredTemplates = promptIds.map(promptId => {
      return prompts.find(prompt => prompt.id === promptId);
    });
    return Object.assign({}, acc, {
      // Remove any undefined values
      [pageSlug]: featuredTemplates.filter(Boolean)
    });
  }, {});
};

// Converts dynamic url mapping into regex
// Converts '/contacts/:id/objects/0-2/views/all/list' -> /\/contacts\/(\d+)\/objects\/0-2\/views\/all\/list.*$/
const toSlugRegex = pageSlug => {
  const matchEndingRegex = '.*$';
  const convertedSlug = pageSlug.replaceAll(':id', '(\\d+)');
  const regex = `${convertedSlug}${matchEndingRegex}`;
  return new RegExp(regex);
};
export function getAppNameFromPageSlug(pageSlug) {
  // For apps with Copilot entry point in the collaboration sidebar
  const pattern = /^\/?collaboration-sidebar-embeddable\/\d+\/panel\/([^/]+)\//;

  // Match the pattern against the provided URL
  const match = pageSlug.match(pattern);
  if (match) {
    // Extract and return the type of app (the 4th parameter in the URL)
    return match[1];
  }
  return '';
}
const getTemplateByCategory = (promptTemplates, category) => {
  return promptTemplates.filter(template => {
    return template.group && template.group.toLowerCase() === category.toLowerCase();
  });
};

/** Featured Templates are already in the correct order */
const toFeaturedTemplateGroup = featuredTemplates => {
  return featuredTemplates && featuredTemplates.length > 0 && featuredTemplates.map(featuredTemplate => {
    return Object.assign({}, featuredTemplate, {
      group: PROMPT_TEMPLATE_CATEGORY.FEATURED
    });
  }) || [];
};
export const getAllGroupedTemplates = promptTemplates => {
  return Object.values(promptTemplates).reduce((acc, templates) => {
    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    return [...acc, ...templates];
  }, []);
};
export const getFeaturedTemplates = ({
  prompts,
  pageSlug
}) => {
  const groupedPrompts = groupPromptsBySlug(prompts);
  const appName = getAppNameFromPageSlug(pageSlug);
  return Object.entries(groupedPrompts).reduce((acc, [slug, promptTemplates]) => {
    const slugRegex = toSlugRegex(slug);
    if (pageSlug.match(slugRegex) || appName.match(slugRegex)) {
      return [...acc, ...promptTemplates];
    }
    return acc;
  }, []);
};
export const getGroupedSuggestedPrompts = (promptTemplates, featuredTemplates) => {
  return {
    [PROMPT_TEMPLATE_CATEGORY_UPPERCASE_TEMPORARY.FEATURED]: toFeaturedTemplateGroup(featuredTemplates),
    [PROMPT_TEMPLATE_CATEGORY_UPPERCASE_TEMPORARY.GENERATE]: getTemplateByCategory(promptTemplates, PROMPT_TEMPLATE_CATEGORY_LABEL.GENERATE_LABEL),
    [PROMPT_TEMPLATE_CATEGORY_UPPERCASE_TEMPORARY.SUMMARIZE]: getTemplateByCategory(promptTemplates, PROMPT_TEMPLATE_CATEGORY_LABEL.SUMMARIZE_LABEL),
    [PROMPT_TEMPLATE_CATEGORY_UPPERCASE_TEMPORARY.RESEARCH]: getTemplateByCategory(promptTemplates, PROMPT_TEMPLATE_CATEGORY_LABEL.RESEARCH_LABEL),
    [PROMPT_TEMPLATE_CATEGORY_UPPERCASE_TEMPORARY.REPORTING]: getTemplateByCategory(promptTemplates, PROMPT_TEMPLATE_CATEGORY_LABEL.REPORTING_LABEL),
    [PROMPT_TEMPLATE_CATEGORY_UPPERCASE_TEMPORARY.ADD_AN_OBJECT]: getTemplateByCategory(promptTemplates, PROMPT_TEMPLATE_CATEGORY_LABEL.ADD_AN_OBJECT_LABEL),
    [PROMPT_TEMPLATE_CATEGORY_UPPERCASE_TEMPORARY.HUBSPOT_HOW_TO]: getTemplateByCategory(promptTemplates, PROMPT_TEMPLATE_CATEGORY_LABEL.HUBSPOT_HOW_TO_LABEL),
    [PROMPT_TEMPLATE_CATEGORY_UPPERCASE_TEMPORARY.PREPARE]: getTemplateByCategory(promptTemplates, PROMPT_TEMPLATE_CATEGORY_LABEL.PREPARE_LABEL)
  };
};
const formatParameterLabel = parameterLabel => {
  return parameterLabel.split('')[0].toUpperCase() + parameterLabel.toLowerCase().split('').slice(1).join('');
};
const parseParameterKey = parameterKey => {
  if (!parameterKey || parameterKey === '') {
    return {
      parameterId: '',
      parameterLabel: ''
    };
  }
  const [unformattedParameterLabel, parameterId] = parameterKey.split(':');
  return {
    parameterId,
    parameterLabel: formatParameterLabel(unformattedParameterLabel)
  };
};
const getPromptInputFields = ({
  parameter,
  promptInputFields
}) => {
  // Changes company name to company_name
  const promptInputKey = parameter.replaceAll(' ', '_').toLocaleLowerCase();
  const promptInputs = promptInputFields && Object.keys(promptInputFields).length > 0 && promptInputFields[promptInputKey];

  // If promptInputs is falsey we return null
  if (!promptInputs) {
    return {
      promptInputObjectType: undefined,
      promptInputPropertyType: undefined
    };
  }
  const {
    promptInputObjectType,
    promptInputPropertyType,
    promptInputDropdownOptions
  } = promptInputs;
  const dropdownOptions = promptInputDropdownOptions && promptInputDropdownOptions.length > 0 && promptInputDropdownOptions.map(({
    dropdownLabel,
    dropdownValue
  }) => ({
    text: dropdownLabel,
    value: dropdownValue
  })) || [];
  return {
    promptInputObjectType,
    promptInputPropertyType: promptInputPropertyType && promptInputPropertyType.toLowerCase(),
    promptInputDropdownOptions: dropdownOptions
  };
};
const constructPrompt = ({
  prompt,
  promptInputFields
}) => {
  // Updated regex: matches only if string starts with '[[' and ends with ']]'
  const PARAMETER_REGEX = /^\[\[(.*?)\]\]$/;
  const promptSections = [];
  let areParametersMapped = true;
  prompt.split(/[.*?]/g).forEach((section, promptIndex) => {
    if (section.trim() !== '') {
      promptSections[promptIndex] = {
        optional: false,
        text: '',
        inputs: []
      };
      let sectionString = '';
      let isSectionOptional = false;

      // Split into blocks where parameters might exist.
      section.split(/(\[\[.*?\]\])/g).forEach(block => {
        // Only process blocks that match exactly our parameter format.
        if (block.match(PARAMETER_REGEX)) {
          // Remove only the starting '[[' and ending ']]'
          const parameter = block.replace(/^\[\[|\]\]$/g, '');
          const [parameterKey, defaultValue] = parameter.split('|');
          // Determine if it's optional based on whether it ends with a '|'
          const optional = parameter.endsWith('|');
          const {
            parameterId,
            parameterLabel
          } = parseParameterKey(parameterKey);
          if (!parameterId) {
            areParametersMapped = false;
          }
          if (optional) {
            isSectionOptional = true;
          } else if (!optional && isSectionOptional) {
            isSectionOptional = false;
          }
          const {
            promptInputObjectType,
            promptInputPropertyType,
            promptInputDropdownOptions
          } = getPromptInputFields({
            parameter,
            promptInputFields
          });
          const id = parameterId || `${promptIndex}-${parameterLabel}`;
          promptSections[promptIndex].inputs.push({
            id,
            placeholder: optional ? `${parameterLabel} (optional)` : parameterLabel,
            optional,
            defaultValue,
            objectType: promptInputObjectType,
            propertyType: promptInputPropertyType,
            dropdownOptions: promptInputDropdownOptions
          });
          sectionString = sectionString.concat(`{{${id}}}`);
        } else {
          sectionString = sectionString + block;
        }
      });
      promptSections[promptIndex].text = sectionString.concat('.');
      promptSections[promptIndex].optional = isSectionOptional;
    }
  });
  return {
    areParametersMapped,
    promptSections
  };
};
export const massageTemplateDataType = template => {
  const {
    areParametersMapped,
    promptSections
  } = constructPrompt({
    prompt: template.command,
    promptInputFields: template.promptInputFields
  });
  return {
    id: template.id,
    header: template.header,
    group: template.category,
    prompt: promptSections,
    legacyPrompt: template.command,
    createdAt: template.createdAt,
    description: template.description,
    hubSpotRequired: template.hubSpotRequired,
    intent: template.intent,
    isFeatured: template.isFeatured,
    isFavorite: template.isFavorite,
    isMapped: Boolean(areParametersMapped && template.intent),
    isNew: template.isNew,
    favoriteId: template.favoriteId,
    mySqlId: template.mySqlId
  };
};
const sortPromptTemplates = templates => {
  return templates.sort((template1, template2) => {
    if (template1.isNew && !template2.isNew) {
      return -1;
    } else if (template2.isNew && !template1.isNew) {
      return 1;
    }
    if (template1.header < template2.header) {
      return -1;
    }
    if (template1.header > template2.header) {
      return 1;
    }
    return 0;
  });
};
const toPromptTemplate = templates => {
  return templates.map(message => {
    const dateMoment = I18n.moment(Number(message.createdAt));
    const isNew = dateMoment.isAfter(I18n.moment().subtract(2, 'weeks').startOf('day'));
    return massageTemplateDataType(Object.assign({}, message, {
      isFavorite: false,
      isNew
    }));
  });
};
export const getPromptTemplates = promptTemplatesResults => {
  const promptTemplates = toPromptTemplate(promptTemplatesResults);
  const sortedPromptTemplates = sortPromptTemplates(promptTemplates);
  return sortedPromptTemplates;
};
const formatPromptTemplatesApi = (response, options) => {
  const {
    data
  } = options;
  const {
    pageSlug
  } = safeParse(data);
  const promptTemplatesResults = response && response.data && response.data.results;
  const promptTemplates = getPromptTemplates(promptTemplatesResults);
  const featuredPromptTemplates = getFeaturedTemplates({
    prompts: promptTemplates,
    pageSlug
  });
  const groupedPromptTemplates = getGroupedSuggestedPrompts(promptTemplates, featuredPromptTemplates);
  return Object.assign({}, response, {
    data: {
      templates: promptTemplates,
      featuredTemplates: featuredPromptTemplates,
      groupedTemplates: groupedPromptTemplates
    }
  });
};
export const formatResponse = (options = {}) => onResponse(response => {
  if (options.url.includes('prompt-templates')) {
    const formattedResponse = formatPromptTemplatesApi(response, options);
    return formattedResponse;
  }
  return response;
})(options);
export const filterPromptTemplates = (groupedPromptTemplates, searchValue, category) => {
  return Object.entries(groupedPromptTemplates).reduce((acc, [group, prompts]) => {
    if (category && group !== category) {
      return acc;
    }

    /** Allows for partial search */
    const filteredPrompts = prompts.filter(prompt => {
      const searchLower = searchValue.toLowerCase();
      const headerLower = prompt.header.toLowerCase();
      return headerLower.includes(searchLower) || searchLower.split(' ').every(term => headerLower.includes(term));
    });
    if (filteredPrompts && filteredPrompts.length > 0) {
      return Object.assign({}, acc, {
        [group]: filteredPrompts
      });
    }
    return acc;
  }, {});
};

/**
 * Returns the total number of prompts in the filteredPromptTemplates object.
 * This is used to display the number of search results in the search results empty state.
 *
 * @param filteredPromptTemplates - The filtered prompt templates object.
 * @returns The total number of prompts in the filteredPromptTemplates object.
 */
export const getFilteredPromptTemplateCount = filteredPromptTemplates => {
  return Object.entries(filteredPromptTemplates).reduce((acc, [group, prompts]) => {
    // Do not count the TEMPLATES_ENABLED_FOR_EMPTY_STATE group
    if (group === 'TEMPLATES_ENABLED_FOR_EMPTY_STATE') {
      return acc;
    }
    return acc + prompts.length;
  }, 0);
};
import { useCallback, useMemo } from 'react';
import { useHttpClient } from '../../../../client/HttpClientContext';
import { useObjectTypeId } from '../../../../v2/hooks/useObjectTypeId';
import { cache as leadPipelineSettingsCache } from '../../../leads/leadPipelineSettingsCache';
import { cache as leadFollowUpHiddenSettingsCache } from '../../../leads/leadFollowUpHiddenSettingsCache';
import { cache as leadSettingsCache } from '../../../leads/leadSettingsCache';
import { SharedPipelinesCache } from '../../../../panels/PipelinesCache';
import { LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useAsyncFunction } from '../../../../utils/hooks/useAsyncFunction';
export const useGetLeadStageQualificationValidator = () => {
  const objectTypeId = useObjectTypeId();
  const httpClient = useHttpClient();
  const getLeadPipelineSettings = useCallback(() => {
    return leadPipelineSettingsCache.get('', httpClient).then(response => response.result);
  }, [httpClient]);
  const getLeadFollowUpHiddenSettings = useCallback(() => {
    return leadFollowUpHiddenSettingsCache.get('', httpClient);
  }, [httpClient]);
  const getLeadSettings = useCallback(() => {
    return leadSettingsCache.get('', httpClient);
  }, [httpClient]);
  const {
    data: leadPipelineSettingsData,
    loading: leadPipelineSettingsLoading,
    error: leadPipelineSettingsError
  } = useAsyncFunction(getLeadPipelineSettings, {
    skip: objectTypeId !== LEAD_TYPE_ID
  });
  const {
    data: leadFollowUpHiddenSettingsData,
    loading: leadFollowUpHiddenSettingsLoading,
    error: leadFollowUpHiddenSettingsError
  } = useAsyncFunction(getLeadFollowUpHiddenSettings, {
    skip: objectTypeId !== LEAD_TYPE_ID
  });
  const {
    data: leadSettingsData,
    loading: leadSettingsLoading,
    error: leadSettingsError
  } = useAsyncFunction(getLeadSettings, {
    skip: objectTypeId !== LEAD_TYPE_ID
  });
  const getPipelines = useCallback(() => {
    return SharedPipelinesCache.get({
      objectTypeId,
      httpClient
    });
  }, [objectTypeId, httpClient]);
  const {
    data: pipelinesData,
    loading: pipelinesLoading,
    error: pipelinesError
  } = useAsyncFunction(getPipelines, {
    skip: objectTypeId !== LEAD_TYPE_ID
  });
  const stageChangeValidator = useCallback(request => {
    const {
      targetStageId,
      targetPipelineId,
      targetDisqualificationReason
    } = request;

    // If we don't have data to validate against, fail open to not block changes
    if (!pipelinesData || !leadPipelineSettingsData || leadPipelineSettingsError || pipelinesError || leadFollowUpHiddenSettingsError || leadFollowUpHiddenSettingsLoading || leadSettingsError || leadSettingsLoading) {
      return {
        isValid: true
      };
    }
    const selectedPipeline = pipelinesData[targetPipelineId];
    const selectedPipelineSettings = leadPipelineSettingsData[targetPipelineId];
    const selectedStage = selectedPipeline.stages.find(pipelineStage => pipelineStage.stageId === targetStageId);
    if (leadPipelineSettingsData && leadSettingsData && selectedStage && selectedStage.metadata.leadState === 'UNQUALIFIED') {
      const reAttemptingLeadFollowUpTask = !leadFollowUpHiddenSettingsData;
      if (leadSettingsData.enableReAttemptingLead && targetDisqualificationReason && leadSettingsData.reAttemptingDisqualifiedReasons.includes(targetDisqualificationReason)) {
        if (reAttemptingLeadFollowUpTask) {
          return {
            isValid: false,
            requirement: 'RE_ATTEMPTING_LEAD_AND_TASK_REQUIRED'
          };
        } else {
          return {
            isValid: false,
            requirement: 'RE_ATTEMPTING_LEAD_REQUIRED'
          };
        }
      }
      if (reAttemptingLeadFollowUpTask) {
        return {
          isValid: false,
          requirement: 'FOLLOW_UP_TASK_REQUIRED'
        };
      }
    }
    if (selectedPipelineSettings && selectedPipelineSettings.requireDealCreationToQualify && selectedStage && selectedStage.metadata.leadState === 'QUALIFIED') {
      return {
        isValid: false,
        requirement: 'DEAL_CREATION_REQUIRED'
      };
    }
    return {
      isValid: true
    };
  }, [leadFollowUpHiddenSettingsData, leadFollowUpHiddenSettingsError, leadFollowUpHiddenSettingsLoading, leadPipelineSettingsData, leadPipelineSettingsError, leadSettingsData, leadSettingsError, leadSettingsLoading, pipelinesData, pipelinesError]);
  return useMemo(() => ({
    loading: leadPipelineSettingsLoading || pipelinesLoading,
    stageChangeValidator
  }), [leadPipelineSettingsLoading, pipelinesLoading, stageChangeValidator]);
};
import I18n from 'I18n';
import getLang from 'I18n/utils/getLang';
import { HS_ALLOW_PARTIAL_PAYMENTS, HS_INVOICE_PAYMENT_COLLECTION_METHOD, INVOICE_PROPERTY_VALUES, HS_RECIPIENT_COMPANY_ADDRESS, HS_RECIPIENT_COMPANY_ADDRESS2, HS_RECIPIENT_COMPANY_CITY, HS_RECIPIENT_COMPANY_COUNTRY, HS_RECIPIENT_COMPANY_COUNTRY_CODE, HS_RECIPIENT_COMPANY_STATE, HS_RECIPIENT_COMPANY_ZIP, HS_RECIPIENT_SHIPPING_NAME, HS_RECIPIENT_SHIPPING_ADDRESS, HS_RECIPIENT_SHIPPING_ADDRESS2, HS_RECIPIENT_SHIPPING_CITY, HS_RECIPIENT_SHIPPING_STATE, HS_RECIPIENT_SHIPPING_ZIP, HS_RECIPIENT_SHIPPING_COUNTRY, HS_RECIPIENT_SHIPPING_COUNTRY_CODE, COMPANY_ADDRESS_PROPERTIES, HS_COLLECT_ADDRESS_TYPES, HS_ALLOWED_PAYMENT_METHODS } from 'invoices-ui-lib/constants/properties';
import { InvoiceOperation } from 'invoices-ui-lib/types/InvoiceOperation';
import { convertPaymentMethodTypesToInvoicePaymentMethods } from 'commerce-shared-components-lib/features/checkout/utils/invoices';
import { getIsShippingEnabled, updateCollectAddressTypesValues } from './paymentSettings';
import { getInvoiceAssociatedObjectProperty, getInvoiceProperty } from './property';
import { FIELD_SHIPPING_ADDRESS_VALUE } from '../constants/collectAddressTypes';
import { InvoiceStatus } from '../constants/status';
export const makeNewProperty = (name, value) => ({
  name,
  value
});
export const COMPANY_OVERRIDE_ADDRESS_PROPERTIES = [HS_RECIPIENT_COMPANY_ADDRESS, HS_RECIPIENT_COMPANY_ADDRESS2, HS_RECIPIENT_COMPANY_CITY, HS_RECIPIENT_COMPANY_STATE, HS_RECIPIENT_COMPANY_ZIP, HS_RECIPIENT_COMPANY_COUNTRY, HS_RECIPIENT_COMPANY_COUNTRY_CODE];
export const getDifferenceBetweenDates = (invoiceDate, dueDate) => {
  return I18n.moment.portalTz(+dueDate).diff(I18n.moment.portalTz(+invoiceDate), 'days');
};
export const getCurrentDateInPortalTz = () => I18n.moment.portalTz().endOf('day').valueOf();
export const getDefaultDueDate = days => I18n.moment.portalTz().add(days, 'days').endOf('day').valueOf();
const getPropertyValueOrEmptyString = (propertyName, company) => company ? getInvoiceAssociatedObjectProperty(company, propertyName) || '' : '';
export const getCompanyAddressProperties = company => ({
  [HS_RECIPIENT_COMPANY_ADDRESS]: makeNewProperty(HS_RECIPIENT_COMPANY_ADDRESS, getPropertyValueOrEmptyString(COMPANY_ADDRESS_PROPERTIES.ADDRESS, company)),
  [HS_RECIPIENT_COMPANY_ADDRESS2]: makeNewProperty(HS_RECIPIENT_COMPANY_ADDRESS2, getPropertyValueOrEmptyString(COMPANY_ADDRESS_PROPERTIES.ADDRESS_2, company)),
  [HS_RECIPIENT_COMPANY_CITY]: makeNewProperty(HS_RECIPIENT_COMPANY_CITY, getPropertyValueOrEmptyString(COMPANY_ADDRESS_PROPERTIES.CITY, company)),
  [HS_RECIPIENT_COMPANY_ZIP]: makeNewProperty(HS_RECIPIENT_COMPANY_ZIP, getPropertyValueOrEmptyString(COMPANY_ADDRESS_PROPERTIES.ZIP, company)),
  [HS_RECIPIENT_COMPANY_STATE]: makeNewProperty(HS_RECIPIENT_COMPANY_STATE, getPropertyValueOrEmptyString(COMPANY_ADDRESS_PROPERTIES.STATE, company)),
  [HS_RECIPIENT_COMPANY_COUNTRY]: makeNewProperty(HS_RECIPIENT_COMPANY_COUNTRY, getPropertyValueOrEmptyString(COMPANY_ADDRESS_PROPERTIES.COUNTRY, company)),
  [HS_RECIPIENT_COMPANY_COUNTRY_CODE]: makeNewProperty(HS_RECIPIENT_COMPANY_COUNTRY_CODE, getPropertyValueOrEmptyString(COMPANY_ADDRESS_PROPERTIES.HS_COUNTRY_CODE, company).toUpperCase())
});
export const resetShippingAddressProperties = {
  [HS_RECIPIENT_SHIPPING_NAME]: makeNewProperty(HS_RECIPIENT_SHIPPING_NAME, undefined),
  [HS_RECIPIENT_SHIPPING_ADDRESS]: makeNewProperty(HS_RECIPIENT_SHIPPING_ADDRESS, undefined),
  [HS_RECIPIENT_SHIPPING_ADDRESS2]: makeNewProperty(HS_RECIPIENT_SHIPPING_ADDRESS2, undefined),
  [HS_RECIPIENT_SHIPPING_CITY]: makeNewProperty(HS_RECIPIENT_SHIPPING_CITY, undefined),
  [HS_RECIPIENT_SHIPPING_ZIP]: makeNewProperty(HS_RECIPIENT_SHIPPING_ZIP, undefined),
  [HS_RECIPIENT_SHIPPING_STATE]: makeNewProperty(HS_RECIPIENT_SHIPPING_STATE, undefined),
  [HS_RECIPIENT_SHIPPING_COUNTRY]: makeNewProperty(HS_RECIPIENT_SHIPPING_COUNTRY, undefined),
  [HS_RECIPIENT_SHIPPING_COUNTRY_CODE]: makeNewProperty(HS_RECIPIENT_SHIPPING_COUNTRY_CODE, undefined)
};
const getDefaultProperties = lastPaymentTerm => ({
  hs_invoice_date: makeNewProperty('hs_invoice_date', `${getCurrentDateInPortalTz()}`),
  hs_due_date: makeNewProperty('hs_due_date', `${getDefaultDueDate(+lastPaymentTerm)}`),
  hs_invoice_status: makeNewProperty('hs_invoice_status', InvoiceStatus.DRAFT)
});
export const getDefaultPropertiesFromQuote = (quote, currencyCode, lastPaymentTerm, company) => Object.assign({}, getDefaultProperties(lastPaymentTerm), {
  hs_language: makeNewProperty('hs_language', getInvoiceAssociatedObjectProperty(quote, 'hs_language')),
  hs_locale: makeNewProperty('hs_locale', getInvoiceAssociatedObjectProperty(quote, 'hs_locale')),
  hs_currency: makeNewProperty('hs_currency', currencyCode),
  hs_domain: makeNewProperty('hs_domain', getInvoiceAssociatedObjectProperty(quote, 'hs_domain'))
}, getCompanyAddressProperties(company));
const sanitizeLocale = locale => locale === 'en' ? 'en-us' : locale;
export const getDefaultPropertiesForBlankInvoice = (currency, lastPaymentTerm) => {
  // @ts-expect-error dependency missing types
  const locale = I18n.locale;
  const defaultProperties = Object.assign({}, getDefaultProperties(lastPaymentTerm), {
    hs_language: makeNewProperty('hs_language', getLang()),
    hs_locale: makeNewProperty('hs_locale', sanitizeLocale(locale)),
    hs_currency: makeNewProperty('hs_currency', currency)
  });
  return defaultProperties;
};
export const getDefaultInitialInvoiceProperties = (defaultCurrency, lastPaymentTerm, company) => {
  // @ts-expect-error dependency missing types
  const locale = I18n.locale;
  const defaultProperties = Object.assign({}, getDefaultProperties(lastPaymentTerm), {
    hs_language: makeNewProperty('hs_language', getLang()),
    hs_locale: makeNewProperty('hs_locale', sanitizeLocale(locale)),
    hs_currency: makeNewProperty('hs_currency', defaultCurrency)
  }, getCompanyAddressProperties(company));
  return defaultProperties;
};
export const filterOutInvoicePropertiesByKeys = (properties, keys) => {
  return Object.keys(properties).filter(key => !keys.includes(key)).reduce((newProperties, key) => {
    newProperties[key] = properties[key];
    return newProperties;
  }, {});
};
export const getInitialCollectAddressTypes = (dataShippingSettings, derivedInitialInvoice) => {
  return getIsShippingEnabled(dataShippingSettings) && derivedInitialInvoice.properties[HS_COLLECT_ADDRESS_TYPES] ? derivedInitialInvoice.properties[HS_COLLECT_ADDRESS_TYPES] : makeNewProperty(HS_COLLECT_ADDRESS_TYPES, updateCollectAddressTypesValues(getInvoiceProperty(derivedInitialInvoice, HS_COLLECT_ADDRESS_TYPES) || '', FIELD_SHIPPING_ADDRESS_VALUE, 'remove'));
};
export const getNonEnrolledCheckoutProperties = () => {
  return {
    [HS_ALLOWED_PAYMENT_METHODS]: makeNewProperty(HS_ALLOWED_PAYMENT_METHODS, ''),
    [HS_COLLECT_ADDRESS_TYPES]: makeNewProperty(HS_COLLECT_ADDRESS_TYPES, ''),
    [HS_INVOICE_PAYMENT_COLLECTION_METHOD]: makeNewProperty(HS_INVOICE_PAYMENT_COLLECTION_METHOD, INVOICE_PROPERTY_VALUES[HS_INVOICE_PAYMENT_COLLECTION_METHOD].NONE),
    [HS_ALLOW_PARTIAL_PAYMENTS]: makeNewProperty(HS_ALLOW_PARTIAL_PAYMENTS, false)
  };
};
export const getEnrolledCheckoutProperties = ({
  invoice,
  defaultPaymentMethods,
  shippingSettings,
  operation
}) => {
  const collectAddressTypesWithShippingEvaluated = getInitialCollectAddressTypes(shippingSettings, invoice);
  const result = {
    [HS_ALLOWED_PAYMENT_METHODS]: operation === InvoiceOperation.CLONE || operation === InvoiceOperation.EDIT ? invoice.properties[HS_ALLOWED_PAYMENT_METHODS] : makeNewProperty(HS_ALLOWED_PAYMENT_METHODS, convertPaymentMethodTypesToInvoicePaymentMethods(defaultPaymentMethods)),
    [HS_INVOICE_PAYMENT_COLLECTION_METHOD]: operation === InvoiceOperation.CLONE || operation === InvoiceOperation.EDIT ? invoice.properties[HS_INVOICE_PAYMENT_COLLECTION_METHOD] : makeNewProperty(HS_INVOICE_PAYMENT_COLLECTION_METHOD, INVOICE_PROPERTY_VALUES[HS_INVOICE_PAYMENT_COLLECTION_METHOD].MANUAL)
  };
  if (operation === InvoiceOperation.CLONE || operation === InvoiceOperation.EDIT) {
    result[HS_COLLECT_ADDRESS_TYPES] = collectAddressTypesWithShippingEvaluated;
  }
  return result;
};
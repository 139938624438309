import { useCallback, useContext, useEffect, useRef } from 'react';
import { areEqualPropertyValues } from '../../v2/utils/areEqualPropertyValues';
import { useGetAdditionalPropertyValue } from '../../v2/hooks/useGetAdditionalPropertyValue';
import { useObjectId } from '../../v2/hooks/useObjectId';
import { useObjectTypeId } from '../../v2/hooks/useObjectTypeId';
import { useProperty } from '../../v2/hooks/useProperty';
import { usePropertyValue } from '../../v2/hooks/usePropertyValue';
import { isPipelineProperty } from 'customer-data-objects/property/PropertyIdentifier';
import { useIdentifierContext } from '../../v2/context/IdentifierContext';
import { AutosaveContext } from '../context/AutosaveContext';
export const usePropertyEdits = ({
  onChange
}) => {
  const {
    inputId
  } = useIdentifierContext();
  const autosaveContext = useContext(AutosaveContext);
  const property = useProperty();
  const objectId = useObjectId();
  const objectTypeId = useObjectTypeId();
  const propertyValue = usePropertyValue();
  const getAdditionalPropertyValue = useGetAdditionalPropertyValue();
  const pendingEditsRef = useRef({});
  const originalValues = useRef({});
  const clearEdits = useCallback(() => {
    pendingEditsRef.current = {};
    originalValues.current = {};
  }, []);
  const getHasPendingEdits = useCallback(() => {
    return Object.keys(pendingEditsRef.current).length > 0;
  }, []);
  const getPendingEdits = useCallback(() => {
    return pendingEditsRef.current;
  }, []);
  const getOriginalValues = useCallback(() => {
    return originalValues.current;
  }, []);
  const wrappedOnChange = useCallback(changes => {
    Object.entries(changes).forEach(([name, value]) => {
      pendingEditsRef.current[name] = value;
      if (!Object.prototype.hasOwnProperty.call(originalValues.current, name)) {
        const originalValue = getAdditionalPropertyValue(name, objectId, objectTypeId);
        originalValues.current[name] = originalValue;
      }
    });

    // Pipeline input can change the secondary property without changing the primary property,
    // so we prevent clearing the pending edits for this case. Otherwise, it would result in failed
    // autosave behavior when only secondary changes are requested.
    const isPipeline = isPipelineProperty(property, objectTypeId);
    if (!isPipeline && changes[property.name] !== undefined) {
      const hasReturnedToOriginal = areEqualPropertyValues(changes[property.name], originalValues.current[property.name], property);
      if (hasReturnedToOriginal) {
        pendingEditsRef.current = {};
      }
    }
    onChange(changes);
  }, [property, onChange, getAdditionalPropertyValue, objectId, objectTypeId]);
  useEffect(() => {
    // If the host gives us a new value that is different from the changes we are tracking,
    // assume that it means the value has been saved elsewhere and updated here.
    const pendingEdit = pendingEditsRef.current[property.name];
    const currentValue = propertyValue;
    if (!areEqualPropertyValues(pendingEdit, currentValue, property)) {
      var _autosaveContext$hasR;
      clearEdits();
      if (autosaveContext !== null && autosaveContext !== void 0 && (_autosaveContext$hasR = autosaveContext.hasRequestForSource) !== null && _autosaveContext$hasR !== void 0 && _autosaveContext$hasR.call(autosaveContext, inputId)) {
        autosaveContext.cancelRequestsForSource(inputId);
      }
    }
  }, [propertyValue, clearEdits, property, autosaveContext, inputId]);
  return {
    clearEdits,
    getPendingEdits,
    getOriginalValues,
    getHasPendingEdits,
    onChange: wrappedOnChange
  };
};
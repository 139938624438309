import { combineTierRangesAndPrices, parseTierPrices, parseTierRanges } from 'commerce-products-lib/utils/pricing';
import { useAdditionalPropertyValues } from '../../../v2/hooks/useAdditionalPropertyValues';
export const useGetTierRangesAndPrices = () => {
  var _additionalValues$tie, _additionalValues$tie2;
  const {
    data: additionalValues
  } = useAdditionalPropertyValues({
    tiersPrices: 'hs_tier_prices',
    tiersRanges: 'hs_tier_ranges'
  });
  const tiersRanges = parseTierRanges((_additionalValues$tie = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues.tiersRanges) !== null && _additionalValues$tie !== void 0 ? _additionalValues$tie : undefined, false);
  const tiersPrices = parseTierPrices((_additionalValues$tie2 = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues.tiersPrices) !== null && _additionalValues$tie2 !== void 0 ? _additionalValues$tie2 : undefined, false);
  const tierRangesAndPrices = combineTierRangesAndPrices(tiersRanges !== null && tiersRanges !== void 0 ? tiersRanges : [], tiersPrices !== null && tiersPrices !== void 0 ? tiersPrices : []);
  return tierRangesAndPrices;
};
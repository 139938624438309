import { useCallback } from 'react';
import { useHttpClient } from '../../client/HttpClientContext';
import { useAsyncFunction } from '../../utils/hooks/useAsyncFunction';
import { PropertyValidationRulesCache } from '../../validation/validationRulesCache';
import { useObjectType } from './useObjectType';
import { useProperty } from './useProperty';
export const usePropertyValidationRules = (options = {
  skip: false
}) => {
  const property = useProperty();
  const objectTypeId = useObjectType();
  const httpClient = useHttpClient();
  const getRules = useCallback(() => {
    return PropertyValidationRulesCache.get({
      objectTypeId,
      propertyName: property.name
    }, httpClient);
  }, [httpClient, objectTypeId, property.name]);
  return useAsyncFunction(getRules, options);
};
import { getFullUrl } from 'hubspot-url-utils';
import PortalIdParser from 'PortalIdParser';
export function getSLAConfigUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/sla`;
}
export function getInboxChannelsTableUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/channels`;
}
export function getAvailabilityManagementUrl() {
  return `${getFullUrl('app')}/settings/${PortalIdParser.get()}/availability-management`;
}
export function getCurrentInboxAccessUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/access`;
}
export function getLiveChatTrackingCodeUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/edit/live-chat/primary/tracking`;
}
export function getWhatsAppCreateUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/whats-app/create`;
}
export function getFormsCreateUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/forms/create`;
}
export function getCallingCreateUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/calling/create`;
}
export function getEmailCreateUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/email/create`;
}
export function getChatChannelCreateUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/live-chat/create`;
}
export function getFacebookMessengerChannelCreateUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/facebook-messenger/create`;
}
import produce from 'immer';
// eslint-disable-next-line conversations/no-private-internals-import
import { fetchCustomViewService } from '../../../views-data/_internal/custom-views/fetchCustomViewService';
import { fetchSpacesAndViews } from '../hooks/useSpacesAndViews';
import { deleteViewFromCache } from './deleteViewFromCache';
export const fetchAndCacheCustomView = async ({
  viewIdToFetch,
  client
}) => {
  const existingData = client.cache.readQuery({
    query: fetchSpacesAndViews
  });
  if (!(existingData !== null && existingData !== void 0 && existingData.fetchSpacesAndViews)) return;
  try {
    const customView = await fetchCustomViewService({
      customViewId: viewIdToFetch
    }).catch(error => {
      if (error.status === 404) {
        deleteViewFromCache({
          viewId: viewIdToFetch.toString(),
          client
        });
      }
      throw error;
    });
    client.cache.updateQuery({
      query: fetchSpacesAndViews
    }, updateExistingData => {
      if (!(updateExistingData !== null && updateExistingData !== void 0 && updateExistingData.fetchSpacesAndViews)) return updateExistingData;
      return {
        fetchSpacesAndViews: produce(updateExistingData.fetchSpacesAndViews, draft => {
          var _draft$globalViews, _draft$preSpaceViews, _draft$spaceViews;
          // Find which array currently contains the view
          if ((_draft$globalViews = draft.globalViews) !== null && _draft$globalViews !== void 0 && _draft$globalViews.find(view => view.customViewId === viewIdToFetch)) {
            draft.globalViews = draft.globalViews.map(view => view.customViewId === viewIdToFetch ? customView : view);
          } else if ((_draft$preSpaceViews = draft.preSpaceViews) !== null && _draft$preSpaceViews !== void 0 && _draft$preSpaceViews.find(view => view.customViewId === viewIdToFetch)) {
            draft.preSpaceViews = draft.preSpaceViews.map(view => view.customViewId === viewIdToFetch ? customView : view);
          } else if ((_draft$spaceViews = draft.spaceViews) !== null && _draft$spaceViews !== void 0 && _draft$spaceViews.find(view => view.customViewId === viewIdToFetch)) {
            draft.spaceViews = draft.spaceViews.map(view => view.customViewId === viewIdToFetch ? customView : view);
          } else if (customView.spaceId === (draft === null || draft === void 0 ? void 0 : draft.selectedSpaceId)) {
            // If it's new and has a spaceId equal to selected spaceId, add to spaceViews
            draft.spaceViews = [...(draft.spaceViews || []), customView];
          } else if (!customView.spaceId) {
            // If it's new and has no spaceId, add to preSpaceViews
            draft.preSpaceViews = [...(draft.preSpaceViews || []), customView];
          }
        })
      };
    });
  } catch (_) {
    void _;
    // Silently fail - the error will be handled by the main query
  }
};
import { ALL_ONBOARDING_PAID_SCOPES, ONBOARDING_SCOPES } from 'onboarding-scopes/scopeConstants';
import { hasScope } from './auth';
import { ONBOARDING_SCOPES_TO_TRIAL_SCOPES } from 'onboarding-scopes/monetization-utils/trialScopes';
export const isOnboardingScope = scope => ALL_ONBOARDING_PAID_SCOPES.includes(scope);
export const isOwned = scopeToCheck => {
  if (isOnboardingScope(scopeToCheck)) {
    const trialScope = ONBOARDING_SCOPES_TO_TRIAL_SCOPES[scopeToCheck];
    if (!trialScope) {
      // We expect no matching trial scope in the case of
      // Starter Hubs, which we don't offer trials for
      return hasScope(scopeToCheck);
    }
    return hasScope(scopeToCheck) && !hasScope(trialScope);
  }
  return false;
};
export const hasTrialVersionOf = scopeToCheck => {
  if (isOnboardingScope(scopeToCheck)) {
    const trialScope = ONBOARDING_SCOPES_TO_TRIAL_SCOPES[scopeToCheck];
    if (!trialScope) {
      // We expect no matching trial scope in the case of
      // Starter Hubs, which we don't offer trials for
      return false;
    }
    return hasScope(trialScope);
  }
  return false;
};
export const ownsAny = scopesToCheck => {
  return scopesToCheck.some(scopeToCheck => isOwned(scopeToCheck));
};
export const ownsNone = scopesToCheck => {
  return !ownsAny(scopesToCheck);
};
export const ownsAll = scopesToCheck => {
  return scopesToCheck.every(scopeToCheck => isOwned(scopeToCheck));
};
export const getOwnsPaidPortal = scopesToCheck => {
  return ownsAny(scopesToCheck);
};
export const hasAnyTrialPaidParcel = scopesToCheck => {
  return scopesToCheck.some(scopeToCheck => {
    if (isOnboardingScope(scopeToCheck)) {
      return hasTrialVersionOf(scopeToCheck);
    }
    return false;
  });
};
export const hasSalesOrServiceEnterprisePaidParcel = () => getOwnsPaidPortal([ONBOARDING_SCOPES.SALES_ENTERPRISE, ONBOARDING_SCOPES.SERVICE_ENTERPRISE]);
export const hasSalesOrServiceEnterpriseTrialParcel = () => hasTrialVersionOf(ONBOARDING_SCOPES.SALES_ENTERPRISE) || hasTrialVersionOf(ONBOARDING_SCOPES.SERVICE_ENTERPRISE);
export const getOwnsAnyPaidParcel = () => getOwnsPaidPortal(ALL_ONBOARDING_PAID_SCOPES);
export const isTrialingSalesOrServiceStarterPlus = () => getOwnsAnyPaidParcel() && hasAnyTrialPaidParcel([ONBOARDING_SCOPES.SALES_ENTERPRISE, ONBOARDING_SCOPES.SERVICE_ENTERPRISE, ONBOARDING_SCOPES.SALES_PRO, ONBOARDING_SCOPES.SERVICE_PRO, ONBOARDING_SCOPES.SALES_STARTER, ONBOARDING_SCOPES.SERVICE_STARTER]);
export const hasSalesOrServiceStarterPlus = () => getOwnsPaidPortal([ONBOARDING_SCOPES.SALES_STARTER, ONBOARDING_SCOPES.SERVICE_STARTER, ONBOARDING_SCOPES.SALES_PRO, ONBOARDING_SCOPES.SERVICE_PRO, ONBOARDING_SCOPES.SALES_ENTERPRISE, ONBOARDING_SCOPES.SERVICE_ENTERPRISE]);
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { registerQuery, useQuery, registerMutation, useMutation } from 'data-fetching-client';
import { useCallback, useMemo } from 'react';
import { getPrefetchState } from 'find-and-filter-data/prefetch/prefetchState';
import { AsyncStatus } from 'find-and-filter-data/common/public';
import { fetchCustomViewSettings, postCustomViewSettings } from './customViewsSettingsClient';
let quickFetchReferenceId = -1;
const fetchCustomViewSettingsWithQuickFetch = async ({
  workspaceId
}) => {
  var _prefetchState$prefet, _prefetchState$userSe;
  // Get the quick fetch state
  const prefetchState = getPrefetchState();
  const prefetchId = (_prefetchState$prefet = prefetchState === null || prefetchState === void 0 ? void 0 : prefetchState.prefetchId) !== null && _prefetchState$prefet !== void 0 ? _prefetchState$prefet : -1;
  const prefetchWorkspaceId = prefetchState === null || prefetchState === void 0 || (_prefetchState$userSe = prefetchState.userSettings.params) === null || _prefetchState$userSe === void 0 ? void 0 : _prefetchState$userSe.workspaceId;
  const userSettingsPromise = prefetchState === null || prefetchState === void 0 ? void 0 : prefetchState.userSettings.userSettingsPromise;

  // If there is an existing prefetch, use that instead
  if (prefetchWorkspaceId === workspaceId && userSettingsPromise && prefetchId > quickFetchReferenceId && (prefetchState === null || prefetchState === void 0 ? void 0 : prefetchState.userSettings.status) !== AsyncStatus.FAILED) {
    try {
      const {
        userSettingsResponse
      } = await userSettingsPromise;
      quickFetchReferenceId = prefetchId;
      if (userSettingsResponse) {
        return userSettingsResponse;
      }
    } catch (_) {
      void _;
    }
  }

  // If no prefetch data is available or prefetch failed, fall back to regular fetch
  return fetchCustomViewSettings({
    workspaceId
  });
};
const GET_VIEW_SETTINGS_QUERY_NAME = 'viewSettings';
const GET_VIEW_SETTINGS = registerQuery({
  args: ['workspaceId'],
  fetcher: fetchCustomViewSettingsWithQuickFetch,
  fieldName: GET_VIEW_SETTINGS_QUERY_NAME
});
const UPDATE_VIEW_SETTINGS = registerMutation({
  args: ['settings'],
  fetcher: postCustomViewSettings,
  fieldName: 'updateViewSettings'
});
const mapToStrings = arr => arr ? arr.map(String) : [];
const defaultSettings = {
  starredViews: [],
  spacesViewGroupOrder: {},
  viewGroupOrder: {},
  viewGroupOrderV2: {},
  viewQuickFilters: []
};
export function useCustomViewUserSettings(workspaceId) {
  var _data$viewSettings$se, _data$viewSettings, _data$viewSettings6;
  const {
    data,
    client,
    loading
  } = useQuery(GET_VIEW_SETTINGS, {
    skip: !workspaceId,
    variables: {
      workspaceId
    },
    onError: err => {
      reportError({
        error: err
      });
    }
  });
  const [mutate] = useMutation(UPDATE_VIEW_SETTINGS, {
    onError: err => {
      reportError({
        error: err
      });
    }
  });
  const {
    viewGroupOrder,
    viewGroupOrderV2,
    viewQuickFilters,
    spacesViewGroupOrder,
    preferredLayout
  } = (_data$viewSettings$se = data === null || data === void 0 || (_data$viewSettings = data.viewSettings) === null || _data$viewSettings === void 0 ? void 0 : _data$viewSettings.settings) !== null && _data$viewSettings$se !== void 0 ? _data$viewSettings$se : defaultSettings;

  // Map number ids to strings for compatibility with UI
  const order = useMemo(() => {
    if (Object.keys(viewGroupOrderV2).length > 0) return viewGroupOrderV2;
    const orderObj = {};
    const groups = Object.keys(viewGroupOrder);
    for (const group of groups) {
      orderObj[group] = mapToStrings(viewGroupOrder[group]);
    }
    return orderObj;
  }, [viewGroupOrder, viewGroupOrderV2]);
  const spacesOrder = spacesViewGroupOrder;
  const optimisticUpdateSettings = useCallback(updatedSettings => {
    client.cache.writeQuery({
      query: GET_VIEW_SETTINGS,
      variables: {
        workspaceId
      },
      data: {
        viewSettings: Object.assign({}, data ? data.viewSettings : {}, {
          settings: updatedSettings
        })
      }
    });
  }, [client.cache, data, workspaceId]);
  const updateSettings = useCallback(updatedSettings => {
    optimisticUpdateSettings(updatedSettings);
    mutate({
      variables: {
        settings: updatedSettings,
        workspaceId
      }
    }).catch(() => {});
  }, [optimisticUpdateSettings, mutate, workspaceId]);
  const memoizedUpdateOrder = useCallback(updatedGroupOrder => {
    var _data$viewSettings2;
    const settings = data === null || data === void 0 || (_data$viewSettings2 = data.viewSettings) === null || _data$viewSettings2 === void 0 ? void 0 : _data$viewSettings2.settings;
    const currentSettings = settings && Object.assign({}, settings);
    const updatedViewGroupOrderV2 = Object.assign({}, currentSettings.viewGroupOrderV2);
    for (const group of Object.keys(updatedGroupOrder)) {
      updatedViewGroupOrderV2[group] = updatedGroupOrder[group];
    }
    const updatedSettings = Object.assign({}, currentSettings, {
      viewGroupOrderV2: updatedViewGroupOrderV2
    });
    updateSettings(updatedSettings);
    return updatedSettings;
  }, [data, updateSettings]);
  const memoizedUpdateSpaceOrder = useCallback((spaceId, viewGroupOrderForSpace) => {
    var _data$viewSettings3;
    const settings = data === null || data === void 0 || (_data$viewSettings3 = data.viewSettings) === null || _data$viewSettings3 === void 0 ? void 0 : _data$viewSettings3.settings;
    const currentSettings = settings && Object.assign({}, settings);
    const updatedSpacesViewGroupOrder = Object.assign({}, currentSettings.spacesViewGroupOrder);
    updatedSpacesViewGroupOrder[spaceId] = viewGroupOrderForSpace;
    const updatedSettings = Object.assign({}, currentSettings, {
      spacesViewGroupOrder: updatedSpacesViewGroupOrder
    });
    updateSettings(updatedSettings);
    return updatedSettings;
  }, [data, updateSettings]);
  const memoizedUpdateViewFilters = useCallback((viewFilterId, filters) => {
    var _data$viewSettings4, _currentSettings$view;
    const settings = data === null || data === void 0 || (_data$viewSettings4 = data.viewSettings) === null || _data$viewSettings4 === void 0 ? void 0 : _data$viewSettings4.settings;
    const currentSettings = settings && Object.assign({}, settings);
    const updatedViewFilters = ((_currentSettings$view = currentSettings.viewQuickFilters) === null || _currentSettings$view === void 0 ? void 0 : _currentSettings$view.filter(({
      customViewId
    }) => Number(viewFilterId) !== customViewId)) || [];
    updatedViewFilters.push({
      customViewId: Number(viewFilterId),
      quickFilters: filters
    });
    const updatedSettings = Object.assign({}, currentSettings, {
      viewQuickFilters: updatedViewFilters
    });
    updateSettings(updatedSettings);
    return updatedSettings;
  }, [data, updateSettings]);
  const memoizedUpdatePreferredLayout = useCallback(layoutPreference => {
    var _data$viewSettings5;
    const settings = data === null || data === void 0 || (_data$viewSettings5 = data.viewSettings) === null || _data$viewSettings5 === void 0 ? void 0 : _data$viewSettings5.settings;
    const currentSettings = settings && Object.assign({}, settings);
    const updatedSettings = Object.assign({}, currentSettings, {
      preferredLayout: layoutPreference
    });
    updateSettings(updatedSettings);
  }, [data, updateSettings]);
  return {
    loading,
    order,
    updateOrder: memoizedUpdateOrder,
    viewQuickFilters,
    updateViewFilters: memoizedUpdateViewFilters,
    spacesOrder,
    updateSpaceOrder: memoizedUpdateSpaceOrder,
    preferredLayout: preferredLayout !== null && preferredLayout !== void 0 ? preferredLayout : null,
    updatePreferredLayout: memoizedUpdatePreferredLayout,
    updatedAt: (data === null || data === void 0 || (_data$viewSettings6 = data.viewSettings) === null || _data$viewSettings6 === void 0 ? void 0 : _data$viewSettings6.updatedAt) || null
  };
}
import { fromJS } from 'immutable';
const DOCUMENTS_URI = 'salescontentsearch/v2/search';
// eslint-disable-next-line no-restricted-syntax
let savedCache;
// eslint-disable-next-line no-restricted-syntax
let cachedParams;

/**
 * Meant to be used in Unit / Integration tests only
 */
export const bustDocumentsCache = () => {
  savedCache = null;
  cachedParams = null;
};
const getPostBody = ({
  user_id,
  limit = 1000,
  offset = 0,
  query = ''
}) => {
  const filters = [{
    field: 'content_type',
    values: ['DOCUMENT']
  }];
  if (user_id) {
    filters.push({
      field: 'user_id',
      values: [`${user_id}`]
    });
  }
  return {
    query,
    limit,
    offset,
    filters,
    contentTypesToHydrate: ['DOCUMENT', 'DOCUMENTS_FOLDER']
  };
};

/**
 *
 * @param {Object} options
 * @param {Object} options.httpClient - an http client containing a `post` method
 * @param {Object} options.cache - HS auth object with that contains a user object with a user_id
 * @returns {Promise}
 */
export function fetchDocuments({
  httpClient,
  auth,
  cache = true,
  queryParams = {}
}) {
  let promise;
  const shouldBustCache = cache && savedCache && cachedParams && fromJS(cachedParams).equals(fromJS(queryParams));
  if (shouldBustCache) {
    promise = Promise.resolve(savedCache);
    promise.catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
    return promise;
  }
  const user_id = auth ? auth.user.user_id : null;
  promise = httpClient.post(DOCUMENTS_URI, {
    data: getPostBody(Object.assign({}, queryParams, {
      user_id
    }))
  }).then(res => {
    savedCache = res;
    cachedParams = queryParams;
    return savedCache;
  }).catch(error => {
    return Promise.reject(error);
  });
  promise.catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return promise;
}
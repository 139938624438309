import ImageView, { imageViewUpdateFn } from './ImageView';
import MeetingTimesView, { meetingTimesViewUpdateFn } from './MeetingTimesView';
import Unsubscribe, { unsubscribeViewUpdateFn } from './Unsubscribe';
import { PersonalizationTokenView, personalizationTokenViewUpdateFn } from './PersonalizationTokenView';
import MeetingsView, { meetingsViewUpdateFn } from './MeetingsView';
import DocumentLinkView, { documentLinkViewUpdateFn } from './DocumentLinkView';
import DocumentPreviewView, { documentPreviewViewUpdateFn } from './DocumentPreviewView';
export const viewConfig = {
  image: {
    component: ImageView,
    updateFn: imageViewUpdateFn
  },
  meetings: {
    component: MeetingsView,
    updateFn: meetingsViewUpdateFn
  },
  documentLink: {
    component: DocumentLinkView,
    updateFn: documentLinkViewUpdateFn
  },
  documentPreview: {
    component: DocumentPreviewView,
    updateFn: documentPreviewViewUpdateFn
  },
  meetingTimesBlock: {
    component: MeetingTimesView,
    updateFn: meetingTimesViewUpdateFn
  },
  unsubscribe: {
    component: Unsubscribe,
    updateFn: unsubscribeViewUpdateFn
  },
  personalization_token: {
    component: PersonalizationTokenView,
    updateFn: personalizationTokenViewUpdateFn
  }
};
import { useQuery } from 'data-fetching-client';
import { fetchChatflowByChannelInstanceIdQuery } from '../_internal/queries/chatflows.queries';
export const useChatflow = ({
  channelInstanceId,
  skip
}) => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchChatflowByChannelInstanceIdQuery, {
    skip,
    variables: {
      channelInstanceId
    }
  });
  return {
    chatflow: data && data.chatflow,
    error,
    loading
  };
};
export const getClickedModalCta = ({
  ctaAction,
  subscreen2 = null
}) => ({
  key: 'clicked-modal-cta-from-thread-view',
  properties: {
    action: 'clicked modal cta',
    'cta-action': ctaAction,
    subscreen2
  }
});
import merge from 'hs-lodash/merge';
import PortalIdParser from 'PortalIdParser';
import I18n from 'I18n';
export const makeInvoice = invoice => {
  const defaultInvoice = {
    associatedObjects: {
      INVOICE_TO_DEAL: [],
      INVOICE_TO_LINE_ITEM: [],
      INVOICE_TO_CONTACT: [],
      INVOICE_TO_COMPANY: [],
      INVOICE_TO_QUOTE: [],
      INVOICE_TO_FEE: [],
      INVOICE_TO_DISCOUNT: [],
      INVOICE_TO_TAX: [],
      INVOICE_TO_TICKET: []
    },
    objectId: null,
    objectTypeId: '0-53',
    portalId: PortalIdParser.get(),
    properties: {}
  };
  return merge(defaultInvoice, invoice);
};
export const makeContact = contact => {
  return merge({
    objectId: null,
    objectTypeId: '0-1',
    portalId: PortalIdParser.get(),
    properties: {}
  }, contact);
};
export const makeLineItem = lineItem => {
  return merge({
    objectId: null,
    objectTypeId: '0-8',
    portalId: PortalIdParser.get(),
    properties: {}
  }, lineItem);
};
export const makeProperty = (name, value) => ({
  name,
  value
});
export const mapProperties = properties => Object.entries(properties).map(([name, property]) => ({
  name,
  value: property.value
}));
export const unmapProperties = properties => Object.fromEntries(properties.map(property => [property.name, property]));
export const mapAssociatedObject = associatedObject => Object.assign({}, associatedObject, {
  properties: mapProperties(associatedObject.properties)
});
export const unmapAssociatedObject = associatedObject => Object.assign({}, associatedObject, {
  properties: unmapProperties(associatedObject.properties)
});
export const createInvoicePayload = invoice => Object.assign({}, invoice, {
  associatedObjects: Object.fromEntries(Object.entries(invoice.associatedObjects).map(([associationKey, associatedObjects]) => [associationKey, associatedObjects.map(mapAssociatedObject)])),
  properties: mapProperties(invoice.properties)
});
export const createInvoiceFromInvoiceResponse = invoiceResponse => makeInvoice(Object.assign({}, invoiceResponse, {
  properties: unmapProperties(invoiceResponse.properties),
  associatedObjects: Object.fromEntries(Object.entries(invoiceResponse.associatedObjects).map(([associationKey, associatedObjects]) => [associationKey, associatedObjects.map(unmapAssociatedObject)]))
}));
export const calculateSampleLineItemPrice = (invoiceCurrency, currencyLimits) => {
  const currencyLimit = currencyLimits.find(limit => limit.currencyCode === invoiceCurrency);
  if (!currencyLimit || !currencyLimit.minimumAmountForPaymentMethod.length) {
    return 1;
  }
  const highestMinimumAmount = Math.max(...currencyLimit.minimumAmountForPaymentMethod.map(method => method.amount));
  return Math.max(highestMinimumAmount, 1);
};
export const getCurrentDateInPortalTz = () => I18n.moment.portalTz().endOf('day').valueOf();
import { Loadable } from 'foundations-components/transitional/utils/Loadable';
import AsyncComponentError from './AsyncComponentError';
import EmptyComponent from './EmptyComponent';
const AsyncBlockThreadPrompt = Loadable({
  loader: () => import(
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
  /* webpackChunkName: "block-thread-prompt" */
  'conversations-thread-view/chat-feed-actions-header/components/ConnectedBlockThreadPrompt'),
  LoadingComponent: EmptyComponent,
  ErrorComponent: AsyncComponentError
});
export default AsyncBlockThreadPrompt;
let _ = t => t,
  _t;
import styledWithTheme from 'foundations-theming/utils';
import FormattedMessage from 'I18n/components/FormattedMessage';
const DisplayResultsText = styledWithTheme(FormattedMessage)(_t || (_t = _`
  padding-block: ${0};
  display: flex;
  justify-content: center;
  text-align: center;
  font-style: italic;
  color: ${0};
  border-bottom: solid 1px ${0};
`), ({
  theme
}) => theme.space['200'], ({
  theme
}) => theme.color['icon-core-subtle'], ({
  theme
}) => theme.color['border-core-subtle']);
export default DisplayResultsText;
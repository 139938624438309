import { HubsSettingClient } from 'frontend-preferences-client';

// Add new user settings as follows here:
// https://product.hubteam.com/docs/appsystems/frontend-preferences/frontend-preferences.html#i-want-to-store-data-in-a-new-key
export const HUB_SETTINGS = {
  INELIGIBLE_FOR_SPECIALISTS_CONTACT_INVOICES: 'CommerceGrowth:IneligibleForSpecialistsContact:Invoices',
  INELIGIBLE_FOR_SPECIALISTS_CONTACT_QUOTES: 'CommerceGrowth:IneligibleForSpecialistsContact:Quotes',
  INELIGIBLE_FOR_SPECIALISTS_CONTACT_SUBSCRIPTIONS: 'CommerceGrowth:IneligibleForSpecialistsContact:Subscriptions',
  INELIGIBLE_FOR_SPECIALISTS_CONTACT_PAYMENT_LINKS: 'CommerceGrowth:IneligibleForSpecialistsContact:PaymentLinks'
};
export const hubsSettingsClient = HubsSettingClient.forCaller('commerce-tools-ui-lib');
export const saveHubSetting = (key, value) => {
  return hubsSettingsClient.write(key, value).catch(() => {});
};
export const fetchHubSetting = (key, defaultValue) => {
  return hubsSettingsClient.fetch(key, defaultValue).catch(() => {});
};
import { CRM_UI, QUOTES } from 'customer-data-objects/property/PropertySourceTypes';
import QuoteRecord from 'customer-data-objects/quote/QuoteRecord';
import apiClient from 'hub-http/clients/apiClient';
import unescapedText from 'I18n/utils/unescapedText';
import { fromJS } from 'immutable';
import downloadPdfClient from 'quotes-ui-lib/api/clients/downloadPdfClient';
import quotePublishedStatusClient from 'quotes-ui-lib/api/clients/quotePublishedStatusClient';
// @ts-expect-error untyped module
import { catchAndRethrowNetworkError } from 'quotes-ui-lib/api/monitoring/errorUtils';
import { getQuoteId, getQuoteProperty, isImmutableQuote, makeQuoteFromQuoteAssociatedObjects, mergeQuoteProperties } from 'quotes-ui-lib/utils/quote';
import { CPQ_QUOTE_TEMPLATE_PATH } from '../constants/cpqQuote';
import { getQuotePayload } from './utils/quotesApi';
export { getQuotePayload };
const HEADERS = {
  'X-Properties-Source': QUOTES,
  'X-Properties-SourceId': CRM_UI
};
export function updatePublishedQuote(quoteId, propertyName, propertyValue) {
  return apiClient.patch(`inbounddbquotes/v1/published-quotes/${quoteId}/property-update`, {
    data: {
      propertyName,
      propertyValue
    }
  });
}
function maybePollForPublished(quoteResponse, isUngatedForSeamlessSending) {
  const isPublishedStatus = getQuoteProperty(quoteResponse, 'hs_status') === 'APPROVED' || getQuoteProperty(quoteResponse, 'hs_status') === 'APPROVAL_NOT_NEEDED';
  if (isUngatedForSeamlessSending) {
    return quoteResponse;
  }
  if (isPublishedStatus) {
    return quotePublishedStatusClient.get(`inbounddbquotes/v1/quotes/${quoteResponse.quote.objectId}/with-associated-objects?fetch-associated-objects=true`).then(QuoteRecord.fromJS).catch(catchAndRethrowNetworkError);
  }
  return quoteResponse;
}
export function createQuote(quote) {
  const requestBody = getQuotePayload(quote);
  return apiClient.post('inbounddbquotes/v1/quotes/with-associated-objects', {
    headers: HEADERS,
    data: isImmutableQuote(requestBody) ? requestBody.toJS() : requestBody
  }).then(QuoteRecord.fromJS).catch(catchAndRethrowNetworkError);
}
export function deleteQuote(quote) {
  const id = getQuoteId(quote);
  return apiClient.delete(`inbounddbquotes/v1/quotes/${id}`, {
    headers: HEADERS
  }).catch(catchAndRethrowNetworkError);
}
export function updateAndMaybePollForPublished(quote, isUngatedForSeamlessSending) {
  const id = getQuoteId(quote);
  const requestBody = getQuotePayload(quote);
  return apiClient.put(`inbounddbquotes/v1/quotes/${id}/with-associated-objects`, {
    headers: HEADERS,
    data: isImmutableQuote(requestBody) ? requestBody.toJS() : requestBody
  }).then(QuoteRecord.fromJS).then(res => {
    return maybePollForPublished(res, isUngatedForSeamlessSending);
  }).catch(catchAndRethrowNetworkError);
}
export function clone(quote) {
  const quoteId = getQuoteId(quote);
  const title = getQuoteProperty(quote, 'hs_title');
  const expirationDate = getQuoteProperty(quote, 'hs_expiration_date');
  const requestBody = {
    quoteId,
    quoteTitle: unescapedText('quotesUiLib.api.copy', {
      quoteTitle: title
    }),
    expirationDate
  };
  return apiClient.post('inbounddbquotes/v1/quotes/with-associated-objects/clone', {
    headers: HEADERS,
    data: requestBody
  }).then(QuoteRecord.fromJS).catch(catchAndRethrowNetworkError);
}
export function recallPublished(quote) {
  const id = getQuoteId(quote);
  return apiClient.post(`inbounddbquotes/v1/quotes/${id}/recall`, {
    headers: HEADERS
  }).then(QuoteRecord.fromJS).catch(catchAndRethrowNetworkError);
}
export function approveAndPollForPublished(quote, isUngatedForSeamlessSending) {
  const id = getQuoteId(quote);
  const requestBody = getQuotePayload(mergeQuoteProperties(quote, {
    hs_status: 'APPROVED'
  }));
  return apiClient.put(`inbounddbquotes/v1/quotes/${id}/with-associated-objects`, {
    headers: HEADERS,
    data: requestBody
  }).then(makeQuoteFromQuoteAssociatedObjects).then(res => {
    return maybePollForPublished(res, isUngatedForSeamlessSending);
  }).catch(catchAndRethrowNetworkError);
}
export function requestChanges(quote, feedback) {
  const id = getQuoteId(quote);
  const requestBody = getQuotePayload(mergeQuoteProperties(quote, {
    hs_status: 'REJECTED',
    hs_feedback: feedback
  }));
  return apiClient.put(`inbounddbquotes/v1/quotes/${id}/with-associated-objects`, {
    headers: HEADERS,
    data: requestBody
  }).then(makeQuoteFromQuoteAssociatedObjects).catch(catchAndRethrowNetworkError);
}
export function fetchQuoteEsignBatch(publicUrlKeys) {
  return apiClient.get(`inbounddbquotes/v1/esign/batch`, {
    query: {
      publicUrlKey: publicUrlKeys
    },
    headers: HEADERS
  }).catch(catchAndRethrowNetworkError);
}
export function getQuoteDownloadUrl(quoteId) {
  return downloadPdfClient.get(`inbounddbquotes/v1/downloads/${quoteId}/pdf`).then(fromJS).catch(catchAndRethrowNetworkError);
}
export function viewQuotePdf(quoteId) {
  return apiClient.get(`inbounddbquotes/v1/downloads/${quoteId}/pdf/view`).then(fromJS).catch(catchAndRethrowNetworkError);
}
export function fetchCountersignerDetails(publicUrlKey) {
  return apiClient.get(`inbounddbquotes/v1/esign/signatureDetails/${publicUrlKey}/counterSigner`);
}
export async function getQuote(quoteId) {
  const response = await apiClient.get(`inbounddbquotes/v1/quotes/${quoteId}/with-associated-objects?fetch-associated-objects=true`);
  return makeQuoteFromQuoteAssociatedObjects(response);
}
export function getQuotePdfLink(quoteId) {
  return apiClient.get(`inbounddbquotes/v1/downloads/${quoteId}/pdf`).then(response => response.url);
}
export function createQuoteFromDeal(dealId, generateCoverLetter, additionalPrompt) {
  return apiClient.post(`inbounddbquotes/v1/quotes/cpq/from-deal/${dealId}?clienttimeout=60000`, {
    data: {
      quoteContentRequest: {
        templatePath: CPQ_QUOTE_TEMPLATE_PATH
      },
      coverLetterConfig: {
        generate: generateCoverLetter,
        promptData: {
          additionalPrompt
        }
      }
    }
  }).then(response => response);
}
import { SUMMARIZE_ACTIVITY_FEED } from '../../constants/CopilotInAppActionTypes';
import { parseObjectId } from '../../utils/parserUtils';
export const summarizeActivityFeed = action => {
  const {
    activityFromDate,
    activityToDate,
    objectId,
    objectSearch,
    objectType,
    objectTypeLabel,
    objectName
  } = action;
  return {
    data: {
      intent: SUMMARIZE_ACTIVITY_FEED,
      activityFromDate,
      activityToDate,
      resolvedObject: {
        object_id: objectId ? parseObjectId(objectId) : undefined,
        object_search: objectSearch,
        object_type: objectType
      },
      object_name: objectName,
      object_type_label: objectTypeLabel
    }
  };
};
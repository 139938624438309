import { useMemo } from 'react';
import { useAuth } from './useAuth';
import { useSubscription } from './useSubscription';
import { CollectionProcess, InvoiceCreation, SubscriptionActions, SubscriptionStatus } from '../types/subscriptions';
import { Scopes } from '../utils/constants';
import { getIsNextPaymentDateToday } from '../utils/dateTime';
import { getIsFirstPaymentProcessing, isComplete, isSubcriptionEditableAndActive, isSubscriptionDeletable, isSubscriptionEditable, isSubscriptionPaymentProcessing, makeActionsRecord } from '../utils/subscriptionUtils';
import { assertNever } from '../utils/common';
import { useHasScope } from './useHasScope';
import { useHasDeleteScopeGate } from './useHasGate';
import unescapedText from 'I18n/utils/unescapedText';
const DEFAULT_VALIDATION = makeActionsRecord(() => null);
export function useActionValidation() {
  const {
    userPermissions
  } = useAuth();
  const isJitaUser = useHasScope(Scopes.user.jitaUser);
  const hasWriteAccess = useHasScope(Scopes.subscription.write);
  const hasDeleteScope = useHasScope(Scopes.subscription.delete);
  const subscription = useSubscription();
  const hasDeleteScopeGate = useHasDeleteScopeGate();
  return useMemo(() => {
    if (!subscription) {
      return makeActionsRecord(() => unescapedText('subscriptions.Actions.loading'));
    }
    const isAutomaticPayments = (subscription === null || subscription === void 0 ? void 0 : subscription.collectionProcess) === CollectionProcess.AutomaticPayments;
    const isUnbilled = subscription.invoiceCreation === InvoiceCreation.Off;
    const isPaused = subscription.status === SubscriptionStatus.Paused;
    return makeActionsRecord(actionName => {
      if (isJitaUser) {
        switch (actionName) {
          case SubscriptionActions.Delete:
            return unescapedText('subscriptions.Actions.delete.errors.jitaUser');
          case SubscriptionActions.Edit:
            return unescapedText('subscriptions.Actions.edit.errors.jitaUser');
          case SubscriptionActions.EditNextPaymentDate:
            return unescapedText('subscriptions.Actions.edit-next-payment-date.errors.jitaUser');
          case SubscriptionActions.EditNextBillingDate:
            return unescapedText('subscriptions.Actions.edit-next-billing-date.errors.jitaUser');
          case SubscriptionActions.UpdatePaymentMethod:
            return unescapedText('subscriptions.Actions.update-payment-method.errors.jitaUser');
          case SubscriptionActions.Pause:
            return unescapedText('subscriptions.Actions.pause.errors.jitaUser');
          case SubscriptionActions.Cancel:
            return unescapedText('subscriptions.Actions.cancel.errors.jitaUser');
          default:
            assertNever(actionName, 'Invalid action name when validating JITA user scope');
            return null;
        }
      }
      if (!userPermissions.currentUserCanEdit || !hasWriteAccess) {
        switch (actionName) {
          case SubscriptionActions.Delete:
            return unescapedText('subscriptions.Actions.delete.errors.unauthorized');
          case SubscriptionActions.Edit:
            return unescapedText('subscriptions.Actions.edit.errors.unauthorized');
          case SubscriptionActions.EditNextPaymentDate:
            return unescapedText('subscriptions.Actions.edit-next-payment-date.errors.unauthorized');
          case SubscriptionActions.EditNextBillingDate:
            return unescapedText('subscriptions.Actions.edit-next-billing-date.errors.unauthorized');
          case SubscriptionActions.UpdatePaymentMethod:
            return unescapedText('subscriptions.Actions.update-payment-method.errors.unauthorized');
          case SubscriptionActions.Pause:
            return unescapedText('subscriptions.Actions.pause.errors.unauthorized');
          case SubscriptionActions.Cancel:
            return unescapedText('subscriptions.Actions.cancel.errors.unauthorized');
          default:
            assertNever(actionName, 'Invalid action name when validating user permissions');
            return null;
        }
      }
      if (!isSubscriptionEditable(subscription.status) && actionName !== SubscriptionActions.Delete) {
        switch (actionName) {
          case SubscriptionActions.Edit:
            return unescapedText('subscriptions.Actions.edit.errors.inactiveSubscription');
          case SubscriptionActions.EditNextPaymentDate:
          case SubscriptionActions.EditNextBillingDate:
            return unescapedText('subscriptions.Actions.edit-next-billing-date.errors.inactiveSubscription');
          case SubscriptionActions.UpdatePaymentMethod:
            return unescapedText('subscriptions.Actions.update-payment-method.errors.inactiveSubscription');
          case SubscriptionActions.Pause:
            return unescapedText('subscriptions.Actions.pause.errors.inactiveSubscription');
          case SubscriptionActions.Cancel:
            return unescapedText('subscriptions.Actions.cancel.errors.inactiveSubscription');
          default:
            assertNever(actionName, 'Invalid action name when validating editability of subscription');
            return null;
        }
      }
      if (isComplete(subscription) && actionName !== SubscriptionActions.Delete && actionName !== SubscriptionActions.Cancel) {
        switch (actionName) {
          case SubscriptionActions.Edit:
            return unescapedText('subscriptions.Actions.edit.errors.completeSubscription');
          case SubscriptionActions.EditNextPaymentDate:
            return unescapedText('subscriptions.Actions.edit-next-payment-date.errors.completeSubscription');
          case SubscriptionActions.EditNextBillingDate:
            return unescapedText('subscriptions.Actions.edit-next-billing-date.errors.completeSubscription');
          case SubscriptionActions.UpdatePaymentMethod:
            return unescapedText('subscriptions.Actions.update-payment-method.errors.completeSubscription');
          case SubscriptionActions.Pause:
            return unescapedText('subscriptions.Actions.pause.errors.completeSubscription');
          default:
            assertNever(actionName, 'Invalid action name when validating completed subscription');
            return null;
        }
      }
      if (isUnbilled && actionName !== SubscriptionActions.Delete) {
        switch (actionName) {
          case SubscriptionActions.Edit:
            return unescapedText('subscriptions.Actions.edit.errors.invoiceCreationOff');
          case SubscriptionActions.EditNextPaymentDate:
            return unescapedText('subscriptions.Actions.edit-next-payment-date.errors.invoiceCreationOff');
          case SubscriptionActions.EditNextBillingDate:
            return unescapedText('subscriptions.Actions.edit-next-billing-date.errors.invoiceCreationOff');
          case SubscriptionActions.UpdatePaymentMethod:
            return unescapedText('subscriptions.Actions.update-payment-method.errors.invoiceCreationOff');
          case SubscriptionActions.Pause:
            return unescapedText('subscriptions.Actions.pause.errors.invoiceCreationOff');
          case SubscriptionActions.Cancel:
            return unescapedText('subscriptions.Actions.cancel.errors.invoiceCreationOff');
          default:
            assertNever(actionName, 'Invalid action name when validating unbilled subscription');
            return null;
        }
      }
      switch (actionName) {
        case SubscriptionActions.UpdatePaymentMethod:
          {
            if (!subscription.buyer) {
              return unescapedText('subscriptions.Actions.update-payment-method.errors.missingContactAssociation');
            }
            if (!isAutomaticPayments) {
              return unescapedText('subscriptions.Actions.update-payment-method.errors.manualPayment');
            }
            break;
          }
        case SubscriptionActions.Edit:
          {
            if (isPaused) {
              return unescapedText('subscriptions.Actions.edit.errors.paused');
            }
            if (!isSubcriptionEditableAndActive(subscription.status)) {
              return unescapedText('subscriptions.Actions.edit.errors.inactiveSubscription');
            }

            // we allow editing of the subscription even if payment is processing
            if (isSubscriptionPaymentProcessing(subscription.paymentStatus)) {
              return null;
            }
            break;
          }
        case SubscriptionActions.EditNextPaymentDate:
        case SubscriptionActions.EditNextBillingDate:
          {
            if (isPaused) {
              return unescapedText('subscriptions.Actions.edit-next-billing-date.errors.paused');
            }
            if (!isSubcriptionEditableAndActive(subscription.status)) {
              return actionName === SubscriptionActions.EditNextPaymentDate ? unescapedText('subscriptions.Actions.edit-next-payment-date.errors.inactiveSubscription') : unescapedText('subscriptions.Actions.edit-next-billing-date.errors.inactiveSubscription');
            }
            if (getIsNextPaymentDateToday(subscription.nextPaymentDueDate)) {
              return actionName === SubscriptionActions.EditNextPaymentDate ? unescapedText('subscriptions.Actions.edit-next-payment-date.errors.paymentProcessing') : unescapedText('subscriptions.Actions.edit-next-billing-date.errors.paymentProcessing');
            }
            break;
          }
        case SubscriptionActions.Cancel:
          {
            if (getIsFirstPaymentProcessing(subscription.numberOfCompletedPayments, subscription.paymentStatus)) {
              return unescapedText('subscriptions.Actions.cancel.errors.paymentProcessing');
            }
            break;
          }
        case SubscriptionActions.Pause:
          {
            if (!isSubcriptionEditableAndActive(subscription.status) && subscription.status !== SubscriptionStatus.Unpaid) {
              return unescapedText('subscriptions.Actions.pause.errors.inactiveSubscription');
            }

            // We allow pause for when a subscription payment is processing
            if (isSubscriptionPaymentProcessing(subscription.paymentStatus)) {
              return null;
            }
            break;
          }
        case SubscriptionActions.Delete:
          {
            if (hasDeleteScopeGate && !hasDeleteScope) {
              return unescapedText('subscriptions.Actions.delete.errors.noDeleteScope');
            }
            if (isSubscriptionPaymentProcessing(subscription.paymentStatus)) {
              return unescapedText('subscriptions.Actions.delete.errors.paymentProcessing');
            }
            if (!(isSubscriptionDeletable(subscription.status) || isUnbilled)) {
              return unescapedText('subscriptions.Actions.delete.errors.undeletableSubscription');
            }
            return null;
          }
        default:
          assertNever(actionName, 'Invalid action name when validating subscription actions');
          return null;
      }
      if (!getIsFirstPaymentProcessing(subscription.numberOfCompletedPayments, subscription.paymentStatus) && isSubscriptionPaymentProcessing(subscription.paymentStatus) && actionName !== SubscriptionActions.Cancel) {
        switch (actionName) {
          case SubscriptionActions.Pause:
            return unescapedText('subscriptions.Actions.pause.errors.paymentProcessing');
          case SubscriptionActions.Edit:
            return unescapedText('subscriptions.Actions.edit.errors.paymentProcessing');
          case SubscriptionActions.EditNextPaymentDate:
            return unescapedText('subscriptions.Actions.edit-next-payment-date.errors.paymentProcessing');
          case SubscriptionActions.EditNextBillingDate:
            return unescapedText('subscriptions.Actions.edit-next-billing-date.errors.paymentProcessing');
          case SubscriptionActions.UpdatePaymentMethod:
            return unescapedText('subscriptions.Actions.update-payment-method.errors.paymentProcessing');
          default:
            assertNever(actionName, 'Invalid action name when validating subscription actions');
            return null;
        }
      }
      return null;
    }, Object.assign({}, DEFAULT_VALIDATION));
  }, [subscription, isJitaUser, userPermissions.currentUserCanEdit, hasWriteAccess, hasDeleteScopeGate, hasDeleteScope]);
}
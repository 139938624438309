import { isExtensionsInboundCallingEnabled } from 'calling-internal-common/utils/isExtensionsInboundCallingEnabled';
import { isProviderSwitchingEnabled } from 'calling-internal-common/utils/isProviderSwitchingEnabled';
export const getAppIdentifierEligibleForProviderSwitching = appIdentifier => {
  return Boolean(appIdentifier && appIdentifier === 'profile');
};
export const getIsUngatedForBidirectionalProviderSwitching = (gates, appIdentifier, provider) => {
  const isUngatedForProviderSwitching = isProviderSwitchingEnabled(gates);
  if (isUngatedForProviderSwitching) {
    return false;
  }
  return Boolean(getAppIdentifierEligibleForProviderSwitching(appIdentifier) && isExtensionsInboundCallingEnabled(gates, provider));
};
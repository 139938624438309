import { useQuery, registerQuery } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
import { catchAndRethrowNetworkError
// @ts-expect-error dependency missing types
} from 'crm_data/bCommerce/monitoring/errorUtils';
const fetchShippingSettings = () => apiClient.get('payments-settings/v1/shipping-settings').catch(catchAndRethrowNetworkError);
const GET_SHIPPING_SETTINGS = registerQuery({
  fieldName: 'shippingSettings',
  fetcher: fetchShippingSettings
});
const useShippingSettings = (options = {}) => useQuery(GET_SHIPPING_SETTINGS, options);
export default useShippingSettings;
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getConversationId, isInboxViewMember, isThreadListMember } from '../../view-members-schema/protected';
import { getViewMemberId, isMemberSeen } from '../../view-members-schema/public';
import { threadSeen, viewMemberSeen } from './common/sharedViewMemberActions';
import { markLastMessageReadByAgent } from './markLastMessageReadByAgent';
import { markTicketAsReadByAgent } from './markTicketAsReadByAgent';
/**
 * @deprecated Use `useMarkTicketSeen` or `useMarkThreadSeen` instead.
 */
export const useMarkViewMemberSeen = ({
  agentId,
  filterKey,
  inboxId = null,
  portalId,
  viewMember
}) => {
  const dispatch = useDispatch();
  const isMarkingAsSeen = useRef(false);
  useEffect(() => {
    if (!inboxId || !viewMember || isMemberSeen(viewMember, agentId) || isMarkingAsSeen.current) {
      return;
    }

    //If the view member is an InboxViewMember or if it's a ThreadListMemberRecord
    //that has been converted from an InboxViewMember, use the inbox-specific endpoint.
    const isConversation = isInboxViewMember(viewMember) || isThreadListMember(viewMember) && +getViewMemberId(viewMember) !== getConversationId(viewMember);
    if (isConversation) {
      const threadId = +getViewMemberId(viewMember);
      //TODO the below line needs to be handled for generic objects
      const cvCrmObjectId = getConversationId(viewMember);
      isMarkingAsSeen.current = true;
      markLastMessageReadByAgent({
        cvCrmObjectId,
        inboxId,
        portalId,
        threadId,
        timestamp: Date.now()
      }).then(({
        customViewIds,
        seen,
        threadLists
      }) => {
        isMarkingAsSeen.current = false;
        dispatch(threadSeen({
          customViewIds,
          filterKey,
          seen,
          threadId: `${threadId}`,
          threadLists
        }));
      }).catch(() => {
        isMarkingAsSeen.current = false;
      });
    } else {
      //If the view member is a HelpDeskViewMember, use the Help Desk endpoint.
      markTicketAsReadByAgent(+getViewMemberId(viewMember), inboxId).then(({
        customViewIds
      }) => {
        isMarkingAsSeen.current = false;
        dispatch(viewMemberSeen({
          agentId,
          customViewIds,
          objectId: getViewMemberId(viewMember)
        }));
      }).catch(() => {
        isMarkingAsSeen.current = false;
      });
    }
  }, [agentId, dispatch, filterKey, inboxId, portalId, viewMember]);
};
import { PermissionsContext } from '../context/PermissionsContext';
import { useContext, useMemo } from 'react';

/**
 * A hook to determine if a user is or is not ungated for a gate
 *
 * @example
 *
 * const DealMergeButton = (props) => {
 *
 *   const isUngatedForDealMerge = useIsUngated('CRM:ExampleGate');
 *
 *   ...
 *
 *   if (!isUngatedForDealMerge) {
 *     return null
 *   }
 *
 *   return (
 *    ...
 *   );
 * }
 *
 * @param {String}  gateName          the name of the gate to check for
 */

export function useIsUngated(gateName) {
  const {
    gates
  } = useContext(PermissionsContext);
  return useMemo(() => gates.includes(gateName), [gates, gateName]);
}
export const CALLING_INBOX_ROUTING = 'calling-inbox-routing';
export const CALLING_HELP_DESK_ROUTING = 'calling-help-desk-routing';
export const CALLING_DEFAULT_OUTCOMES_WRITE = 'calling-default-outcomes-write';
export const CALLING_US_PHONE_NUMBER_ACQUISITION = 'calling-us-phone-number-acquisition';
export const CALLING_INTERNATIONAL_PHONE_NUMBER_ACQUISITION = 'calling-international-phone-number-acquisition';
export const CALLING_PORTING = 'calling-porting';
export const CALLING_INBOUND_ACCESS = 'calling-inbound-access';
export const CALLING_OUTBOUND_ACCESS = 'calling-outbound-access';
export const CALLING_OUTBOUND_DIALER_ACCESS = 'calling-outbound-dialer-access';
export const CALLING_TWILIO_CONNECT_ACCESS = 'calling-twilio-connect-access';
export const CALLING_IVR_READ = 'calling-ivr-read';
export const CALLING_IVR_WRITE = 'calling-ivr-write';
export const CALLING_ACCESS = 'calling-access';
export const CALLING_PAID_SEAT_ACCESS = 'calling-paid-seat-access';
export const CALLING_BYON_REGISTRATION = 'calling-byon-registration';
export const CALLING_INBOX_CHANNEL_WRITE = 'calling-inbox-channel-write';
export const CALLING_HELP_DESK_CHANNEL_WRITE = 'calling-help-desk-channel-write';
export const CALLING_ANALYTICS_READ = 'calling-analytics-read';
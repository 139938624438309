import { useMemo } from 'react';
import { useInlineImages } from './useInlineImages';
import { useRichTextConfig } from './useRichTextConfig';
import { useFileAttachmentConfig } from './useFileAttachmentConfig';
import { useAiAssistantConfig } from './useAiAssistantConfig';
import { useDraftSaveConfig } from './useDraftSaveConfig';
/**
 * @description This hook will replace useEditorCapabilities (which derives capabilities from FE blob)
 * After all config properties have been migrated over, useEditorCapabilities can probably be safely deleted.
 */
export const useProsemirrorConfigFromCapabilities = ({
  renderExistingFileSelect,
  fileAttachmentProps: {
    attachments,
    threadId,
    handleAttachmentChange,
    fileUploadsInProgCount,
    uploadFile,
    uploadDroppedFiles
  },
  aiAssistantProps,
  draftSaveFailed
}) => {
  const inlineImagesConfig = useInlineImages(renderExistingFileSelect);
  const {
    richTextConfig,
    loading: richTextLoading
  } = useRichTextConfig();
  const fileAttachmentConfig = useFileAttachmentConfig({
    renderExistingFileSelect,
    attachments,
    threadId,
    handleAttachmentChange,
    fileUploadsInProgCount,
    uploadFile,
    uploadDroppedFiles
  });
  const aiAssistantConfig = useAiAssistantConfig(aiAssistantProps);
  const draftSaveConfig = useDraftSaveConfig({
    draftSaveFailed
  });
  const loading = richTextLoading;
  const prosemirrorConfig = useMemo(() => Object.assign({}, richTextConfig, fileAttachmentConfig, inlineImagesConfig, aiAssistantConfig, draftSaveConfig), [richTextConfig, fileAttachmentConfig, inlineImagesConfig, aiAssistantConfig, draftSaveConfig]);
  return {
    prosemirrorConfig,
    loading
  };
};
import { FloatingAlertStore } from 'foundations-components/transitional/components/FloatingAlertList';
export function addAlert({
  title,
  message,
  type
}) {
  return FloatingAlertStore.addAlert({
    titleText: title,
    message,
    type,
    'data-alert-type': type
  });
}
import http from 'hub-http/clients/apiClient';
import { createRpcClientV2 } from 'rpc-client-utils';
import { getAccessControlsRpc, patchAccessControlsRpc } from '../../__generated__/chirp/com/hubspot/ai/access/controls/rpc/AiAccessControlsChirpService';
const rpcClient = createRpcClientV2({
  hubHttpClient: http
});
export const getAccess = () => {
  return rpcClient.call(getAccessControlsRpc, {});
};
export const updateAccess = ({
  access
}) => {
  return rpcClient.call(patchAccessControlsRpc, {
    request: access
  });
};
import { useMemo } from 'react';
import { getInvoiceProperty } from 'invoices-ui-lib/utils/property';
import { useQuery } from '@apollo/client';
import { CUSTOM_PROPERTIES_QUERY } from '../graphQL/InvoicesEditorQueries';
const useGetInvoiceCustomPropertyValues = ({
  invoice,
  isClone,
  originalInvoiceId,
  skip = false
}) => {
  const customPropertyNames = useMemo(() => invoice && getInvoiceProperty(invoice, 'hs_custom_fields') ? getInvoiceProperty(invoice, 'hs_custom_fields').split(';') : undefined, [invoice]);
  const shouldSkipFetch = useMemo(() => skip || !(invoice !== null && invoice !== void 0 && invoice.objectId) && !isClone || !customPropertyNames, [invoice, customPropertyNames, isClone, skip]);
  const {
    data: customProperties,
    error
  } = useQuery(CUSTOM_PROPERTIES_QUERY, {
    variables: {
      objectId: isClone ? originalInvoiceId : invoice === null || invoice === void 0 ? void 0 : invoice.objectId,
      propertyNames: customPropertyNames
    },
    skip: shouldSkipFetch
  });
  const derivedCustomProperties = useMemo(() => {
    if (!invoice) return undefined;
    if (shouldSkipFetch) return {};
    return !customProperties ? undefined : customProperties.crmObject.properties.reduce((propertiesToAdd, property) => {
      propertiesToAdd[property.name] = {
        name: property.name,
        value: property.value
      };
      return propertiesToAdd;
    }, {});
  }, [customProperties, shouldSkipFetch, invoice]);
  return {
    derivedCustomProperties,
    error: !!error
  };
};
export default useGetInvoiceCustomPropertyValues;
import { getMetadataIsForward } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
import curry from 'transmute/curry';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import pipe from 'transmute/pipe';
import { isThreadListMember } from '../thread-list-member/isThreadListMember';
import { ASSIGNEE, ASSIGNEE_ID, ASSOCIATED_TICKET_IDS, CHANNELS, CONVERSATION_ID, EMAIL_METADATA, FILTERED_SOURCE, HAS_FILE_ATTACHMENT, IS_COMMENT, IS_LATEST_MESSAGE_A_DRAFT, LATEST_MESSAGE_FAILED, LATEST_MESSAGE_PREVIEW, LATEST_MESSAGE_TIMESTAMP, LATEST_RECEIVED_TIMESTAMP, LATEST_SENT_TIMESTAMP, ORIGINAL_GENERIC_CHANNEL_ID, PREVIEW, PREVIEW_MESSAGE_ID, PREVIOUS, REMOVAL, SEEN, SEEN_BY_AGENT_IDS, SLA_DETAILS_ACTIVE_SLA, SLA_DETAILS_GOALS, SORT_TIMESTAMP, STATUS, THREAD_ID, UPDATE, VID, VISITOR } from './threadListMemberKeyPaths';
export const getAssignee = threadListMember => getIn(ASSIGNEE, threadListMember);
export const getAssigneeId = threadListMember => getIn(ASSIGNEE_ID, threadListMember);
export const getAssociatedTicketIds = threadListMember => getIn(ASSOCIATED_TICKET_IDS, threadListMember);
export const getHasAssociatedTickets = threadListMember => getIn(ASSOCIATED_TICKET_IDS, threadListMember).length > 0;
export const getConversationId = threadListMember => getIn(CONVERSATION_ID, threadListMember);
const getEmailMetadata = threadListMember => getIn(EMAIL_METADATA, threadListMember);
export const getFilteredSource = threadListMember => getIn(FILTERED_SOURCE, threadListMember);
export const getGenericChannel = threadListMember => get(ORIGINAL_GENERIC_CHANNEL_ID, threadListMember);
export const getHasFileAttachment = threadListMember => getIn(HAS_FILE_ATTACHMENT, threadListMember);
export const getIsComment = threadListMember => getIn(IS_COMMENT, threadListMember);
export const getIsLatestMessageADraft = threadListMember => getIn(IS_LATEST_MESSAGE_A_DRAFT, threadListMember);
export const getLatestMessageTimestamp = threadListMember => getIn(LATEST_MESSAGE_TIMESTAMP, threadListMember);
export const getLatestReceivedTimestamp = threadListMember => getIn(LATEST_RECEIVED_TIMESTAMP, threadListMember);
export const getLatestSentTimestamp = threadListMember => getIn(LATEST_SENT_TIMESTAMP, threadListMember);
export const getLatestMessageFailed = threadListMember => getIn(LATEST_MESSAGE_FAILED, threadListMember);
export const getLatestMessagePreview = threadListMember => getIn(LATEST_MESSAGE_PREVIEW, threadListMember);
export const getPreview = threadListMember => getIn(PREVIEW, threadListMember);
export const getPreviewMessageId = threadListMember => getIn(PREVIEW_MESSAGE_ID, threadListMember);
export const getPrevious = threadListMember => getIn(PREVIOUS, threadListMember);
export const getRemoval = threadListMember => getIn(REMOVAL, threadListMember);
export const getSeen = threadListMember => getIn(SEEN, threadListMember);
export const getSeenByAgentIds = threadListMember => getIn(SEEN_BY_AGENT_IDS, threadListMember);
export const getSenderId = threadListMember => {
  const preview = getLatestMessagePreview(threadListMember);
  const sender = preview ? get('sender', preview) : null;
  return sender ? get('id', sender) : null;
};
export const getSortTimestamp = threadListMember => getIn(SORT_TIMESTAMP, threadListMember);
export const getStatus = threadListMember => getIn(STATUS, threadListMember);

/**
 *
 * @deprecated - use getViewMemberId instead
 */
export const getThreadId = threadListMember => getIn(THREAD_ID, threadListMember);
export const getUpdate = threadListMember => getIn(UPDATE, threadListMember); // TODO: Update the types after ThreadListMemberOverlay record has been migrated to inbox-lib

export const getVid = threadListMember => getIn(VID, threadListMember);
export const getVisitor = threadListMember => getIn(VISITOR, threadListMember);
export const getChannels = threadListMember => getIn(CHANNELS, threadListMember);
export const getIsForwardedEmail = pipe(getEmailMetadata, getMetadataIsForward);
export const getActiveSLA = threadListMember => getIn(SLA_DETAILS_ACTIVE_SLA, threadListMember);
export const getSLAGoals = threadListMember => getIn(SLA_DETAILS_GOALS, threadListMember);

/**
 * @description Gets the timestamp of the ThreadListMember associated with the given orderType
 */
//Genericized getters only where applicable due to partial typing of TLM
export const getOrderedByFieldTimestamp = curry((orderType, member) => {
  switch (orderType) {
    case 'LATEST_AGENT_RESPONSE':
      if (isThreadListMember(member)) return getLatestSentTimestamp(member);
      return member.latestSentTimestamp;
    case 'LATEST_TICKET':
      if (isThreadListMember(member)) return getSortTimestamp(member);
      return member.sortTimestamp;
    case 'LATEST_VISITOR_RESPONSE':
      if (isThreadListMember(member)) return getLatestReceivedTimestamp(member);
      return member.latestReceivedTimestamp;
    case 'LATEST_MESSAGE':
    default:
      if (isThreadListMember(member)) return getLatestMessageTimestamp(member);
      return member.latestMessageTimestamp;
  }
});
export const getHasBeenRepliedTo = member => {
  if (isThreadListMember(member)) {
    const latestMessageTimestamp = getLatestMessageTimestamp(member);
    const latestSentTimestamp = getLatestSentTimestamp(member);
    if (!latestMessageTimestamp || !latestSentTimestamp) return false;
    return latestMessageTimestamp === latestSentTimestamp;
  }
  if (!member.latestMessageTimestamp || !member.latestSentTimestamp) return false;
  return member.latestMessageTimestamp === member.latestSentTimestamp;
};
export const getActiveSLAGoal = member => {
  if (isThreadListMember(member)) {
    const activeSLA = getActiveSLA(member);
    const goals = getSLAGoals(member);
    if (goals && goals.length && goals.length > 0) {
      const activeGoal = goals.find(({
        goalType
      }) => goalType === activeSLA);
      return activeGoal || null;
    }
  }
  const activeSLA = member.goalDetails && member.goalDetails['activeGoalType'];
  const goals = activeSLA && member.goalDetails['goals'];
  if (goals && goals.length && goals.length > 0) {
    const activeGoal = goals.find(({
      goalType
    }) => goalType === activeSLA);
    return activeGoal || null;
  }
  return null;
};
export const getActiveSLAStatus = member => {
  const activeGoal = getActiveSLAGoal(member);
  return activeGoal ? activeGoal['goalStatus'] : null;
};
export const getAttachmentTypes = threadListMember => {
  const preview = getLatestMessagePreview(threadListMember);
  return preview ? get('attachmentTypes', preview) : null;
};
import PropTypes from 'prop-types';
export const NOT_INITIALIZED = 'NOT_INITIALIZED';
export const PENDING = 'PENDING';
export const INSTALLED = 'INSTALLED';
export const UNINSTALLED = 'UNINSTALLED';
export const ERROR = 'ERROR';
export const INSTALL_TYPE = {
  WORDPRESS: 'WORDPRESS',
  TRACKINGCODE: 'TRACKINGCODE',
  BOTH: 'both'
};
export const WORDPRESS_PLUGIN_ID_PROD = 179510;
export const WORDPRESS_PLUGIN_ID_QA = 1206770;
export const STATUS_PROPTYPE = PropTypes.oneOf([NOT_INITIALIZED, PENDING, INSTALLED, UNINSTALLED, ERROR]).isRequired;
module.exports = {
  "view": {
    "name": "view",
    "class": "view",
    "properties": {
      "sourceApp": {
        "type": "string"
      },
      "objectType": {
        "type": "string"
      },
      "component": {
        "type": [
          "ShareToolModal"
        ]
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "isEnrolled": {
        "type": "boolean",
        "isOptional": true
      },
      "paymentEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "paymentMethods": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "commerce-tools-ui-lib",
    "version": "1"
  },
  "interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "sourceApp": {
        "type": "string"
      },
      "objectType": {
        "type": "string"
      },
      "component": {
        "type": [
          "ShareToolModal"
        ]
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "isEnrolled": {
        "type": "boolean",
        "isOptional": true
      },
      "paymentEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "paymentMethods": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": [
          "close modal",
          "tab changed",
          "copy link",
          "download pdf",
          "retry copy link",
          "retry download pdf",
          "saw copy link error",
          "saw download pdf error",
          "click write manual email button",
          "click add cc button",
          "click Manage HubSpot connected emails link",
          "click manage connected personal email connections link",
          "click manage connected team email connections link",
          "click send email button returned an error",
          "click test checkout button",
          "click set up online payments button",
          "copy embed code",
          "click download qr code button",
          "click copy qr code button"
        ]
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "commerce-tools-ui-lib",
    "version": "1"
  },
  "usage": {
    "name": "usage",
    "class": "usage",
    "properties": {
      "sourceApp": {
        "type": "string"
      },
      "objectType": {
        "type": "string"
      },
      "component": {
        "type": [
          "ShareToolModal"
        ]
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "isEnrolled": {
        "type": "boolean",
        "isOptional": true
      },
      "paymentEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "paymentMethods": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": [
          "click send email button",
          "click set up connect email inbox button",
          "email body changed",
          "email subject changed"
        ]
      }
    },
    "namespace": "commerce-tools-ui-lib",
    "version": "1"
  },
  "commerceSpecialistsInteraction": {
    "name": "interaction",
    "class": "interaction",
    "namespace": "commerce",
    "properties": {
      "action": {
        "type": [
          "commerceSpecialistsContactModalInteraction",
          "commerceSpecialistsContactAlertInteraction",
          "commerceSpecialistsTooltipInteraction"
        ]
      },
      "subAction": {
        "type": [
          "meetingsLinkClicked",
          "emailLinkClicked",
          "emailLinkHovered",
          "contactModalClosed",
          "openContactModal",
          "tooltipViewed"
        ]
      },
      "app": {
        "type": [
          "invoices",
          "subscriptions",
          "payment-links",
          "payments-enrollment",
          "commerce-home-pre-enroll",
          "commerce-home-post-enroll"
        ]
      },
      "source": {
        "type": [
          "editorAbandoned",
          "indexPage",
          "commerceHome"
        ],
        "isOptional": true
      }
    },
    "version": "1"
  }
};
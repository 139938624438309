// eslint-disable-next-line no-restricted-imports -- we use http by default
import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { INCOMING_EMAIL, EMAIL, MEETING, FORWARDED_EMAIL, EMAIL_ACTIVITY, CUSTOM_CHANNEL_CONVERSATION } from 'customer-data-objects/engagement/EngagementTypes';
import { COMMUNICATION, MEETING_EVENT } from 'customer-data-objects/constants/ObjectTypes';
const FETCH_AUTO_ASSOCIATIONS_FOR_ACTIVITY = registerQuery({
  fieldName: 'autoAssociationsData',
  args: ['subjectObjectTypeId', 'subjectObjectId', 'engagementType', 'includedObjects', 'apiClient'],
  fetcher: ({
    subjectObjectTypeId,
    subjectObjectId,
    engagementType,
    includedObjects,
    apiClient
  }) => apiClient.post('/crm-associations-features/v3/auto-associations', {
    data: {
      subjectObjectTypeId,
      subjectObjectId,
      engagementType,
      includedObjects
    }
  })
});
const formatEngagementType = engagementOrInteractionType => {
  // some host apps may pass CDO's ObjectType FQN `MEETING_EVENT` instead of CDO's
  // EngagementType `MEETING` which is the BE's expected value
  // NOTE: This is a different expectation than useFetchUniversalEngagementAssociations, which expects `MEETING_EVENT` instead of `MEETING`
  if (engagementOrInteractionType === MEETING_EVENT) {
    return MEETING;
    // some host apps may pass CDO's EngagementType `INCOMING_EMAIL`, `FORWARDED_EMAIL`, or `EMAIL_ACTIVITY` instead of CDO's
    // ObjectType FQN `EMAIL` which is the BE's expected value
  } else if (engagementOrInteractionType === INCOMING_EMAIL || engagementOrInteractionType === FORWARDED_EMAIL || engagementOrInteractionType === EMAIL_ACTIVITY) {
    return EMAIL;
    // some host apps may pass CDO's EngagementType `CUSTOM_CHANNEL_CONVERSATION` instead of CDO's
    // ObjectType FQN `COMMUNICATION` which is the BE's expected value
  } else if (engagementOrInteractionType === CUSTOM_CHANNEL_CONVERSATION) {
    return COMMUNICATION;
  }
  return engagementOrInteractionType;
};
export const useFetchAutoAssociations = ({
  subjectObjectTypeId,
  subjectObjectId,
  engagementType,
  skip,
  apiClient = http
}) => {
  return useQuery(FETCH_AUTO_ASSOCIATIONS_FOR_ACTIVITY, {
    variables: {
      subjectObjectTypeId: subjectObjectTypeId || '',
      // NOTE: query is skipped if subjectObjectTypeId is undefined
      subjectObjectId: subjectObjectId || '',
      // NOTE: query is skipped if subjectObjectId is undefined
      engagementType: formatEngagementType(engagementType),
      includedObjects: {},
      apiClient
    },
    fetchPolicy: 'network-only',
    skip
  });
};
import { useIsLocationEnabledForObjectType } from 'location-customization-utils-lib/hooks/useIsLocationEnabledForObjectType';
import { useUserInfoContext } from '../../common/context/internal/UserInfoContext';
import { FetchStatuses } from '../../common/types/FetchStatus';

/**
 * Fetches `crmPageEditor` FAS data and determines whether the page editor is available for the
 * location and objectTypeId provided. Note: there are some locations that aren't directly customizable but
 * are indirectly customizable. This hook will return false for those locations.
 *
 * Example: A user can customize the middle column (CRM_RECORD_MIDDLE) indirectly via the full record page
 * editor (CRM_RECORD_PAGE). This hook returns false for CRM_RECORD_MIDDLE but true for CRM_RECORD_PAGE.
 *
 *
 * @param objectTypeId
 * @param location
 * @returns True if object type and location combination are available in the page editor.
 */
export const useObjectTypeSupportsCustomization = (objectTypeId, location) => {
  var _userInfo$gates, _userInfo$user$scopes, _userInfo$user;
  const userInfo = useUserInfoContext();
  const gates = (_userInfo$gates = userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates) !== null && _userInfo$gates !== void 0 ? _userInfo$gates : [];
  const scopes = (_userInfo$user$scopes = userInfo === null || userInfo === void 0 || (_userInfo$user = userInfo.user) === null || _userInfo$user === void 0 ? void 0 : _userInfo$user.scopes) !== null && _userInfo$user$scopes !== void 0 ? _userInfo$user$scopes : [];
  const {
    isLocationEnabledForObjectType,
    loading,
    error
  } = useIsLocationEnabledForObjectType({
    objectTypeId,
    location,
    scopes,
    gates
  });
  if (loading) {
    return {
      status: FetchStatuses.LOADING,
      data: null
    };
  }
  if (error) {
    return {
      status: FetchStatuses.REJECTED,
      data: null,
      error
    };
  }
  return {
    status: FetchStatuses.RESOLVED,
    data: isLocationEnabledForObjectType
  };
};
import { Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
export function isLineItem(maybeLineItem) {
  return maybeLineItem.isNew !== undefined;
}
function isImmutable(maybeSubject) {
  return ImmutableMap.isMap(maybeSubject.properties);
}
export function getLineItem(lineItem) {
  if (!lineItem || !isImmutable(lineItem)) {
    return lineItem;
  }
  const {
    isNew,
    objectId,
    properties
  } = lineItem;
  return {
    isNew,
    objectId,
    properties: properties.toJS()
  };
}
export function getProduct(product) {
  if (!product || !isImmutable(product)) {
    return product;
  }
  const {
    objectId,
    properties
  } = product;
  return {
    objectId,
    properties: properties.toJS()
  };
}
// ported from https://git.hubteam.com/HubSpot/CRM/blob/master/crm_ui/static/js/utils/FormatNumber.js
export function parseNumber(number) {
  if (typeof number === 'number') {
    return number;
  }
  const parsed = Number(number);
  if (!isNaN(parsed)) {
    return parsed;
  }
  // @ts-expect-error type dependency error
  return I18n.parseNumber(number);
}
export function getSubject(maybeSubject) {
  if (!maybeSubject) {
    // This cast is done for the null/undefined case when called via JS
    return maybeSubject;
  }
  if (isLineItem(maybeSubject)) {
    return getLineItem(maybeSubject);
  }
  return getProduct(maybeSubject);
}
export function getId(maybeSubject) {
  const {
    objectId
  } = getSubject(maybeSubject);
  return parseNumber(objectId);
}
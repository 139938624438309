export let ProductLevel;
(function (ProductLevel) {
  ProductLevel["PRO"] = "pro";
  ProductLevel["NONE"] = "none";
  ProductLevel["ENTERPRISE"] = "ent";
  ProductLevel["STARTER"] = "starter";
})(ProductLevel || (ProductLevel = {}));
export let HubProducts;
(function (HubProducts) {
  HubProducts["marketing"] = "marketing";
  HubProducts["sales"] = "sales";
  HubProducts["service"] = "service";
  HubProducts["cms"] = "cms";
  HubProducts["ops"] = "ops";
})(HubProducts || (HubProducts = {}));
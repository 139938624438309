export const WIDGET = 'widget';
export const REMOTE = 'remote';
export const WINDOW = 'window';
const iframeLocation = {
  WIDGET,
  REMOTE,
  WINDOW
};
export const COMMUNICATOR = 'communicator';
export const HELP_DESK = 'help-desk';
export const NAVIGATION = 'navigation';
const iframeSource = {
  COMMUNICATOR,
  HELP_DESK,
  NAVIGATION
};
import { getId, ThreadList } from 'find-and-filter-data/views-schema/public';

/**
 * `buildIndexedViews` will accept arrays of custom views and thread lists and
 *  then index then by id.
 */

export function buildIndexedViews({
  customViews,
  threadLists
}) {
  var _threadLists$map;
  const views = [...customViews, ...((_threadLists$map = threadLists === null || threadLists === void 0 ? void 0 : threadLists.map(view => new ThreadList(view))) !== null && _threadLists$map !== void 0 ? _threadLists$map : [])];

  // index all views by viewId
  return views.reduce((acc, curr) => {
    acc[getId(curr)] = curr;
    return acc;
  }, {});
}
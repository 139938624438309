import http from 'hub-http/clients/apiClient';
import { HIDDEN_FOLDER_PATH, HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE } from '../utils/constants';
/**
 * @param {File} file
 * @param {Object} options
 * @param {Function} options.onProgress
 * @param {String} options.access One of the FileAccess enums from FileManagerLib
 */
export function uploadFile(file, options = {}) {
  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hideAndProtectFile' does not exist on ty... Remove this comment to see the full error message
    hideAndProtectFile,
    // deprecated
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onProgress' does not exist on type '{}'.
    onProgress,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type '{}'.
    query,
    // deprecated
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'access' does not exist on type '{}'.
    access,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'folderUuid' does not exist on type '{}'.
    folderUuid
  } = options;
  if (query || hideAndProtectFile || !access) {
    console.warn('draft-plugins has migrated off of the deprecated v2 FileManagerApi. ' + 'To set file visibility for non-image files, specify access type in the FileDrop plugin. ' + 'e.g. `{ fileOptions: { access: SOME_FILE_ACCESS_ENUM } }`. ' + 'Do not use a `query` object or `hideAndProtectFile`.');
  }
  const finalFormData = new FormData();
  finalFormData.append('file', file);
  finalFormData.append('folderPath', folderUuid ? `${HIDDEN_FOLDER_PATH}_${folderUuid}` : HIDDEN_FOLDER_PATH);
  const formDataOptions = {
    access: access || HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE
  };
  finalFormData.append('options', JSON.stringify(formDataOptions));
  return http.post('filemanager/api/v3/files/upload', {
    timeout: 0,
    headers: {
      'content-type': false
    },
    data: finalFormData,
    withXhr: xhr => {
      if (onProgress) {
        xhr.upload.addEventListener('progress', onProgress, false);
      }
    }
  });
}
export function getFileInfo(path) {
  const encodedPath = encodeURIComponent(path);
  return http.get(`filemanager/api/v2/files/info/${encodedPath}`);
}
export function fetchVideoSharingPage(uuid, vyemail, customId) {
  return http.post(`filemanager/api/v3/videos/${uuid}/personalization-token`, {
    data: {
      vyemail,
      custom_id: customId
    }
  });
}
import { useCallback, useMemo } from 'react';
import { EDIT_INPUT_MODE } from '../../../../v2/types/PropertyInputV2Component';
import { useGetLeadStageQualificationValidator } from './useGetLeadStageQualificationValidator';
import { useLeadStageModal } from './useLeadStageModal';
import { useObjectTypeId } from '../../../../v2/hooks/useObjectTypeId';
import { useObjectId } from '../../../../v2/hooks/useObjectId';
import { LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { getPipelinePropertyName, getPipelineStagePropertyName } from '../../utils/pipelineUtils';
import { useAdditionalPropertyValues } from '../../../../v2/hooks/useAdditionalPropertyValues';
import { useGetIsUngated } from '../../../../v2/hooks/useGetIsUngated';
import { gate } from 'hub-http/gates';
export const useLeadStageChangeValidation = props => {
  const {
    onChange,
    inputMode,
    onApply
  } = props;
  const objectTypeId = useObjectTypeId();
  const objectId = useObjectId();
  const {
    loading,
    stageChangeValidator
  } = useGetLeadStageQualificationValidator();
  const getIsUngated = useGetIsUngated();
  const isUngatedForCDPLeadCreateDeal = getIsUngated(gate('Leads:CDP:RequireDealCreate'));
  const pipelineStagePropertyName = getPipelineStagePropertyName(objectTypeId);
  const pipelinePropertyName = getPipelinePropertyName(objectTypeId);
  const {
    data: additionalValues,
    loading: additionalValuesLoading
  } = useAdditionalPropertyValues({
    originPipelineId: pipelinePropertyName,
    primaryContactId: 'hs_primary_contact_id',
    primaryCompanyId: 'hs_primary_company_id'
  }, {
    skip: objectTypeId !== LEAD_TYPE_ID
  });
  const originPipelineId = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues.originPipelineId;
  const {
    stageValidationModal,
    openModal,
    closeModal
  } = useLeadStageModal();
  const handleValidation = useCallback((validationRequest, pendingChanges) => {
    const validationResult = stageChangeValidator(validationRequest);
    if (validationResult.isValid) {
      // Otherwise continue sending values back to host
      onChange(pendingChanges);
      return;
    }

    // If we're aware that specific validation codes should warrant a confirmation modal we fire an open modal action here.
    // The modal needs to propagate the original onChange callback once onConfirm is called so we include the callback as part of the dispatched modal data.
    switch (validationResult.requirement) {
      case 'DEAL_CREATION_REQUIRED':
        {
          openModal({
            modal: 'DEAL_CREATE',
            extraProperties: Object.assign({}, additionalValues),
            onConfirm: () => {
              onChange(pendingChanges);
              onApply();
              closeModal();
            },
            onCancel: () => {
              closeModal();
            }
          });
          return;
        }
      case 'RE_ATTEMPTING_LEAD_REQUIRED':
      case 'FOLLOW_UP_TASK_REQUIRED':
      case 'RE_ATTEMPTING_LEAD_AND_TASK_REQUIRED':
        {
          openModal({
            modal: validationResult.requirement,
            extraProperties: {
              disqualificationReason: (pendingChanges === null || pendingChanges === void 0 ? void 0 : pendingChanges['hs_lead_disqualification_reason']) || '',
              disqualificationNote: (pendingChanges === null || pendingChanges === void 0 ? void 0 : pendingChanges['hs_lead_disqualification_note']) || ''
            },
            onConfirm: () => {
              onChange(pendingChanges);
              onApply();
              closeModal();
            },
            onCancel: () => {
              closeModal();
            }
          });
          return;
        }
      default:
        {
          return validationResult.requirement;
        }
    }
  }, [stageChangeValidator, onChange, openModal, additionalValues, closeModal, onApply]);
  const onChangeWithLeadStageValidation = useCallback(changes => {
    // We don't want this validation triggering for other input modes such as bulk actions
    if (!objectId || inputMode !== EDIT_INPUT_MODE || objectTypeId !== LEAD_TYPE_ID || !isUngatedForCDPLeadCreateDeal) {
      onChange(changes);
      return;
    }
    const stageId = changes[pipelineStagePropertyName];

    // Check local changes first, then fallback to host app's value to grab the pipeline
    const pipelineId = changes[pipelinePropertyName] || originPipelineId;
    const targetDisqualificationReason = changes['hs_lead_disqualification_reason'];

    // If stage or pipeline isn't part of the property change, there is no validation needed.
    if (!stageId || !pipelineId) {
      onChange(changes);
      return;
    }
    handleValidation({
      targetPipelineId: pipelineId,
      targetStageId: stageId,
      targetDisqualificationReason
    }, changes);
  }, [objectId, inputMode, objectTypeId, isUngatedForCDPLeadCreateDeal, pipelineStagePropertyName, pipelinePropertyName, originPipelineId, handleValidation, onChange]);
  return useMemo(() => ({
    leadStageChangeValidatorLoading: objectTypeId === LEAD_TYPE_ID && (loading || additionalValuesLoading),
    leadStageValidationModal: stageValidationModal,
    onChangeWithLeadStageValidation
  }), [objectTypeId, loading, additionalValuesLoading, stageValidationModal, onChangeWithLeadStageValidation]);
};
import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { fetchHubsInfo } from './hubsInfoApi';
import { ProductLevel } from '../types/products';
import { fetchHighestProductLevel } from './irisApi';
const US_COUNTRY_CODE = 'US';
const COMPANY_DETAILS_ENDPOINT = `company-details/v1/company-details/${PortalIdParser.get()}`;
export const fetchCompanyCountry = async () => {
  const hubsInfo = await fetchHubsInfo();
  const highestProductLevel = await fetchHighestProductLevel();
  const isFriendsAndFamilyPortal = hubsInfo.friendsAndFamily;
  const isProductLevelFree = highestProductLevel && highestProductLevel === ProductLevel.NONE;

  // API is expected to fail for Free and Friends & Family portals so we just skip the request entirely
  if (isFriendsAndFamilyPortal || isProductLevelFree) {
    return null;
  }
  try {
    const {
      shippingAddress
    } = await http.get(COMPANY_DETAILS_ENDPOINT);
    return shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress.country;
  } catch (error) {
    // 404s/403 are expected if a portal doesn't have account details defined
    // so we don't want to log them as errors
    if (error.status !== 404 && error.status !== 403) {
      throw error;
    }
    return null;
  }
};
export const fetchDoesPortalHaveUSCompanyAddress = async () => {
  const country = await fetchCompanyCountry();
  return country === US_COUNTRY_CODE;
};
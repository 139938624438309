import { useCallback, useEffect, useRef } from 'react';
import { getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { useCommonReply } from './useCommonReply';
import { getIsUngatedForPersistedDrafts } from 'conversations-thread-data/auth/public/selectors';
import { useSelector } from 'react-redux';
import { usePersistedCommonReply } from './usePersistedCommonReply';
function useUpdateReplyChannelOnChannelIdChange({
  onChangeReplyChannel,
  reply
}) {
  const currentCommonMessage = getCommonMessage(reply);
  const currentMessageChannelId = getGenericChannelId(currentCommonMessage);
  const stableOnChangeReplyChannel = useRef(onChangeReplyChannel);
  stableOnChangeReplyChannel.current = onChangeReplyChannel;
  useEffect(() => {
    if (stableOnChangeReplyChannel.current) {
      stableOnChangeReplyChannel.current(currentMessageChannelId || 'UNSET');
    }
  }, [currentMessageChannelId]);
}
/**
 * @description a hook that decorates the passed change handlers
 * with internal state management in order to do things like
 * reset reply state on submission and update reply state on change.
 */
export const useCommonReplyForEditor = ({
  emptyReply,
  onDraftChange,
  onSubmit,
  reconcileReplies,
  onChangeReplyChannel,
  idType,
  idNumber
}) => {
  const isUngatedForPersistedDrafts = useSelector(getIsUngatedForPersistedDrafts);
  const persistedReplyHook = usePersistedCommonReply({
    emptyReply,
    onDraftChange,
    reconcileReplies,
    idType,
    idNumber
  });
  const commonReplyHook = useCommonReply({
    emptyReply,
    reconcileReplies
  });
  const [reply, setReply, resetReply] = isUngatedForPersistedDrafts ? persistedReplyHook.replyHandlers : commonReplyHook;
  useUpdateReplyChannelOnChannelIdChange({
    onChangeReplyChannel,
    reply
  });
  const handleSubmit = useCallback((newReply, textOptions) => {
    // simple check to make sure communicator reply object is being used for submission instead of the hook referenced reply object used by inline composer
    const {
      html,
      plainText,
      options = {}
    } = textOptions || newReply;
    const replyToSubmit = textOptions ? newReply : reply;
    onSubmit(replyToSubmit, {
      html,
      plainText,
      communicatorType: 'reply-editor',
      replyType: 'reply',
      options
    });
    resetReply();
  }, [onSubmit, resetReply, reply]);
  return Object.assign({
    onChange: setReply,
    onSubmit: handleSubmit,
    reply
  }, isUngatedForPersistedDrafts && Number(idNumber) && {
    loadedPersistentDrafts: persistedReplyHook.loadedPersistentDrafts,
    hasDraftContent: persistedReplyHook.hasDraftContent,
    savePersistentDraft: persistedReplyHook.saveDraft,
    clearPersistentDraft: persistedReplyHook.clearDraft,
    draftSaveFailed: persistedReplyHook.draftSaveFailed
  });
};
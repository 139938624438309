import I18n from 'I18n';
import { BILLING_FREQUENCIES_OBJECT, WEEKLY_BILLING_FREQUENCIES } from 'customer-data-objects/product/BillingFrequency';
import unescapedText from 'I18n/utils/unescapedText';
function toNumberOrNull(value) {
  // prevents null conversion to 0 for initial price calculations
  if (value === null) return null;
  const number = Number(value);
  return isNaN(number) ? null : number;
}
export function getMonthsPerPeriod(periodISOString) {
  if (!periodISOString) {
    return null;
  }
  const duration = I18n.moment.duration(periodISOString.toUpperCase());
  return duration.asMonths();
}
export function getWeeksPerPeriod(periodISOString) {
  if (!periodISOString) {
    return null;
  }
  const duration = I18n.moment.duration(periodISOString.toUpperCase());
  return duration.asWeeks();
}
export function getPeriodFromMonths(months) {
  const monthsAsNumber = toNumberOrNull(months);
  return monthsAsNumber ? `P${Math.round(monthsAsNumber)}M` : '';
}
export function getPeriodFromWeeks(weeks) {
  const weeksAsNumber = toNumberOrNull(weeks);
  const numberOfDays = weeksAsNumber ? Math.round(weeksAsNumber) * 7 : null;
  return numberOfDays ? `P${numberOfDays}D` : '';
}
export function getBillingPeriod(billingFrequency, periodLength) {
  const isBillingFrequencyWeeklyOrBiweekly = isWeeklyOrBiweeklyBillingFrequency(billingFrequency);
  return isBillingFrequencyWeeklyOrBiweekly ? getPeriodFromWeeks(periodLength) : getPeriodFromMonths(periodLength);
}
export function getMonthsPerBillingFrequency(rate) {
  const billingFrequencyInfo = BILLING_FREQUENCIES_OBJECT[rate];
  return billingFrequencyInfo.months;
}
export function getWeeksPerBillingFrequency(rate) {
  const billingFrequencyInfo = BILLING_FREQUENCIES_OBJECT[rate];
  return billingFrequencyInfo.weeks;
}
export function isWeeklyOrBiweeklyBillingFrequency(billingFrequency) {
  return WEEKLY_BILLING_FREQUENCIES.has(billingFrequency);
}

// Case-insensitive patterns for ISO 8601 durations
const daysPeriodPattern = /^P(\d+)D$/i;
const monthsPeriodPattern = /^P(\d+)M$/i;
export const WEEKS = 'WEEKS';
export const MONTHS = 'MONTHS';

/**
 * Retrieves the period value and unit from a given ISO 8601 period string.
 *
 * @param periodISOString - The ISO 8601 period string to extract the period value and unit from.
 * @returns An object containing the period value and unit, or null if the period string is falsy.
 */
export function parsePeriodValue(periodISOString) {
  if (!periodISOString) {
    return null;
  }
  const daysMatch = periodISOString.match(daysPeriodPattern);
  if (daysMatch) {
    const value = toNumberOrNull(daysMatch[1]);
    return {
      unit: WEEKS,
      periodValue: value ? Math.floor(value / 7) : value
    };
  }
  const monthsMatch = periodISOString.match(monthsPeriodPattern);
  if (monthsMatch) {
    return {
      unit: MONTHS,
      periodValue: toNumberOrNull(monthsMatch[1])
    };
  }
  return null;
}
export function doesBillingFrequencyEvenlyDividePeriod(period, billingFrequency) {
  var _parsePeriodValue$per, _parsePeriodValue;
  if (!billingFrequency || !period) {
    return true;
  }

  // Sometimes the period string is not the latest value, so we need to
  // manually parse it and get the number of weeks/months instead of using moment
  const periodValue = (_parsePeriodValue$per = (_parsePeriodValue = parsePeriodValue(period)) === null || _parsePeriodValue === void 0 ? void 0 : _parsePeriodValue.periodValue) !== null && _parsePeriodValue$per !== void 0 ? _parsePeriodValue$per : 0;
  if (isWeeklyOrBiweeklyBillingFrequency(billingFrequency)) {
    const weeksPerFrequency = getWeeksPerBillingFrequency(billingFrequency);
    return periodValue % weeksPerFrequency === 0;
  }
  const monthsPerFrequency = getMonthsPerBillingFrequency(billingFrequency);
  return periodValue % monthsPerFrequency === 0;
}
export function formatPeriodText(period, recurringBillingFrequency) {
  if (period == null) {
    return '';
  }
  if (recurringBillingFrequency) {
    const isWeeklyOrBiweeklyFrequency = isWeeklyOrBiweeklyBillingFrequency(recurringBillingFrequency);

    // Format with I18n and return display string
    if (isWeeklyOrBiweeklyFrequency) {
      return unescapedText('customerDataProperties.billingPeriod.weeks', {
        count: period
      });
    }
    return unescapedText('customerDataProperties.billingPeriod.months', {
      count: period
    });
  }
  return String(period);
}
import I18n from 'I18n';
import { getDynamicNamespace } from 'conversations-infrastructure/i18n/getDynamicNamespace';
import { CONTACT, LOCATION, QUICK_REPLIES, UNSUPPORTED_CONTENT } from 'conversations-message-history/common-message-format/constants/attachmentTypes';
//foundations icons
import { ContactsIcon, LocationIcon, QuestionAnswerIcon, WarningIcon } from 'foundations-assets';
const FALLBACK_ICON = WarningIcon;
const attachmentTypeIcons = {
  [CONTACT]: ContactsIcon,
  [LOCATION]: LocationIcon,
  [QUICK_REPLIES]: QuestionAnswerIcon
};
export const formatCommonAttachmentData = attachment => {
  const knownTypes = Object.keys(attachmentTypeIcons);
  const type = knownTypes.includes(attachment['@type']) ? attachment['@type'] : UNSUPPORTED_CONTENT;
  const friendlyType = I18n.text(getDynamicNamespace({
    prefix: 'conversations-inbox-message-history.attachments.type',
    translationOptions: [...knownTypes, UNSUPPORTED_CONTENT],
    defaultOption: UNSUPPORTED_CONTENT
  }, type));
  const Icon = attachmentTypeIcons[type] || FALLBACK_ICON;
  return {
    Icon,
    name: null,
    type: friendlyType,
    url: null
  };
};
import { syntheticEvent } from '../../utils/SyntheticEvent';
import { callIfPossible } from '../../utils/functions';
import { loadMoreOptionValue } from './Select.constants';
import { isOptionGroup as _isOptionGroup } from './Select.types';
import { getCheckboxState, getPaginationCache, isVirtualized } from './Select.helpers';
import { closest } from '../../utils/dom';
export const createHandlers = component => ({
  handleChange: option => {
    // react-select-plus gives us an option object or null, which we pass to
    // onSelectedOptionChange, but our onChange handler expects just the new value.
    const {
      onChange,
      onSelectedOptionChange,
      value
    } = component.props;
    callIfPossible(onSelectedOptionChange, syntheticEvent(option));
    let newVal = null;
    if (Array.isArray(option)) {
      newVal = option.reduce((acc, item) => {
        if (item) {
          const {
            value: itemValue
          } = item;
          const i = acc.indexOf(itemValue);
          if (i > -1) {
            acc.splice(i, 1);
          } else {
            acc.push(itemValue);
          }
        }
        return acc;
      }, []);
    } else if (option) {
      newVal = option.value;
    }
    if (newVal !== value) {
      callIfPossible(onChange, syntheticEvent(newVal));
    }
  },
  handleInputKeyDown: evt => {
    const {
      anchorType = 'auto',
      multi = false,
      onInputKeyDown,
      value
    } = component.props;
    const {
      key
    } = evt;
    callIfPossible(onInputKeyDown, evt);
    if (evt.defaultPrevented) {
      return;
    }

    // In multi-mode, we need to handle ENTER to allow the focused option to be toggled.
    if (key === 'Enter' && multi && component.getAnchorType({
      anchorType,
      multi
    }) !== 'combined') {
      const internalSelect = component.getInternalSelect();
      if (!internalSelect) {
        return;
      }
      const focusedOption = internalSelect._focusedOption;
      if (!focusedOption) {
        return;
      }
      if (focusedOption.value === loadMoreOptionValue) {
        component.triggerLoadMore();
        return;
      }
      if (focusedOption.isSpecial || focusedOption.clearableValue === false) {
        return;
      }
      const valueToToggle = focusedOption.value;
      const willBeSelected = Array.isArray(value) ? value.indexOf(valueToToggle) === -1 : true;
      component.toggleMultiValue(valueToToggle, willBeSelected);
      evt.preventDefault();
    }
  },
  handleOptionToggle: (option, evt) => {
    const {
      value
    } = component.props;
    if (_isOptionGroup(option) && option.selectAll) {
      const {
        checked,
        indeterminate
      } = getCheckboxState(option, value);
      component.toggleGroup(option, !checked && !indeterminate);
    } else {
      const valueToToggle = option.value;
      component.toggleMultiValue(valueToToggle, evt.target.checked);
    }
  },
  handleClose: evt => {
    const {
      onClose,
      onOpenChange
    } = component.props;
    onOpenChange(syntheticEvent(false));
    callIfPossible(onClose, evt);
  },
  handleOpen: evt => {
    const {
      onOpen,
      onOpenChange
    } = component.props;
    onOpenChange === null || onOpenChange === void 0 || onOpenChange(syntheticEvent(true));
    callIfPossible(onOpen, evt);
  },
  triggerOpen: () => {
    const {
      onOpenChange
    } = component.props;
    onOpenChange(syntheticEvent(true));
  },
  handleInputChange: str => {
    const {
      loadOptions,
      multi,
      onInputChange,
      menuRenderer
    } = component.props;
    callIfPossible(onInputChange, str);
    component.setState({
      inputValue: str
    });

    // Handle typing multi values separated by splitRegex.
    if (multi) {
      const splitValues = component.getSplitValuesFromString(str, false);
      if (splitValues.length > 0) {
        component.addMultiValues(splitValues);
        return '';
      }
    }

    // If the user typed a request that will hit the cache, ignore any pending request.
    if (loadOptions != null) {
      const internalAsyncSelect = component.getInternalAsyncSelect();
      if (internalAsyncSelect != null) {
        if (internalAsyncSelect._cache && Object.prototype.hasOwnProperty.call(internalAsyncSelect._cache, str)) {
          internalAsyncSelect._callback = null;
          component.setState({
            isLoading: false
          });
        }
      }
    }

    // Ensure row height for virtualized menu renderer is recomputed
    if (isVirtualized(menuRenderer)) {
      if (component._virtualizedMenuRenderer) component._virtualizedMenuRenderer.recomputeRowHeights();
    }
    return str;
  },
  handleInputValueChange: evt => {
    const {
      onInputValueChange
    } = component.props;
    callIfPossible(onInputValueChange, evt);
    component.setState(Object.assign({}, getPaginationCache(component.props), {
      inputValue: evt.target.value
    }));
  },
  handlePaste: evt => {
    const {
      multi,
      onPaste
    } = component.props;

    // Allow developers to override default paste handling.
    callIfPossible(onPaste, evt);
    if (evt.defaultPrevented) {
      return;
    }
    if (!multi) return;
    const clipboardData = evt.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('Text');
    const splitValues = component.getSplitValuesFromString(pastedText, true);
    if (splitValues.length > 0) {
      evt.preventDefault();
      component.addMultiValues(splitValues);
    }
  },
  handleAutosize: () => {
    if (component.popoverRef.current) {
      component.popoverRef.current.reposition();
    }
  },
  handleMouseDownOnDropdown: evt => {
    // react-select-plus normally closes the dropdown and blurs the input on mousedown. We need to
    // prevent that behavior for certain elements.
    const {
      target
    } = evt;
    if (closest(target, '[data-dropdown-footer]')) {
      evt.stopPropagation();
      evt.preventDefault();
      return;
    }
    if (closest(target, '[data-load-more]')) {
      component.triggerLoadMore();
      evt.stopPropagation();
      evt.preventDefault();
      return;
    }
    if (closest(target, '[data-checkbox]')) {
      evt.stopPropagation();
      evt.preventDefault();
      return;
    }
  }
});
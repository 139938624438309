import { InMemoryCache } from '../cache/InMemoryCache';
const fetchValidationRules = ({
  objectTypeId,
  propertyName
}, httpClient) => {
  return httpClient.get(`property-validation/v1/custom-property-validation/`, {
    query: {
      objectTypeId,
      propertyName
    }
  });
};
const getCacheKey = ({
  objectTypeId,
  propertyName
}) => {
  return `${objectTypeId}/${propertyName}`;
};
export const PropertyValidationRulesCache = new InMemoryCache('PropertyValidationRulesCache', fetchValidationRules, getCacheKey);

// exported for unit test usage only
export const _resetCache = () => {
  PropertyValidationRulesCache.clear();
};
import { useQuery } from 'data-fetching-client';
import { FETCH_BLOCK_LIST } from '../rest/InvoicesEditorQueries';
export const usePropertyBlockList = () => {
  const {
    data: blockList
  } = useQuery(FETCH_BLOCK_LIST, {
    fetchPolicy: 'cache-first'
  });
  return {
    propertyBlockList: (blockList === null || blockList === void 0 ? void 0 : blockList.blockList) || []
  };
};
import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
import { handleError } from '../../utils/errorHandling';
export const FETCH_LOCALES = registerQuery({
  fieldName: 'localesResponse',
  args: [],
  fetcher: () => apiClient.get('hub-locales/v2/i18n').catch(error => {
    throw handleError(error);
  })
});
export function useFetchLocales(options) {
  const query = useQuery(FETCH_LOCALES, options);
  return useMemo(() => {
    var _query$data$localesRe, _query$data, _query$data$localesRe2, _query$data2;
    return Object.assign({}, query, {
      data: {
        language: (_query$data$localesRe = (_query$data = query.data) === null || _query$data === void 0 || (_query$data = _query$data.localesResponse) === null || _query$data === void 0 ? void 0 : _query$data.language) !== null && _query$data$localesRe !== void 0 ? _query$data$localesRe : null,
        locale: (_query$data$localesRe2 = (_query$data2 = query.data) === null || _query$data2 === void 0 || (_query$data2 = _query$data2.localesResponse) === null || _query$data2 === void 0 ? void 0 : _query$data2.locale) !== null && _query$data$localesRe2 !== void 0 ? _query$data$localesRe2 : null
      }
    });
  }, [query]);
}
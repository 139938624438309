export const STATUS = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PROCESSING: 'PROCESSING',
  PAID: 'PAID',
  APPROVAL_NOT_NEEDED: 'APPROVAL_NOT_NEEDED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED'
};
export const DERIVED_STATUS = {
  DRAFT: 'DRAFT',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE',
  SIGNED: 'SIGNED',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  PAID: 'PAID',
  PUBLISHED: 'PUBLISHED',
  PUBLISHING: 'PUBLISHING',
  PROCESSING: 'PROCESSING',
  ACCEPTED: 'ACCEPTED'
};
import { registerQuery, useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import http from 'conversations-http/clients/http';
const CV_FORMS_INTEGRATION_LEGACY_PREFIX = 'cv-forms-integration-legacy/v1';
const getConnectedForms = () => http.get(`${CV_FORMS_INTEGRATION_LEGACY_PREFIX}/connected-crm-object`);
const fetchConnectedForms = registerQuery({
  fetcher: getConnectedForms,
  fieldName: 'connectedForms'
});
export const useConnectedForm = id => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchConnectedForms);
  return useMemo(() => ({
    connectedForm: data && data.connectedForms.find(form => form.id === id),
    error,
    loading
  }), [data, error, id, loading]);
};
export const useConnectedFormByChannelInstanceId = channelInstanceId => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchConnectedForms);
  return useMemo(() => ({
    connectedForm: data && data.connectedForms.find(form => form.channelInstanceId === channelInstanceId),
    error,
    loading
  }), [channelInstanceId, data, error, loading]);
};
import { Iterable, Map as ImmutableMap } from 'immutable';
import { LINE_ITEM, PRODUCT } from 'customer-data-objects/constants/ObjectTypes';
import { LINE_ITEM_TYPE_ID, PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { parseNumber } from 'products-ui-components/utils/toNumber';
import isEqual from 'hs-lodash/isEqual';
export function getSubject(maybeSubject) {
  if (!maybeSubject) {
    // This cast is done for the null/undefined case when called via JS
    return maybeSubject;
  }
  if (isLineItem(maybeSubject)) {
    return getLineItem(maybeSubject);
  }
  return getProduct(maybeSubject);
}
export function getObjectType(maybeSubject) {
  if (isLineItem(maybeSubject)) {
    return LINE_ITEM;
  }
  return PRODUCT;
}
export function getObjectTypeId(maybeSubject) {
  if (isLineItem(maybeSubject)) {
    return LINE_ITEM_TYPE_ID;
  }
  return PRODUCT_TYPE_ID;
}
export function getLineItem(lineItem) {
  if (!lineItem || !isImmutable(lineItem)) {
    return lineItem;
  }
  const {
    isNew,
    objectId,
    properties
  } = lineItem;
  return {
    isNew,
    objectId,
    properties: properties.toJS()
  };
}
export function getLineItems(lineItems) {
  if (!lineItems) {
    // This cast is done for the null/undefined case when called via JS
    return lineItems;
  }
  let mappedLineItems;
  if (isLineItemMap(lineItems)) {
    mappedLineItems = [...lineItems.values()];
  } else {
    mappedLineItems = lineItems.map(lineItem => getLineItem(lineItem));
  }
  if (!Iterable.isIterable(mappedLineItems)) {
    return mappedLineItems;
  }
  return mappedLineItems.toArray();
}
function isLineItemMap(lineItems) {
  return lineItems instanceof Map;
}
export function getLineItemsMap(lineItems) {
  if (!lineItems) {
    return lineItems;
  }
  return getLineItems(lineItems).reduce((acc, lineItem) => {
    return acc.set(getId(lineItem), lineItem);
  }, new Map());
}
export function getProduct(product) {
  if (!product || !isImmutable(product)) {
    return product;
  }
  const {
    objectId,
    properties
  } = product;
  return {
    objectId,
    properties: properties.toJS()
  };
}
export function isLineItem(maybeLineItem) {
  return maybeLineItem.isNew !== undefined;
}
export function assertIsLineItem(maybeLineItem) {
  if (!isLineItem(maybeLineItem)) {
    throw new Error(`${maybeLineItem} is not a LineItem`);
  }
}
function isImmutable(maybeSubject) {
  return ImmutableMap.isMap(maybeSubject.properties);
}
export function getId(maybeSubject) {
  const {
    objectId
  } = getSubject(maybeSubject);
  return parseNumber(objectId);
}
export function subjectEquals(maybeSubjectA, maybeSubjectB) {
  if (!maybeSubjectA || !maybeSubjectB) {
    // null or undefined case compare with instance equality
    return maybeSubjectA === maybeSubjectB;
  }
  const subjectA = getSubject(maybeSubjectA);
  const subjectB = getSubject(maybeSubjectB);

  // Deep equals object comparison
  return isEqual(subjectA, subjectB);
}
export function getBaseObjectContents(maybeBaseObjectContents) {
  if (ImmutableMap.isMap(maybeBaseObjectContents.properties)) {
    return Object.assign({}, maybeBaseObjectContents, {
      properties: maybeBaseObjectContents.properties.toJS()
    });
  }
  return maybeBaseObjectContents;
}
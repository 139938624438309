export let AppIdentifiers;
(function (AppIdentifiers) {
  AppIdentifiers["PROFILE"] = "profile";
  AppIdentifiers["HELP_DESK"] = "help desk";
  AppIdentifiers["INBOX"] = "inbox";
  AppIdentifiers["REFERENCE_UI"] = "reference-ui";
  AppIdentifiers["ACTIVITY_CREATOR"] = "activity creator";
  AppIdentifiers["OUTBOUND_DIALER"] = "outbound dialer";
  AppIdentifiers["SHARED_DEAL_CARD"] = "shared deal card";
  AppIdentifiers["GUIDED_EXECUTION"] = "guided execution";
})(AppIdentifiers || (AppIdentifiers = {}));
export const PROFILE = AppIdentifiers.PROFILE;
export const HELP_DESK = AppIdentifiers.HELP_DESK;
export const INBOX = AppIdentifiers.INBOX;
export const REFERENCE_UI = AppIdentifiers.REFERENCE_UI;
export const ACTIVITY_CREATOR = AppIdentifiers.ACTIVITY_CREATOR;
export const OUTBOUND_DIALER = AppIdentifiers.OUTBOUND_DIALER;
export const SHARED_DEAL_CARD = AppIdentifiers.SHARED_DEAL_CARD;
export const GUIDED_EXECUTION = AppIdentifiers.GUIDED_EXECUTION;
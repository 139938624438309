/**
 * Tiers
 * -----
 */ // Constants
export const VOLUME = 'volume';
export const GRADUATED = 'graduated';
export const STAIRSTEP = 'stairstep';
export const TIERED_PRICING = new Set([GRADUATED, STAIRSTEP, VOLUME]);
export const MIN_TIERS_LENGTH = 2;
export const MAX_TIER_PRICE = 2147483647;

// Types

// Type Guards
export function isTier(value) {
  return !!value && typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'start');
}
export function isTierArray(value) {
  return Array.isArray(value) && (value.length === 0 || isTier(value.at(0)));
}
export function isTierRange(value) {
  return !!value && typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'start');
}
export function isTierRangeArray(value) {
  return Array.isArray(value) && (value.length === 0 || isTierRange(value.at(0)));
}
export function isTierPrice(value) {
  return !!value && typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'index');
}
export function isTierPriceArray(value) {
  return Array.isArray(value) && (value.length === 0 || isTierPrice(value.at(0)));
}

// Type Assertions
export function assertIsTier(value) {
  if (!isTier(value)) {
    throw new Error(`${value} is not a Tier`);
  }
}
export function assertIsTierArray(value) {
  if (!isTierArray(value)) {
    throw new Error(`${value} is not a Tier[]`);
  }
}
export function assertIsTierRangeArray(value) {
  if (!isTierRangeArray(value)) {
    throw new Error(`${value} is not a TierRange[]`);
  }
}
export function assertIsTierPriceArray(value) {
  if (!isTierPriceArray(value)) {
    throw new Error(`${value} is not a TierPrice[]`);
  }
}

/**
 * Pricing Model
 * ------------
 */

// Constants
export const FLAT = 'flat';
export const TIERED = 'tiered';

// Types

// Type Guards
export function isPricingModelTiered(value) {
  if (!value) return false;
  return TIERED_PRICING.has(value);
}
export function isPricingModelFlat(value) {
  return value === FLAT || !value;
}
export function isPricingModel(value) {
  return isPricingModelTiered(value) || isPricingModelFlat(value);
}

// Type Assertions
export function assertIsTieredPricingModel(value) {
  if (!isPricingModelTiered(value)) {
    throw new Error(`${value} is not a PricingMethodValue`);
  }
}
export function assertIsPricingModel(value) {
  if (!isPricingModel(value)) {
    throw new Error(`${value} is not a PricingModel`);
  }
}
import { getFullUrl } from 'hubspot-url-utils';
import PortalIdParser from 'PortalIdParser';
export function getEmailEditConfigurationUrl({
  inboxId,
  emailId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/edit/email/${emailId}/configuration`;
}
export function getEditFormRulesUrl({
  inboxId,
  formId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/edit/forms/${formId}`;
}
export function getInboxChannelsTableUrl({
  inboxId
}) {
  return `${getFullUrl('app')}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/channels`;
}
import { useObjects } from './useObjects';
export const useObjectDefinition = objectTypeId => {
  const {
    error,
    loading,
    objectDefinitions
  } = useObjects();
  return {
    error,
    loading,
    objectDefinition: objectDefinitions.get(objectTypeId)
  };
};
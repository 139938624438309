import { useCallback, useEffect, useState } from 'react';
import { createResetActiveCallDetailsMessage, createUpdateWidgetDetailsMessage } from 'calling-cross-tab-library/messages/ToWorkerMessages';
import { logError } from 'calling-cross-tab-library/utils/logError';
import CallingCrossTabClientError from 'calling-cross-tab-library/utils/CallingCrossTabClientError';
import { getInitialAppData } from 'calling-cross-tab-library/appData/constants/InitialAppData';
import { waitForSharedWorkerConnectionSingleton } from './waitForSharedWorkerConnectionSingleton';
import { getCallingWidgetConfig } from './getCallingWidgetConfig';
import { addAppDataUpdateListener, CALLING_APP_DATA_UPDATE, removeAppDataUpdateListener } from './appDataEvents';
import { ConnectionStatusTypes } from 'shared-worker-versioning/lib/types/ConnectionStatuses';
const canSendMessage = connectionStatus => [ConnectionStatusTypes.FAILED, ConnectionStatusTypes.UNSUPPORTED].includes(connectionStatus) === false;
export const updateWidgetDetails = (message, instance) => {
  const widgetDetailsMessage = createUpdateWidgetDetailsMessage({
    widgetDetails: message,
    originConnectionId: getCallingWidgetConfig().connectionId
  });
  if (instance && canSendMessage(instance.connectionStatus)) {
    instance.sendMessage(widgetDetailsMessage);
    return Promise.resolve();
  }
  return waitForSharedWorkerConnectionSingleton().then(asyncInstance => {
    if (asyncInstance && canSendMessage(asyncInstance.connectionStatus)) asyncInstance.sendMessage(widgetDetailsMessage);
  }).catch(error => {
    logError(new CallingCrossTabClientError({
      message: 'Error waiting for sharedWorkerConnection singleton in updateWidgetDetails'
    }), {
      extra: {
        error
      }
    });
  });
};
export const resetStandaloneConnection = instance => {
  const widgetDetailsMessage = createUpdateWidgetDetailsMessage({
    originConnectionId: getCallingWidgetConfig().connectionId,
    widgetDetails: {
      standaloneConnectionId: undefined
    }
  });
  if (instance && canSendMessage(instance.connectionStatus)) {
    instance.sendMessage(widgetDetailsMessage);
    return Promise.resolve();
  }
  return waitForSharedWorkerConnectionSingleton().then(asyncInstance => {
    if (asyncInstance && canSendMessage(asyncInstance.connectionStatus)) asyncInstance.sendMessage(widgetDetailsMessage);
  }).catch(error => {
    logError(new CallingCrossTabClientError({
      message: 'Error waiting for sharedWorkerConnection singleton in resetStandaloneConnection'
    }), {
      extra: {
        error
      }
    });
  });
};
export const resetActiveCallDetailsAndCallStatus = instance => {
  const resetWidgetDetailsMessage = createResetActiveCallDetailsMessage(getCallingWidgetConfig().connectionId);
  if (instance && canSendMessage(instance.connectionStatus)) {
    instance.sendMessage(resetWidgetDetailsMessage);
    return Promise.resolve();
  }
  return waitForSharedWorkerConnectionSingleton().then(asyncInstance => {
    if (asyncInstance && canSendMessage(asyncInstance.connectionStatus)) asyncInstance.sendMessage(resetWidgetDetailsMessage);
  }).catch(error => {
    logError(new CallingCrossTabClientError({
      message: 'Error waiting for sharedWorkerConnection singleton in resetActiveCallDetailsAndCallStatus'
    }), {
      extra: {
        error
      }
    });
  });
};
export const useWidgetDetails = instance => {
  const [widgetDetails, setWidgetDetails] = useState(getInitialAppData().widgetDetails);
  useEffect(() => {
    let mounted = true;
    if (instance) {
      setWidgetDetails(instance.appData.widgetDetails);
    } else {
      waitForSharedWorkerConnectionSingleton().then(instanceAsync => {
        if (instanceAsync && mounted) setWidgetDetails(instanceAsync.appData.widgetDetails);
      }).catch(() => {
        // nothing to do here since we already logged the error in waitForSharedWorkerConnectionSingleton.
      });
    }
    return () => {
      mounted = false;
    };
  }, [instance]);
  useEffect(() => {
    const connectionStatusUpdate = evt => {
      if (evt.type === CALLING_APP_DATA_UPDATE && evt.detail && evt.detail.appData && evt.detail.updates.length > 0) {
        const {
          detail: {
            updates,
            appData
          }
        } = evt;
        if (updates.includes('widgetDetails')) setWidgetDetails(appData.widgetDetails);
      }
    };
    addAppDataUpdateListener(connectionStatusUpdate);
    return () => {
      removeAppDataUpdateListener(connectionStatusUpdate);
    };
  }, []);
  const resetActiveCallDetailsAndCallStatusHandler = useCallback(() => resetActiveCallDetailsAndCallStatus(instance), [instance]);
  const resetStandaloneConnectionHandler = useCallback(() => resetStandaloneConnection(instance), [instance]);
  const updateWidgetDetailsHandler = useCallback(message => updateWidgetDetails(message, instance), [instance]);
  return {
    widgetDetails,
    updateWidgetDetails: updateWidgetDetailsHandler,
    resetActiveCallDetailsAndCallStatus: resetActiveCallDetailsAndCallStatusHandler,
    resetStandaloneConnection: resetStandaloneConnectionHandler
  };
};
export const useCallSettings = instance => {
  const {
    widgetDetails
  } = useWidgetDetails(instance);
  const callSettings = {
    connectFromNumbers: widgetDetails.connectFromNumbers,
    externalFromNumbers: widgetDetails.externalFromNumbers,
    portalFromNumbers: widgetDetails.portalFromNumbers,
    hasCallingAccess: widgetDetails.hasCallingAccess,
    isHubSpotCallingEnabled: widgetDetails.isHubSpotCallingEnabled,
    isRecordingEnabled: widgetDetails.isRecordingEnabled,
    isPaidHub: widgetDetails.isPaidHub
  };
  return callSettings;
};
import { useCallback, useEffect, useState } from 'react';
import { localSuperstore } from '../superstore/localSuperstore';
import { getInitialState } from '../utils/localStorageUtils';
export const useLocalSuperstore = ({
  key
}) => {
  // localSuperstoreChecked indicates whether the async check for localSuperstoreValue has completed
  const [localSuperstoreChecked, setLocalSuperstoreChecked] = useState(false);
  const [localSuperstoreValue, setLocalSuperstoreValue] = useState(null);
  useEffect(() => {
    if (!localSuperstoreChecked) {
      // TODO: Remove localStorage migration after 03/25/2025
      const migrateFromLocalStorage = () => {
        const legacyValue = getInitialState(key, 'false');
        if (legacyValue && localSuperstore) {
          localSuperstore.setItem(key, legacyValue);
          return legacyValue;
        }
        return null;
      };
      if (localSuperstore) {
        localSuperstore.get(key).then(result => {
          // If no value in superstore, check localStorage for migration
          if (!result) {
            const migratedValue = migrateFromLocalStorage();
            if (migratedValue) {
              setLocalSuperstoreValue(migratedValue);
            }
          } else {
            setLocalSuperstoreValue(result);
          }
          setLocalSuperstoreChecked(true);
        }).catch(error => {
          throw error;
        });
      }
    }
  }, [key, localSuperstoreChecked]);
  const handleSetLocalSuperstoreValue = useCallback(value => {
    if (localSuperstore) {
      localSuperstore.setItem(key, value);
      setLocalSuperstoreValue(value);
    }
  }, [key]);
  return {
    localSuperstoreChecked,
    localSuperstoreValue,
    handleSetLocalSuperstoreValue
  };
};
// adapted from: https://git.hubteam.com/HubSpot/ui-addon-conversations-settings/blob/3c89aee1836bb1efd278f39f1bb316d4f77c6c81/static/js/users-and-teams/operators/getTextFromSearchObject.ts

const getNodeText = node => {
  if (typeof node === 'string') {
    return node;
  }
  if (typeof node === 'number' || typeof node === 'boolean') {
    return `${node}`;
  }
  if (!node || !('props' in node) || !('children' in node.props)) {
    return '';
  }
  if (Array.isArray(node.props.children)) {
    return node.props.children.map(childNode => getNodeText(childNode)).join('');
  }
  return getNodeText(node.props.children);
};
export const getTextFromSearchObject = word => getNodeText(word.text);
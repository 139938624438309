import { useQuery } from '@apollo/client';
import { FETCH_PAYMENTS_ELIGIBILITY } from '../graphQL/PaymentsEligibilityQueries';
export const usePaymentsEligibility = ({
  skip
} = {
  skip: false
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_PAYMENTS_ELIGIBILITY, {
    skip
  });
  const paymentsEligibility = (data === null || data === void 0 ? void 0 : data.paymentsEligibility) || undefined;
  return {
    loading,
    error,
    paymentsEligibility
  };
};
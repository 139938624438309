import { ONBOARDING_SCOPES } from 'onboarding-scopes/scopeConstants';
import { CHANNEL_MANAGER_EXP_CALLING_TRIALS } from '../constants/CallingGates';
import { getLimit, hasGate } from './auth';
import { getOwnsAnyPaidParcel, getOwnsPaidPortal } from './scopeUtils';
export const hasCallingMinutesPerPortal = () => {
  // Trials will get calling minutes, but we don't want trials
  // without a paid parcel to have calling minutes.
  const isUngatedForTrials = hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS);
  const hasRequiredPaidParcel = isUngatedForTrials ? getOwnsAnyPaidParcel() : getOwnsPaidPortal([ONBOARDING_SCOPES.SALES_STARTER, ONBOARDING_SCOPES.SERVICE_STARTER, ONBOARDING_SCOPES.SALES_PRO, ONBOARDING_SCOPES.SERVICE_PRO, ONBOARDING_SCOPES.SALES_ENTERPRISE, ONBOARDING_SCOPES.SERVICE_ENTERPRISE]);
  if (!hasRequiredPaidParcel) {
    return false;
  }
  const callingMinutesPerPortal = getLimit('calling-minutes-per-portal');
  return callingMinutesPerPortal ? callingMinutesPerPortal > 0 : false;
};
export const getMaximumCallingChannelsPerPortal = () => {
  return getLimit('calling-maximum-calling-channels-per-portal');
};
import { COMMUNICATION_SUMMARY, COMPANY_NEWS, COMPANY_TECH, OBJECT_ASSOCIATIONS, OBJECT_ENGAGEMENTS_SUMMARY, OBJECT_SUMMARY, OBJECT_UPDATE, SUMMARIZE_ACTIVITY_FEED, WORKFLOW_CREATE, INVOICES_CREATE, HUBSPOT_HOW_TO } from '../constants/CopilotInAppActionTypes';
import { objectSummaryActionTextParser } from './actionTextParsers/objectSummaryActionTextParser';
import { objectEngagementsSummaryActionTextParser } from './actionTextParsers/objectEngagementsSummaryActionTextParser';
import { companyTechActionTextParser } from './actionTextParsers/companyTechActionTextParser';
import { objectUpdateActionTextParser } from './actionTextParsers/objectUpdateActionTextParser';
import { summarizeActivityFeedActionTextParser } from './actionTextParsers/summarizeActivityFeedActionTextParser';
import { workflowCreateActionTextParser } from './actionTextParsers/workflowCreateActionTextParser';
import { companyNewsActionTextParser } from './actionTextParsers/companyNewsActionTextParser';
import { communicationSummaryActionTextParser } from './actionTextParsers/communicationSummaryActionTextParser';
import { invoicesCreateActionTextParser } from './actionTextParsers/invoicesCreateActionTextParser';
import { hubspotHowToActionTextParser } from './actionTextParsers/hubspotHowToActionTextParser';
export const getActionText = action => {
  const rawAction = action.raw_action;
  if (!rawAction) {
    return '';
  }
  switch (rawAction.intent) {
    case COMPANY_NEWS:
      return companyNewsActionTextParser(rawAction);
    case OBJECT_SUMMARY:
      return objectSummaryActionTextParser(rawAction);
    case OBJECT_ASSOCIATIONS:
      return objectEngagementsSummaryActionTextParser(rawAction);
    case OBJECT_ENGAGEMENTS_SUMMARY:
      return objectEngagementsSummaryActionTextParser(rawAction);
    case COMMUNICATION_SUMMARY:
      return communicationSummaryActionTextParser(rawAction);
    case COMPANY_TECH:
      return companyTechActionTextParser(rawAction);
    case OBJECT_UPDATE:
      return objectUpdateActionTextParser(rawAction);
    case SUMMARIZE_ACTIVITY_FEED:
      return summarizeActivityFeedActionTextParser(rawAction);
    case WORKFLOW_CREATE:
      return workflowCreateActionTextParser(rawAction);
    case INVOICES_CREATE:
      return invoicesCreateActionTextParser(rawAction);
    case HUBSPOT_HOW_TO:
      return hubspotHowToActionTextParser(rawAction);
    default:
      return '';
  }
};
import { useFileUploads } from 'composer-data/file-uploads/public/hooks';
import { useEditorCapability } from 'composer-data/channel-capabilities/public/hooks';
import { DELETE_FILE_UPLOAD_MUTATION } from 'composer-data/file-uploads/public/mutations';
import { useMutation } from 'data-fetching-client';
export const useAttachmentAndFileUploads = ({
  onUpload,
  onError,
  threadId
}) => {
  const allowedFileAttachmentMimeTypes = useEditorCapability('allowedFileAttachmentMimeTypes') || [];
  const maxFileAttachmentCount = useEditorCapability('maxFileAttachmentCount') || 0;
  const maxFileAttachmentSizeBytes = useEditorCapability('maxFileAttachmentSizeBytes') || 0;
  const maxTotalFileAttachmentSizeBytes = useEditorCapability('maxTotalFileAttachmentSizeBytes') || 0;
  const allowInlineImages = useEditorCapability('allowInlineImages');
  const outgoingAttachmentTypes = useEditorCapability('outgoingAttachmentTypes');
  const allowOutgoingFileAttachment = outgoingAttachmentTypes.includes('FILE');
  const {
    indexedAsyncFileUploads,
    removeFileUpload,
    uploadFile
  } = useFileUploads({
    threadId,
    maxFileSize: maxFileAttachmentSizeBytes,
    fileTypeToOverride: allowedFileAttachmentMimeTypes,
    onUpload,
    onError
  });
  const [deleteFileUpload] = useMutation(DELETE_FILE_UPLOAD_MUTATION);
  return {
    indexedAsyncFileUploads,
    removeFileUpload,
    uploadFile,
    deleteFileUpload,
    maxFileLimit: maxFileAttachmentCount,
    maxTotalFileSizeLimit: maxTotalFileAttachmentSizeBytes,
    hasImageCapabilities: allowInlineImages,
    hasAttachmentCapabilities: allowOutgoingFileAttachment
  };
};
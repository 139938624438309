import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { optimisticUpdateThreadListMember } from '../viewMemberActions';
export const useOptimisticUpdateThreadListMember = () => {
  const dispatch = useDispatch();
  const update = useCallback(payload => {
    dispatch(optimisticUpdateThreadListMember(payload));
  }, [dispatch]);
  return {
    update
  };
};
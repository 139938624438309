import { rateLimitExceededErrorStatus } from '../utils/invoiceEmail';
const useSendInvoice = (setIsModalOpen, onSuccess, onFailure, onRateLimitExceededFailure) => {
  const handleSendEmailModalOnClose = ({
    success,
    error,
    fatalError
  }) => {
    if (success) {
      onSuccess();
    } else if (error || fatalError) {
      if (error && error.status === rateLimitExceededErrorStatus) {
        onRateLimitExceededFailure();
      } else {
        onFailure();
      }
    }
    setIsModalOpen(false);
  };
  return {
    handleSendEmailModalOnClose
  };
};
export default useSendInvoice;
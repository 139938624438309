import { localStorage } from 'find-and-filter-data/common/protected';
export const defaultSortState = {
  sortDirection: 'none'
};
function parseSortState(sortState) {
  try {
    return JSON.parse(sortState);
  } catch (e) {
    return {};
  }
}
export function persistSortState(sorts, viewId) {
  const savedSortState = parseSortState(localStorage.getItem('SORT_STATE', '{}'));
  if (viewId) {
    savedSortState[viewId] = sorts;
  }
  localStorage.setItem('SORT_STATE', JSON.stringify(savedSortState));
}
export function getPersistedSortState(viewId) {
  const savedSortState = parseSortState(localStorage.getItem('SORT_STATE', '{}'));
  if (viewId && savedSortState[viewId]) {
    return savedSortState[viewId];
  }
  return defaultSortState;
}
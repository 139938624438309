import unescapedText from 'I18n/utils/unescapedText';
let emptyGroup;
export const getEmptyGroup = () => {
  emptyGroup = emptyGroup || {
    disabled: true,
    text: unescapedText('salesUI.UITypeahead.noOptions'),
    value: '__EMPTY_GROUP__'
  };
  return emptyGroup;
};
let noMatchesOption;
export const getNoMatchesOption = () => {
  noMatchesOption = noMatchesOption || {
    disabled: true,
    groupIdx: -1,
    text: unescapedText('salesUI.UITypeahead.noMatchesFound'),
    value: '__EMPTY_OPTION__'
  };
  return noMatchesOption;
};
export const getSearchRegExp = str => {
  return new RegExp(str.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
};
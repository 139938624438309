import I18n from 'I18n';
import Visitor from 'conversations-internal-schema/visitor/records/Visitor';
import { EMAIL_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID, CALLING_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import EmailMetadata from 'conversations-message-history/common-message-format/records/EmailMetadata';
import isRecord from 'transmute/isRecord';
import { getViewMemberId } from '../view-member/getViewMemberId';
import { isInboxViewMember } from '../view-member/isInboxViewMember';
import { buildThreadListMember } from './buildThreadListMember';
import { isThreadListMember } from './isThreadListMember';
const mapTicketSourceToGenericChannelId = ticketSource => {
  const map = {
    CHAT: LIVE_CHAT_GENERIC_CHANNEL_ID,
    EMAIL: EMAIL_GENERIC_CHANNEL_ID,
    FORM: FORMS_GENERIC_CHANNEL_ID,
    PHONE: CALLING_GENERIC_CHANNEL_ID
  };
  return ticketSource in map ? map[ticketSource] : ticketSource;
};

/**
 * @deprecated Do not use! Only for temporary use.
 */

export function buildTlmFromViewMember(viewMember, currentAgentId) {
  //If we're being provided a TLM, just return it.
  if (isThreadListMember(viewMember)) {
    const tlm = isRecord(viewMember) ? viewMember : buildThreadListMember(viewMember);
    const isSeenMissing = tlm.seen === undefined || tlm.seen === null;
    if (isSeenMissing && currentAgentId && viewMember.seenByAgentIds) {
      return tlm.set('seen', viewMember.seenByAgentIds.includes(currentAgentId));
    }
    return tlm;
  }

  //Otherwise, construct a TLM record.
  else if (isInboxViewMember(viewMember)) {
    var _viewMember$text, _viewMember$latestMes;
    const agentType = viewMember.viewMemberOwner && viewMember.viewMemberOwner.previewCardOwnerType === 'BOT' ? 'BOT' : 'HUMAN';
    const assignee = viewMember.viewMemberOwner ? {
      agentId: viewMember.viewMemberOwner.id,
      agentType: agentType
    } : null;
    const associatedTicketIds = viewMember.associatedTicketObjectKey ? [viewMember.associatedTicketObjectKey.objectId] : [];
    const emailMetadata = viewMember.genericChannelId === EMAIL_GENERIC_CHANNEL_ID ? EmailMetadata({
      subject: viewMember.subject,
      forward: viewMember.forwarded
    }) : null;
    const seen = currentAgentId !== undefined ? viewMember.seenByAgentIds.includes(currentAgentId) : undefined;
    return buildThreadListMember({
      assignee,
      associatedTicketIds,
      channels: viewMember.pubSubChannels,
      cvCrmObjectId: viewMember.objectKey.objectId,
      filteredSource: viewMember.filteredSource,
      // @ts-expect-error goalDetails type mismatch while we migrate off GoalDetails enum
      goalDetails: viewMember.goalDetails,
      latestMessagePreview: {
        emailMetadata,
        attachmentTypes: viewMember.attachmentTypes,
        failed: viewMember.hasLatestMessageFailed,
        hasFileAttachment: viewMember.attachmentTypes.includes('FILES'),
        isThreadComment: viewMember.latestMessageThreadComment,
        isLatestMessageADraft: viewMember.latestMessageADraft,
        text: (_viewMember$text = viewMember.text) !== null && _viewMember$text !== void 0 ? _viewMember$text : null,
        timestamp: viewMember.latestMessageTimestamp,
        sender: (_viewMember$latestMes = viewMember.latestMessageSender) === null || _viewMember$latestMes === void 0 ? void 0 : _viewMember$latestMes.sender
      },
      latestMessageTimestamp: viewMember.latestMessageTimestamp,
      latestReceivedTimestamp: viewMember.latestReceivedTimestamp,
      latestSentTimestamp: viewMember.latestSentTimestamp,
      originalChannelInstanceId: viewMember.genericChannelInstanceId,
      originalGenericChannelId: viewMember.genericChannelId,
      seen,
      seenByAgentIds: viewMember.seenByAgentIds,
      sortTimestamp: viewMember.sortTimestamp,
      status: viewMember.threadStatus,
      threadId: getViewMemberId(viewMember),
      vid: viewMember.vid,
      visitor: viewMember.visitor && Visitor(viewMember.visitor) || undefined
    });
  } else {
    var _viewMember$text2;
    //if is HelpDeskViewMember
    const agentType = viewMember.viewMemberOwner && viewMember.viewMemberOwner.previewCardOwnerType === 'BOT' ? 'BOT' : 'HUMAN';
    const assignee = viewMember.viewMemberOwner ? {
      agentId: viewMember.viewMemberOwner.id,
      agentType: agentType
    } : null;
    const emailMetadata = EmailMetadata({
      subject: viewMember.subject,
      forward: false
    });
    const seen = currentAgentId !== undefined ? viewMember.seenByAgentIds.includes(currentAgentId) : undefined;
    const sourceProperty = viewMember.objectPropertyMap['0-5']['source_type'];
    return buildThreadListMember({
      assignee,
      associatedTicketIds: [+getViewMemberId(viewMember)],
      channels: viewMember.pubSubChannels,
      cvCrmObjectId: viewMember.objectKey.objectId,
      filteredSource: 'NOT_FILTERED',
      // @ts-expect-error goalDetails type mismatch while we migrate off GoalDetails enum
      goalDetails: viewMember.goalDetails,
      latestMessagePreview: {
        emailMetadata,
        attachmentTypes: [],
        failed: false,
        hasFileAttachment: false,
        isThreadComment: viewMember.latestMessageThreadComment,
        isLatestMessageADraft: viewMember.latestMessageADraft,
        text: viewMember.associatedConversations[0] ? (_viewMember$text2 = viewMember.text) !== null && _viewMember$text2 !== void 0 ? _viewMember$text2 : null : I18n.text('find-and-filter-components.preview-card.conversation-not-started'),
        timestamp: viewMember.latestMessageTimestamp || null,
        sender: {
          '@type': 'AGENT_SENDER',
          id: 0
        }
      },
      latestMessageTimestamp: viewMember.latestMessageTimestamp,
      latestReceivedTimestamp: viewMember.latestReceivedTimestamp,
      latestSentTimestamp: viewMember.latestSentTimestamp,
      originalChannelInstanceId: null,
      originalGenericChannelId: sourceProperty ? mapTicketSourceToGenericChannelId(sourceProperty) : undefined,
      seen,
      seenByAgentIds: viewMember.seenByAgentIds,
      status: 'STARTED',
      sortTimestamp: viewMember.sortTimestamp,
      threadId: +getViewMemberId(viewMember),
      vid: viewMember.associatedContacts[0] && viewMember.associatedContacts[0].vid || null,
      visitor: viewMember.associatedContacts[0] && Visitor(viewMember.associatedContacts[0]) || undefined
    });
  }
}
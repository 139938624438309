import { registerQuery, useQuery } from 'data-fetching-client';
import { getAccess } from '../clients/Access';
const FETCH_AI_SETTINGS_ACCESS = registerQuery({
  fieldName: 'aiAccessControls',
  fetcher: getAccess
});
export const useFetchAiSettingsAccess = () => {
  const {
    loading,
    data
  } = useQuery(FETCH_AI_SETTINGS_ACCESS, {
    fetchPolicy: 'cache-and-network',
    onError: error => console.error('AI access controls request failed', error)
  });
  const accessControls = (data === null || data === void 0 ? void 0 : data.aiAccessControls) || {};
  return Object.assign({
    generativeAiEnabled: false,
    copilotEnabled: false,
    fileDataEnabled: false,
    crmPropertiesEnabled: false,
    conversationDataEnabled: false
  }, accessControls, {
    isAccessRequestLoading: loading
  });
};
import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { handleError } from '../../utils/errorHandling';
// API docs: https://tools.hubteam.com/api-catalog/services/InvoicesService/v1/spec/internal#operations-Settings-get-%2Finvoices%2Fv1%2Fsettings%2Fpresets%2Fdefault
export const GET_DEFAULT_INVOICE_PRESET = registerQuery({
  fieldName: 'presetResponse',
  args: [],
  fetcher: () => http.get('invoices/v1/settings/presets/default').catch(err => {
    handleError(err);
    return {};
  })
});
export function useFetchInvoicePreset(options = {}) {
  return useQuery(GET_DEFAULT_INVOICE_PRESET, options);
}
import { COMMUNICATOR, NAVIGATION } from 'calling-integration-sdk-support/types/IframeLocation';
export const PRE_CALL_FOOTER = 'pre call footer';
export const CALLEE_NUMBERS = 'callee numbers';
export const NO_CALLING_ACCESS_ALERT = 'no calling access alert';
export const UPGRADE_ALERT = 'upgrade alert';
export const HUBSPOT_HEADER = 'hubspot header';
export const THIRD_PARTY_HEADER = 'third party header';
export const communicatorLocations = [PRE_CALL_FOOTER, CALLEE_NUMBERS];
export const callingRemoteAppLocations = [NO_CALLING_ACCESS_ALERT, UPGRADE_ALERT, HUBSPOT_HEADER, THIRD_PARTY_HEADER];
export const getButtonSource = location => {
  if (communicatorLocations.includes(location)) {
    return COMMUNICATOR;
  }
  return NAVIGATION;
};
import { isMultienum } from 'customer-data-objects/property/PropertyIdentifier';
/**
 * Compares two property values for equality
 *
 * 1. (!a && !b) handles all "empty" cases:
 *    - undefined === undefined
 *    - null === null
 *    - '' === ''
 *    - undefined === null
 *    - undefined === ''
 *    - null === ''
 *    These are all considered equivalent empty values
 *
 * 2. (a === b) handles the case where both values are actual strings
 *    Using strict equality ensures the strings match exactly
 *
 * 3. If the property is a multienum, we sort the values and compare them
 *
 * So two values are considered the same if:
 * - They are both empty values (null/undefined/'')
 * - OR they are the exact same string
 * - OR they are multienum values that contain the same set of values, regardless of order
 */
export const areEqualPropertyValues = (a, b, property) => {
  const isEqual = !a && !b || a === b;
  if (isMultienum(property)) {
    return isEqual || (a === null || a === void 0 ? void 0 : a.split(';').sort().join('')) === (b === null || b === void 0 ? void 0 : b.split(';').sort().join(''));
  }
  return isEqual;
};
import { LINE_ITEM_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import PortalIdParser from 'PortalIdParser';
import memoize from 'transmute/memoize';
export const sanitizeLineItemProperties = memoize((properties, blockList) => {
  return Object.entries(properties).filter(([name]) => !blockList.includes(name)).reduce((acc, [propertyName, {
    name,
    value
  }]) => {
    acc[propertyName] = {
      name,
      value
    };
    return acc;
  }, {});
});
export const convertLineItemToAssociatedObject = (lineItem, propertyBlockList) => ({
  objectId: lineItem.objectId,
  objectTypeId: LINE_ITEM_TYPE_ID,
  portalId: PortalIdParser.get(),
  properties: propertyBlockList ? sanitizeLineItemProperties(lineItem.properties, propertyBlockList) : lineItem.properties
});
import { useCallback, useState } from 'react';
import { patchInvoice } from '../api/InvoicesAPI';
import { InvoiceStatus } from '../constants/status';
const useVoidInvoice = (invoice, setIsModalOpen, refetchInvoice, onSuccess, onFailure, onFailureGeneric) => {
  const [isLoading, setIsLoading] = useState(false);
  const onConfirmVoidInvoice = useCallback(() => {
    if (!invoice.objectId) {
      onFailureGeneric();
      setIsModalOpen(false);
      return;
    }
    setIsLoading(true);
    patchInvoice(invoice.objectId, [{
      name: 'hs_invoice_status',
      value: InvoiceStatus.VOIDED
    }]).then(() => {
      refetchInvoice(invoice.objectId).then(() => {
        onSuccess();
      }).catch(() => {
        onFailureGeneric();
      });
    }).catch(() => {
      onFailure();
    }).finally(() => {
      setIsLoading(false);
      setIsModalOpen(false);
    });
  }, [invoice.objectId, refetchInvoice, onFailure, onFailureGeneric, onSuccess, setIsModalOpen]);
  return {
    voidLoading: isLoading,
    onConfirmVoidInvoice
  };
};
export default useVoidInvoice;
import http from 'conversations-http/clients/http';
import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { gate } from 'hub-http/gates';
export function isGetSearchMembersResponse(response) {
  return 'viewMemberPagedResult' in response;
}
export const DEFAULT_LIMIT = 100;
export const fetchHelpDeskViewMembersService = ({
  crmOffset,
  customViewId,
  filterGroups,
  inboxId,
  gates,
  limit = DEFAULT_LIMIT,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection = 'DESC',
  sortProperty
}) => {
  if (gates !== null && gates !== void 0 && gates.includes(gate('HelpDesk:CrmOffsetPagination'))) {
    return http.post('/conversations-custom-views/v1/members/page', {
      data: {
        crmOffset,
        customViewId,
        filterGroups,
        inboxId,
        limit,
        objectTypeId: TICKET_TYPE_ID,
        offsetId,
        maxPreviewLength: 200,
        searchQuery,
        sortDirection,
        userRequestedSortProperty: sortProperty,
        includeHiddenFromVisitor: true
      }
    });
  }
  return http.post('/conversations-custom-views/v1/members/search', {
    data: {
      customViewId,
      filterGroups,
      inboxId,
      limit,
      objectTypeId: TICKET_TYPE_ID,
      offsetTimestamp,
      offsetId,
      primaryOffsetValue,
      secondaryOffsetValue,
      maxPreviewLength: 200,
      searchQuery,
      sortDirection,
      userRequestedSortProperty: sortProperty,
      includeHiddenFromVisitor: true
    }
  });
};
import { findDOMNode } from 'react-dom';
import { computeControlWidth } from './Select.helpers';
export const createRefCallbacks = component => ({
  dropdownContentRefCallback: dropdownEl => {
    if (dropdownEl) {
      dropdownEl.addEventListener('mousedown', component.handleMouseDownOnDropdown);
    }
  },
  virtualizedMenuRendererRefCallback: ref => {
    if (ref) {
      component._virtualizedMenuRenderer = ref;
    }
  },
  anchorRefCallback: anchorRef => {
    var _component$props$open;
    const {
      controlWidth,
      target
    } = component.state;
    component._anchorRef = anchorRef;
    /* eslint-disable-next-line react/no-find-dom-node */
    const newTarget = findDOMNode(component._anchorRef);
    const newControlWidth = computeControlWidth(newTarget, (_component$props$open = component.props.open) !== null && _component$props$open !== void 0 ? _component$props$open : false);
    if (target !== newTarget || controlWidth !== newControlWidth) {
      component.setState({
        controlWidth: newControlWidth,
        target: newTarget
      });
    }
  },
  selectRefCallback: () => {
    // Hackily override Select's selectValue and handleMouseDownOnMenu to support "Load more"
    const internalSelect = component.getInternalSelect();
    if (!internalSelect) return;
    internalSelect.selectValue = component.selectValueProxy.bind(component, internalSelect.selectValue);
    const internalCreatableSelect = component.getInternalCreatableSelect();
    if (!internalCreatableSelect) return;
    // TODO(TS): replace this with a better check once we're okay with changing behavior
    internalCreatableSelect.onInputChange = component.creatableOnInputChangeProxy;
  }
});
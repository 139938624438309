export const sortPaymentMethods = ({
  paymentMethod: paymentMethodA
}, {
  paymentMethod: paymentMethodB
}) => {
  // CARD should always be listed first
  if (paymentMethodA === 'CARD') {
    return -1;
  }
  if (paymentMethodB === 'CARD') {
    return 1;
  }

  // All other payment methods are sorted alphabetically
  return paymentMethodA < paymentMethodB ? -1 : paymentMethodA > paymentMethodB ? 1 : 0;
};

/*  Removes payment methods that require additional verification before they can be used.
 *  A payment method has additional verification requirements when its capabilityStatus is not "ACTIVE".
 *
 *  The exception to this is when Stripe has disabled charges (i.e. cardCapabilityStatus is not "ACTIVE").
 *  In this case, all payment method capabilities are not "ACTIVE", but we want to ignore capabilityStatus
 *  and make all payment methods available for use.
 *  This allows users to continue managing payment method settings while working with Stripe to re-enable their charges or in "flip the funnel."
 *
 *  We only want to remove payment methods when they are disabled due to additional verification requirements
 *  https://docs.stripe.com/payments/sepa-debit#verification-requirements
 *  https://docs.stripe.com/payments/payment-methods/bacs-debit#verification-requirements
 * */
export const removePaymentMethodsWithAdditionalVerificationRequirements = (paymentMethod, idx, paymentMethods) => {
  const cardPaymentMethod = paymentMethods.find(pm => pm.paymentMethod === 'CARD');
  if (paymentMethod.capabilityStatus !== 'ACTIVE' && cardPaymentMethod && cardPaymentMethod.capabilityStatus === 'ACTIVE') {
    return false;
  }
  return true;
};
import { useCallback } from 'react';
import { gate } from 'hub-http/gates';
import { useQuery } from 'data-fetching-client';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { isAppObjectTypeId } from 'customer-data-objects/crmObject/isAppObjectTypeId';
import { CRM_PAGE_EDITOR_APP_SETTINGS } from '../data/FrameworkBuilderService';
import { CUSTOMIZABLE_LOCATIONS } from '../data/static/CustomizableLocations';

/**
 * Custom hook to get the list of enabled locations for a specific object type from the crmPageEditor Framework App Settings.
 * For HubSpot-defined object types, this checks FAS to determine which locations are enabled.
 * For portal-specific and App object types, all customizable locations are enabled by default.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string[]} params.scopes - The scopes to check.
 * @param {string[]} params.gates - The gates to check.
 * @returns {Object} - An object containing:
 *   - `getEnabledLocationsForObjectType` (function): Function that takes an object type and returns enabled locations
 *   - `loading` (boolean): Whether the app settings data is currently loading.
 *   - `error` (Error | undefined): Any error that occurred while fetching the app settings data.
 *
 * @example
 * ```ts
 * const { getEnabledLocationsForObjectType, loading, error } = useEnabledLocationsForObjectType({
 *   scopes: [...],
 *   gates: [...],
 * });
 *
 * const locations = getEnabledLocationsForObjectType('0-5');
 * console.log(locations);
 * // [
 * //   'CRM_OBJECT_PREVIEW',
 * //   'HELPDESK_SIDEBAR',
 * //   'CRM_RECORD_PAGE',
 * // ]
 * ```
 */
export const useEnabledLocationsForObjectType = ({
  scopes,
  gates
}) => {
  const {
    data: appSettingsData,
    loading: appSettingsLoading,
    error: appSettingsError
  } = useQuery(CRM_PAGE_EDITOR_APP_SETTINGS);
  const getEnabledLocationsForObjectType = useCallback(objectTypeId => {
    var _enabledObjectTypeSet, _enabledObjectTypeSet2;
    if (isPortalSpecificObjectType(objectTypeId) || isAppObjectTypeId(objectTypeId)) {
      // For non-HubSpot defined object types, return all customizable locations
      // since they don't have gates/scopes restrictions
      return [...CUSTOMIZABLE_LOCATIONS];
    }
    if (appSettingsLoading || appSettingsError || !appSettingsData) {
      return [];
    }
    const enabledObjectTypeSettings = Object.values(appSettingsData.crmPageEditorAppSettings).find(settings => {
      var _settings$crmPageEdit, _settings$crmPageEdit2;
      return ((_settings$crmPageEdit = settings.crmPageEditor) === null || _settings$crmPageEdit === void 0 || (_settings$crmPageEdit = _settings$crmPageEdit.metadata.objectTypeId) === null || _settings$crmPageEdit === void 0 ? void 0 : _settings$crmPageEdit.value) === objectTypeId && ((_settings$crmPageEdit2 = settings.crmPageEditor) === null || _settings$crmPageEdit2 === void 0 || (_settings$crmPageEdit2 = _settings$crmPageEdit2.metadata.enabled) === null || _settings$crmPageEdit2 === void 0 ? void 0 : _settings$crmPageEdit2.value);
    });
    const enabledLocations = (_enabledObjectTypeSet = enabledObjectTypeSettings === null || enabledObjectTypeSettings === void 0 || (_enabledObjectTypeSet2 = enabledObjectTypeSettings.crmPageEditor) === null || _enabledObjectTypeSet2 === void 0 || (_enabledObjectTypeSet2 = _enabledObjectTypeSet2.metadata.enabledLocations) === null || _enabledObjectTypeSet2 === void 0 ? void 0 : _enabledObjectTypeSet2.value) !== null && _enabledObjectTypeSet !== void 0 ? _enabledObjectTypeSet : [];
    return enabledLocations.filter(({
      gates: locationGates,
      scopes: locationScopes
    }) => {
      const isUngatedForLocation = !locationGates || locationGates.every(locationGate => gates.includes(locationGate) ||
      // eslint-disable-next-line hubspot-dev/no-bare-gate-strings
      gates.includes(gate(locationGate)));
      const isScopedForLocation = !locationScopes || locationScopes.every(scope => scopes.includes(scope));
      return isUngatedForLocation && isScopedForLocation;
    }).map(({
      location
    }) => location);
  }, [appSettingsData, appSettingsLoading, appSettingsError, gates, scopes]);
  return {
    getEnabledLocationsForObjectType,
    loading: appSettingsLoading,
    error: appSettingsError
  };
};
function textEncode(str) {
  const utf8 = unescape(encodeURIComponent(str));
  const result = new Uint8Array(utf8.length);
  for (let i = 0; i < utf8.length; i++) {
    result[i] = utf8.charCodeAt(i);
  }
  return result;
}
function getTruncatedHash(hashBuffer) {
  // convert buffer to byte array
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  // convert bytes to hex string
  const hashHex = hashArray.map(b => b.toString(16)).join('');
  // truncate hash to 80 bits (20 hex digits)
  return hashHex.slice(0, 20);
}
export function generateDefaultSlug() {
  const textEncodedSeed = textEncode(`${Date.now()}-${Math.random()}`);
  if (window.crypto) {
    return window.crypto.subtle.digest('SHA-1', textEncodedSeed).then(getTruncatedHash);
  }
  const random20DigitInt = Math.floor(Math.random() * 100000000000000000000);
  return new Promise(resolve => resolve(random20DigitInt.toString()));
}
import { userInfoSync } from 'hub-http/userInfo';
const AI_ACCESS_CONTROLS_WRITE_SCOPE = 'ai-access-controls-write';
const getScopes = () => {
  try {
    const auth = userInfoSync();
    return auth.user.scopes;
  } catch (err) {
    return [];
  }
};
export const useIsSuperAdmin = () => {
  const scopes = getScopes();
  return scopes.includes(AI_ACCESS_CONTROLS_WRITE_SCOPE);
};
export const AI_ASSISTANT = 'AI_ASSISTANT';
export const AI_SUMMARY = 'AI_SUMMARY';
export const CONTENT_ASSISTANT = 'CONTENT_ASSISTANT';
export const BOLD = 'BOLD';
export const ITALIC = 'ITALIC';
export const UNDERLINE = 'UNDERLINE';
export const CLEAR_FORMATTING = 'CLEAR_FORMATTING';
export const SNIPPETS = 'SNIPPETS';
export const TEMPLATES = 'TEMPLATES';
export const TEXT_ALIGN = 'TEXT_ALIGN';
export const LISTS = 'LISTS';
export const BLOCKQUOTE = 'BLOCKQUOTE';
export const TEXT_HIGHLIGHT = 'TEXT_HIGHLIGHT';
export const TEXT_COLOR = 'TEXT_COLOR';
export const FONT_STYLE = 'FONT_STYLE';
export const FONT_SIZE = 'FONT_SIZE';
export const GLOBAL_VARS = 'GLOBAL_VARS';
export const LINK = 'LINK';
export const KNOWLEDGE_ARTICLE = 'KNOWLEDGE_ARTICLE';
export const FILE_ATTACHMENTS = 'FILE_ATTACHMENTS';
export const INLINE_IMAGE = 'INLINE_IMAGE';
export const RENDER_TRAY = 'RENDER_TRAY';
export const RENDER_SUBMIT_BUTTON = 'RENDER_SUBMIT_BUTTON';
export const FILE_DROP = 'FILE_DROP';
export const VIDEO_CONFERENCE_LINK = 'VIDEO_CONFERENCE_LINK';
export const VIDEO_INSERTION = 'VIDEO_INSERTION';
export const STRIKETHROUGH = 'STRIKETHROUGH';
export const MEETINGS = 'MEETINGS';
export const DOCUMENTS = 'DOCUMENTS';
export const QUOTES = 'QUOTES';
export const KEYMAP = 'KEYMAP';
export const HORIZONTAL_RULE = 'HORIZONTAL_RULE';
export const PASTE_OVERRIDE = 'PASTE_OVERRIDE';
export const PAYMENT_LINK = 'PAYMENT_LINK';
export const TRACKING = 'TRACKING';
export const EMOJI = 'EMOJI';
export const INVOICES = 'INVOICES';
export const SIGNATURE = 'SIGNATURE';
export const INLINE_EDITOR_AI = 'INLINE_EDITOR_AI';
export const TOOLBAR_CUSTOMIZATION = 'TOOLBAR_CUSTOMIZATION';
export const CONTENT_ASSISTANT_INLINE_MENU = 'CONTENT_ASSISTANT_INLINE_MENU';
export const CONTENT_ASSISTANT_SLASH_COMMAND = 'CONTENT_ASSISTANT_SLASH_COMMAND';
export const PERSONALIZATION_TOKEN = 'PERSONALIZATION_TOKEN';
export const DRAFT_NOT_SAVED = 'DRAFT_NOT_SAVED';
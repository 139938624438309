import { userInfoSync } from 'hub-http/userInfo';
import memoize from 'react-utils/memoize';
import { logError } from 'calling-orchestration-shared-library/error-handling/utils/logError';
let memoizedScopesAsSet = memoize(() => {
  try {
    const scopes = userInfoSync().user.scopes;
    return new Set(scopes);
  } catch (e) {
    logError(new Error('userInfoSync failed in auth.ts when resolving scopes'));
    return new Set();
  }
});
export function isFriendsAndFamilyPortal() {
  try {
    return userInfoSync().portal.friends_and_family;
  } catch (e) {
    logError(new Error('userInfoSync failed in auth.ts when resolving isFriendsAndFamilyPortal'));
    return false;
  }
}
export function hasScope(scope) {
  return memoizedScopesAsSet().has(scope);
}
export function resetMemoizedScopes() {
  memoizedScopesAsSet = memoize(() => {
    try {
      const scopes = userInfoSync().user.scopes;
      return new Set(scopes);
    } catch (e) {
      logError(new Error('userInfoSync failed in auth.ts when resolving scopes'));
      return new Set();
    }
  });
}
let memoizedGatesAsSet = memoize(() => {
  try {
    const gates = userInfoSync().gates;
    return new Set(gates);
  } catch (e) {
    logError(new Error('userInfoSync failed in auth.ts when resolving gates'));
    return new Set();
  }
});
export function hasGate(gateName) {
  return memoizedGatesAsSet().has(gateName);
}
export function getLimit(limitName) {
  try {
    return userInfoSync().portal.limits[limitName];
  } catch (e) {
    logError(new Error('userInfoSync failed in auth.ts when resolving limits'));
    return null;
  }
}
export function resetMemoizedGates() {
  memoizedGatesAsSet = memoize(() => {
    try {
      const gates = userInfoSync().gates;
      return new Set(gates);
    } catch (e) {
      logError(new Error('userInfoSync failed in auth.ts when resolving gates'));
      return new Set();
    }
  });
}
import { fetchInstallStatus } from 'ui-addon-scriptloader/installStatus/InstallStatusActions';
import InstallStatusStore from 'ui-addon-scriptloader/installStatus/InstallStatusStore';
import { NOT_INITIALIZED, INSTALLED, PENDING } from 'ui-addon-scriptloader/installStatus/InstallStatusConstants';
const installBoolKey = 'isInstalled';
function createResponse(statusCode) {
  return {
    [installBoolKey]: statusCode === INSTALLED,
    statusCode
  };
}
export function getInstallStatus(opts = {}) {
  const useCache = !!opts.useCache;
  const currentStatus = InstallStatusStore.get();
  if (useCache && currentStatus !== NOT_INITIALIZED && currentStatus !== PENDING) {
    return Promise.resolve(createResponse(currentStatus));
  }
  return fetchInstallStatus().then(() => {
    const newStatus = InstallStatusStore.get();
    return createResponse(newStatus);
  });
}
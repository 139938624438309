import http from 'hub-http/clients/apiClient';
import { createRpcClientV2 } from 'rpc-client-utils';
const withErrorRethrow = fn => async (details, inputs) => {
  try {
    const result = await fn(details, inputs);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      // This code makes sure that if the CHIRP call throws an error, we capture and rethrow it
      // so that the stack trace is attributed to routing-ui and not rpc-client-utils
      // Doing this _should_ allow errors thrown to be routed to the routing teams JS errors page
      // instead of the consuming application's while also getting a good cause stack trace for where
      // the error originated

      // This relies on how Raven is used for error reporting, so if the way we report errors changes, this
      // approach may not work as expected
      error.stack = new Error().stack;
    }
    throw error;
  }
};
const makeChirpClient = options => {
  const client = createRpcClientV2(options);
  return {
    call: withErrorRethrow(client.call),
    callWithResultUnion: client.callWithResultUnion
  };
};
export const chirpClient = makeChirpClient({
  hubHttpClient: http
});
import I18n from 'I18n';
import { getCurrencyPriceProperty } from 'customer-data-objects/lineItem/PropertyNames';
import { isMultiCurrencyPrice } from 'customer-data-objects/property/PropertyIdentifier';
import { isLineItem } from './subject';
export function getPrimaryPricePropertyName(subject, currencyCode, isMultiCurrencyActive = false) {
  return isLineItem(subject) || !isMultiCurrencyActive ? 'price' : getCurrencyPriceProperty(currencyCode);
}

/**
 * Creates a comparator function that prioritizes a specific property and then sorts alphabetically
 */
const createPriorityPropertyComparator = primaryPropertyName => (a, b) => {
  if (a.name === primaryPropertyName) return -1;
  if (b.name === primaryPropertyName) return 1;
  return a.name.localeCompare(b.name);
};

/**
 * Fetches the price property
 */
export const getPriceProperty = (properties, currencyCode) => {
  if (properties.size === 0) {
    return undefined;
  }
  const propertyName = currencyCode ? getCurrencyPriceProperty(currencyCode) : 'price';
  return properties.get(propertyName);
};

/**
 * Gets and sorts all multicurrency properties with the primary currency first
 */
export const getSortedMulticurrencyProperties = (properties, defaultCurrencyCode) => {
  if (properties.size === 0 || !defaultCurrencyCode) {
    return [];
  }
  const primaryPriceProperty = getPriceProperty(properties, defaultCurrencyCode);
  const multicurrencyProperties = [...properties.values()].filter(isMultiCurrencyPrice);
  if (multicurrencyProperties.length === 0) {
    return [];
  }
  const sortWithPrimaryCurrencyFirst = createPriorityPropertyComparator(primaryPriceProperty === null || primaryPriceProperty === void 0 ? void 0 : primaryPriceProperty.name);
  return multicurrencyProperties.sort(sortWithPrimaryCurrencyFirst);
};

/**
 * Returns the number of decimal digits for the currency. Defaults to 2.
 *
 * @param {object} currencySymbols
 * @param {string} lineItemCurrencyCode
 *
 * @returns {number} The number of decimal digits for the currency
 */
export function getDecimalDigits({
  currencyCode
}) {
  const currencySymbols = I18n.currencySymbols;
  const symbolData = currencySymbols[currencyCode];
  if (!symbolData) return 2;
  return getDecimalPrecision(currencySymbols, currencyCode);
}
export function getDecimalPrecision(currencySymbols = {}, /** @FIXME emitted-types stop allowing undefined */
lineItemCurrencyCode) {
  if (!lineItemCurrencyCode) {
    return 2;
  }

  // @ts-expect-error currencySymbols not typed
  const currencyInfo = currencySymbols[lineItemCurrencyCode];
  return currencyInfo ? currencyInfo.alternative_decimal_digits || currencyInfo.decimal_digits : 2;
}
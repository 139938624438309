import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["__typename"];
import { handleError } from './errorHandling';

/**
 * Type guard for None
 * @param nullable any value that is Nullable
 * @returns boolean
 *
 * isNone(null) -> true
 * isNone(undefined) -> true
 * isNone('') -> false
 * isNone(0) -> false
 * isNone(false) -> false
 * isNone({}) -> false
 * isNone([]) -> false */
export function isNone(nullable) {
  return nullable === null || nullable === undefined;
}

/**
 * Type guard for T
 * @param nullable any value that is Nullable
 * @returns boolean
 *
 * isSome(null) -> false
 * isSome(undefined) -> false
 * isSome('') -> true
 * isSome(0) -> true
 * isSome(false) -> true
 * isSome({}) -> true
 * isSome([]) -> true */
export function isSome(nullable) {
  return !isNone(nullable);
}
export function isError(error) {
  return error instanceof Error;
}
export function isString(x) {
  return typeof x === 'string';
}
export function isObject(x) {
  return isSome(x) && typeof x === 'object' && !Array.isArray(x);
}
export const isNumeric = value => isSome(value) && !isNaN(value) && typeof value === 'number';
export function parseNumber(value) {
  if (isNone(value) || value.trim() === '') {
    return null;
  }
  const parsed = Number(value);
  if (isNumeric(parsed)) {
    return parsed;
  }
  return null;
}
export function parseNumberOrThrow(value) {
  const parsed = parseNumber(value);
  if (isNone(parsed)) {
    throw new Error(`Could not parse number from ${value}`);
  }
  return parsed;
}

/** @deprecated use getProperty in gqlHelpers */
export function getValue(property) {
  var _property$value;
  if ((property === null || property === void 0 ? void 0 : property.value) === '') {
    return null;
  }
  return (_property$value = property === null || property === void 0 ? void 0 : property.value) !== null && _property$value !== void 0 ? _property$value : null;
}
export const valuesOf = Object.values;
export const entriesOf = Object.entries;
export const cloneOf = obj => 'structuredClone' in window && structuredClone ? structuredClone(obj) : JSON.parse(JSON.stringify(obj));
export const mapEntries = (object, mapper) => Object.fromEntries(Object.entries(object).map(([k, v]) => mapper([k, v])));

/** forces exhaustive checking in a switch statement (you will get a type error if you don't check every case) and throws a traceable error if it ever actually gets called at runtime.
 * Stick it in the default case of a switch statement to make sure you've covered all cases.  */
export function assertNever(val, message) {
  handleError(new Error(`unexpected 'never' case encountered in switch statement over ${message} for value: ${JSON.stringify(val, null, 2)}`), {
    stacktrace: true
  });
}
export const noop = () => {};
export function pick(keys, obj) {
  const result = {};
  for (const key of keys) {
    if (key in obj) {
      result[key] = obj[key];
    }
  }
  return result;
}
export function getAssociationProperty(property, data, fallback = null) {
  var _data$defaultProperti, _data$defaultProperti2;
  // @ts-expect-error cannot narrow defaultProperties[property]
  // because 'CRMObject' is a union
  return (_data$defaultProperti = data === null || data === void 0 || (_data$defaultProperti2 = data.defaultProperties) === null || _data$defaultProperti2 === void 0 || (_data$defaultProperti2 = _data$defaultProperti2[property]) === null || _data$defaultProperti2 === void 0 ? void 0 : _data$defaultProperti2.value) !== null && _data$defaultProperti !== void 0 ? _data$defaultProperti : fallback;
}
export function getAssociationProperties(data) {
  if (!(data !== null && data !== void 0 && data.defaultProperties)) {
    return {};
  }
  const _data$defaultProperti3 = data.defaultProperties,
    properties = _objectWithoutPropertiesLoose(_data$defaultProperti3, _excluded);
  return Object.keys(properties).reduce((acc, property) => {
    acc[property] = getAssociationProperty(property, data);
    return acc;
  }, {});
}
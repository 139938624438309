import { GYPSUM, KOALA, OLAF, HEFFALUMP, SLINKY } from 'HubStyleTokens/colors';
import { DEFAULT, DARK, SHADE } from './useOptions';
export const COLOUR_MAPPINGS = {
  [DEFAULT]: {
    primary: GYPSUM,
    secondary: KOALA
  },
  [SHADE]: {
    primary: KOALA,
    secondary: GYPSUM
  },
  [DARK]: {
    primary: HEFFALUMP,
    secondary: SLINKY
  }
};
export const getTokenizedColorNames = ({
  use
}) => {
  switch (use) {
    case SHADE:
      return {
        primary: 'fill-tertiary-default',
        secondary: 'fill-surface-raised'
      };
    case DARK:
      return {
        primary: 'fill-accent-neutral-default',
        secondary: 'fill-accent-neutral-hover'
      };
    case DEFAULT:
    default:
      return {
        primary: 'fill-surface-raised',
        secondary: 'fill-tertiary-default'
      };
  }
};
export const getTokenizedColors = ({
  use,
  theme
}) => {
  const {
    primary,
    secondary
  } = getTokenizedColorNames({
    use
  });
  return {
    primary: theme.color[primary],
    secondary: theme.color[secondary]
  };
};
export const BLANK_BACKGROUND = `
  background: ${OLAF};
`;
export const makeBlankBackground = ({
  theme
}) => ({
  background: theme.color['fill-surface-default']
});
export const makeGradientBackgroundCss = ({
  primary,
  secondary
}) => `
  background: ${primary};
  background: linear-gradient(to right, ${primary}, ${secondary} 20%, ${primary} 40%);
`;
import { useEffect, useMemo, useState } from 'react';
import { generateDefaultSlug } from '../utils/domains';
import { useQuery as useDFCQuery } from 'data-fetching-client';
import { FETCH_DOMAINS } from '../graphQL/DomainQueries';
import { getInvoiceProperty } from 'invoices-ui-lib/utils/property';
import { HS_DOMAIN, HS_SLUG } from 'invoices-ui-lib/constants/properties';
const useGetDerivedDomainAndSlug = ({
  invoice,
  isEditing,
  skip = false
}) => {
  const [defaultSlug, setDefaultSlug] = useState(null);
  const [slugError, setSlugError] = useState(null);
  const [shouldShowAlert, setShouldShowAlert] = useState(false);
  useEffect(() => {
    generateDefaultSlug().then(setDefaultSlug).catch(setSlugError);
  }, []);
  const {
    data: allDomains,
    error,
    startPolling,
    stopPolling
  } = useDFCQuery(FETCH_DOMAINS, {
    skip,
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.domains.length === 0) {
        startPolling(2000);
      } else {
        stopPolling();
      }
    },
    pollInterval: 0
  });
  const defaultDomain = useMemo(() => {
    if (!allDomains || allDomains.domains.length === 0) return null;
    return (allDomains.domains.find(domain => domain.isDefault) || allDomains.domains[0]).domain;
  }, [allDomains]);
  const derivedDomain = useMemo(() => {
    if (!invoice || !allDomains || !defaultDomain) return null;
    const initialDomain = getInvoiceProperty(invoice, HS_DOMAIN);
    if (!initialDomain) return defaultDomain;
    const isInitialDomainStillValid = !!allDomains.domains.find(domain => domain.domain === initialDomain);
    if (isInitialDomainStillValid) {
      return initialDomain;
    } else {
      setShouldShowAlert(true);
      return defaultDomain;
    }
  }, [allDomains, defaultDomain, invoice]);
  const derivedSlug = useMemo(() => {
    if (!invoice || !defaultSlug) return null;
    if (isEditing) {
      return getInvoiceProperty(invoice, HS_SLUG) || defaultSlug;
    }
    return defaultSlug;
  }, [invoice, isEditing, defaultSlug]);
  return {
    derivedSlug,
    derivedDomain,
    hasError: Boolean(slugError || error),
    shouldShowAlert
  };
};
export default useGetDerivedDomainAndSlug;
'use es6';

import Raven from 'raven-js';
import enviro from 'enviro';
const CPQ_FE = 'cpqFe';
// Copied from https://git.hubteam.com/HubSpot/growth-onboarding-reliability/blob/85385d9c1f7a8c3c36009135d8dd4099c36b0e3b/growth-onboarding-reliability/static-1.2501/js/utils/raven.js

// Network errors to isolate into groups regardless of URL
const ISOLATED_NETWORK_CODES = ['ABORT', 'NETWORKERROR', 'TIMEOUT', '0', '401', '500', '502'];

// taken from https://stackoverflow.com/a/3809435
const uriRegex =
// eslint-disable-next-line no-useless-escape
/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

// removes portalId or quote publicUrlKey from URL
export function stripPortalIdOrKey(str = '') {
  if (str.includes('/esign/') && !str.includes('/batch')) {
    return str.replace(new RegExp(/esign\/.*/, 'g'), 'esign/<portal_id_or_key>');
  }
  const portalIdOrKeyRegex = new RegExp(/\/[0-9]+/, 'g');
  return str.replace(portalIdOrKeyRegex, '/<portal_id_or_key>');
}
export function cleanseUrl(url = '') {
  return stripPortalIdOrKey(stripQueryParams(url));
}
function stripQueryParams(url = '') {
  return url.replace(/\?.*$/, '');
}
function splitIntoLines(str = '') {
  return str.split(/\r?\n/g);
}
function containsUri(str = '') {
  return uriRegex.test(str);
}
function parseUri(str = '') {
  const matches = uriRegex.exec(str);
  return matches && matches[0];
}

// taken from https://stackoverflow.com/a/1981366
function stripRepeatedWhitespace(str = '') {
  return str.replace(/\s\s+/g, ' ');
}

/**
 * We want to avoid having multiple sentries for the same error but we still want to track it.
 * The problem is that every request has the portalId in the query string, which results in a
 * different error message, thus a different error.
 * What we are doing here is manually capturing an error with Sentry and adding a fingerprint.
 * Sentry will group all the errors with the same fingerprint regardless of the actual error.
 * The fingerprint for each error network is:
 * url (without query string) + request verb + response status code
 */
export function catchAndRethrowNetworkError(error) {
  const code = error.errorCode || error.status;
  if (code && error.options && error.options.url) {
    const cleansedUrl = cleanseUrl(error.options.url);
    const method = error.options.method;
    const envPrefix = enviro.isQa() ? 'QA: ' : '';
    const message = `${envPrefix}${method} ${code}: ${cleansedUrl}`;
    error.message = message;
    Raven.captureException(error, {
      fingerprint: ISOLATED_NETWORK_CODES.includes(code.toString()) ? [code] : [code, method, cleansedUrl],
      tags: {
        [CPQ_FE]: true
      }
    });
  }
  throw error;
}
function parseStacktracedError(message, type = 'Unspecified Error') {
  const [firstLine] = splitIntoLines(message);
  if (containsUri(firstLine)) {
    return `Stacktraced Network Error: ${cleanseUrl(parseUri(firstLine))}`;
  } else {
    // Strip any meaningless characters or strings from JS exceptions.
    return `${type}: ${stripRepeatedWhitespace(message)}`;
  }
}
export function ravenDataCallback(data, original) {
  // If the raven request already has a fingerprint, it has already been handled by
  // upstream from us by either our own network failure catches or by other FE tools
  // (e.g. usage tracking), and doesn't need to be assigned a fingerprint
  if (!data.fingerprint) {
    // The gist of the logic here is that we continuously try and find network errors through:
    // - `data` having an `exception` where a URL is mentioned in the first line
    // - `data` having a `request` property
    // - `data` having a `message` where a URL is mentioned in the first line
    // Otherwise we strip for excess whitespace and treat as a JS error.

    let fingerprint;
    if (data.exception && data.exception.values && data.exception.values.length > 0) {
      const [exception] = data.exception.values;
      fingerprint = [parseStacktracedError(exception.value, exception.type)];
    } else if (data.request && data.request.url) {
      // Strip portal ids and query params from requests...
      fingerprint = [cleanseUrl(data.request.url)];
    } else {
      fingerprint = [parseStacktracedError(data.message)];
    }
    // This will cause raven to ignore its default grouping behaviour and go solely
    // off our scrubbed error message
    data.fingerprint = fingerprint;
  }
  return original ? original(data) : data;
}
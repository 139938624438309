import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { viewMemberUpdated } from 'find-and-filter-data/realtime-updates/public';
import { generateViewId } from 'find-and-filter-data/views-schema/public';
import { Map as ImmutableMap } from 'immutable';
import set from 'transmute/set';
import { AsyncStatus } from '../../common/public';
import { omnibusFulfilled } from '../../omnibus/public';
import { applyViewUpdateMessage } from '../../realtime-updates/public';
import { fetchSearchAndFilterViewMembers } from '../../search-and-filter-data/protected';
import { threadsUpdated } from '../../view-member-optimistic-updates/public';
import { getMembers } from '../../view-members-schema/protected';
import { mergeIncomingViewMembersPage } from './common/fetchViewMembersHelpers';
import { threadSeen } from './common/sharedViewMemberActions';
import { SPAM_THREAD_LIST_ID } from './thread-list-member/constants';
import { mergeViewMembersWithUpdates } from './thread-list-member/mergeViewMembersWithUpdates';
import { buildViewMembersPage } from './view-member-page/buildViewMembersPage';
import { fetchSpamViewMembers, fetchViewMembers, optimisticUpdateThreadListMember } from './viewMemberActions';
const initialState = {
  data: ImmutableMap(),
  //This defaults to false so that we will fetch viewMembers if omnibus call fails.
  loading: false,
  failed: false,
  status: AsyncStatus.UNINITIALIZED
};
const updateThreadListMembers = ({
  data,
  viewId,
  currentAgentId,
  message
}) => {
  return data.update(viewId, page => {
    if (!page) return page;
    return page.update('indexedMembers', members => {
      const updatedMembers = applyViewUpdateMessage(members, message, currentAgentId);
      return updatedMembers;
    });
  });
};
export const viewMembersSlice = createSlice({
  name: 'viewMembers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(omnibusFulfilled, (state, {
      payload
    }) => {
      state.loading = false;
      if (payload.omnibus.threadListMembers) {
        state.data = ImmutableMap({
          [payload.currentViewId]: buildViewMembersPage(payload.omnibus.threadListMembers)
        });
        state.status = AsyncStatus.SUCCEEDED;
      }
    }).addCase(threadSeen, (state, action) => {
      const {
        payload
      } = action;
      const {
        threadId,
        seen,
        customViewIds,
        threadLists
      } = payload;
      let newState = state.data;

      //Update the ViewMember record in cached custom views.
      for (const viewId of customViewIds) {
        newState = newState.updateIn([`${viewId}`, 'indexedMembers', threadId], viewMember => {
          return viewMember && viewMember.set('seen', seen);
        });
      }

      //Update the ViewMember record in cached thread lists.
      for (const {
        agentId,
        threadListId,
        threadListType
      } of threadLists) {
        const threadListIdString = generateViewId({
          threadListId,
          threadListType
        });
        newState = newState.updateIn([threadListIdString, 'indexedMembers', threadId], viewMember => {
          let seenByAgentIds = (viewMember === null || viewMember === void 0 ? void 0 : viewMember.seenByAgentIds) || [];
          if (agentId && !seenByAgentIds.includes(agentId)) {
            seenByAgentIds = [...seenByAgentIds, agentId];
          }
          return viewMember && viewMember.set('seen', seen).set('seenByAgentIds', seenByAgentIds);
        });
      }
      state.data = newState;
    }).addCase(threadsUpdated, (state, {
      payload: {
        currentAgentId,
        message
      }
    }) => {
      const viewId = generateViewId({
        customViewId: message.customViewId,
        threadListId: message.threadListId,
        threadListType: message.threadListType
      });
      if (state.data.get(viewId)) {
        state.data = state.data.update(viewId, page => {
          const updatedMembers = mergeViewMembersWithUpdates(getMembers(page), message, currentAgentId);
          return set('indexedMembers', updatedMembers, page);
        });
      }
    }).addCase(viewMemberUpdated, (state, action) => {
      const {
        currentAgentId,
        message
      } = action.payload;
      const viewId = generateViewId(message.viewKey);
      state.data = updateThreadListMembers({
        data: state.data,
        viewId,
        currentAgentId,
        message
      });
    }).addCase(optimisticUpdateThreadListMember, (state, action) => {
      const {
        payload
      } = action;
      const {
        threadId,
        viewId,
        viewMemberUpdater
      } = payload;

      //If view ID is not cached, do nothing.
      if (!state.data.get(viewId)) {
        return state;
      }
      //Update the member in the cached thread list.
      state.data = state.data.update(viewId, page => {
        const updatedMembers = page.indexedMembers.update(`${threadId}`, member => {
          if (!member) return member;
          return viewMemberUpdater(member);
        });
        return page.set('indexedMembers', updatedMembers);
      });
    }).addMatcher(isAnyOf(fetchSpamViewMembers.pending, fetchViewMembers.pending, fetchSearchAndFilterViewMembers.pending), (state, {
      meta
    }) => {
      var _meta$arg$options;
      state.failed = false;
      const disabledLoad = (_meta$arg$options = meta.arg.options) === null || _meta$arg$options === void 0 ? void 0 : _meta$arg$options.disabledLoad;
      if (!disabledLoad) {
        if (meta.arg.offsetId === undefined || meta.arg.offsetId === null || meta.arg.offsetId < 0) {
          state.status = AsyncStatus.INITIAL_FETCH_LOADING;
          state.data = ImmutableMap();
        } else {
          state.status = AsyncStatus.PAGINATED_FETCH_LOADING;
        }
        state.loading = true;
      }
    }).addMatcher(isAnyOf(fetchSpamViewMembers.fulfilled, fetchViewMembers.fulfilled, fetchSearchAndFilterViewMembers.fulfilled), (state, {
      meta,
      payload
    }) => {
      var _meta$arg$viewId;
      const viewId = (_meta$arg$viewId = meta.arg.viewId) !== null && _meta$arg$viewId !== void 0 ? _meta$arg$viewId : SPAM_THREAD_LIST_ID;
      if (meta.arg.offsetId && meta.arg.offsetId > 0) {
        const oldPage = state.data.get(viewId);
        const newPage = buildViewMembersPage(payload);
        const updatedPage = mergeIncomingViewMembersPage({
          oldPage,
          newPage,
          totalCount: payload.totalCount
        });
        if (updatedPage) state.data = state.data.set(viewId, updatedPage);
      } else {
        state.data = ImmutableMap({
          [viewId]: buildViewMembersPage(payload)
        });
      }
      state.status = AsyncStatus.SUCCEEDED;
      state.loading = false;
    }).addMatcher(isAnyOf(fetchSpamViewMembers.rejected, fetchViewMembers.rejected, fetchSearchAndFilterViewMembers.rejected), state => {
      state.status = AsyncStatus.FAILED;
      state.loading = false;
      state.failed = true;
    });
  }
});
import I18n from 'I18n';
/**
 * Converts a value to a number or null if conversion is not possible
 * @param value - The value to convert
 * @returns The converted number, or null if value is null or cannot be converted
 */
export function toNumberOrNull(value) {
  // prevents null conversion to 0 for initial price calculations
  if (value === null) return null;
  const number = Number(value);
  return isNaN(number) ? null : number;
}

/**
 * Converts a value to a number or undefined if conversion is not possible
 * @param value - The value to convert
 * @returns The converted number, or undefined if value is null/undefined or cannot be converted
 */
export function toNumberOrUndefined(value) {
  // prevents null conversion to 0 for initial price calculations
  if (value == null) return undefined;
  const number = Number(value);
  return isNaN(number) ? undefined : number;
}

/**
 * Converts a value to a number, defaulting to 0 if conversion is not possible.
 *
 * Exists to facilitate TS conversion when null or undefined are not acceptable
 * @param value - The value to convert
 * @returns The converted number, or 0 if the value cannot be converted
 */
export function toNumber(value) {
  const number = Number(value);
  return isNaN(number) ? 0 : number;
}

// ported from https://git.hubteam.com/HubSpot/CRM/blob/master/crm_ui/static/js/utils/FormatNumber.js
/**
 * Parses a value to a number using either native conversion or I18n locale-aware parsing
 * @param number - The value to parse
 * @returns The parsed number
 */
export function parseNumber(number) {
  if (typeof number === 'number') {
    return number;
  }
  const parsed = Number(number);
  if (!isNaN(parsed)) {
    return parsed;
  }
  // @ts-expect-error type dependency error
  return I18n.parseNumber(number);
}
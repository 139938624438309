import { getViewMemberId } from 'find-and-filter-data/view-members-schema/public';
import Raven from 'raven-js';
function mergeViewMembers(existingViewMember, newViewMember) {
  const type = existingViewMember['@type'];
  return Object.assign({}, existingViewMember, newViewMember, {
    '@type': type
  });
}
export function mergeUpserted(upsertedMembers, viewMembers) {
  const indexedRecords = upsertedMembers.reduce((indexedMembers, currViewMember) => {
    try {
      const viewMemberId = getViewMemberId(currViewMember);
      const existingMember = indexedMembers.get(viewMemberId);
      if (existingMember) {
        return indexedMembers.set(viewMemberId,
        // This is used to retain the type if the view member is HelpDeskSearchViewMember
        // and also retain search related fields like messageColumnText
        // since we always get a HelpDeskViewMember from the backend
        mergeViewMembers(existingMember, currViewMember));
      }
      return indexedMembers.set(getViewMemberId(currViewMember), currViewMember);
    } catch (e) {
      Raven.captureException(e, {
        extra: {
          currViewMemberType: typeof currViewMember,
          indexedMembersType: typeof indexedMembers,
          viewMemberId: `${getViewMemberId(currViewMember)}`
        }
      });
      return indexedMembers;
    }
  }, viewMembers);
  return indexedRecords;
}
function mergeRemovedTickets(removedIds, viewMembers) {
  return removedIds.reduce((members, removedId) => {
    try {
      return members.delete(`${removedId}`);
    } catch (e) {
      Raven.captureException(e, {
        extra: {
          currViewMemberType: typeof members.get(`${removedId}`),
          viewMemberId: `${removedId}`
        }
      });
      return members;
    }
  }, viewMembers);
}
export function applyHelpDeskBulkUpdateMessage(viewMembers, viewMembersUpdated) {
  const upsertedMembers = mergeUpserted([...viewMembersUpdated.added, ...viewMembersUpdated.updated], viewMembers);
  if (viewMembersUpdated.removed) {
    const updatedMembers = mergeRemovedTickets(viewMembersUpdated.removed, upsertedMembers);
    return updatedMembers;
  }
  return upsertedMembers;
}
import { hasGate, hasScope, isFriendsAndFamilyPortal } from './auth';
import { PARTNER_ADMIN, SUPER_ADMIN } from '../constants/Scopes';
import { CALLING_ACCESS, CALLING_BYON_REGISTRATION, CALLING_DEFAULT_OUTCOMES_WRITE, CALLING_HELP_DESK_CHANNEL_WRITE, CALLING_INBOX_CHANNEL_WRITE, CALLING_INTERNATIONAL_PHONE_NUMBER_ACQUISITION, CALLING_IVR_WRITE, CALLING_OUTBOUND_DIALER_ACCESS, CALLING_PAID_SEAT_ACCESS, CALLING_PORTING, CALLING_TWILIO_CONNECT_ACCESS, CALLING_US_PHONE_NUMBER_ACQUISITION, CALLING_INBOUND_ACCESS, CALLING_OUTBOUND_ACCESS, CALLING_ANALYTICS_READ } from '../constants/CallingScopes';
import { CHANNEL_MANAGER_EXP_CALLING_TRIALS } from '../constants/CallingGates';
import { getOwnsAnyPaidParcel, getOwnsPaidPortal, hasAnyTrialPaidParcel, hasSalesOrServiceEnterprisePaidParcel, hasSalesOrServiceEnterpriseTrialParcel, isTrialingSalesOrServiceStarterPlus, hasSalesOrServiceStarterPlus } from './scopeUtils';
import { CallingLimitHigh, CallingLimitMedium } from '../constants/AccessLevels';
import { getLocalStorageSupportsChannelConnection } from './getLocalStorageSupportsChannelConnection';
import { ONBOARDING_SCOPES } from 'onboarding-scopes/scopeConstants';
import memoizeOne from 'react-utils/memoizeOne';
import enviro from 'enviro';
export const hasCallingAccess = () => {
  return hasScope(CALLING_ACCESS);
};
export const hasAdminScope = () => hasScope(PARTNER_ADMIN) || hasScope(SUPER_ADMIN);
const getPortingAccess = () => {
  if (isFriendsAndFamilyPortal() && enviro.isProd()) {
    return false;
  }
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_PORTING);
  }
  return hasAdminScope() && hasSalesOrServiceStarterPlus();
};

// This is a replacement for calls/v1/settings portalSummary.paidHub (but more accurate as its only true for sales/service parcels)
const _hasPaidHub = () => {
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return isTrialingSalesOrServiceStarterPlus() || hasSalesOrServiceStarterPlus();
  }
  return hasSalesOrServiceStarterPlus();
};
const _hasCallingAnalyticsAccess = () => {
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_ANALYTICS_READ) && getOwnsAnyPaidParcel();
  }
  return hasSalesOrServiceStarterPlus();
};
const getUSPhoneNumberAcquisitionAccess = () => {
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_US_PHONE_NUMBER_ACQUISITION);
  }
  return hasAdminScope();
};
const getInternationalPhoneNumberAcquisitionAccess = () => {
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_INTERNATIONAL_PHONE_NUMBER_ACQUISITION);
  }
  return hasAdminScope();
};
const getHasTwilioConnectAccess = () => {
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_TWILIO_CONNECT_ACCESS);
  }
  return hasScope(CallingLimitHigh);
};
const getIVRWriteAccess = () => {
  const hasWriteAccess = hasScope(CALLING_IVR_WRITE) && hasScope(CALLING_ACCESS);
  const hasWriteAccessWithoutTrial = hasWriteAccess && hasSalesOrServiceEnterprisePaidParcel();
  if (hasWriteAccessWithoutTrial) {
    return true;
  }
  return hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS) && hasWriteAccess && getOwnsAnyPaidParcel() && hasSalesOrServiceEnterpriseTrialParcel();
};
const getRequiresParcelUpgradeForPorting = () => {
  if (!hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return false;
  }
  return isTrialingSalesOrServiceStarterPlus() && !getPortingAccess();
};
const getRequiresParcelUpgradeForUSPhoneNumberAcquisition = () => {
  if (!hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return false;
  }
  return isTrialingSalesOrServiceStarterPlus() && !getUSPhoneNumberAcquisitionAccess();
};
const getRequiresParcelUpgradeForIVR = () => {
  const hasEnterprisePaidParcel = hasSalesOrServiceEnterprisePaidParcel();
  if (hasEnterprisePaidParcel) {
    return false;
  }
  const isEnterpriseTrialWithPaidParcel = hasSalesOrServiceEnterpriseTrialParcel() && getOwnsAnyPaidParcel() && hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS);
  if (isEnterpriseTrialWithPaidParcel) {
    return false;
  }
  return true;
};
const gethasMissingIVRPermissions = () => {
  return !getIVRWriteAccess() && !getRequiresParcelUpgradeForIVR();
};
export const hasDefaultOutcomesWrite = () => hasScope(CALLING_DEFAULT_OUTCOMES_WRITE);
const getHasPaidSeatAccess = () => {
  const hasSalesLimitHighOrMedium = hasScope(CallingLimitHigh) || hasScope(CallingLimitMedium);
  if (!hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasSalesLimitHighOrMedium;
  }
  return hasScope(CALLING_PAID_SEAT_ACCESS) && getOwnsAnyPaidParcel();
};
const getHasBYONRegistrationAccess = () => {
  if (!hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CallingLimitHigh) || hasScope(CallingLimitMedium);
  }
  return hasScope(CALLING_BYON_REGISTRATION);
};
const getHasInboxChannelWriteAccess = () => {
  if (!hasScope(CALLING_ACCESS)) {
    return false;
  }
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_INBOX_CHANNEL_WRITE) && getOwnsAnyPaidParcel();
  }
  return getOwnsPaidPortal([ONBOARDING_SCOPES.SALES_PRO, ONBOARDING_SCOPES.SERVICE_PRO, ONBOARDING_SCOPES.SALES_ENTERPRISE, ONBOARDING_SCOPES.SERVICE_ENTERPRISE]);
};
const getHasHelpDeskChannelWriteAccess = () => {
  if (!hasScope(CALLING_ACCESS)) {
    return false;
  }
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_HELP_DESK_CHANNEL_WRITE) && (getOwnsAnyPaidParcel() || getLocalStorageSupportsChannelConnection());
  }
  return getOwnsPaidPortal([ONBOARDING_SCOPES.SERVICE_PRO, ONBOARDING_SCOPES.SERVICE_ENTERPRISE]) || hasAnyTrialPaidParcel([ONBOARDING_SCOPES.SERVICE_PRO, ONBOARDING_SCOPES.SERVICE_ENTERPRISE]) && getLocalStorageSupportsChannelConnection();
};
const getHasOutboundDialerAccess = () => {
  if (!hasScope(CALLING_ACCESS)) {
    return false;
  }
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_OUTBOUND_DIALER_ACCESS);
  }
  return getHasPaidSeatAccess();
};
const getHasInboundCallingAccess = () => {
  if (!hasScope(CALLING_ACCESS)) {
    return false;
  }
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_INBOUND_ACCESS) && getOwnsAnyPaidParcel();
  }
  return getHasPaidSeatAccess();
};
const getHasOutboundCallingAccess = () => {
  if (!hasScope(CALLING_ACCESS)) {
    return false;
  }
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return hasScope(CALLING_OUTBOUND_ACCESS) && getOwnsAnyPaidParcel();
  }
  return getHasPaidSeatAccess();
};
const getHasHubSpotCallingToggleAccess = () => {
  if (!hasScope(CALLING_ACCESS)) {
    return false;
  }
  if (hasGate(CHANNEL_MANAGER_EXP_CALLING_TRIALS)) {
    return (hasSalesOrServiceStarterPlus() || isTrialingSalesOrServiceStarterPlus()) && hasAdminScope();
  }
  return hasSalesOrServiceStarterPlus() && hasAdminScope();
};

// Memoized exports
export let hasPaidHub = memoizeOne(_hasPaidHub);
export let hasPortingAccess = memoizeOne(getPortingAccess);
export let hasUSPhoneNumberAcquisitionAccess = memoizeOne(getUSPhoneNumberAcquisitionAccess);
export let hasInternationalPhoneNumberAcquisitionAccess = memoizeOne(getInternationalPhoneNumberAcquisitionAccess);
export let hasIVRWriteAccess = memoizeOne(getIVRWriteAccess);
export let requiresParcelUpgradeForIVR = memoizeOne(getRequiresParcelUpgradeForIVR);
export let hasMissingIVRPermissions = memoizeOne(gethasMissingIVRPermissions);
export let hasTwilioConnectAccess = memoizeOne(getHasTwilioConnectAccess);
export let hasPaidSeatAccess = memoizeOne(getHasPaidSeatAccess);
export let hasBYONRegistrationAccess = memoizeOne(getHasBYONRegistrationAccess);
export let hasInboxChannelWriteAccess = memoizeOne(getHasInboxChannelWriteAccess);
export let hasHelpDeskChannelWriteAccess = memoizeOne(getHasHelpDeskChannelWriteAccess);
export let hasOutboundDialerAccess = memoizeOne(getHasOutboundDialerAccess);
export let hasInboundCallingAccess = memoizeOne(getHasInboundCallingAccess);
export let hasOutboundCallingAccess = memoizeOne(getHasOutboundCallingAccess);
export let hasHubSpotCallingToggleAccess = memoizeOne(getHasHubSpotCallingToggleAccess);
export let hasCallingAnalyticsAccess = memoizeOne(_hasCallingAnalyticsAccess);
export let requiresParcelUpgradeForPorting = memoizeOne(getRequiresParcelUpgradeForPorting);
export let requiresParcelUpgradeForUSPhoneNumberAcquisition = memoizeOne(getRequiresParcelUpgradeForUSPhoneNumberAcquisition);

// Memoization reset function
export const __clearHasCallingFeatureMemoization = () => {
  hasPaidHub = memoizeOne(_hasPaidHub);
  hasPortingAccess = memoizeOne(getPortingAccess);
  hasUSPhoneNumberAcquisitionAccess = memoizeOne(getUSPhoneNumberAcquisitionAccess);
  hasInternationalPhoneNumberAcquisitionAccess = memoizeOne(getInternationalPhoneNumberAcquisitionAccess);
  hasIVRWriteAccess = memoizeOne(getIVRWriteAccess);
  requiresParcelUpgradeForIVR = memoizeOne(getRequiresParcelUpgradeForIVR);
  hasMissingIVRPermissions = memoizeOne(gethasMissingIVRPermissions);
  hasTwilioConnectAccess = memoizeOne(getHasTwilioConnectAccess);
  hasPaidSeatAccess = memoizeOne(getHasPaidSeatAccess);
  hasBYONRegistrationAccess = memoizeOne(getHasBYONRegistrationAccess);
  hasInboxChannelWriteAccess = memoizeOne(getHasInboxChannelWriteAccess);
  hasHelpDeskChannelWriteAccess = memoizeOne(getHasHelpDeskChannelWriteAccess);
  hasOutboundDialerAccess = memoizeOne(getHasOutboundDialerAccess);
  hasInboundCallingAccess = memoizeOne(getHasInboundCallingAccess);
  hasOutboundCallingAccess = memoizeOne(getHasOutboundCallingAccess);
  hasHubSpotCallingToggleAccess = memoizeOne(getHasHubSpotCallingToggleAccess);
  hasCallingAnalyticsAccess = memoizeOne(hasCallingAnalyticsAccess);
  requiresParcelUpgradeForPorting = memoizeOne(getRequiresParcelUpgradeForPorting);
  requiresParcelUpgradeForUSPhoneNumberAcquisition = memoizeOne(getRequiresParcelUpgradeForUSPhoneNumberAcquisition);
};
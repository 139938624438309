import { LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useHttpClient } from '../../client/HttpClientContext';
import { useEffect, useState } from 'react';
import { cache } from '../../input/leads/leadSettingsCache';
import Raven from 'raven-js';
import { useAdditionalPropertyValues } from './useAdditionalPropertyValues';
import { useGetHasScope } from './useGetHasScope';
const NEW = 'NEW';
const IN_PROGRESS = 'IN_PROGRESS';
const QUALIFIED = 'QUALIFIED';
const UNQUALIFIED = 'UNQUALIFIED';
const closedStates = new Set([QUALIFIED, UNQUALIFIED]);
export const useIsReadOnlyLeadOwnerProperty = (objectTypeId, propertyDefinition) => {
  const getHasScope = useGetHasScope();
  const httpClient = useHttpClient();
  const shouldCheck = objectTypeId === LEAD_TYPE_ID && propertyDefinition.hubspotDefined && propertyDefinition.name === 'hubspot_owner_id';
  const {
    data: additionalValues
  } = useAdditionalPropertyValues({
    pipelineStageCategory: 'hs_pipeline_stage_category'
  }, {
    skip: !shouldCheck
  });
  const leadStageCategory = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues.pipelineStageCategory;
  const isLeadClosed = leadStageCategory ? closedStates.has(leadStageCategory) : false;
  const canViewAsOther = getHasScope('prospecting-workspace-as-other-view-access');
  const isSuperAdmin = getHasScope('super-admin');
  const [isReadOnly, setIsReadOnly] = useState(true);
  useEffect(() => {
    if (!shouldCheck) {
      return;
    }
    cache.get('leadSettings', httpClient).then(data => {
      const ownerSyncEnabled = data.mirrorBackingObjectOwner === true;
      const canUpdateLeadOwner = !ownerSyncEnabled && (isLeadClosed ? canViewAsOther || isSuperAdmin : true);
      setIsReadOnly(!canUpdateLeadOwner);
    }).catch(err => {
      // We want to fail 'open' here, so we don't block the user from editing the property
      setIsReadOnly(false);
      Raven.captureException(new Error('Fetching lead settings failed open'), {
        extra: {
          originalError: err
        }
      });
    });
  }, [httpClient, shouldCheck, canViewAsOther, isLeadClosed, isSuperAdmin, leadStageCategory]);
  return shouldCheck ? isReadOnly : false;
};
import { Async, Creatable, MIN_MENU_WIDTH } from './Select.constants';
import { getPaginationCache, getOptionsCache, getResetOption, isVirtualized, makeNewOptionsCache } from './Select.helpers';
import unescapedText from 'I18n/utils/unescapedText';
import { lazyEval } from '../../utils/lazyEval';
import devLogger from 'react-utils/devLogger';
let hasShownDevWarning = false;
let hasPingedNewRelic = false;
export const createGetters = component => ({
  getDerivedStateFromProps(props, state) {
    const cacheOptions = getOptionsCache(props, state);
    const resetOption = getResetOption(props, state);
    let paginationCache;
    let initiallyOpen;
    let prevOpen;
    if (!state.prevOpen && props.open) {
      paginationCache = getPaginationCache(props);
    } else if (state.prevOpen && !props.open) {
      initiallyOpen = {
        initiallyOpen: false
      };
    }
    if (state.prevOpen !== props.open) {
      prevOpen = {
        prevOpen: props.open
      };
    }
    return Object.assign({}, cacheOptions, resetOption, paginationCache, initiallyOpen, prevOpen);
  },
  getSplitValuesFromString(str, includeLastValue) {
    const {
      allowCreate,
      splitRegex
    } = component.props;
    let strParts = str.split(splitRegex).map(s => s.trim());
    if (!includeLastValue) {
      strParts = strParts.slice(0, strParts.length - 1);
    }
    if (strParts.length === 0) return [];

    // Create a map of option labels to options
    const {
      optionsCache
    } = component.state;
    const reverseOptionsCache = {};
    Object.keys(optionsCache).forEach(key => {
      const option = optionsCache[key];
      if ((option === null || option === void 0 ? void 0 : option.value) != null) {
        reverseOptionsCache[option.text || key] = option.value;
      }
    });

    // For each piece, see if it maps to an option label. If so, add that option's value to
    // the result. Otherwise, if allowCreate is enabled, add a new option.
    const result = [];
    strParts.forEach(label => {
      if (Object.prototype.hasOwnProperty.call(reverseOptionsCache, label)) {
        result.push(reverseOptionsCache[label]);
      } else if (allowCreate && result.indexOf(label) === -1) {
        result.push(label);
      }
    });
    return result;
  },
  getMenuWidth() {
    const {
      menuWidth
    } = component.props;
    if (menuWidth === 'auto') {
      return '';
    }
    if (typeof menuWidth === 'number') {
      return menuWidth;
    }
    return Math.max(component.state.controlWidth, MIN_MENU_WIDTH);
  },
  getInternalSelect() {
    // Return our react-select Select instance, or null
    if (!component._anchorRef) {
      return null;
    }
    if (component._anchorRef.getInternalSelect) {
      return component._anchorRef.getInternalSelect();
    }
    // TODO(TS): replace this with a better check once we're okay with changing behavior
    return component._anchorRef.select || component._anchorRef;
  },
  getInternalCreatableSelect() {
    // Return our react-select Creatable instance, or null
    if (!component._anchorRef) {
      return null;
    }
    if (component._anchorRef.constructor === Creatable) {
      return component._anchorRef;
    }
    return null;
  },
  getInternalAsyncSelect: () => {
    // Return our react-select Async instance, or null
    if (!component._anchorRef) {
      return null;
    }
    // TODO(TS): replace this with a better check once we're okay with changing behavior
    if (component._anchorRef.getInternalAsyncSelect) {
      // TODO(TS): replace this with a better check once we're okay with changing behavior
      return component._anchorRef.getInternalAsyncSelect();
    }
    if (component._anchorRef.constructor === Async) {
      return component._anchorRef;
    }
    return null;
  },
  getLoadOptionsProxyCallback(input, loadOptionsCallback) {
    const proxyCallback = (error, data, ...rest) => {
      if (!component._isMounted) return;
      const {
        optionsCache,
        paginationCache
      } = component.state;
      let newOptionsCache = optionsCache;
      let newPaginationCache = paginationCache;

      // Cache options so that we can render the selected value properly
      if (data && data.options) {
        newOptionsCache = makeNewOptionsCache(optionsCache, data.options);
        if (data.pagination) {
          newPaginationCache = Object.assign({}, paginationCache);
          newPaginationCache[input || ''] = data.pagination;
        }
      }
      const internalAsyncSelect = component.getInternalAsyncSelect();
      component.setState({
        isLoading: internalAsyncSelect && internalAsyncSelect._callback != null && component._latestSearch !== input,
        optionsCache: newOptionsCache,
        paginationCache: newPaginationCache
      });
      loadOptionsCallback(error, data, ...rest);
    };
    return proxyCallback;
  },
  getValueAsObject(value) {
    // In async mode, react-select needs the current value's object form to render it
    const {
      optionsCache
    } = component.state;
    if (!optionsCache) {
      return value;
    }
    if (Array.isArray(value)) {
      return value.map(v => Object.prototype.hasOwnProperty.call(optionsCache, `${v}`) ? optionsCache[`${v}`] : v);
    }
    return Object.prototype.hasOwnProperty.call(optionsCache, `${value}`) ? optionsCache[`${value}`] : value;
  },
  getAnchorType({
    anchorType,
    multi
  }) {
    if (anchorType === 'auto') {
      return multi ? 'combined' : 'button';
    }
    return anchorType;
  },
  getNoResultsText({
    noResultsText
  }) {
    if (noResultsText != null) return lazyEval(noResultsText);
    const {
      inputValue
    } = component.state;
    return inputValue ? unescapedText('salesUI.UISelect.noResults') : unescapedText('salesUI.UISelect.typeToSearch');
  },
  getMenuRenderer({
    itemComponent,
    menuRenderer,
    menuWidth,
    optionRenderer,
    optionComponent
  }) {
    if (isVirtualized(menuRenderer)) {
      if (optionRenderer || itemComponent !== 'span') {
        devLogger.warn({
          message: 'Select: virtualize cannot be used with optionRenderer or itemComponent',
          key: 'Select: virtualizeCustomRenderer'
        });
        return undefined;
      }
      if (menuWidth === 'auto') {
        devLogger.warn({
          message: 'Select: virtualize canot be used with menuWidth="auto"',
          key: 'Select: virtualizeAutoWidth'
        });
        return undefined;
      }
      return menuRenderer(component.getMenuWidth(), component.virtualizedMenuRendererRefCallback);
    }
    if (component.checkForFormattedMessages() && !hasShownDevWarning) {
      devLogger.warn({
        message: 'Select: Combining Link and FormattedMessage inside of `react-select-plus` leads to broken links in any disabled options. Use a FormattedJSXMessage or unescapedText instead of FormattedMessage inside any Link used in Select.',
        key: 'Select: FormattedMessage'
      });
      hasShownDevWarning = true;
      if (window.newrelic && !hasPingedNewRelic) {
        window.newrelic.addPageAction('ui-select-formatted-message', {
          component: itemComponent || optionComponent
        });
        hasPingedNewRelic = true;
      }
    }
    return menuRenderer;
  }
});
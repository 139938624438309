import { useQuery } from 'data-fetching-client';
import { GET_CURRENT_USER_CAPACITY_DATA_QUERY, GET_USER_CAPACITY_DATA_QUERY } from '../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/UserCapacityLimitsV2Service.dfc';
import { useMemo } from 'react';
import { useHasCapacityLimitsV2ReadScope } from '../../auth/hooks/useScopes';
import { useIsUngatedForUserLevelCapacityLimitsTicketType } from '../../auth/hooks/useGates';
import { getUserCapacityData } from '../utils/capacityUtils';
export const useUserLevelCapacityLimits = agentId => {
  const hasCapacityLimitsV2ReadScope = useHasCapacityLimitsV2ReadScope();
  const {
    data,
    loading
  } = useQuery(GET_USER_CAPACITY_DATA_QUERY, {
    skip: !hasCapacityLimitsV2ReadScope,
    variables: {
      userId: agentId
    }
  });
  const capacityLimits = useMemo(() => {
    var _data$getUserCapacity;
    if (!data) {
      return;
    }
    return {
      capacityLimit: (_data$getUserCapacity = data.getUserCapacityData.totalTicketUserCapacityData.capacityLimit) !== null && _data$getUserCapacity !== void 0 ? _data$getUserCapacity : Infinity,
      capacityLoad: data.getUserCapacityData.totalTicketUserCapacityData.capacityLoad
    };
  }, [data]);
  return {
    capacityLimits,
    loading
  };
};
export const useCurrentUserCapacityLimits = () => {
  const isUngatedForUserLevelCapacityLimitsTicketType = useIsUngatedForUserLevelCapacityLimitsTicketType();
  const hasCapacityLimitsV2ReadScope = useHasCapacityLimitsV2ReadScope();
  const {
    data,
    loading,
    error
  } = useQuery(GET_CURRENT_USER_CAPACITY_DATA_QUERY, {
    skip: !hasCapacityLimitsV2ReadScope || !isUngatedForUserLevelCapacityLimitsTicketType
  });
  if (!data) {
    return {
      loading,
      error
    };
  }
  return {
    capacity: getUserCapacityData(data.getCurrentUserCapacityData),
    loading,
    error
  };
};
import { useCallback } from 'react';
import http from 'hub-http/clients/apiClient';
export const useDeleteProductById = objectId => {
  const deleteProduct = useCallback(() => {
    if (!objectId) {
      throw new Error('Object ID is required');
    }
    return http.delete(`inbounddbproducts/v1/products/${objectId}`);
  }, [objectId]);
  return deleteProduct;
};
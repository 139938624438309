const spaceStyleProps = {
  padding: true,
  paddingInline: true,
  paddingInlineStart: true,
  paddingInlineEnd: true,
  paddingBlock: true,
  paddingBlockStart: true,
  paddingBlockEnd: true,
  margin: true,
  marginInline: true,
  marginInlineStart: true,
  marginInlineEnd: true,
  marginBlock: true,
  marginBlockStart: true,
  marginBlockEnd: true
};
export function filterSpaceProps(props) {
  const spaceProps = {};
  const restProps = {};
  Object.keys(props).forEach(key => {
    if (spaceStyleProps[key]) {
      spaceProps[key] = props[key];
    } else {
      restProps[key] = props[key];
    }
  });
  return {
    spaceProps,
    restProps
  };
}
// original: https://git.hubteam.com/HubSpot/ui-addon-conversations-settings/blob/a3074a0a75dfbdb23ad52e6a2e9a41799014b3b1/static/js/team/operators/teamTreeToOptions.ts

import { toOptionWithDepth } from './toOptionWithDepth';
const nodeToOption = (node, depth, routingSource) => {
  return toOptionWithDepth(node.get('data'), depth, routingSource);
};
const traverse = (node, list, depth, routingSource) => {
  list.push(nodeToOption(node, depth, routingSource));
  node.get('childTeams').forEach(childTeam => {
    traverse(childTeam, list, depth + 1, routingSource);
  });
  return list;
};
export const teamTreeToOptions = (teamTree, routingSource) => {
  const list = [];
  teamTree.forEach(node => {
    traverse(node, list, 1, routingSource);
  });
  return list;
};
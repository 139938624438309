import { loadMoreOptionValue } from './Select.constants';
import { LoadMoreComponent, getLoadMoreOption } from './Select.helpers';
import { filterInputValue, getIsLoadingOption, getMultiDummyOption } from './Select.helpers';
export const createProxies = component => ({
  optionRendererProxy: option => {
    const {
      optionRenderer,
      optionRendererIgnoresSpecialOptions = false
    } = component.props;
    if (optionRenderer == null || option.isSpecial && optionRendererIgnoresSpecialOptions) {
      return component.defaultOptionRenderer(option);
    }
    return optionRenderer(option);
  },
  filterOptionsProxy: (options, input, excludeOptions, ...rest) => {
    const {
      anchorType = 'auto',
      clearable,
      filterOptions,
      ignoreAccents,
      ignoreCase,
      isLoading: isLoadingProp,
      loadMoreComponent = LoadMoreComponent,
      multi,
      resetValue,
      value
    } = component.props;
    const {
      isLoading,
      paginationCache,
      resetOption
    } = component.state;
    let filterOptionsFunc;
    if (filterOptions === false) {
      filterOptionsFunc = x => x;
    } else if (typeof filterOptions === 'function') {
      filterOptionsFunc = filterOptions;
    } else {
      filterOptionsFunc = component.getDefaultFilterOptionsFunc();
    }
    const showSelectedOptions = component.areOptionsToggleable({
      anchorType,
      multi
    });
    let filteredOptions = filterOptionsFunc(options, input, showSelectedOptions ? [] : excludeOptions, ...rest);
    if (input.length === 0) {
      if (multi && options.length > 0 && filteredOptions.length === 0) {
        filteredOptions = filteredOptions.concat(getMultiDummyOption());
      }
      if (clearable && !multi && value != null && value !== resetValue) {
        filteredOptions = (resetOption != null ? [resetOption] : []).concat(filteredOptions);
      }
    }
    if (isLoading || isLoadingProp) {
      // Add "Loading..." option, if needed
      filteredOptions = filteredOptions.concat(getIsLoadingOption());
    } else {
      const paginationCacheKey = filterInputValue(input, {
        ignoreAccents,
        ignoreCase
      });
      const pagination = paginationCache[paginationCacheKey];
      // Add "Load more" option, if needed (#2031)
      if (pagination && pagination.hasMore) {
        filteredOptions = filteredOptions.concat(getLoadMoreOption({
          input,
          loadMoreComponent,
          options,
          pagination
        }));
      }
    }
    return filteredOptions;
  },
  selectValueProxy: (selectValue, selectedOption) => {
    // If this is a "Load more" option, trigger load.
    if (selectedOption.value === loadMoreOptionValue) {
      component.triggerLoadMore();
    }
    // Prevent "special" options from being selected.
    if (selectedOption.isSpecial) {
      return;
    }
    selectValue(selectedOption);
  },
  creatableOnInputChangeProxy: str => {
    const internalCreatableSelect = component.getInternalCreatableSelect();
    const newInputValue = component.handleInputChange(str);
    if (internalCreatableSelect) {
      internalCreatableSelect.inputValue = newInputValue;
    }
    return newInputValue;
  },
  loadOptionsProxy: (input, loadOptionsCallback) => {
    const {
      open,
      loadOptions
    } = component.props;
    if (input == null) {
      return;
    }

    // Show loading indicator until loadOptionsProxyCallback is called
    component.setState({
      isLoading: true
    });
    component._latestSearch = open ? input : null; // Special case: autoload search is designated as null

    // Keep a copy of all options returned by loadOptions
    const {
      paginationCache
    } = component.state;
    const proxyCallback = component.getLoadOptionsProxyCallback(open ? input : null, loadOptionsCallback);
    let result;
    try {
      result = loadOptions(input, proxyCallback, paginationCache[input || '']);
    } catch (error) {
      proxyCallback(error);
      setTimeout(() => {
        throw error;
      }, 0);
    }
    if (result && typeof result.then === 'function') {
      return result.then(data => {
        proxyCallback(null, data || {});
      }, error => {
        proxyCallback(error);
        throw error;
      });
    }
  }
});
import { InvoicePaymentStatus } from '../types/InvoicePaymentStatus';
import { getFinalizedInvoiceUrl, getFirstInvoiceAssociationWithRecordPage, getInvoicePropertyHistoryUrl, hasPayments, isAnyAssociatedPaymentFailed, isAssociatedPaymentsFailed } from '../utils/invoice';
import { getInvoiceProperty } from '../utils/property';
import { useMemo } from 'react';
import useCanCreateInvoice from './useCanCreateInvoice';
import { NON_BILLABLE_GATE, SUBSCRIPTIONS_RECORD_PAYMENT_GATE } from '../constants/gates';
const useDisplayButtonsProps = (invoice, paymentStatus, useIsUngatedFor, handleUnexpectedValidationError) => {
  var _associatedSubscripti;
  const isUngatedForNonBillable = useIsUngatedFor(NON_BILLABLE_GATE);
  const isBillableInvoice = !isUngatedForNonBillable || getInvoiceProperty(invoice, 'hs_invoice_billable') === undefined || getInvoiceProperty(invoice, 'hs_invoice_billable') === null || getInvoiceProperty(invoice, 'hs_invoice_billable') === 'true';
  const invoiceHasPayments = hasPayments(invoice) || paymentStatus === InvoicePaymentStatus.PENDING;
  const isPaymentCollectedAutomatically = getInvoiceProperty(invoice, 'hs_invoice_payment_collection_method') === 'automatic';
  const associatedSubscription = invoice.associatedObjects.INVOICE_TO_SUBSCRIPTION && invoice.associatedObjects.INVOICE_TO_SUBSCRIPTION[0];
  const hasActiveSubscriptions = invoice.associatedObjects.INVOICE_TO_SUBSCRIPTION ? invoice.associatedObjects.INVOICE_TO_SUBSCRIPTION.some(subscription => {
    var _subscription$propert, _subscription$propert2;
    return !['expired', 'canceled'].includes((_subscription$propert = subscription === null || subscription === void 0 || (_subscription$propert2 = subscription.properties) === null || _subscription$propert2 === void 0 || (_subscription$propert2 = _subscription$propert2.hs_status) === null || _subscription$propert2 === void 0 ? void 0 : _subscription$propert2.value) !== null && _subscription$propert !== void 0 ? _subscription$propert : '');
  }) : false;
  const isBalancePositive = useMemo(() => Number(getInvoiceProperty(invoice, 'hs_balance_due')) > 0, [invoice]);
  const isUngatedForRecordPaymentsOnAutoCollect = useIsUngatedFor(SUBSCRIPTIONS_RECORD_PAYMENT_GATE);
  const shouldDisableRecordPaymentButton = isUngatedForRecordPaymentsOnAutoCollect ? isPaymentCollectedAutomatically && !isAnyAssociatedPaymentFailed(invoice) || paymentStatus === InvoicePaymentStatus.PENDING : isPaymentCollectedAutomatically || paymentStatus === InvoicePaymentStatus.PENDING;
  const upcomingReminderDates = getInvoiceProperty(invoice, 'hs_upcoming_reminder_dates');
  const {
    loading: cloneLoading,
    disabledReasons: cloneDisabledReasons,
    unknownValidationError: unknownCloneValidationError
  } = useCanCreateInvoice(invoice, handleUnexpectedValidationError);
  return {
    isBillableInvoice,
    invoiceHasPayments,
    isAllAssociatedPaymentsFailed: isAssociatedPaymentsFailed(invoice),
    hasUpcomingReminderDates: !!upcomingReminderDates,
    isPaymentCollectedAutomatically,
    hasAssociatedSubscription: !!associatedSubscription,
    associatedSubscriptionId: (_associatedSubscripti = associatedSubscription === null || associatedSubscription === void 0 ? void 0 : associatedSubscription.objectId) !== null && _associatedSubscripti !== void 0 ? _associatedSubscripti : null,
    hasActiveSubscriptions,
    isBalancePositive,
    invoiceLink: getFinalizedInvoiceUrl(invoice),
    invoicePropertyHistoryLink: getInvoicePropertyHistoryUrl(invoice),
    hasAssociatedObjectToLink: !!getFirstInvoiceAssociationWithRecordPage(invoice),
    cloneLoading,
    unknownCloneValidationError,
    cloneDisabledReasons,
    shouldDisableRecordPaymentButton
  };
};
export default useDisplayButtonsProps;
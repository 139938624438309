import { getFullUrl } from 'hubspot-url-utils';
import enviro from 'enviro';
export const callingUserPreferencesUrl = portalId => {
  return `${getFullUrl('app')}/settings/${portalId}/user-preferences/calling`;
};
export const supportUrl = portalId => {
  return `${getFullUrl('app')}/support/${portalId}/open`;
};
export const callingToolSettings = portalId => {
  return `${getFullUrl('app')}/settings/${portalId}/calling`;
};
export const callingMarketplaceAppsUrl = portalId => {
  return `${getFullUrl('app')}/ecosystem/${portalId}/marketplace/apps/calling`;
};
export const upgradeSalesStarterUrl = portalId => {
  return `${getFullUrl('app')}/pricing/${portalId}/sales`;
};
export const settingsUsersUrl = portalId => {
  return `${getFullUrl('app')}/settings/${portalId}/users`;
};
export const callingTabUrl = (portalId, queryString) => {
  const subdomainPrefix = enviro.deployed() ? 'app' : 'local';
  return `${getFullUrl(subdomainPrefix)}/calling-page-application/${portalId}${queryString ? `?${queryString}` : ''}`;
};
export const callingWindowUrl = (portalId, queryString) => {
  const subdomainPrefix = enviro.deployed() ? 'app' : 'local';
  return `${getFullUrl(subdomainPrefix)}/calling-window-ui/${portalId}${queryString ? `?${queryString}` : ''}`;
};
export const callingMinutesUsageUpgradeUrl = portalId => {
  return `${getFullUrl('app')}/pricing/${portalId}/bundle`;
};
export const getInboxThreadUrl = ({
  portalId,
  threadId,
  messageId
}) => {
  const subdomainPrefix = enviro.deployed() ? 'app' : 'local';
  return `${getFullUrl(subdomainPrefix)}/live-messages/${portalId}/inbox/${threadId}?focusCallNotesEditor=true&messageId=${messageId}`;
};
export const getCallReviewPageUrl = ({
  portalId,
  crmObjectId
}) => {
  const subdomainPrefix = enviro.deployed() ? 'app' : 'local';
  return `${getFullUrl(subdomainPrefix)}/calls/${portalId}/review/${crmObjectId}`;
};
export const getCallingSettingsUrl = portalId => {
  return `${getFullUrl('app')}/settings/${portalId}/calling`;
};
export const getTaskUrl = ({
  portalId,
  taskId
}) => {
  return `${getFullUrl('app')}/tasks/${portalId}/task/${taskId}`;
};
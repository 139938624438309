const paymentMethodTypeToInvoicePaymentMethodMap = {
  ACH: 'ach',
  BACS: 'bacs',
  CARD: 'credit_or_debit_card',
  PADS: 'pads',
  SEPA: 'sepa'
};
const invoicePaymentMethodToPaymentMethodTypeMap = {
  ach: 'ACH',
  bacs: 'BACS',
  credit_or_debit_card: 'CARD',
  pads: 'PADS',
  sepa: 'SEPA'
};
export const convertPaymentMethodTypesToInvoicePaymentMethods = paymentMethodTypes => {
  return paymentMethodTypes.map(paymentMethod => paymentMethodTypeToInvoicePaymentMethodMap[paymentMethod]).join(';');
};
export const convertInvoicePaymentMethodsToPaymentMethodTypes = paymentMethodsString => {
  return paymentMethodsString.split(';').filter(Boolean).map(paymentMethod => invoicePaymentMethodToPaymentMethodTypeMap[paymentMethod]);
};
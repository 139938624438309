module.exports = {
  "paymentCRMSidebarInteraction": {
    "name": "payment-crm-sidebar-interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "refundActionClicked",
          "refundModalCancelClicked",
          "sendAuthorizationCopyActionClicked",
          "sendAuthorizationCopyModalCancelClicked",
          "deleteActionClicked",
          "deleteModalCancelClicked",
          "seeFeesActionClicked"
        ]
      }
    },
    "namespace": "commerce-sidebar-cards",
    "version": "1"
  },
  "paymentCRMSidebarUsage": {
    "name": "payment-crm-sidebar-usage",
    "class": "usage",
    "properties": {
      "action": {
        "type": [
          "refundModalSubmitClicked",
          "sendAuthorizationCopyModalSubmitClicked",
          "sendReceiptEmailSubmitClicked",
          "sendRefundEmailSubmitClicked",
          "deleteModalSubmitClicked"
        ]
      }
    },
    "namespace": "commerce-sidebar-cards",
    "version": "1"
  }
};
import { registerQuery } from 'data-fetching-client';
export const FETCH_BLOCK_LIST = registerQuery({
  fieldName: 'blockList',
  // TODO This needs to come from the BE
  // https://git.hubteam.com/HubSpot/Invoices/issues/1090

  fetcher: () => {
    const blockList = ['hs_billing_period_end_date', 'hs_billing_period_start_date', 'hs_billing_start_delay_days', 'hs_billing_start_delay_months', 'hs_billing_start_delay_type', 'hs_recurring_billing_end_date', 'hs_recurring_billing_period', 'hs_recurring_billing_start_date', 'hs_recurring_billing_terms', 'hs_term_in_months', 'recurringbillingfrequency'];
    return Promise.resolve(blockList);
  }
});
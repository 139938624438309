import { useCallback, useState } from 'react';
import retryUntilSuccess from '../utils/retryUntilSuccess';
import Raven from 'raven-js';
import { useAsyncEffect } from 'react-utils/hooks/useAsyncEffect';
import { trackInteraction } from '../usage-tracker/track';
const useCopyLink = getRequest => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFailed, setIsFailed] = useState(false);
  const [link, setLink] = useState();
  const fetchCopyLink = useCallback(async () => {
    setIsLoading(true);
    setIsFailed(false);
    try {
      const response = await retryUntilSuccess({
        promiseFn: getRequest,
        successCondition: res => !!res,
        maxAttempts: 12,
        delayMs: 250
      });
      setLink(response);
    } catch (error) {
      Raven.captureException(error);
      setIsFailed(true);
      trackInteraction({
        action: 'saw copy link error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [getRequest]);
  useAsyncEffect(() => {
    fetchCopyLink().catch(Raven.captureException);
  }, [fetchCopyLink]);
  return {
    isCopyLinkLoading: isLoading,
    isCopyLinkFailed: isFailed,
    copyLink: link,
    fetchCopyLink: () => {
      fetchCopyLink().catch(Raven.captureException);
    }
  };
};
export default useCopyLink;
import unescapedText from 'I18n/utils/unescapedText';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
export const objectSummaryActionTextParser = action => {
  var _action$objectTypeLab;
  const objectType = (_action$objectTypeLab = action.objectTypeLabel) === null || _action$objectTypeLab === void 0 ? void 0 : _action$objectTypeLab.toLowerCase();
  if (action.objectName) {
    if (action.objectTypeId === '0-29') {
      // Specific for emails
      return unescapedText('copilotToolkit.streamingMessage.objectSummary.marketingEmail', {
        objectName: action.objectName
      });
    }

    // Use object-specific translation keys instead of a generic one with parameters
    const isValidKey = isValidI18nKey(`copilotToolkit.streamingMessage.objectSummary.tellAbout.${objectType}`);
    return unescapedText(isValidKey ? `copilotToolkit.streamingMessage.objectSummary.tellAbout.${objectType}` : `copilotToolkit.streamingMessage.objectSummary.tellAbout.default`, {
      objectName: action.objectName
    });
  }
  if (action.label) {
    const isValidKey = isValidI18nKey(`copilotToolkit.streamingMessage.objectSummary.summarize.${objectType}`);
    return unescapedText(isValidKey ? `copilotToolkit.streamingMessage.objectSummary.summarize.${objectType}` : `copilotToolkit.streamingMessage.objectSummary.summarize.default`, {
      label: action.label
    });
  }
  const isValidKey = isValidI18nKey(`copilotToolkit.streamingMessage.objectSummary.tellAboutUnknown.${objectType}`);
  return unescapedText(isValidKey ? `copilotToolkit.streamingMessage.objectSummary.tellAboutUnknown.${objectType}` : `copilotToolkit.streamingMessage.objectSummary.tellAboutUnknown.default`);
};